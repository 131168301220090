import { useCallback, useMemo } from 'react';
import { IFieldValues } from 'types';
import { IRule } from 'types/Form';

interface IFormRulesParams {
  fieldValues: IFieldValues;
  locationFieldValues?: { [locationId: string]: IFieldValues };
  formRules?: IRule[];
}

export interface IRuleMap {
  [fieldId: string]: boolean;
}

type TConditionalValues = [fieldId: string, value: string | null][];

const operatorCheck = (
  operator: IRule['operator'],
  valueOptions: IRule['valueOptions'] = [],
  conditionFieldValue: string | null
) => {
  switch (operator) {
    case 'is':
      return valueOptions.every((v) => v === conditionFieldValue);
    case 'has':
      return valueOptions.some(
        (v) => v === conditionFieldValue || conditionFieldValue?.includes(v)
      );
    default:
      return valueOptions.some((v) => v === conditionFieldValue);
  }
};

export const useFormRules = ({
  fieldValues,
  locationFieldValues = {},
  formRules = [],
}: IFormRulesParams) => {
  const fieldsToValidate = useMemo(
    () => formRules.map((r) => r?.conditionFieldId),
    [formRules]
  );

  // Narrows down field values to only include key/value pairs that need to be checked for rules
  const getConditionals = useCallback(
    (fieldValues: IFieldValues): TConditionalValues =>
      Object.entries(fieldValues).filter(([fieldId]) =>
        fieldsToValidate.includes(fieldId)
      ),
    [fieldsToValidate]
  );

  // used for single form or estimate-specific fieldValues
  const conditionalValues = useMemo(
    () => getConditionals(fieldValues),
    [fieldValues, getConditionals]
  );

  // used if getting rules for multiple locations' fieldValues
  const locationConditionalValues = useMemo(() => {
    return Object.entries(locationFieldValues).reduce(
      (
        acc: {
          [locationId: string]: TConditionalValues;
        },
        [locationId, fieldValues]
      ) => {
        const conditionals = getConditionals(fieldValues);

        acc[locationId] = conditionals;
        return acc;
      },
      {}
    );
  }, [getConditionals, locationFieldValues]);

  // Checks form rules and conditional value to determine if field can show,
  // Returns key/value pairs of fieldId/canShow
  const getRuleMap = useCallback(
    (conditions: TConditionalValues): IRuleMap => {
      return conditions.reduce(
        (acc: IRuleMap, [conditionFieldId, conditionFieldValue]) => {
          const rules = formRules.filter(
            (r) => r?.conditionFieldId === conditionFieldId
          );
          rules.forEach((r) => {
            const { operator, valueOptions, fieldId } = r;
            const canShow = operatorCheck(
              operator,
              valueOptions,
              conditionFieldValue
            );
            acc[fieldId] = canShow;
          });
          return acc;
        },
        {}
      );
    },
    [formRules]
  );

  // Rule map for single form or estimate-only
  const ruleMap = useMemo(
    () => getRuleMap(conditionalValues),
    [conditionalValues, getRuleMap]
  );

  // Rule maps generated specific to locations within estimate
  const locationRuleMap = useMemo(
    () =>
      Object.entries(locationConditionalValues).reduce(
        (acc: { [locationId: string]: IRuleMap }, [locationId, conditions]) => {
          const ruleMap = getRuleMap(conditions);
          acc[locationId] = ruleMap;
          return acc;
        },
        {}
      ),
    [getRuleMap, locationConditionalValues]
  );

  // Rule keys for single form or estimate-only
  const ruleKeys = useMemo(() => Object.keys(ruleMap), [ruleMap]);

  // Rule keys specific to locations within estimate
  const locationRuleKeys = useMemo(
    () =>
      Object.entries(locationRuleMap).reduce(
        (acc: { [locationId: string]: string[] }, [locationId, ruleMap]) => {
          const keys = Object.keys(ruleMap);
          acc[locationId] = keys;
          return acc;
        },
        {}
      ),
    [locationRuleMap]
  );

  return { ruleMap, ruleKeys, locationRuleMap, locationRuleKeys };
};
