import { useMutation, useQueryClient } from 'react-query';
import { IOfferData } from 'types';
import { offerRequest, queryKeys } from 'utils';

export const useDeleteOffer = () => {
  const queryClient = useQueryClient();
  const allOffersKey = queryKeys().all.offers;
  const { mutate: deleteOffer } = useMutation(
    (offerId: string) =>
      offerRequest({
        method: 'DELETE',
        endpoint: `device-offer-mapper/${offerId}`,
      }),
    {
      onMutate: async (offerId: string) => {
        await queryClient.cancelQueries(allOffersKey);
        const prevOffers =
          queryClient.getQueryData<IOfferData[]>(allOffersKey) || [];
        const newOffers = prevOffers.filter((b) => b.id !== offerId);
        queryClient.setQueryData(allOffersKey, newOffers);
        return { prevOffers };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allOffersKey);
        queryClient.setQueryData(allOffersKey, context?.prevOffers);
      },
      onSettled: () => {
        queryClient.invalidateQueries(allOffersKey);
      },
    }
  );

  return {
    deleteOffer,
  };
};
