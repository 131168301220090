// Packages
import React, { useMemo } from 'react';

// Redux

// Components
import { KiteModal } from '@kite/react-kite';
import { CopyHistoryRow } from 'components';

// Hooks

// Utils

// Types
import { ICopyCloneHistory, ISalesTeamMember } from 'types';

// Styles
import './CopyHistoryModal.scss';
import { useQueryData } from 'hooks';
import { getFormNameByFormId } from 'utils/defaultsAndConstants';

export interface ICopyHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentHistory: ICopyCloneHistory;
  currentHistoryTeamMember?: ISalesTeamMember;
}

/** Displays history item for a copy reponses entry */

const CopyHistoryModal = ({
  isOpen,
  onClose,
  currentHistory,
  currentHistoryTeamMember,
}: ICopyHistoryModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    sourceEstimateId,
    sourceLocationId,
    targetEstimateId,
    targetLocationId,
    formIds,
    actionType,
  } = currentHistory || ({} as ICopyCloneHistory);

  const { currentEstimate, allEstimates } = useQueryData();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const { source, target } = useMemo(() => {
    if (actionType === 'COPY') {
      const sourceLocationName = currentEstimate?.locations.find(
        (l) => l.id === sourceLocationId
      )?.name;
      const targetLocationName = currentEstimate?.locations.find(
        (l) => l.id === targetLocationId
      )?.name;
      return {
        source: { estimate: '', location: sourceLocationName },
        target: { estimate: '', location: targetLocationName },
      };
    } else {
      const sourceEstimateName = allEstimates?.find(
        (e) => e.id === sourceEstimateId
      )?.name;

      const sourceLocationName = allEstimates
        ?.find((e) => e.id === sourceEstimateId)
        ?.locations.find((l) => l.id === sourceLocationId)?.name;

      const targetEstimateName = allEstimates.find(
        (e) => e.id === targetEstimateId
      )?.name;

      const targetLocationName = allEstimates
        ?.find((e) => e.id === targetEstimateId)
        ?.locations.find((l) => l.id === targetLocationId)?.name;
      return {
        source: { estimate: sourceEstimateName, location: sourceLocationName },
        target: { estimate: targetEstimateName, location: targetLocationName },
      };
    }
  }, [
    currentEstimate,
    sourceLocationId,
    sourceEstimateId,
    targetLocationId,
    targetEstimateId,
    allEstimates,
    actionType,
  ]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="copy-history-modal"
      canShow={isOpen && !!currentHistory}
      onHide={onClose}
      title={
        (actionType === 'CLONE' ? 'Clone' : 'Copy') + ' Responses Activity'
      }
    >
      <CopyHistoryRow
        historyData={currentHistory}
        teamMember={currentHistoryTeamMember}
      />
      {actionType === 'CLONE' ? (
        <div className="copy-history-modal__body">
          <p>
            Cloned{' '}
            <strong>
              {formIds
                ? formIds.map((f) => getFormNameByFormId[f]).join(', ')
                : ''}
            </strong>{' '}
            {currentEstimate?.id === sourceEstimateId ? 'to' : 'from'}
          </p>
          {currentEstimate?.id === sourceEstimateId ? (
            <>
              {' '}
              <h2>{target.location}</h2> of <h2>{target.estimate}</h2> from{' '}
              <h2>{source.location}</h2>
            </>
          ) : (
            <>
              {' '}
              <h2>{source.location}</h2> of <h2>{source.estimate}</h2> to{' '}
              <h2>{target.location}</h2>
            </>
          )}
        </div>
      ) : (
        <div className="copy-history-modal__body">
          <p>
            Copied{' '}
            <strong>{formIds ? getFormNameByFormId[formIds[0]] : ''}</strong>{' '}
            from
          </p>
          <h2>{source?.location}</h2> to
          <h2>{target?.location}</h2>
        </div>
      )}
    </KiteModal>
  );
};

export default CopyHistoryModal;
