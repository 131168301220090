// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput, KiteSelect } from '@kite/react-kite';

// Hooks

// Utils
import { handleNumInputKeydown, isValidJsonString } from 'utils';

// Types
import { IMNEInputProps } from 'types';

// Styles
import '../MNEFieldStyles.scss';
import MNERepeatButton from '../MNERepeatButton';

import { licenseTypeOptions, licenseLevelOptions } from 'utils';
import {
  eneLicenseLevelOptions,
  eneLicenseTypeOptions,
} from 'utils/defaultsAndConstants/constants';

type TSelectEdge = [
  select: string,
  quantity: string,
  licenseType: string,
  licenseLevel: string,
  licenseExpiration: string,
  others?: string
];

interface IMNESelectEdgeInputProps {
  sq: TSelectEdge;
  index: number;
  repeatable: boolean;
  isLast: boolean;
  isOnly: boolean;
  onRepeat: () => void;
  onDelete: (index: number) => () => void;
  onChange: (sq: TSelectEdge, index: number) => void;
  label: string;
  id: string;
  inputName: string;
  value: string | null;
  options: string[] | null;
}

/** Custom input for 'select-license' inputType with repeatable inputs on UCQ Field */
const MNESelectEdge = ({ fieldInput, onFieldChange }: IMNEInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    value,
    id,
    inputName,
    options,
    repeatable = true,
    label,
  } = fieldInput;

  const blankSQ: TSelectEdge = useMemo(() => ['', '', '', '', ''], []);
  const defaultValue: TSelectEdge[] = useMemo(() => {
    if (value && value !== 'No') {
      const sqValues: TSelectEdge[] = isValidJsonString(value)
        ? JSON.parse(value)
        : value;
      return sqValues;
    }
    return [blankSQ];
  }, [value, blankSQ]);

  const [selectEdge, setselectEdge] = useState(defaultValue);

  const getCompletedSqs = useCallback(
    (sqs: TSelectEdge[]) => sqs.filter((sq) => sq.every(Boolean)),
    []
  );

  const onChange = useCallback(
    (sq: TSelectEdge, index: number) => {
      const sqCopy = Array.from(selectEdge);
      sqCopy.splice(index, 1, sq);
      setselectEdge(sqCopy);
      const completedSqs = getCompletedSqs([sq]);
      if (completedSqs.length) {
        onFieldChange(id, JSON.stringify(sqCopy));
      }
    },
    [getCompletedSqs, id, onFieldChange, selectEdge]
  );

  const onRepeat = useCallback(() => {
    const newSQs = [...selectEdge, blankSQ];
    setselectEdge(newSQs);
  }, [blankSQ, selectEdge]);

  const onDelete = useCallback(
    (index: number) => () => {
      const sqCopy = Array.from(selectEdge);
      sqCopy.splice(index, 1);
      setselectEdge(sqCopy);
      const completedSqs = getCompletedSqs(sqCopy);
      onFieldChange(id, completedSqs.length ? JSON.stringify(sqCopy) : null);
    },
    [getCompletedSqs, id, onFieldChange, selectEdge]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderSQs = useCallback(() => {
    return selectEdge?.map((sq, i) => {
      return (
        <MNESelectEdgeInput
          key={i}
          sq={sq}
          index={i}
          onDelete={onDelete}
          onRepeat={onRepeat}
          isLast={i === selectEdge.length - 1}
          repeatable={repeatable}
          onChange={onChange}
          isOnly={selectEdge.length === 1}
          label={label}
          id={id}
          inputName={inputName}
          options={options}
          value={value}
        />
      );
    });
  }, [
    selectEdge,
    onDelete,
    onRepeat,
    repeatable,
    id,
    inputName,
    label,
    options,
    value,
    onChange,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setselectEdge(defaultValue);
  }, [defaultValue]);

  // =============================================
  // Return
  // =============================================
  return <div className="mne-field__precheck-container">{renderSQs()}</div>;
};

// CHILD FOR `MNESelectQuantity` BELOW (renders multiple inputs)
const MNESelectEdgeInput = ({
  sq,
  onDelete,
  onRepeat,
  isLast,
  index,
  repeatable,
  onChange,
  isOnly,
  label,
  options,
  value,
  id,
  inputName,
}: IMNESelectEdgeInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [selectEdge, setSelectEdge] = useState(sq);
  //const [selectedOption, setSelectedOption] = useState(sq[0] ? sq[0] : '');
  const [
    select,
    quantity,
    licenseType,
    licenseLevel,
    licenseExpiration,
    otherValue,
  ] = selectEdge;
  const buttonText = `Add ${label.replace(/^\d+\. /, '')}`;
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const selectOptions = useMemo(() => {
    return (
      options?.map((o) => (
        <option key={o} value={o}>
          {o}
        </option>
      )) || []
    );
  }, [options]);

  const selectLicenseType = useMemo(() => {
    if (inputName.toLocaleUpperCase() === 'ENEGENERALEDGE') {
      return eneLicenseTypeOptions?.map((lt) => (
        <option key={lt} value={lt}>
          {lt}
        </option>
      ));
    }
    return licenseTypeOptions?.map((lt) => (
      <option key={lt} value={lt}>
        {lt}
      </option>
    ));
  }, [inputName]);

  const selectLicenseLevel = useMemo(() => {
    if (inputName.toLocaleUpperCase() === 'ENEGENERALEDGE') {
      return eneLicenseLevelOptions?.map((lt) => (
        <option key={lt} value={lt}>
          {lt}
        </option>
      ));
    }
    return licenseLevelOptions?.map((ll) => (
      <option key={ll} value={ll}>
        {ll}
      </option>
    ));
  }, [inputName]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;

      const newSQ: TSelectEdge = [...selectEdge];
      if (name === 'select-edge') {
        if (value.toLowerCase() === 'other') {
          newSQ[5] = '';
        }
        if (value.toLowerCase() !== 'other' && newSQ[5]) {
          newSQ.pop();
        }
        newSQ[0] = value;
      } else if (name === 'quantity') {
        if (parseInt(value) === 0) {
          newSQ[1] = '';
        } else {
          newSQ[1] = value;
        }
      } else if (name === 'license-type') {
        newSQ[2] = value;
      } else if (name === 'license-level') {
        newSQ[3] = value;
      } else if (name === 'license-expiration') {
        newSQ[4] = value;
      } else if (name === 'other') {
        newSQ[5] = value;
      }
      setSelectEdge(newSQ);
      onChange(newSQ, index);
    },
    [index, onChange, selectEdge]
  );
  useEffect(() => {
    setSelectEdge(sq);
  }, [sq]);

  // =============================================
  // Effects
  // =============================================
  // Make sure correct values are in inputs on TQ delete
  useEffect(() => {
    setSelectEdge(sq);
  }, [sq]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-field__select-edge-container">
      <KiteSelect
        name="select-edge"
        id={id}
        value={select}
        onChange={handleChange}
        maxWidth="450px"
        placeholder={select ? '' : 'Make Selection'}
      >
        {selectOptions}
      </KiteSelect>
      {select === 'Other' && (
        <KiteInput
          name="other"
          value={otherValue}
          onChange={handleChange}
          maxWidth="450px"
          placeholder="Others"
        />
      )}
      <KiteInput
        name="quantity"
        value={quantity}
        onChange={handleChange}
        onKeyDown={handleNumInputKeydown}
        maxWidth="100px"
        placeholder="Quantity"
        inputProps={{ type: 'number', min: 1 }}
      />
      <KiteSelect
        name="license-type"
        id={id}
        value={licenseType}
        onChange={handleChange}
        maxWidth="400px"
        placeholder={licenseType ? '' : 'License Type'}
      >
        {selectLicenseType}
      </KiteSelect>
      <KiteSelect
        id={id}
        name="license-level"
        value={licenseLevel}
        onChange={handleChange}
        maxWidth="400px"
        placeholder={licenseLevel ? '' : 'License Level'}
      >
        {selectLicenseLevel}
      </KiteSelect>
      <KiteInput
        name="license-expiration"
        value={licenseExpiration}
        onChange={handleChange}
        maxWidth="150px"
        inputProps={{ type: 'date' }}
        label="License Expiration"
        margin="-20px 1.5px 0px 1.5px"
      />
      {repeatable && (
        <MNERepeatButton
          text={buttonText}
          onRepeat={onRepeat}
          onDelete={onDelete(index)}
          isLast={isLast}
          isOnly={isOnly}
          isAllFieldsAdded={
            selectEdge.every((value) => value !== '') ? true : false
          }
        />
      )}
    </div>
  );
};
export default MNESelectEdge;
