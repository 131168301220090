import React from 'react';
import downloadCSV from 'download-csv';
import { KiteButton } from '@kite/react-kite';

import './CSVButton.scss';
interface DownloadCSVButtonProps {
  jsonData: any; // Array of objects to be converted to CSV
  fileName: string; // Desired file name for the CSV file
}

const CSVButton: React.FC<DownloadCSVButtonProps> = ({
  jsonData,
  fileName,
}) => {
  const handleDownload = () => {
    try {
      // Trigger the download using download-csv
      downloadCSV(jsonData, '', fileName);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    // <button onClick={handleDownload}>
    //   Download CSV
    // </button>
    <div>
      <KiteButton onClick={handleDownload} type="outline">
        Download CSV
      </KiteButton>
    </div>
  );
};

export default CSVButton;
