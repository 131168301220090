// Packages
import React, { useCallback, useMemo, useState } from 'react';

// Components
import { AddPortPill, AutobuildPill, MneEneInstallOptions } from 'components';

// Hooks
import {
  useCalculatePowerAndPorts,
  useQueryData,
  useGenerateISWPricing,
  useSelectedSolutionCheck,
  useUpdateLocation,
} from 'hooks';

// Utils

// Types
import { ISubmission } from 'types';

// Styles
import './MneEneBadgeStatus.scss';
import {
  eneTermFieldId,
  mneAccessPoint,
  mneTermFieldId,
} from 'utils/defaultsAndConstants/constants';

export interface IMneEneBadgeStatusProps {
  submissions?: ISubmission[];
  type?: 'MNE' | 'ENE';
  disableBadge?: boolean;
}

const MneEneBadgeStatus = ({
  submissions = [],
  type = 'MNE',
  disableBadge,
}: IMneEneBadgeStatusProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const [showInstallOption, setShowInstallOption] = useState(false);

  const { currentEstimate, currentLocation } = useQueryData();
  const { isPortOK, isPowerOK, portDiff, powerDiff } =
    useCalculatePowerAndPorts({ submissions, type });
  const { ISWPrice = 0 } = useGenerateISWPricing(submissions, type);
  const { solutionBadgeLabel } = useSelectedSolutionCheck({
    currentLocation,
  });
  const { updateLocation } = useUpdateLocation(currentEstimate?.id);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const termFieldId = type === 'MNE' ? mneTermFieldId : eneTermFieldId;

  const termField = submissions?.find((p) => p.fieldId === termFieldId);

  const rate = 0.008333;

  const { iswValue, paymentOption } = useMemo(() => {
    let paymentOption =
      type === 'MNE'
        ? currentLocation?.mnePaymentOption
        : currentLocation?.enePaymentOption;

    if (paymentOption === null || paymentOption === 'One-time') {
      return { iswValue: ISWPrice, paymentOption: 'One-time' };
    }

    if (
      termField?.answer &&
      (termField?.answer === 'MTM' ||
        Number(paymentOption) > Number(termField?.answer))
    ) {
      paymentOption =
        termField.answer === 'MTM' ? 'One-time' : termField.answer;
      type === 'MNE'
        ? updateLocation({
            ...currentLocation!,
            mnePaymentOption: paymentOption,
          })
        : updateLocation({
            ...currentLocation!,
            enePaymentOption: paymentOption,
          });
    }

    const payment = Math.round(
      (Number(ISWPrice) * rate * (1 + rate) ** Number(paymentOption!)) /
        ((1 + rate) ** Number(paymentOption!) - 1)
    ).toFixed(2);

    return {
      iswValue:
        paymentOption === 'One-time'
          ? ISWPrice
          : Math.round(Number(paymentOption!) * Number(payment)),
      paymentOption,
    };
  }, [ISWPrice, termField, type, currentLocation, updateLocation]);

  const isLocationAutobuild = useMemo(() => {
    return (
      currentEstimate?.locations.find((e) => e.id === currentLocation?.id)
        ?.isAutobuild || false
    );
  }, [currentEstimate, currentLocation]);

  const isLocationENEQuestionReq = useMemo(() => {
    const isENEQuestionanswer = currentEstimate?.submissions
      ?.filter(
        (s) =>
          s.locationId === currentLocation?.id &&
          s.formId === 'dc9ad13c-90e7-45ea-8100-d93672c3c2eb'
      )
      .find((s) => s.answer);
    return (
      currentEstimate?.submissions?.find(
        (s) =>
          s.locationId === currentLocation?.id &&
          s.fieldId === 'd8478da9-4936-4639-8d65-113b03c0541a'
      )?.answer === 'Yes, Fortinet firewall' && !isENEQuestionanswer
    );
  }, [currentEstimate, currentLocation]);

  const sparcname = isLocationAutobuild ? 'No DRT' : 'DRT Required';

  const icbRequiredBudge = useMemo(() => {
    const MR28Submission = submissions?.find(
      (s) =>
        s.locationId === currentLocation?.id && s.fieldId === mneAccessPoint
    );
    let totalMR28 = 0;
    if (MR28Submission?.answer) {
      const answers = JSON.parse(MR28Submission?.answer);
      answers.forEach((a: string[]) => {
        if (a[1] && a[0] === 'MR28') {
          totalMR28 += Number(a[1]);
        }
      });
    }
    if (totalMR28 >= 4) {
      return true;
    }
    return false;
  }, [submissions, currentLocation]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const toggleInstallModal = useCallback(() => {
    setShowInstallOption(!showInstallOption);
  }, [showInstallOption]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-ene-badges" id="mne-ene-badges-id">
      <AutobuildPill isAutobuild={isLocationAutobuild} />
      {icbRequiredBudge ? (
        <AddPortPill name={'ICB Req’d'} isOkay={!icbRequiredBudge} />
      ) : null}
      <AddPortPill
        name={isPowerOK ? 'Power OK' : 'Add Switch'}
        isOkay={isPowerOK}
        value={powerDiff}
        unit="Watts"
      />
      <AddPortPill name="Wifi Good" isOkay={true} />
      <AddPortPill
        name={isPortOK ? 'Port OK' : 'Add Ports'}
        isOkay={isPortOK}
        value={portDiff}
        unit="Ports"
      />
      <AddPortPill name={sparcname} isOkay={isLocationAutobuild} />
      {isLocationENEQuestionReq ? (
        <AddPortPill
          name={'ENE Questions Req’d'}
          isOkay={!isLocationENEQuestionReq}
        />
      ) : null}
      <AddPortPill
        name={solutionBadgeLabel}
        isOkay={solutionBadgeLabel !== 'Solution Not Selected'}
      />

      <b className="mne-ene-badges-isw-price" id="iswPrice">
        <div>
          {paymentOption === 'One-time'
            ? disableBadge
              ? paymentOption + ' NRC'
              : paymentOption
            : paymentOption + ' Month IP'}
        </div>
        <div>
          ISW Price: {!isNaN(Number(ISWPrice)) ? '$' + iswValue : ISWPrice}
        </div>
      </b>

      {!disableBadge && (
        <MneEneInstallOptions
          canShow={showInstallOption}
          onToggle={toggleInstallModal}
          iswPrice={ISWPrice}
          disabled={isNaN(Number(ISWPrice))}
          termField={termField!}
          type={type}
        />
      )}
    </div>
  );
};

export default MneEneBadgeStatus;
