// Packages
import React, { useCallback, useMemo } from 'react';
import { NodeProps, useReactFlow, useNodes } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { NodeLabel } from '..';

// Hooks

// Utils
import { convertCamelCase } from 'utils';

// Types
import { IRCQDesignFlowNode } from 'types';

// Styles
import './BuildingNode.scss';

/** Renders a Building (type: 'bdg') distro node within react-flow chart */

const BuildingNode = ({
  id,
  data: { name, editNode },
  xPos,
  yPos,
}: NodeProps<IRCQDesignFlowNode>) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { setViewport } = useReactFlow();

  const nodes = useNodes<IRCQDesignFlowNode>();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const thisNode = useMemo(() => nodes.find((n) => n.id === id), [id, nodes]);

  const directChildren = useMemo(
    () => nodes.filter((n) => n.parentNode === id),
    [id, nodes]
  );

  const externalSources = useMemo(() => {
    const internalIds = directChildren.map((n) => n.id);
    return nodes.filter(
      (n) =>
        n.data.toNodeIds?.some((toId) => internalIds.includes(toId)) &&
        n.parentNode !== id &&
        n.type === 'idf'
    );
  }, [directChildren, nodes, id]);

  const internalTotals = useMemo(
    () =>
      directChildren.reduce(
        (acc, node) => {
          const {
            data: { phones = 0, switchPorts = 0 },
          } = node;
          acc.phones += phones;
          acc.switchPorts += switchPorts;
          return acc;
        },
        { switchPorts: 0, phones: 0 }
      ),
    [directChildren]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const zoomToNode = useCallback(() => {
    setViewport(
      { x: xPos + 300, y: yPos ? -(yPos - 100) : yPos + 50, zoom: 1 },
      { duration: 800 }
    );
  }, [setViewport, xPos, yPos]);

  const handleEdit = useCallback(() => {
    if (thisNode && editNode) {
      editNode(thisNode);
    }
  }, [thisNode, editNode]);

  // =============================================
  // Render Methods
  // =============================================
  const internalIds = useMemo(() => {
    const idToNum = (id: string) => parseInt(id.slice(1));
    return directChildren.reduce((acc: JSX.Element[], node, i) => {
      const { id, type } = node;
      const label = type === 'mdf' ? id.toUpperCase() : id;

      if (type === 'mdf') {
        acc.push(
          <span key={id} className={`bdg-node__header-id--${type}`}>
            {label}
          </span>
        );

        if (directChildren[i + 1]) {
          acc.push(<span key={`${id},`}>,</span>);
        }

        return acc;
      }

      const prevNode = !i ? node : directChildren.at(i - 1);
      const nextNode =
        i === directChildren.length - 1 ? node : directChildren.at(i + 1);
      const currentIdf = idToNum(id);
      const prevIdf =
        prevNode?.type === 'idf' ? idToNum(prevNode.id) : currentIdf;
      const nextIdf =
        nextNode?.type === 'idf' ? idToNum(nextNode.id) : currentIdf;
      const isFirst = currentIdf === prevIdf;
      const isLast = currentIdf === nextIdf;
      const isOnly = prevIdf === nextIdf;
      const idfPrevInterval = currentIdf - prevIdf;
      const idfNextInterval = nextIdf - currentIdf;

      if (
        isFirst ||
        (!isLast && idfPrevInterval > 1) ||
        (!isLast && idfNextInterval > 1)
      ) {
        acc.push(
          <span key={id} className={`bdg-node__header-id--${type}`}>
            {label}
          </span>
        );
      }

      if (idfNextInterval === 1 && (!idfPrevInterval || idfPrevInterval > 1)) {
        acc.push(<span key={`${id}-`}>-</span>);
      }

      if (idfNextInterval > 1) {
        acc.push(<span key={`${id},`}>,</span>);
      }

      if (isLast && !isOnly) {
        acc.push(
          <span key={id} className={`bdg-node__header-id--${type}`}>
            {label}
          </span>
        );
      }

      return acc;
    }, []);
  }, [directChildren]);

  const externalIds = useMemo(
    () =>
      externalSources?.map((source) => {
        const { id, type = 'idf' } = source;
        return (
          <NodeLabel
            key={id}
            label={`from ${type === 'idf' ? id : id.toUpperCase()}`}
            type={type as NonNullable<IRCQDesignFlowNode['nodeType']>}
          />
        );
      }, {}) || {},
    [externalSources]
  );

  const totals = useMemo(() => {
    return Object.entries(internalTotals).map(([type, total]) => {
      return (
        <div className="bdg-node__total" key={type}>
          {convertCamelCase(type)}: {total}
        </div>
      );
    });
  }, [internalTotals]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="bdg-node" onDoubleClick={zoomToNode}>
      <div className="bdg-node__header">
        <div className="bdg-node__header-left">
          <NodeLabel label={id.toUpperCase()} type="bdg" />
          {externalIds}
          <strong>{name || `Building ${id}`}</strong>
          <KiteButton type="standalone-link" onClick={handleEdit}>
            <KiteIcon name="edit" />
          </KiteButton>
        </div>
        <div className="bdg-node__header-right">
          <div className="bdg-node__header-right-ids">{internalIds}</div>
          {totals}
        </div>
      </div>
    </div>
  );
};

export default BuildingNode;
