// Packages
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks
import { useQueryData } from 'hooks';

// Utils
import { UcQuestionIconsMap } from 'utils';

// Types
import { IField, ISalesTeamMember, ISubmissionHistory } from 'types';

// Styles
import './UCQHistoryRow.scss';

export interface IUCQHistoryRowProps {
  historyData?: ISubmissionHistory | null;
  teamMember?: ISalesTeamMember;
  onClick?: () => void;
  headerField: IField | null;
  onSelectField?: (selectedField: IField) => void;
}

/** Row that displays a change in the UCQHistory drawer */

const UCQHistoryRow = ({
  historyData,
  teamMember,
  onClick,
  headerField,
  onSelectField,
}: IUCQHistoryRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [imgError, setImgError] = useState(false);

  const { currentEstimate } = useQueryData();

  const {
    editedBy: pid,
    formName = '',
    fieldOrder,
    createdAt = '',
    locationId,
    fieldLabel = '',
  } = historyData || ({} as ISubmissionHistory);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationName = useMemo(
    () =>
      currentEstimate?.locations.find((l) => l.id === locationId)?.name ||
      'General',
    [locationId, currentEstimate]
  );

  const { firstName, lastName } = teamMember || {
    firstName: pid,
    lastName: '',
  };

  const displayName = `${firstName} ${
    lastName.length ? lastName.split('')[0] + '.' : ''
  }`;

  const formatCreatedAt = dayjs(createdAt).format('hh:mm A');

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="ucq-history-row__avatar--generic"
          size="30px"
        />
      );
    return (
      <img
        src={teamMember.imageURI}
        alt={`${teamMember.fullName} avatar`}
        className="ucq-history-row__avatar"
        onError={() => setImgError(true)}
      />
    );
  }, [teamMember, imgError]);

  const rowContent = useMemo(
    () =>
      formName !== 'Discovery Questions/Notes' &&
      formName !== 'General' &&
      formName !== 'MNE Opportunity Type' &&
      formName !== 'MNE Network Draft' &&
      formName !== 'MNE Discovery Questions/Notes' &&
      formName !== 'MNE Hospitality' &&
      formName !== 'ENE Discovery Questions/Notes' &&
      formName !== 'ENE Opportunity Type' ? (
        <div className="ucq-history-row__content">
          <div className="ucq-history-row__title">
            {avatarImage} <span>{displayName}</span>{' '}
            <span
              className="ucq-history-row__title--location"
              title={locationName}
            >
              {locationName}
            </span>
          </div>
          <div className="ucq-history-row__sub-title">
            <KiteIcon
              className="ucq-history-row__sub-title__icon"
              name={
                fieldLabel === 'Notes' || fieldOrder === 100
                  ? 'info-circle-f'
                  : UcQuestionIconsMap[
                      formName === 'Network Draft' ||
                      formName === 'General Discovery'
                        ? formName
                        : headerField?.header!
                    ]
              }
            />
            <span>
              {formName === 'Network Draft' || formName === 'General Discovery'
                ? formName
                : headerField?.header}
            </span>

            <span className="ucq-history-row__sub-title--question-change">
              {(formName === 'Network Draft' ||
                formName === 'General Discovery') &&
              fieldLabel !== 'Notes'
                ? `Question ${fieldOrder} changed`
                : headerField?.order &&
                  fieldLabel !== 'Notes' &&
                  fieldOrder - headerField?.order
                ? `Question ${
                    Math.round((fieldOrder - headerField?.order) * 10) / 10
                  } changed`
                : fieldLabel === 'Notes'
                ? 'Notes changed'
                : headerField?.order && !(fieldOrder - headerField?.order)
                ? 'Section Complete Question changed'
                : ''}
            </span>
            <span className="ucq-history-row__sub-title--time">
              {formatCreatedAt}
            </span>
          </div>
        </div>
      ) : null,
    [
      avatarImage,
      locationName,
      displayName,
      fieldOrder,
      formName,
      formatCreatedAt,
      fieldLabel,
      headerField,
    ]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    rowContent && (
      <div className="ucq-history-row">
        {onClick ? <button onClick={onClick}>{rowContent}</button> : rowContent}
      </div>
    )
  );
};

export default UCQHistoryRow;
