// Packages
import React, { useCallback, useState } from 'react';

// Hooks

// Components
import { KiteAlert, KiteButton, KiteCard } from '@kite/react-kite';
import {
  AutobuildPill,
  AutosaveBadge,
  LocationsDrawer,
  UcProgressButton,
  RcProgressButton,
  ENEProgressButton,
} from 'components';

// Utils

// Styles
import './CustomerBar.scss';
import { useParams } from 'react-router-dom';
import MNEProgressButton from 'components/MneProgressButton';

export interface ICustomerBarProps {
  /** Current company name */
  companyName: string;
  /** Current opportunity name. If opportunity name is falsy, a "no linked opportunity" warning will be displayed */
  opportunityName: string;
  /** Determines type of autobuild pill to display */
  isAutobuild?: boolean;
}

/** A bar with button controls for the PQE estimator */

const CustomerBar = ({
  companyName = '',
  opportunityName = '',
  isAutobuild = true,
}: ICustomerBarProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();
  const [locDrawerIsOpen, setLocDrawerIsOpen] = useState(false);

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleLocationDrawer = useCallback(() => {
    setLocDrawerIsOpen(!locDrawerIsOpen);
  }, [locDrawerIsOpen, setLocDrawerIsOpen]);
  // =============================================
  // Render Methods
  // =============================================
  const renderControls = useCallback(() => {
    const autobuildPill = opportunityName ? (
      <AutobuildPill isAutobuild={isAutobuild} />
    ) : null;

    return (
      <div className="customer-bar__controls">
        <AutosaveBadge />
        {autobuildPill}
        <KiteButton
          maxWidth="min-content"
          minWidth="min-content"
          onClick={toggleLocationDrawer}
          type="outline"
        >
          Locations
        </KiteButton>
        <UcProgressButton />
        <RcProgressButton />
        <MNEProgressButton />
        <ENEProgressButton />
      </div>
    );
  }, [opportunityName, isAutobuild, toggleLocationDrawer]);

  const renderLabel = useCallback(() => {
    return !opportunityName ? (
      <KiteAlert
        level="page"
        description="There is no opportunity attached to this Estimate."
        type="caution"
      />
    ) : (
      <div className="customer-bar__label">
        <span className="customer-bar__label-subheader">{opportunityName}</span>
        <span className="customer-bar__label-header">{companyName}</span>
      </div>
    );
  }, [opportunityName, companyName]);

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <KiteCard className="customer-bar">
        {renderLabel()}
        {renderControls()}
      </KiteCard>
      <LocationsDrawer
        isOpen={locDrawerIsOpen}
        onClose={toggleLocationDrawer}
        isSandbox={estimateId === 'sandbox'}
      />
    </>
  );
};

export default CustomerBar;
