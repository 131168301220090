// Packages
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';

// Utils
import {
  _3MonthsPromoCheck,
  display$0Price,
  inFootprintIswId,
  outOfFootprintIswId,
  rcIswId,
  rcPhonesAndDevicesId,
  AlgoOnSiteProductId,
  AlgoRemoteProductId,
} from 'utils';

// Types
import { ILocationSelection, IPromo } from 'types';

// Styles
import './PricingTableRow.scss';

interface IPricingTableRowProps {
  selection: ILocationSelection;
  isSubRow: boolean;
  appliedPromos: IPromo[];
}

const PricingTableRow = ({
  selection,
  isSubRow,
  appliedPromos,
}: IPricingTableRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { allProductFamilies, currentProductFocus, currentSelections } =
    useQueryData();
  const { generateProductTotals } = useGenerateProductTotals();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const productFamily = useMemo(() => {
    return allProductFamilies.find((f) => f.id === selection.familyId);
  }, [allProductFamilies, selection.familyId]);

  const isPromoProduct = useCallback(
    (promo: IPromo) => {
      if (promo.id === 'd4cf26a3-5785-427a-905a-7705dc00df6b') {
        return _3MonthsPromoCheck({ promo, currentSelections, selection });
      }
      const { includedFamilyIds, includedProductIds, excludedProductIds } =
        promo;

      if (excludedProductIds.includes(selection.productId)) {
        return false;
      }
      if (includedFamilyIds.includes(productFamily!.id)) {
        // Promo only applies to UC Connect w/ Webex if
        // there are at least 5 seats configured
        if (selection.familyId === 'c886b29b-20f4-4244-9c29-2ccf415ce44b') {
          return !(selection.quantity < 5);
        }
        return true;
      }
      if (includedProductIds.includes(selection.productId)) {
        return true;
      }
    },
    [selection, productFamily, currentSelections]
  );

  const rowTitle = useMemo(() => {
    const { name } = selection;
    const promoStar = appliedPromos.find((promo) => isPromoProduct(promo))
      ? ' *'
      : '';
    // Display quantity if the product uses a number input
    const quantity =
      productFamily?.type === 'number' ? `${selection.quantity} ` : '';

    if (
      selection.productId === inFootprintIswId ||
      selection.productId === outOfFootprintIswId
    ) {
      const ipPlan =
        selection.eipTerm !== 'One-time Payment'
          ? `${selection.eipTerm} Month Installment`
          : selection.eipTerm;
      return `Inside Wiring Service (${ipPlan})`;
    }

    if (selection.profileId || (isSubRow && !quantity)) {
      return name + promoStar;
    } else if (isSubRow && quantity) {
      return `${quantity} x ${name} ${promoStar}`;
    }

    return `${productFamily?.name}: ${quantity}${name} ${promoStar}`;
  }, [selection, isSubRow, productFamily, isPromoProduct, appliedPromos]);

  const isCurrentFocus = useMemo(() => {
    if (productFamily && !selection.profileId) {
      return currentProductFocus.includes(productFamily.name);
    } else {
      return false;
    }
  }, [currentProductFocus, productFamily, selection.profileId]);

  // =============================================
  // Render Methods
  // =============================================
  const tableValues = useMemo(() => {
    return generateProductTotals({
      selections: [selection],
    });
  }, [generateProductTotals, selection]);

  const tableValuesNum = () => {
    return Number(tableValues.rateNrc.toString().replace(/[^0-9.-]+/g, ''));
  };

  const isEIPSelection =
    ((selection.familyId === rcPhonesAndDevicesId ||
      selection.productId === rcIswId) &&
      selection.eipTerm &&
      selection.eipTerm !== 'Purchase' &&
      selection.productId !== AlgoRemoteProductId &&
      selection.productId !== AlgoOnSiteProductId) ||
    false;

  const isIPSelection =
    ((selection.productId === inFootprintIswId ||
      selection.productId === outOfFootprintIswId) &&
      selection.eipTerm &&
      selection.eipTerm !== 'One-time Payment') ||
    false;

  const eipOrIpNotes = isEIPSelection ? (
    <i>
      ${(tableValuesNum() / Number(selection.eipTerm)).toFixed(2)}/month for{' '}
      {selection.eipTerm}m
    </i>
  ) : isIPSelection ? (
    <i>
      ${(tableValuesNum() / Number(selection.eipTerm)).toFixed(2)} / month for{' '}
      {selection.eipTerm} months
    </i>
  ) : (
    '-'
  );
  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <tr
      className={classNames({
        'pricing-table-row': !isSubRow,
        'pricing-table-row--addon': isSubRow,
        'pricing-table-row--focus': isCurrentFocus,
      })}
      data-testid={`${selection.name} ${selection.quantity}`}
    >
      <td className="pricing-table-row__title">{rowTitle}</td>
      <td>{tableValues.rateMrc}</td>
      <td>
        {display$0Price.includes(selection.name) ? '$0' : tableValues.rateNrc}
      </td>
      <td>{tableValues.solutionMrc}</td>
      <td>
        {display$0Price.includes(selection.name)
          ? '$0'
          : tableValues.solutionNrc}
      </td>
      <td>{eipOrIpNotes}</td>
    </tr>
  );
};

export default PricingTableRow;
