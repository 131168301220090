// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteAlert, KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import { CopyModal } from 'components';

// Hooks
import { useRenderFields, useQueryData, useScrollToTop } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { renderFormIcon } from 'utils';

// Types
import { IForm, ISubmission } from 'types';

// Styles
import './UCQForm.scss';

export interface IUCQFormProps {
  /** Current form data */
  form: IForm;
  /** Submission data for current form */
  formSubmissions: ISubmission[];
  /** Change handler for field focus, updates help text in layout */
  onFocusChange: (helpString: string) => void;
  allSubmissions?: ISubmission[];
  estimate: any;
}

const defaultShareText = {
  label: 'Share With Team',
  icon: 'share-f',
};
/** Renders currently selected form within UCQuestions page*/

const UCQForm = ({
  form,
  onFocusChange,
  formSubmissions,
  allSubmissions,
  estimate,
}: IUCQFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { name, fields, id } = form;
  const { currentEstimate } = useQueryData();

  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [displayCopyError, setDisplayCopyError] = useState(false);
  const [selectedLocations, setSelectedLocation] = useState([]);

  const [helpHtml, setHelpHtml] = useState('');
  const [shareText, setShareText] = useState(defaultShareText);
  const { locationId = '', formName } = useParams();

  const {
    fieldComponents,
    currentFocusId,
    requiredFieldIds,
    requiredSubmissions,
    updateSubmissionsError,
    onCopy,
    copySubmissionsError,
  } = useRenderFields({ fields, submissions: formSubmissions, formId: id });

  const hasIncomplete = requiredSubmissions.length !== requiredFieldIds.length;

  /*
  removed this code as part of performance enhancement for submissions api calls
  const { data: importSubmissions } = useGetSubmissions({
    params: { estimateId, locationId: importLocationId },
  });*/

  const ref = useRef<HTMLFormElement>(null);
  useScrollToTop({ ref, refNestLevel: 0, form });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const otherLocations = useMemo(
    () => (currentEstimate?.locations || []).filter((l) => l.id !== locationId),
    [currentEstimate, locationId]
  );

  const showCopyBtn =
    formName !== 'network-draft' &&
    locationId !== 'general' &&
    !!otherLocations.length;

  const submissionsToCopy = useMemo(() => {
    return !copyModalOpen ? [] : formSubmissions.filter((s) => s.formId === id);
  }, [copyModalOpen, formSubmissions, id]);

  const showHelpBtn = formName !== 'network-draft';
  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    // could add manual save here if needed
    e.preventDefault();
    // saveSubmissions(submissionsToSave);
  }, []);

  const toggleCopyModal = useCallback(() => {
    setSelectedLocation([]);
    setCopyModalOpen(!copyModalOpen);
  }, [copyModalOpen]);

  const handleLocationSelections = useCallback(
    (selections: any) => {
      setSelectedLocation(selections);
      if (displayCopyError) {
        setDisplayCopyError(false);
      }
    },
    [displayCopyError]
  );

  const handleCopy = useCallback(() => {
    if (selectedLocations.length) {
      if (submissionsToCopy.length) {
        onCopy(submissionsToCopy, selectedLocations);
      }
      toggleCopyModal();
    } else {
      setDisplayCopyError(true);
    }
  }, [onCopy, submissionsToCopy, toggleCopyModal, selectedLocations]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Render help text in parent (UCQLayout) component
    const helpHtml =
      fields.find((f) => f.id === currentFocusId)?.helpText || '';
    onFocusChange(helpHtml);
  }, [currentFocusId, fields, onFocusChange]);

  const onShareClick = useCallback(() => {
    navigator.clipboard.writeText(document.location.href).then(() => {
      if (estimate?.webexSpaceUrl) {
        setTimeout(() => {
          window.open(estimate.webexSpaceUrl, '_blank');
        }, 1000);
      }
    });
    setShareText({ label: 'Link Copied', icon: 'checkmark' });
  }, [estimate]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="ucq-form">
      <div className="ucq-form__header-container">
        <div className="ucq-form__header">
          {renderFormIcon(form.name, '24px')}
          <h2>{name}</h2>
          <span className="ucq-form__header-complete">{`${requiredSubmissions.length}/${requiredFieldIds.length}`}</span>
        </div>
        <div className="ucq-form__header-btns">
          {hasIncomplete && (
            <div className="ucq-field__incomplete">
              Incomplete Answer:{' '}
              <div className="ucq-field__incomplete-marker"></div>
            </div>
          )}
          {showHelpBtn && (
            <>
              <div className="ucq-field__incomplete">
                <KiteIcon
                  name="question-circle-f"
                  size="22px"
                  color="#0073d1"
                />{' '}
                Help
              </div>
              {
                <KiteButton
                  maxWidth="none"
                  minWidth="min-content"
                  leftIcon={shareText.icon}
                  onClick={onShareClick}
                >
                  {shareText.label}
                </KiteButton>
              }
            </>
          )}
          {showCopyBtn && (
            <CopyModal
              formName={name}
              canShow={copyModalOpen}
              locations={otherLocations}
              onCopy={handleCopy}
              onToggle={toggleCopyModal}
              onHandleLocationSelections={handleLocationSelections}
              disabled={false}
              displayError={displayCopyError}
            />
          )}
        </div>
      </div>
      {updateSubmissionsError && (
        <KiteAlert
          type="alert"
          title="Save Error"
          description="Something went wrong saving your answers. Any changes will not be saved. Please refresh and try again later."
        />
      )}
      {copySubmissionsError && (
        <KiteAlert
          type="alert"
          title="Copy Responses Error"
          description="Something went wrong with copying your answers. Please refresh and try again later."
        />
      )}
      <form
        ref={ref}
        onSubmit={handleSubmit}
        className={classNames({
          'ucq-form__fields': name !== 'Network Draft',
          'ucq-form__fields--flow-chart': name === 'Network Draft',
        })}
      >
        {fieldComponents}
      </form>
    </KiteCard>
  );
};

export default UCQForm;
