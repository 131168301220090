// Packages
import React, { useEffect, useMemo } from 'react';

// Components
import { KiteLoader } from '@kite/react-kite';

// Hooks
import { useAnalytics, useGetEstimateDetails } from 'hooks';
import { useParams } from 'react-router-dom';

// Types
import { MNEFormsRequest } from 'types';

// Styles
import './ENEQuestionsPage.scss';
import { ENEFORMS } from 'utils/defaultsAndConstants/constants';

import { MNEuseGetForms } from 'hooks/apiHooks/useGetForms';
import { ENELayout } from 'components/pageLayout';

const ENEQuestionsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { estimateId, locationId } = useParams();
  const {
    estimateData,
    estimateLoading,
    error: estimateError,
  } = useGetEstimateDetails(estimateId);

  const formsQueryParams: MNEFormsRequest['params'] = useMemo(() => {
    if (locationId === 'general') {
      return { location: 0, type: ENEFORMS };
    }
    return { location: 1, type: ENEFORMS };
  }, [locationId]);

  const {
    data: formsData,
    isLoading: formsLoading,
    error: formsError,
  } = MNEuseGetForms({
    params: formsQueryParams,
  });

  formsData?.map((form) => {
    if (form.name === 'Network Draft') {
      form.id = '818622d3-af38-4ffa-b000-5c971371fb92';
    }
    return form;
  });

  formsData?.map(
    (form) =>
      form.name === 'Network Draft' &&
      form.fields.map(
        (formField) => (formField.id = '0f55ba91-8126-4c74-ad89-0229f5ff42f4')
      )
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const isError = !!formsError || !!estimateError;

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('ENEQuestionsPage'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================
  if (estimateLoading && formsLoading) {
    return <KiteLoader className="ene-questions-page__loader" />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <ENELayout
      forms={formsData}
      estimate={estimateData}
      formsLoading={formsLoading}
    />
  );
};

export default ENEQuestionsPage;
