import axios from 'axios';
import { IRequestConfig, IRequestParams } from 'types';
import { env } from './env';
import { throwQueryError } from './throwQueryError';
import { ISWZipAndCostCodes } from 'types/Location';
import qs from 'qs';

interface IZipCodeRequestParams extends IRequestParams {
  zipcodes: string[];
}

export interface IZipCodeRequest extends IRequestConfig<ISWZipAndCostCodes> {
  params: IZipCodeRequestParams;
}

export const zipCodeRequest = async (config?: IZipCodeRequest) => {
  const {
    endpoint = 'isw-cost-zip-codes/isw/zipcodes',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with fields ${method}`,
      })
    );
};
