import { IForm, MForm, RForm } from 'types';

export const mockFormData: IForm = {
  name: 'General Discovery',
  id: '3da882a6-3305-4f94-bc77-1f223838b250',
  createdAt: '2022-06-23T19:35:13.285Z',
  updatedAt: '2022-06-23T19:35:13.285Z',
  type: 'AEE - MS - SE',
  fields: [
    {
      inputName: 'opportunity',
      label:
        'Does this customer have ACTIVE Spectrum Unified Communications already installed or in process at any other locations today? If So, you MUST provide opportunity that this will be tied to.',
      inputType: 'text',
      order: 1,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: 'Test Header',
      subtext:
        'This is an `isPrecheck` field, renders an initial check before input value appears (reduces chance for user error + amount of rules needed). `isPrecheck` is NOT a rule & is contextual based off the actual question. Use best judgement when applying `isPrecheck` in dev.',
      tooltip: null,
      options: null,
      helpText: 'Data available in SF',
      id: '54b998c7-996a-4251-80e6-f7d8f0383d1e',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      isPrecheck: true,
      rules: [],
    },
    {
      inputName: 'testSubQuestion',
      label: 'Test subquestion w/ date input (order is between 1 - 2)',
      inputType: 'date',
      order: 1.1,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: 'test subtext',
      tooltip: null,
      options: null,
      helpText: null,
      id: '12345',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testNumberText',
      label: 'Test number-text w/ precheck',
      isPrecheck: true,
      inputType: 'number-text',
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      order: 1.2,
      header: null,
      subtext: null,
      tooltip: null,
      options: ['0', '20'],
      helpText: null,
      id: '1234547',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testNumberCopy',
      label: 'Test number-copy w/ precheck',
      inputType: 'number-copy',
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      isPrecheck: true,
      order: 1.3,
      header: null,
      subtext: null,
      tooltip: null,
      options: ['0', '300'],
      helpText: null,
      id: '123454',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },

    {
      inputName: 'testTextQuantity',
      label: 'Test text-quantity w/ precheck (external intercoms question)',
      inputType: 'text-quantity',
      order: 1.01,
      isPrecheck: true,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      helpText: null,
      repeatable: true,
      id: '123456',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testFileUpload',
      label: 'Test "file" upload inputType (TCO Document)',
      inputType: 'file',
      order: 1.02,
      isPrecheck: true,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      helpText: null,
      id: '12345699988880000',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testImageUpload',
      label: 'Test "image" upload inputType (google maps image)',
      inputType: 'image',
      order: 1.02,
      isPrecheck: true,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      helpText: null,
      id: '123456999999113333',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testTextCopy',
      label: 'Test text-copy',
      inputType: 'text-copy',
      order: 1.4,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      helpText: null,
      id: '123455',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'lanDesign',
      label: 'Use to test rules (select "Customer Provided")',
      inputType: 'select',
      order: 2,
      formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
      header: null,
      subtext: null,
      tooltip: null,
      options: [
        'Dedicated LAN(dual drop = UC Switches)',
        'Shared LAN(single drop = UC Switches)',
        'Customer Provided(Provide switch Make/Model be used)',
        'Managed LAN (MNE -MS switches MUST be used)',
      ],
      required: true,
      helpText:
        '<ul><li>Required technical data</li>\n    <li>May require new field or field modification</li></ul>',
      id: 'd8c1ac05-d3cd-4077-98d4-a404553748d6',
      createdAt: '2022-06-28T17:09:26.702Z',
      updatedAt: '2022-06-28T17:09:26.702Z',
      rules: [],
    },
    {
      inputName: 'customerSwitches',
      label:
        'This should show up for the rule if "Customer Provided" is above value',
      inputType: 'text',
      order: 2.1,
      formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      required: true,
      helpText: 'Customer Switch Data',
      id: '731fb92f-aaaf-48d4-ad54-98364e7f6206',
      createdAt: '2022-06-28T17:09:26.702Z',
      updatedAt: '2022-06-28T17:09:26.702Z',
      rules: [
        {
          operator: 'is',
          valueOptions: [
            'Customer Provided(Provide switch Make/Model be used)',
          ],
          fieldId: '731fb92f-aaaf-48d4-ad54-98364e7f6206',
          conditionFieldId: 'd8c1ac05-d3cd-4077-98d4-a404553748d6',
          id: '24827c48-bc43-465b-961d-60f39dc7c6cd',
          createdAt: '2022-06-28T17:09:26.704Z',
          updatedAt: '2022-06-28T17:09:26.704Z',
        },
      ],
    },
    {
      inputName: 'unityCrm',
      label:
        'If Unity CRM integration is being requested for any user what will they be using?',
      inputType: 'select',
      order: 3,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: 'Test tooltip',
      options: [
        'Salesforce CRM',
        'Zendesk CRM',
        'Agile CRM',
        'Zoho',
        'Sugar',
        'No Unity CRM Integration',
      ],
      helpText: null,
      id: '16d60c3c-12ca-428f-93cd-31d49cf8f08a',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
    {
      inputName: 'testNotes',
      label: 'Test label for notes',
      inputType: 'text-area',
      order: 4,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      header: null,
      subtext: null,
      tooltip: null,
      options: null,
      helpText: null,
      id: '123',
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      rules: [],
    },
  ],
};

export const mockFormDataRC: RForm = {
  name: '',
  id: '',
  createdAt: '2022-06-23T19:35:13.285Z',
  updatedAt: '2022-06-23T19:35:13.285Z',
  type: 'Ring Central',
  fields: [],
};

export const mockFormDataMNE: MForm = {
  name: '',
  id: '',
  createdAt: '2022-06-23T19:35:13.285Z',
  updatedAt: '2022-06-23T19:35:13.285Z',
  type: 'MNE General',
  order: 8,
  fields: [
    {
      rules: [],
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      inputName: 'mneTest',
      label: 'Test-Label',
      inputType: 'select-quantity',
      order: 15,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      subtext: null,
      tooltip: null,
      header: null,
      options: [
        'MS120-8',
        'MS120-8 POE',
        'MS120-24 POE',
        'MS125-48 POE',
        'MS210-24-POE',
        'MS210-48-POE',
      ],
      helpText: null,
      repeatable: true,
      warningMessage: null,
      required: false,
      isPrecheck: false,
      validationType: null,
      noAutobuildOptions: null,
      id: '123',
    },
    {
      rules: [],
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      inputName: 'mneTest1',
      label: 'Test-Label1',
      inputType: 'multiple-selection',
      order: 14,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      subtext: null,
      tooltip: null,
      header: null,
      options: ['MX68', 'MX85', 'MX105', 'MX250', 'MX450'],
      helpText: null,
      repeatable: true,
      warningMessage: null,
      required: false,
      isPrecheck: false,
      validationType: null,
      noAutobuildOptions: null,
      id: '456',
    },
    {
      rules: [],
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      inputName: 'mneTest2',
      label: 'Test-Label2',
      inputType: 'select-general-edge',
      order: 17,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      subtext: null,
      tooltip: null,
      header: null,
      options: ['MX68', 'MX85', 'MX105', 'MX250', 'Other'],
      helpText: null,
      repeatable: true,
      warningMessage: null,
      required: false,
      isPrecheck: false,
      validationType: null,
      noAutobuildOptions: null,
      id: '789',
    },
    {
      rules: [],
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      inputName: 'mneTest3',
      label: 'Test-Label3',
      inputType: 'select-general-switch',
      order: 18,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      subtext: null,
      tooltip: null,
      header: null,
      options: [
        'MS120-8',
        'MS120-8 POE',
        'MS120-24 POE',
        'MS125-48 POE',
        'Other',
      ],
      helpText: null,
      repeatable: true,
      warningMessage: null,
      required: false,
      isPrecheck: false,
      validationType: null,
      noAutobuildOptions: null,
      id: '234',
    },
    {
      rules: [],
      createdAt: '2022-06-23T19:35:13.287Z',
      updatedAt: '2022-06-23T19:35:13.287Z',
      inputName: 'mneTest4',
      label: 'Test-Label4',
      inputType: 'check-box',
      order: 1,
      formId: '3da882a6-3305-4f94-bc77-1f223838b250',
      subtext: null,
      tooltip: null,
      header: 'General',
      options: null,
      helpText: null,
      repeatable: false,
      warningMessage: null,
      required: true,
      isPrecheck: false,
      validationType: null,
      noAutobuildOptions: null,
      id: '567',
    },
  ],
};

export const mockFormList: IForm[] = [
  {
    name: 'General Discovery',
    id: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [
      {
        inputName: 'opportunity',
        label:
          'Does this customer have <strong>ACTIVE</strong> Spectrum Unified Communications already installed or in process at any other locations today? If So, you MUST provide opportunity that this will be tied to.',
        inputType: 'text',
        order: 1,
        formId: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText:
          '<p>Data available in SF</p>\n      <p>If this is an existing customer or if there is a current</p>\n      <p>Opportunity for them that information is in SF</p>',
        id: '524344d2-67bd-4429-9c24-8d8a18040cea',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        isPrecheck: true,
        rules: [],
      },
      {
        inputName: 'unityCrm',
        label:
          'If Unity CRM integration is being requested for any user what will they be using?',
        inputType: 'select',
        order: 2,
        formId: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Salesforce CRM',
          'Zendesk CRM',
          'Agile CRM',
          'Zoho',
          'Sugar',
          'No Unity CRM Integration',
        ],
        required: true,
        helpText: null,
        id: 'e09b137e-ff8f-4daf-ae6a-a93f3b483d6c',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'TCO',
        label: 'Has a TCO been completed for this opportunity?',
        inputType: 'select',
        order: 3,
        formId: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
        header: null,
        subtext: null,
        tooltip: null,
        options: ['Yes', 'No'],
        required: true,
        helpText: null,
        id: '88ab691f-7a4f-47aa-ac14-55f2a463ae7c',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'Omni or hoteling',
        label:
          '4. Will the customer require our <strong>Omni Channel</strong>  or <strong>Hoteling</strong> feature?',
        inputType: 'select',
        order: 4,
        formId: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
        header: null,
        subtext: null,
        tooltip: null,
        options: ['Yes', 'No'],
        required: true,
        helpText: null,
        id: '6b889e3b-9303-431e-aa73-22f87faa5bd8',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'notes',
        label: 'Notes',
        inputType: 'text-area',
        order: 100,
        formId: '1286ecfa-d980-40ed-9796-353aa07dc4f2',
        header: null,
        subtext: '(optional)',
        tooltip: null,
        options: null,
        required: false,
        helpText: null,
        id: '4e5ee16e-ab15-4f29-b67c-57ca0f6f9568',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'Intro',
    id: '3f42d9f5-0863-45e0-88e6-92c8298d9026',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [
      {
        inputName: 'mainT',
        label: 'Customer Main TN (Billing TN)',
        inputType: 'text',
        order: 1,
        formId: '3f42d9f5-0863-45e0-88e6-92c8298d9026',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText: 'Data is available in SF',
        id: '75fe81a9-3cef-491d-97d2-58d6dbff6bf3',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'tollFreeNumbers',
        label: 'Are there any toll free phone numbers?',
        inputType: 'text',
        order: 2,
        formId: '3f42d9f5-0863-45e0-88e6-92c8298d9026',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText:
          '<ul>\n    <li>Addition to PQE v7.0</li>\n      <li>Data available in SF</li>\n      <li>(Confirmation Requirement)</li>\n      </ul>',
        id: 'a92ee484-5712-4380-beec-4b0bb0847bad',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'Discovery',
    id: 'd8fd752e-3a5d-4101-b7a2-6b9abc853f32',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [
      {
        inputName: 'carrier',
        label: 'What Spectrum Carrier will we be utilizing?',
        inputType: 'select',
        order: 1,
        formId: 'd8fd752e-3a5d-4101-b7a2-6b9abc853f32',
        header: null,
        subtext: null,
        tooltip: null,
        options: ['BHN', 'CHTR', 'TWC', 'BW.COM'],
        required: true,
        helpText: 'Addition to PQE v7.0',
        id: 'bb02fb18-5fcc-41cc-9cc9-b866d8e93671',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'remoteCallForwarding',
        label:
          "Is the customer aware of any TN's being RCF'd (Remote Call Forwarding)?",
        inputType: 'select',
        order: 2,
        formId: 'd8fd752e-3a5d-4101-b7a2-6b9abc853f32',
        header: null,
        subtext: null,
        tooltip: null,
        options: ["Yes - Must see 2 Rate Center Validation TN's below", 'No'],
        required: true,
        helpText: 'Addition to PQE v7.0',
        id: '70bc12a3-3522-4de3-bbfd-ebaf3e09168c',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'SE Discovery',
    id: 'c39390ad-9ceb-4723-bd0c-0c3bae68084b',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [
      {
        inputName: 'disasterRecoveryPlan',
        label: 'What is the current Voice Disaster Recovery Plan?',
        inputType: 'text',
        order: 1,
        formId: 'c39390ad-9ceb-4723-bd0c-0c3bae68084b',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText: 'Customer current state and future request',
        id: 'd4882eba-6e70-4a63-bdac-28ba10c95080',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'DualWan',
        label: 'Does the customer require Dual Wan or High Availability?',
        inputType: 'select',
        order: 2,
        formId: 'c39390ad-9ceb-4723-bd0c-0c3bae68084b',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Dual WAN - Note DRT',
          'High Availability - Note DRT',
          'Dual WAN + HA - Note DRT',
        ],
        required: true,
        helpText: 'Required technical data',
        id: 'd056fa37-a252-476b-b1f4-a75dba4c614b',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'Workflow',
    id: '8cea867c-9c63-4473-959d-138bbed9808c',
    createdAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    updatedAt: '2022-06-28T17:09:26.701Z',
    fields: [
      {
        inputName: 'transportType',
        label: 'What transport type will UC utilize?',
        inputType: 'select',
        order: 1,
        formId: '8cea867c-9c63-4473-959d-138bbed9808c',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Spectrum Softphone Only(No SPARC or ISW Survey)',
          'Spectrum Fiber',
          'Spectrum DOCSIS (no more than 30 Seats allowed HVoD:)',
          'Type II = Non-AB',
          'OTT (Customer Provide Transport) = Non- AB',
          'Finished Internet = Non -AB ',
        ],
        required: true,
        helpText: 'Product name indicates transport type, also Special routing',
        id: '688a37bf-2cc1-4aef-8e82-568ba173eabb',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'dealParameters',
        label:
          'Does this qualify per Deal Parameters as Bundled UC, \n    Standalone UC / meets MRR requirements, Softphone Only location?',
        inputType: 'select',
        order: 2,
        formId: '8cea867c-9c63-4473-959d-138bbed9808c',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Dual WAN - Note DRT',
          'High Availability - Note DRT',
          'Dual WAN + HA - Note DRT',
        ],
        required: true,
        helpText:
          '<ul><li>In version 6.02 not in 7.01</li>\n    <li>This is a confirmation check that deal parameters have been met</li>\n    <li>Today these checks are manual by different teams</li></ul>',
        id: '903961af-8eef-48cb-8b4e-085df9eafd6e',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'LAN/WAN',
    id: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
    createdAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    updatedAt: '2022-06-28T17:09:26.701Z',
    fields: [
      {
        inputName: 'lanDesign',
        label: 'Please select LAN design to be utilized',
        inputType: 'select',
        order: 1,
        formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Dedicated LAN(dual drop = UC Switches)',
          'Shared LAN(single drop = UC Switches)',
          'Customer Provided(Provide switch Make/Model be used)',
          'Managed LAN (MNE -MS switches MUST be used)',
        ],
        required: true,
        helpText:
          '<ul><li>Required technical data</li>\n    <li>May require new field or field modification</li></ul>',
        id: 'd8c1ac05-d3cd-4077-98d4-a404553748d6',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'customerSwitches',
        label: 'Please provide the customer’s make and model of switches.',
        inputType: 'text',
        order: 1.1,
        formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText: 'Customer Switch Data',
        id: '731fb92f-aaaf-48d4-ad54-98364e7f6206',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [
          {
            operator: 'is',
            valueOptions: [
              'Customer Provided(Provide switch Make/Model be used)',
            ],
            fieldId: '731fb92f-aaaf-48d4-ad54-98364e7f6206',
            conditionFieldId: 'd8c1ac05-d3cd-4077-98d4-a404553748d6',
            id: '24827c48-bc43-465b-961d-60f39dc7c6cd',
            createdAt: '2022-06-28T17:09:26.704Z',
            updatedAt: '2022-06-28T17:09:26.704Z',
          },
        ],
      },
      {
        inputName: 'viopPhones',
        label: 'Are the VoIP phones Spectrum  OR Customer provided?',
        inputType: 'select',
        order: 2,
        formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
        header: 'ISW',
        subtext: null,
        tooltip: null,
        options: [
          'No Cabling required -or- Customer assuming responsibility',
          'Spectrum Provided - pay NRC',
          'Spectrum Provided - pay MRR',
        ],
        required: true,
        helpText:
          '<ul>\n    <li>Addition to PQE v7.0</li>\n    <li>(This is used for determining charges, and for Field Ops to know for their WO)</li>\n    </ul>',
        id: '2602619b-f094-4a58-b795-32dc60fcd9c1',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'analogPhones',
        label: 'Are the Analog phones Spectrum  OR Customer provided?',
        inputType: 'select',
        order: 3,
        formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'No Cabling required -or- Customer assuming responsibility',
          'Spectrum Provided - pay NRC',
          'Spectrum Provided - pay MRR',
        ],
        required: true,
        helpText:
          '<ul>\n    <li>Addition to PQE v7.0</li>\n    <li>(This is used for determining charges, and for Field Ops to know for their WO)</li>\n    </ul>',
        id: 'd897eca9-99e6-4337-a47a-b2dea0c90de0',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'notes',
        label: 'Notes',
        inputType: 'text-area',
        order: 100,
        formId: '6809c71d-12ae-4f85-b0f5-bd0a0f6d3c07',
        header: null,
        subtext: '(optional)',
        tooltip: null,
        options: null,
        required: false,
        helpText: null,
        id: '218abbf5-4054-4b8b-bdf8-37059b241b12',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'MDF/IDF',
    id: 'f8855c04-7a14-49a2-8ef8-37222f30df87',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [
      {
        inputName: 'campus name',
        label:
          'IF this site has more than one (1) MDF/IDF or is a CAMPUS style environment, who will be responsible for providing interconnect?',
        inputType: 'select',
        order: 1,
        formId: 'f8855c04-7a14-49a2-8ef8-37222f30df87',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'Yes - Customer has Spare Fiber strands dedicated for Spectrum UC',
          'Yes - Customer has dedicated Ethernet for Spectrum UC',
          'Yes Spectrum to Provide Fiber Interconnect(Include in SPARC request)',
          'Yes - Spectrum to provide CAT6 - Game changer(Include in SPARC request)',
          'NA - Single MDF',
        ],
        required: true,
        helpText: 'Required technical data',
        id: '71a809b6-eb68-43b5-84ef-a7e5bf398e2c',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'space for MDF',
        label: 'Is there adequate space in the MDF for M500 and Switch?',
        inputType: 'select',
        order: 2,
        formId: 'f8855c04-7a14-49a2-8ef8-37222f30df87',
        header: null,
        subtext: null,
        tooltip: null,
        options: [
          'No Space',
          'Yes - Customer Provided Rack Space(Min of & RU Required per closet)',
          'Yes - Spectrum Provided Rac(2x2 Wall space required per closet)',
        ],
        required: true,
        helpText: 'Customer site data',
        id: '62dd4692-e36f-4e80-bc08-7d70aa982386',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'wall mount',
        label:
          'How many wall mount brackets does the customer need for phones?',
        inputType: 'number-text',
        order: 3,
        formId: 'f8855c04-7a14-49a2-8ef8-37222f30df87',
        header: null,
        subtext: null,
        tooltip: null,
        options: null,
        required: true,
        helpText:
          '<ul>\n    <li>Addition to PQE v7.0</li>\n    <li>(This is used for determining charges, and for Field Ops to know for their WO)</li></ul>',
        id: '63f19ea6-7870-4f8e-8ffa-55c3577d534b',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
      {
        inputName: 'notes',
        label: 'Notes',
        inputType: 'text-area',
        order: 100,
        formId: 'f8855c04-7a14-49a2-8ef8-37222f30df87',
        header: null,
        subtext: '(optional)',
        tooltip: null,
        options: null,
        required: false,
        helpText: null,
        id: '31c1955a-7947-486c-a9de-d8815d8b569f',
        createdAt: '2022-06-28T17:09:26.702Z',
        updatedAt: '2022-06-28T17:09:26.702Z',
        rules: [],
      },
    ],
  },
  {
    name: 'MDF/IDF Design',
    id: '38ebd57c-9d57-472d-8918-c38910fe50ff',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [],
  },
  {
    name: 'Contact Info',
    id: '107a08e8-2dc5-4487-be53-df8d21b1438d',
    createdAt: '2022-06-28T17:09:26.701Z',
    updatedAt: '2022-06-28T17:09:26.701Z',
    type: 'AEE - MS - SE',
    fields: [],
  },
];
export const mockFormListRC: RForm[] = [];
export const mockFormListMNE: MForm[] = [];
