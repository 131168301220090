import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KiteAlert, KiteTooltip } from '@kite/react-kite';
import {
  IField,
  IFieldValues,
  ISubmission,
  IFieldInput,
  ILocation,
  IPostCopyCloneHistory,
} from 'types';
import {
  RCQSelect,
  RCQDate,
  RCQTextQuantity,
  RCQUpload,
  RCQBasicInput,
} from 'components';
import { useRCQuestionsTotal } from './useRCQuestionsTotal';
import { RCQDesignFlowLayout } from 'components/rcQuestions';
import {
  useAnalytics,
  useDeleteSubmissions,
  useFormRules,
  useQueryData,
  useSelections,
  useUpdateSubmissions,
  useCopySubmissions,
  useUpdateLocation,
  usePostCopyCloneHistory,
} from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useParams } from 'react-router-dom';
import { collatorSort } from 'utils';

interface IRCUseRenderFieldsParams {
  fields: IField[];
  submissions: ISubmission[];
  formId?: string;
  isCpw?: boolean;
  onFocusChange: (helpString: string) => void;
}

export const RCuseRenderFields = ({
  fields,
  submissions,
  formId,
  isCpw,
  onFocusChange,
}: IRCUseRenderFieldsParams) => {
  // Initial field values (used to compare changes)
  // Updates on fields/submissions changes
  const initFieldValues = useMemo(
    () =>
      fields.reduce((acc: IFieldValues, field) => {
        const submission = submissions?.find(
          (sub) => sub.fieldId === field.id
        )?.answer;
        acc[field.id] = submission || null;
        return acc;
      }, {}),
    [fields, submissions]
  );

  const { estimateId = '', locationId = '' } = useParams();
  const { userId, currentLocation, currentEstimate } = useQueryData();
  const { applySelectionsforSubmission } = useSelections();
  const { trackSelectAction } = useAnalytics();

  /* * * * * * * *
   *             *
   * STATE/HOOKS *
   *             *
   * * * * * * * */

  // Key-value pairs of fieldId + current value
  const [fieldValues, setFieldValues] = useState(initFieldValues);
  // Id of currently focused field
  const [currentFocusId, setCurrentFocusId] = useState<string>('');

  // Flat array of all form rules
  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  // Return from rule engine
  // ruleKeys is array of all fieldIds that rely on a rule
  // ruleMap is key-value pairs of fieldId + canShow (boolean)
  const { ruleMap, ruleKeys } = useFormRules({ fieldValues, formRules });

  // Get required field Ids and submissions based on current submissions + form
  const { completePercent, requiredSubmissions, requiredFieldIds } =
    useRCQuestionsTotal({ submissions, formId });

  /* * * * * *
   *         *
   * HELPERS *
   *         *
   * * * * * */

  // Use requiredFieldIds to get fieldValues only for required fields
  const requiredFieldValues = useMemo(
    () =>
      Object.entries(fieldValues).filter(([fieldId, value]) => {
        if (new Set(requiredFieldIds).has(fieldId) && value) {
          return true;
        }
        return false;
      }),
    [fieldValues, requiredFieldIds]
  );
  // code for dynamic location in RC
  const optionLocations = useMemo(
    () =>
      currentEstimate?.locations.map((l) => {
        return l.name;
      }) || [],
    [currentEstimate]
  );
  // Compares initFieldValues w/ current fieldValues to determine if form state has changed
  const checkIsDirty = useCallback(
    (fieldValues: IFieldValues) =>
      Object.entries(initFieldValues).some(
        ([fieldId, value]) => fieldValues[fieldId] !== value
      ),
    [initFieldValues]
  );

  // Get array of all fields that are different from original value
  // Returns as [fieldId, value][]
  const getFieldsToUpdate = useCallback(
    (fieldValues: IFieldValues) => {
      const isDirty = checkIsDirty(fieldValues);
      if (!isDirty) {
        return [];
      }

      return Object.entries(fieldValues).filter(
        ([fieldId, value]) => initFieldValues[fieldId] !== value
      );
    },
    [checkIsDirty, initFieldValues]
  );

  const { updateSubmissions, updateSubmissionsError } = useUpdateSubmissions({
    estimateId,
    locationId,
    fields,
  });

  const { copySubmissions, copySubmissionsError } = useCopySubmissions({
    estimateId,
  });

  const { postCopyCloneHistory } = usePostCopyCloneHistory();

  const { deleteSubmissions } = useDeleteSubmissions({
    estimateId,
    locationId,
  });

  const { updateLocation } = useUpdateLocation(estimateId);

  const getApiLocationId = useCallback(
    () => (locationId === 'general' ? null : locationId),
    [locationId]
  );

  const ezPassFieldId = fields.find((f) => f.inputName === 'EZ Pass')?.id;

  const saveSubmissions = useCallback(
    (submissions: Partial<ISubmission>[]) => {
      if (submissions.length) {
        if (ezPassFieldId && currentLocation) {
          const isEzPass =
            submissions.find((s) => s.fieldId === ezPassFieldId)?.answer ===
            'Yes'
              ? true
              : false;
          updateLocation({ ...currentLocation, isEzPass });
        }

        updateSubmissions(submissions);
      }
    },
    [currentLocation, ezPassFieldId, updateLocation, updateSubmissions]
  );

  // Sort fields by order
  const sortedFields = useMemo(() => {
    return fields.sort((a, b) => a.order - b.order);
  }, [fields]);

  /* * * * * * * * * * * * *
   *                       *
   * INTERACTION HANDLERS  *
   *                       *
   * * * * * * * * * * * * */

  // Set focusId for current field
  const handleFocus = useCallback(
    (fieldId: string) => () => {
      setCurrentFocusId(fieldId);
    },
    []
  );

  const findSubmissionDuplicates = useCallback(
    (config: { submissions: ISubmission[]; fieldValues: IFieldValues }) => {
      const { submissions, fieldValues } = config;
      const fieldEntries = Object.entries(fieldValues);
      const submissionsToDelete = fieldEntries.reduce(
        (acc: ISubmission[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          acc.push(...subMatches.filter((s) => s.id !== mostRecentSub?.id));
          return acc;
        },
        []
      );
      return submissionsToDelete;
    },
    []
  );

  // Change handler for updating inputs
  const onFieldChange = useCallback(
    (id: string, newValue: ISubmission['answer'], callback?: () => void) => {
      const newFieldValues = { ...fieldValues, [id]: newValue };
      let htmlString: any = '';
      if (id === 'd21142c3-2bcf-4147-aa72-716757182d8d') {
        if (newValue === 'Other') {
          htmlString = fields[3].helpText;
          onFocusChange(htmlString);
        } else {
          onFocusChange('');
        }
      } else if (id === 'a6ae4cb7-4517-4c67-abf9-16c88d875833') {
        if (newValue !== 'Does not have MNE / ENE Edge') {
          htmlString = fields[4].helpText;
          onFocusChange(htmlString);
        } else {
          onFocusChange('');
        }
      } else if (id === '0f21bd0a-8ad4-4592-b890-a63acdac5f9e') {
        if (newValue !== 'Does not have MNE / ENE Switch') {
          htmlString = fields[5].helpText;
          onFocusChange(htmlString);
        } else {
          onFocusChange('');
        }
      }

      const fieldsToUpdate = getFieldsToUpdate(newFieldValues);
      const submissionsToSave = fieldsToUpdate.reduce(
        (acc: Partial<ISubmission>[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          if (mostRecentSub) {
            const newSub: Partial<ISubmission> = {
              ...mostRecentSub,
              formId: undefined,
              answer: newValue ?? null,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer: newValue ?? null,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );
      saveSubmissions(submissionsToSave);

      if (callback) {
        callback();
      }

      trackSelectAction(
        `Submission Change (Field Id: ${id}, Answer: ${newValue})`,
        { opType: 'inputFieldUpdate' }
      );
      if (
        currentEstimate &&
        submissionsToSave.some(
          (p) =>
            p.fieldId === 'a6ae4cb7-4517-4c67-abf9-16c88d875833' ||
            p.fieldId === '0f21bd0a-8ad4-4592-b890-a63acdac5f9e'
        )
      ) {
        setTimeout(() => {
          applySelectionsforSubmission(currentEstimate);
        }, 2500);
      }
    },
    [
      fieldValues,
      getFieldsToUpdate,
      saveSubmissions,
      trackSelectAction,
      submissions,
      userId,
      estimateId,
      getApiLocationId,
      currentEstimate,
      applySelectionsforSubmission,
      fields,
      onFocusChange,
    ]
  );

  const debounceFieldChange = useDebouncedCallback(onFieldChange, 350);

  const onImport = useCallback(
    (importSubmissions: ISubmission[]) => {
      const importedSubmissions = importSubmissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          const sub = submissions.find((sub) => sub.fieldId === fieldId);
          if (sub) {
            const newSub: Partial<ISubmission> = {
              ...sub,
              formId: undefined,
              answer,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );
      const importedIds = new Set(importedSubmissions.map((s) => s.id));
      const removedSubmissions = submissions.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if ((!formId || s.formId === formId) && !importedIds.has(s.id)) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [
        ...importedSubmissions,
        ...removedSubmissions,
      ];
      debounceFieldChange.cancel();
      updateSubmissions(updatedSubmissions);
    },
    [
      debounceFieldChange,
      estimateId,
      formId,
      getApiLocationId,
      submissions,
      updateSubmissions,
      userId,
    ]
  );

  const onCopy = useCallback(
    (copy1Submissions: ISubmission[], loctions: ILocation[]) => {
      const sourceFormId = new Set(copy1Submissions.map((s) => s.formId!));

      const copiedSubmissions = copy1Submissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          loctions.forEach((l) => {
            const sub = currentEstimate?.submissions!.find(
              (sub) => sub.fieldId === fieldId && sub.locationId === l.id
            );
            if (sub) {
              const newSub: Partial<ISubmission> = {
                ...sub,
                formId: undefined,
                answer,
                editedBy: userId,
              };
              acc.push(newSub);
            } else {
              acc.push({
                estimateId,
                locationId: l.id,
                fieldId,
                answer,
                editedBy: userId,
              });
            }
          });

          return acc;
        },
        []
      );
      const importedIds = new Set(copiedSubmissions.map((s) => s.id));
      const removedSubmissions = currentEstimate?.submissions!.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if (
            !importedIds.has(s.id) &&
            s.formId &&
            sourceFormId.has(s.formId) &&
            loctions.find((l) => l.id === s.locationId)
          ) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [...copiedSubmissions, ...removedSubmissions!];
      debounceFieldChange.cancel();
      const maxChunkSize = 2500;
      let index = 0;

      //breaking down updatedSubmissions into multiple chunks
      //to avoid PAYLOAD TOO LARGE ERROR
      while (index < updatedSubmissions.length) {
        const nextChunkSize = updatedSubmissions.length - index;
        const currentChunkSize = Math.min(nextChunkSize, maxChunkSize);

        const chunk = updatedSubmissions.slice(index, index + currentChunkSize);
        copySubmissions(chunk);

        index += currentChunkSize;
      }

      const copyHistoryEntries: IPostCopyCloneHistory[] = loctions.map((l) => {
        return {
          sourceEstimateId: currentEstimate?.id!,
          sourceLocationId: currentLocation?.id!,
          targetEstimateId: l.estimateId,
          targetLocationId: l.id,
          formIds: Array.from(sourceFormId.values()),
          actionType: 'COPY',
          triggeredBy: userId,
        };
      });
      postCopyCloneHistory(copyHistoryEntries);
    },
    [
      debounceFieldChange,
      estimateId,
      copySubmissions,
      userId,
      currentEstimate,
      currentLocation,
      postCopyCloneHistory,
    ]
  );

  /* * * * * * * * * *
   *                 *
   * RENDER METHODS  *
   *                 *
   * * * * * * * * * */

  // Parse html string into field label
  const renderLabel = useCallback((field: IFieldInput) => {
    // "defaultValue" string prevents incomplete marker from temporarily rendering
    const { label, tooltip, subtext, value = 'defaultValue' } = field;
    const isComplete =
      !!value ||
      label === 'Notes' ||
      label === 'Current Solution' ||
      label === 'Special Voice Solution Needs, Additional Solution Notes' ||
      label === 'Please enter existing Spectrum Account number' ||
      label === 'Please enter applicable Campaign code';

    return (
      <div className="rcq-field__label">
        <div className="rcq-field__label-question">
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
          {!isComplete && <div className="rcq-field__incomplete-marker"></div>}
        </div>
        {tooltip && (
          <KiteTooltip className="rcq-field__tooltip">{tooltip}</KiteTooltip>
        )}
        {subtext && <span className="rcq-field__subtext">{subtext}</span>}
      </div>
    );
  }, []);

  // Map input type to component
  // `field` argument requires mapping submission value to field ojbect (IFieldInput)
  const renderInput = useCallback(
    (field: IFieldInput, isDisabled?: boolean) => {
      switch (field.inputType) {
        case 'date':
          return (
            <RCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="date"
            />
          );
        case 'time':
          return (
            <RCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="time"
            />
          );
        case 'file':
          return (
            <RCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="tco"
            />
          );
        case 'select':
          return (
            <RCQSelect
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              isDisabled={isDisabled}
            />
          );
        case 'text':
          return (
            <RCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
            />
          );
        case 'text-area':
          return (
            <RCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text-area"
            />
          );
        case 'number-text':
          return (
            <RCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
            />
          );
        case 'image':
          return (
            <RCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="image"
            />
          );
        case 'text-quantity':
          return (
            <RCQTextQuantity
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );
        case 'text-copy':
          return (
            <RCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
              isCopy={true}
            />
          );
        case 'number-copy':
          return (
            <RCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
              isCopy={true}
            />
          );
        default:
          return null;
      }
    },
    [debounceFieldChange]
  );

  // Generate array of functional form field components
  const fieldComponents = useMemo(
    () =>
      sortedFields.map((field) => {
        const { header, label, order, id, inputType, options } = field;
        let { warningMessage } = field;

        if (inputType === 'distro') {
          return (
            <RCQDesignFlowLayout
              key={id}
              onFieldChange={onFieldChange}
              fieldId={id}
            />
          );
        }

        // If order is not whole integer, it is a sub question & won't display number next to label
        const isSubQuestion = order && order % 1 !== 0;
        const formattedLabel =
          isSubQuestion || order === 100 || order === 101
            ? label
            : `${!isCpw ? order + '. ' : ''}${label}`;
        const isRule = ruleKeys.includes(id); // Check if field relies on a rule
        const canShow = isRule ? ruleMap[id] : true; // Check field map to get current rule state (true/false)
        const value = fieldValues[id]; // Submission value for field
        const fieldInput = {
          ...field,
          label: formattedLabel,
          value,
          options:
            id === 'bb46256f-8e61-4df7-a7ba-ffd13eea1038'
              ? optionLocations
              : options,
        }; // Converted field w/ formatted label and submission value

        if (order === 1.1 && value) {
          warningMessage = warningMessage
            ? warningMessage
            : 'Please enter the existing Spectrum Account number into SalesForce once identified';
        }

        if (order === 1.2 && value) {
          warningMessage = warningMessage
            ? warningMessage
            : 'Please enter the applicable Campaign code into SalesForce once identified';
        }
        return canShow ? (
          <div
            key={id}
            className="rcq-field"
            onFocus={handleFocus(id)}
            onBlur={handleFocus('')}
          >
            {header && <h3 className="rcq-field__header">{header}</h3>}
            {warningMessage && (
              <KiteAlert
                id={id}
                level="page"
                type="caution"
                description={warningMessage}
              />
            )}
            {renderLabel(fieldInput)}
            {renderInput(fieldInput)}
          </div>
        ) : null;
      }),
    [
      sortedFields,
      renderInput,
      fieldValues,
      isCpw,
      ruleKeys,
      ruleMap,
      handleFocus,
      renderLabel,
      onFieldChange,
      optionLocations,
    ]
  );

  /* * * * * * *
   *           *
   *  EFFECTS  *
   *           *
   * * * * * * */

  // Reset field values if initFieldValues changes (new form, submission mutation, etc.)
  useEffect(() => {
    if (!debounceFieldChange.isPending() && checkIsDirty(fieldValues)) {
      const subsToDelete = findSubmissionDuplicates({
        submissions,
        fieldValues: initFieldValues,
      });
      if (subsToDelete.length) {
        deleteSubmissions(subsToDelete.map((s) => s.id));
      }
      setFieldValues(() => initFieldValues);
    }
  }, [
    checkIsDirty,
    debounceFieldChange,
    deleteSubmissions,
    fieldValues,
    findSubmissionDuplicates,
    initFieldValues,
    submissions,
  ]);

  return {
    completePercent,
    currentFocusId,
    fieldComponents,
    fieldValues,
    fieldsToUpdate: getFieldsToUpdate(fieldValues),
    isDirty: checkIsDirty(fieldValues),
    requiredFieldIds,
    requiredFieldValues,
    requiredSubmissions,
    onImport,
    updateSubmissionsError,
    onCopy,
    copySubmissionsError,
  };
};
