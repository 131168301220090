// Packages
import React, { useMemo } from 'react';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import '../RCQFieldStyles.scss';

export interface IRCQRepeatButtonProps {
  // Text to be displayed in button
  text: string;
  // Handler for repeating input
  onRepeat: () => void;
  // Handler for deleting input
  onDelete: () => void;
  // Determines if current field is the last of the group
  isLast: boolean;
  // Determines if there is only one field
  isOnly: boolean;
}

/** Repeats the value of an input field (onRepeat handler should add a value an array of values, onDelete should remove values from that array)*/
const RCQRepeatButton = ({
  text = 'Repeat',
  onRepeat,
  onDelete,
  isLast,
  isOnly,
}: IRCQRepeatButtonProps) => {
  // =============================================
  // Render Methods
  // =============================================
  const repeatBtn = useMemo(
    () => (
      <KiteButton
        onClick={onRepeat}
        minWidth="min-content"
        maxWidth="min-content"
        type="outline"
      >
        {text}
      </KiteButton>
    ),
    [onRepeat, text]
  );

  const deleteBtn = useMemo(
    () => (
      <KiteButton
        onClick={onDelete}
        minWidth="min-content"
        maxWidth="min-content"
        type="outline"
      >
        <KiteIcon name={'trash'} />
      </KiteButton>
    ),
    [onDelete]
  );

  // =============================================
  // Early Return 1
  // =============================================
  if (isOnly) {
    return repeatBtn;
  }

  // =============================================
  // Early Return 2
  // =============================================
  if (isLast) {
    return (
      <div className="rcq-field__repeat-btn-container">
        {deleteBtn}
        {repeatBtn}
      </div>
    );
  }

  // =============================================
  // Return
  // =============================================
  return deleteBtn;
};

export default RCQRepeatButton;
