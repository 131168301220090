import { ReactNode } from "react";


export interface IOfferData {
  id:string,
  offerName: string;
  deviceName:string;
  updatedAt: string;
  updatedBy:string;
  createdAt:string;
  type:string;
}

export interface IOfferTableRow {
  id:string,
  updatedAt: string;
  offerName: string;
  deviceName:string
  // sort: ReactNode;
  edit: ReactNode;
  delete: ReactNode;
  updatedBy:string;
  createdAt:string;
  type:string;
}

export const OfferMapperTypes={
  MNE:"MNE",
  ENE:"ENE"
}


export type TOfferMapperTypes = keyof typeof OfferMapperTypes;