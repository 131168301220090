// External packages
import React, { useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
// Redux

// Components
import {
  KiteButton,
  KiteIcon,
  KiteInput,
  KiteModal,
  KiteSelect,
} from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

// Utils
import { collatorSort } from 'utils';

// Hooks
import { useQueryData, useGetOffers } from 'hooks';

// Types
import { IOfferData, IOfferTableRow } from 'types';

// Styles
import './OfferDashboard.scss';
import { OfferMapperTypes } from 'types/Offer';
import { usePostOffer, useUpdateOffer, useDeleteOffer } from 'hooks/apiHooks';

const OfferDashboard = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { userId } = useQueryData();
  const [showOfferModal, setShowOfferModal] = useState(false);
  // const [offerModalType, setOfferModalType] = useState<'edit' | 'new'>('edit');
  const [isEdit, setIsEdit] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState<IOfferData | null>(null);
  const [offerName, setOfferName] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [offerMapperType, setOfferMapperType] = useState('');
  const [offerNameError, setOfferNameError] = useState('');
  const [deviceNameError, setDeviceNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [updateOfferId, setUpdateOfferId] = useState('');
  const { data: allOffers, isLoading: allOffersLoading } = useGetOffers();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const columns = useMemo(
    () => [
      {
        label: 'Offer Name',
        sortEnabled: true,
        sortKey: 'offerName',
      },
      {
        label: 'Device',
        sortEnabled: true,
        sortKey: 'deviceName',
      },
      {
        label: 'Type',
        sortEnabled: true,
        sortKey: 'type',
      },
      {
        label: 'Edit',
        sortEnabled: false,
        sortKey: 'edit',
      },
      {
        label: 'Delete',
        sortEnabled: false,
        sortKey: 'delete',
      },
    ],
    []
  );

  const onSaveSuccess = useCallback(() => {
    // clear state values after save/update success
    setOfferName('');
    setDeviceName('');
    setOfferMapperType('');
  }, []);

  const onSaveError = useCallback(() => {
    console.log('onSaveSuccess Error Called');
  }, []);

  const { postOffer } = usePostOffer(onSaveSuccess, onSaveError);
  const { updateOffer } = useUpdateOffer(onSaveSuccess, onSaveError);
  const { deleteOffer } = useDeleteOffer();

  const onTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setOfferMapperType(e.target.value);
      setTypeError('');
    },
    []
  );

  const displayOptions = useMemo(() => {
    return Object.keys(OfferMapperTypes).map((value) => (
      <option key={`Offer Option ${value}`} value={value}>
        {value}
      </option>
    ));
  }, []);

  const validateNewRecord = (
    newOffer: any,
    allOffersData: IOfferData[],
    currentOfferId: string
  ) => {
    const offerNameConflict = allOffersData.some(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type !== newOffer.type
    );

    const duplicateRecordConflict = allOffersData.some(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type === newOffer.type &&
        offer.deviceName.trim().toLowerCase() ===
          newOffer.deviceName.trim().toLowerCase()
    );

    const offerNameWithTypeConflict = allOffersData.some(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type !== newOffer.type
    );

    const deviceNameConflict = allOffersData.some(
      (offer) =>
        offer.deviceName.trim().toLowerCase() ===
          newOffer.deviceName.trim().toLowerCase() &&
        offer.type !== newOffer.type
    );

    if (offerNameConflict || offerNameWithTypeConflict) {
      setOfferNameError('Offer Name must be unique');
    }

    if (duplicateRecordConflict) {
      setOfferNameError(
        'Same Offer Name , Device Name with same Type already exists'
      );
    }

    if (deviceNameConflict) {
      setDeviceNameError('Device Name must be unique');
    }
    return !(
      offerNameConflict ||
      deviceNameConflict ||
      offerNameWithTypeConflict ||
      duplicateRecordConflict
    );
  };

  const validateUpdateRecord = (
    newOffer: any,
    allOffersData: IOfferData[],
    currentOfferId: string
  ) => {
    const offerMappedData = allOffersData.filter(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
        newOffer.offerName.trim().toLowerCase()
    );

    const offerNameConflict = allOffersData.some(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type !== newOffer.type
    );

    const deviceMappedData = allOffersData.filter(
      (offer) =>
        offer.deviceName.trim().toLowerCase() ===
        newOffer.deviceName.trim().toLowerCase()
    );

    const deviceNameConflict = allOffersData.filter(
      (offer) =>
        offer.deviceName.trim().toLowerCase() ===
        newOffer.deviceName.trim().toLowerCase() &&
        offer.type !== newOffer.type
    );


    const duplicateRecordConflict = allOffersData.some(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type === newOffer.type &&
        offer.deviceName.trim().toLowerCase() ===
          newOffer.deviceName.trim().toLowerCase() &&
          offer.id !==newOffer.id
    );

    const duplicateRecordData = allOffersData.filter(
      (offer) =>
        offer.offerName.trim().toLowerCase() ===
          newOffer.offerName.trim().toLowerCase() &&
        offer.type === newOffer.type &&
        offer.deviceName.trim().toLowerCase() ===
          newOffer.deviceName.trim().toLowerCase()
    );

if (duplicateRecordConflict &&  duplicateRecordData[0].id !== newOffer.id ) {
      setOfferNameError(
        'Same Offer Name , Device Name with same Type already exists'
      );
      return false;
    }

    if(offerNameConflict && offerMappedData.length>1){
      setOfferNameError('Offer Name must be unique');
      return false;
    }

    if(deviceNameConflict && deviceMappedData.length>1){
      setDeviceNameError('Device Name must be unique');
      return false;
    }

    
    if (
      offerMappedData.length === 1 &&
      offerMappedData[0].id !== newOffer.id &&
      offerMappedData[0].deviceName.trim().toLowerCase() ===
        newOffer.deviceName.trim().toLowerCase()
    ) {
      setOfferNameError('Offer Name must be unique');
      return false;
    }
    if (
      offerMappedData.length === 1 &&
      offerMappedData[0].id !== newOffer.id &&
      offerMappedData[0].type != newOffer.type 
    ) {
      setOfferNameError('Same Offer Name already exist with different type');
      return false;
    }

    if (
      deviceMappedData.length === 1 &&
      deviceMappedData[0].id !== newOffer.id &&
      deviceMappedData[0].type !== newOffer.type &&
      offerMappedData.length === 0
    ) {
      setDeviceNameError('Same Device Name already found');
      return false;
    }
    if (
      deviceMappedData.length === 1 &&
      deviceMappedData[0].type !== newOffer.type &&
      deviceMappedData[0].id !== newOffer.id
    ) {
      setDeviceNameError('Same Device Name already exist with different type');
      return false;
    }

    if (
      deviceMappedData.length === 1 &&
      deviceMappedData[0].type !== newOffer.type &&
      deviceMappedData[0].id !== newOffer.id &&
      deviceMappedData[0].deviceName.trim().toLowerCase() ===
        newOffer.deviceName.trim().toLowerCase()
    ) {
      setDeviceNameError('Same Device Name already exist with different type');
      return false;
    }
    return true;
  };
  // =============================================
  // Interaction Handlers
  // =============================================
  const handleOfferEdit = useCallback(
    (offer?: IOfferData) => () => {
      setIsEdit(true);
      // setOfferModalType('edit');
      setUpdateOfferId(offer?.id || '');
      setShowOfferModal(true);
      setDeviceName(offer?.deviceName || '');
      setOfferName(offer?.offerName || '');
      setOfferMapperType(offer?.type || '');
      setOfferNameError('');
      setDeviceNameError('');
      setTypeError('');
    },
    []
  );

  const toggleOfferModal = useCallback(
    (params: { id?: string; modalType: 'edit' | 'new' }) => {
      // const { modalType } = params;
      setOfferName('');
      setDeviceName('');
      setOfferMapperType('');
      setDeviceNameError('');
      setOfferNameError('');
      setTypeError('');
      setIsEdit(false);
      // setOfferModalType(modalType);
      setShowOfferModal(!showOfferModal);
    },
    [showOfferModal]
  );

  const handleDeleteOffer = useCallback(async () => {
    if (offerToDelete) {
      deleteOffer(offerToDelete.id);
      setOfferToDelete(null);
    }
  }, [offerToDelete, deleteOffer]);

  // =============================================
  // Render Methods
  // =============================================
  const formatTableData = useCallback(
    (offer: IOfferData): IOfferTableRow => {
      return {
        id: offer.id,
        offerName: offer.offerName,
        deviceName: offer.deviceName,
        type: offer.type,
        createdAt: offer.createdAt,
        updatedBy: offer.updatedBy,
        updatedAt: dayjs(offer.updatedAt).format('M/DD/YYYY'),
        edit: (
          <KiteIcon
            name="edit"
            size="16px"
            ariaLabel="Edit Offer"
            onClick={handleOfferEdit(offer)}
          />
        ),

        delete: (
          <KiteIcon
            name="trash"
            size="16px"
            ariaLabel="Delete Offer"
            onClick={() => setOfferToDelete(offer)}
          />
        ),
      };
    },
    [handleOfferEdit]
  );

  const sortOffers = useCallback((offers: IOfferData[]) => {
    if (!Array.isArray(offers)) {
      console.error('Expected an array but got:', offers);
      return [];
    }
    return offers.sort((a, b) => {
      const { offerName: aName } = a;
      const { offerName: bName } = b;
      return collatorSort(aName, bName);
    });
  }, []);

  // const handleOfferNameErrorCheck = useCallback(() => {
  //   const otherScenarioNames = allOffers?.reduce((acc: string[], offer) => {
  //     acc.push(offer.offerName);
  //     return acc;
  //   }, []);

  //   if (otherScenarioNames?.includes(offerName)) {
  //     setOfferNameError('Offer Name must be unique.');
  //     return true;
  //   } else {
  //     setOfferNameError('');
  //     return false;
  //   }
  // }, [allOffers, offerName]);

  // Create table rows from offers
  const featuredRows = useMemo(() => {
    return sortOffers(allOffers ? allOffers : []).map((offer) =>
      formatTableData(offer)
    );
  }, [allOffers, formatTableData, sortOffers]);

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setOfferName(value);
      setOfferNameError('');
    },
    []
  );

  const handleDeviceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setDeviceName(value);
      setDeviceNameError('');
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    if (!offerName) {
      setOfferNameError('Offer Name can not be blank');
    }
    if (!deviceName) {
      setDeviceNameError('Device Name can not be blank');
    }
    if (!offerMapperType) {
      setTypeError('Type can not be blank');
    }
    if (!offerName || !deviceName || !offerMapperType) {
      return true;
    }
    const offerData = {
      offerName,
      deviceName,
      type: offerMapperType,
      updatedBy: userId,
      id: isEdit ? updateOfferId : v4(),
    };

    if (isEdit) {
      const isNameError = validateUpdateRecord(
        offerData,
        allOffers || [],
        updateOfferId
      );
      if (!isNameError) {
        return true;
      }
      await updateOffer(offerData);
      setShowOfferModal(false);
    } else {
      const isNameError = validateNewRecord(
        offerData,
        allOffers || [],
        offerData.id
      );
      if (!isNameError) {
        return true;
      }
      await postOffer(offerData);
      setShowOfferModal(false);
    }
  }, [
    deviceName,
    offerName,
    offerMapperType,
    userId,
    isEdit,
    updateOfferId,
    allOffers,
    updateOffer,
    postOffer,
  ]);

  // Return
  // =============================================
  return (
    <div className="offer-dashboard">
      <div className="offer-dashboard__header-wrapper">
        <h2 className="kite-h2">Offer Mapper</h2>

        <KiteButton
          onClick={() => toggleOfferModal({ modalType: 'new' })}
          type="primary"
          size="small"
        >
          Add Offer
        </KiteButton>
      </div>
      <div>
        <SortableTable
          loading={allOffersLoading}
          headerTitle="Featured"
          columns={columns}
          tableData={featuredRows}
        />
      </div>

      <KiteModal
        modalId="delete-offer"
        canShow={!!offerToDelete}
        onHide={() => setOfferToDelete(null)}
        title={`Are you sure you would like to delete ${
          offerToDelete ? offerToDelete.offerName : 'this offer'
        }?`}
        ctaCopy="Delete Offer"
        ctaAction={handleDeleteOffer}
        secondaryCtaCopy="Keep"
        secondaryCtaAction={() => setOfferToDelete(null)}
      >
        <p>This action cannot be undone.</p>
      </KiteModal>
      <div className="scenario-modal-offer">
      <KiteModal
        modalId="add-offer"
        title={!isEdit ? 'New Offer' : 'Edit Offer'}
        canShow={showOfferModal}
        onHide={() => setShowOfferModal(false)}
        ctaCopy={!isEdit ? 'Save Offer' : 'Update Offer'}
        ctaAction={handleSubmit}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={() => setShowOfferModal(false)}
      >
        <KiteInput
          className="scenario-modal__text"
          id="offerName"
          name="offerName"
          label="Offer Name"
          value={offerName}
          onChange={handleTextChange}
          errorMessage={offerNameError}
          placeholder="Offer Name"
        />
        <KiteInput
          className="scenario-modal__text"
          id="deviceName"
          name="deviceName"
          label="Device Name"
          value={deviceName}
          onChange={handleDeviceChange}
          errorMessage={deviceNameError}
          placeholder="Device Name"
        />
        <KiteSelect
          id="type"
          name="type"
          label="Select Type"
          value={offerMapperType}
          onChange={onTypeChange}
          inputProps={{
            'data-testid': 'Offer',
          }}
          errorMessage={typeError}
          placeholder={offerMapperType ? offerMapperType : 'Make Selection'}
        >
          {displayOptions}
        </KiteSelect>
      </KiteModal>
      </div>
    </div>
  );
};

export default OfferDashboard;
