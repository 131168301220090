// Packages
import React from 'react';

// Redux

// Components
import { KiteRadio } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { IFieldInput } from 'types';

// Styles
import '../RCQFieldStyles.scss';

export interface IRCQPrecheckProps {
  /** Related field input */
  fieldInput: IFieldInput;
  /** Current precheck value */
  precheckValue: string;
  /** Change handler for when precheck option is selected */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/** Renders a precheck option "yes"/"no" for questions that are dependent on themselves i.e. "Does this location have <X>, if so what is the <Thing about X>?"*/

const RCQPrecheck = ({
  fieldInput,
  precheckValue = 'Yes',
  onChange,
}: IRCQPrecheckProps) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const { inputName, id } = fieldInput;

  // =============================================
  // Return
  // =============================================
  return (
    <KiteRadio
      className="rcq-field__precheck"
      name={`precheck-${inputName}`}
      radioButtons={[
        { id: `yes-${id}`, label: 'Yes', checked: precheckValue === 'Yes' },
        { id: `no-${id}`, label: 'No', checked: precheckValue === 'No' },
      ]}
      onChange={onChange}
    />
  );
};

export default RCQPrecheck;
