import { ILocationSelection, TProductList } from 'types';
import { EnterpriseIntSpeedAlert } from 'utils/defaultsAndConstants/constants';

type AlertInfo = {
  title: string;
  description: string;
  level: 'page' | 'inline' | 'global';
  type: 'alert' | 'caution' | 'info' | 'confirm';
};

export const informationAlerts = (
  familyName: TProductList,
  currentProducts: ILocationSelection[]
) => {
  const alertInfo: AlertInfo = {
    title: 'Caution',
    description: '',
    level: 'page',
    type: 'caution',
  };

  const product = currentProducts.find((p) => p.familyName === familyName);
  if (!product) return;

  switch (familyName) {
    case 'Enterprise Internet':
      alertInfo.description = EnterpriseIntSpeedAlert;
      break;
    case 'Cloud Connect Single':
      alertInfo.description =
        'Data Centers available only in: New York, NY; Dallas, TX; Chicago, IL; San Jose, CA; Los Angeles, CA; Seattle, WA; and Ashburn, VA';
      break;
    case 'Cloud Connect Dual':
      alertInfo.description =
        'Data Centers available only in: New York, NY; Dallas, TX; Chicago, IL; San Jose, CA; Los Angeles, CA; Seattle, WA; and Ashburn, VA';
      break;
    case 'MNE Cloud Storage': {
      // need brackets here to keep the totals variable scoped to this case
      const totals = currentProducts.reduce(
        (acc, prod) => {
          if (prod.familyName === 'MNE Cameras') {
            acc.cameras += prod.quantity;
          } else if (prod.familyName === 'MNE Cloud Storage') {
            acc.clouds += prod.quantity;
          }
          return acc;
        },
        { cameras: 0, clouds: 0 }
      );

      if (totals.clouds > totals.cameras) {
        alertInfo.description =
          'You are selecting more archiving licenses than cameras. Please note that CVA only supports Meraki cameras.';
      }
      break;
    }
    case 'ENE WiFi': {
      // need brackets here to keep the totals variable scoped to this case
      const totals = currentProducts.reduce(
        (acc, prod) => {
          if (prod.familyName === 'ENE WiFi') {
            acc.wifi += prod.quantity;
          } else if (prod.familyName === 'ENE Switches') {
            acc.switches += prod.quantity;
          }
          return acc;
        },
        { wifi: 0, switches: 0 }
      );

      if (totals.wifi > totals.switches) {
        alertInfo.description =
          'WiFi devices require power over the network. Please ensure the customer has existing PoE switches, or has PoE-Enabled Enterprise Network Switch offers being quoted.';
      }
      break;
    }
    case 'MNE Cameras': {
      // need brackets here to keep the totals variable scoped to this case
      const totals = currentProducts.reduce(
        (acc, product) => {
          if (product.name === 'Flex') {
            acc.flex += product.quantity;
          } else if (product.familyName === 'MNE Virtual MX') {
            acc.edge += product.quantity;
          }
          return acc;
        },
        { flex: 0, edge: 0 }
      );

      if (!totals.edge && totals.flex) {
        alertInfo.description =
          'Flex cameras must be purchased with an Edge device or get ICB approval.';
      }

      break;
    }

    case 'RC Add Ons':
    case 'RC Phones / Devices': {
      // need brackets here to keep the totals variable scoped to this case
      const totals = currentProducts.reduce(
        (acc, prod) => {
          if (prod.name === 'Advanced' || prod.name === 'Ultra') {
            acc.seats += prod.quantity;
          }
          return acc;
        },
        { seats: 0 }
      );

      if (totals.seats < 1) {
        alertInfo.description = 'You have not selected at least one seat.';
      }
      break;
    }

    case 'RC Additional NRC': {
      const totals = currentProducts.reduce(
        (acc, product) => {
          if (product.name === 'Inside Wiring') {
            acc.isw += product.quantity;
          }
          return acc;
        },
        { isw: 0 }
      );

      if (totals.isw > 0) {
        alertInfo.description =
          'Any MACD request(s) for ISW must be ordered manually in SF (if eligible)';
        alertInfo.title = 'REMINDER';
        alertInfo.type = 'info';
      }

      break;
    }

    case 'MNE Advanced Analytics':
      alertInfo.description =
        "All MX devices in a customer's network must have the same type of analytics.  If a customer wants either Insights or SD-WAN Plus and has existing MX devices, ICB will be required.";
      alertInfo.title = 'Info';
      alertInfo.type = 'info';
      break;
    case 'MNE Device Management':
    case 'ENE Device Management': {
      // need brackets here to keep the totals variable scoped to this case
      const totals = currentProducts.reduce(
        (acc, product) => {
          if (product.name.includes('Edge')) {
            acc.edge += product.quantity;
          } else {
            acc.other += product.quantity;
          }
          return acc;
        },
        { edge: 0, other: 0 }
      );

      if (totals.edge < 1) {
        alertInfo.description = 'You have not selected at least 1 Edge device.';
      }
      break;
    }
  }

  if (alertInfo.description) return alertInfo;
};
