// Packages
import React, { useMemo, useState } from 'react';

// Redux

// Components

// Hooks

// Utils

// Types
import { ICopyCloneHistory, ISalesTeamMember } from 'types';

// Styles
import './CopyHistoryRow.scss';
import { useQueryData } from 'hooks';
import dayjs from 'dayjs';
import { KiteIcon } from '@kite/react-kite';

export interface ICopyHistoryRowProps {
  historyData: ICopyCloneHistory;
  teamMember?: ISalesTeamMember;
  onClick?: () => void;
}

const CopyHistoryRow = ({
  teamMember,
  historyData,
  onClick,
}: ICopyHistoryRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const [imgError, setImgError] = useState(false);

  const { currentEstimate, allEstimates } = useQueryData();

  const {
    triggeredBy: pid,
    sourceEstimateId,
    sourceLocationId,
    targetEstimateId,
    targetLocationId,
    createdAt,
    actionType,
  } = historyData || ({} as ICopyCloneHistory);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const { source, target } = useMemo(() => {
    if (actionType === 'COPY') {
      const sourceLocationName = currentEstimate?.locations.find(
        (l) => l.id === sourceLocationId
      )?.name;
      const targetLocationName = currentEstimate?.locations.find(
        (l) => l.id === targetLocationId
      )?.name;
      return { source: sourceLocationName, target: targetLocationName };
    } else {
      const sourceLocationName = allEstimates
        ?.find((e) => e.id === sourceEstimateId)
        ?.locations.find((l) => l.id === sourceLocationId)?.name;

      const targetLocationName = allEstimates
        ?.find((e) => e.id === targetEstimateId)
        ?.locations.find((l) => l.id === targetLocationId)?.name;
      return { source: sourceLocationName, target: targetLocationName };
    }
  }, [
    sourceEstimateId,
    sourceLocationId,
    targetEstimateId,
    targetLocationId,
    actionType,
    allEstimates,
    currentEstimate,
  ]);

  const { firstName, lastName } = teamMember || {
    firstName: pid,
    lastName: '',
  };

  const displayName = `${firstName} ${
    lastName.length ? lastName.split('')[0] + '.' : ''
  }`;

  const formatCreatedAt = dayjs(createdAt).format('hh:mm A');

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="copy-history-row__avatar--generic"
          size="30px"
        />
      );
    return (
      <img
        src={teamMember.imageURI}
        alt={`${teamMember.fullName} avatar`}
        className="copy-history-row__avatar"
        onError={() => setImgError(true)}
      />
    );
  }, [teamMember, imgError]);

  const rowContent = useMemo(
    () => (
      <div className="copy-history-row__content">
        <div className="copy-history-row__title">
          {avatarImage} <span>{displayName}</span>{' '}
          <span
            className="copy-history-row__title--location"
            title={
              actionType === 'CLONE' && currentEstimate?.id === sourceEstimateId
                ? source
                : target
            }
          >
            {actionType === 'CLONE' && currentEstimate?.id === sourceEstimateId
              ? source
              : target}
          </span>
        </div>
        <div className="copy-history-row__sub-title">
          <KiteIcon
            className="copy-history-row__sub-title__icon"
            name={'document'}
          />
          <span>
            {actionType === 'CLONE'
              ? currentEstimate?.id === sourceEstimateId
                ? 'Cloned to'
                : 'Cloned from'
              : 'Copied from'}
          </span>
          <span
            className="copy-history-row__sub-title--location"
            title={
              actionType === 'CLONE' && currentEstimate?.id === sourceEstimateId
                ? target
                : source
            }
          >
            {actionType === 'CLONE' && currentEstimate?.id === sourceEstimateId
              ? target
              : source}
          </span>
          <span className="copy-history-row__sub-title--time">
            {formatCreatedAt}
          </span>
        </div>
      </div>
    ),
    [
      avatarImage,
      source,
      target,
      displayName,
      formatCreatedAt,
      currentEstimate,
      sourceEstimateId,
      actionType,
    ]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`copy-history-row`}>
      {onClick ? <button onClick={onClick}>{rowContent}</button> : rowContent}
    </div>
  );
};

export default CopyHistoryRow;
