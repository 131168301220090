// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteButton, KiteInput, KiteSelect } from '@kite/react-kite';

// Hooks
import { useAnalytics, useQueryData } from 'hooks';

// Utils
import { TableCategoryIconMap } from 'utils';

// Types
import { TProductCategory, TProductList } from 'types';

// Styles
import './AddProductPrices.scss';

const defaultTableValues = {
  '12': 0,
  '24': 0,
  '36': 0,
  '48': 0,
  '60': 0,
  '84': 0,
};

const AddProductPrices = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies } = useQueryData();

  const [categorySelect, setCategorySelect] = useState<
    TProductCategory | 'Select Option'
  >('Select Option');

  const [familySelect, setFamilySelect] = useState<
    TProductList | 'Select Option'
  >('Select Option');

  const [productSelect, setProductSelect] = useState('Select Option');

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const categoryOptions = useMemo(() => {
    return Object.keys(TableCategoryIconMap).map((category) => (
      <option key={category} value={category}>
        {category}
      </option>
    ));
  }, []);

  const familyOptions = useMemo(() => {
    const familyNames = allProductFamilies
      .reduce((acc: string[], family) => {
        if (family.category === categorySelect) {
          acc.push(family.name);
        }
        return acc;
      }, [])
      .sort();

    return familyNames.map((family) => (
      <option key={family} value={family}>
        {family}
      </option>
    ));
  }, [allProductFamilies, categorySelect]);

  const familyProducts = useMemo(() => {
    return (
      allProductFamilies.find((family) => family.name === familySelect)
        ?.products || []
    );
  }, [allProductFamilies, familySelect]);

  const familyProductOptions = useMemo(() => {
    return familyProducts.map((product) => (
      <option key={product.name} value={product.name}>
        {product.name}
      </option>
    ));
  }, [familyProducts]);

  const productPricingTableValues = useMemo(() => {
    const product = familyProducts.find((p) => p.name === productSelect);
    if (!product) return defaultTableValues;
    return product.prices.reduce((acc, price) => {
      acc[price.term] = price.price;
      return acc;
    }, defaultTableValues);
  }, [familyProducts, productSelect]);

  const [pricingTableValues, setPricingTableValues] = useState(
    productPricingTableValues
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSelectChange = useCallback(
    (updateFunction: (newValue: any) => void) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateFunction(e.target.value);
      },
    []
  );

  const onPriceChange = useCallback(
    (term: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value);
      setPricingTableValues((prevState) => {
        return {
          ...prevState,
          [term]: value,
        };
      });
    },
    []
  );

  const handleSavePrices = useCallback(() => {
    const product = familyProducts.find((p) => p.name === productSelect);
    const updatedPrices = product?.prices.map((price) => {
      const tableDataTerm = price.term.split(
        ' '
      )[0] as keyof typeof pricingTableValues;
      const newPrice = pricingTableValues[tableDataTerm];

      return { ...price, price: newPrice };
    });
  }, [familyProducts, productSelect, pricingTableValues]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('AddProductPrices'), [trackPageView]);

  useEffect(() => {
    // Only want to set the state when a new product is selected
    setPricingTableValues(productPricingTableValues);
    // eslint-disable-next-line
  }, [productSelect]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="add-product-prices">
      <KiteSelect
        id="product-categories-options"
        name="product-categories"
        value={categorySelect}
        label="Product Family Category"
        onChange={onSelectChange(setCategorySelect)}
      >
        <option value="Select Option">Select Option</option>
        {categoryOptions}
      </KiteSelect>

      {categorySelect !== 'Select Option' && (
        <KiteSelect
          id="product-family-options"
          name="product-families"
          value={familySelect}
          label="Product Family"
          onChange={onSelectChange(setFamilySelect)}
        >
          <option value="Select Option">Select Option</option>
          {familyOptions}
        </KiteSelect>
      )}

      {familySelect !== 'Select Option' && (
        <KiteSelect
          id="product-options"
          name="products"
          value={productSelect}
          label="Product"
          onChange={onSelectChange(setProductSelect)}
        >
          <option value="Select Option">Select Option</option>
          {familyProductOptions}
        </KiteSelect>
      )}

      {productSelect !== 'Select Option' && (
        <table>
          <thead>
            <tr>
              {Object.keys(pricingTableValues).map((header, i) => {
                return <td key={`${header} ${i}`}>{header} Months</td>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.entries(pricingTableValues).map(([header, price], i) => {
                return (
                  <td key={`${price} ${i}`}>
                    <KiteInput
                      value={price}
                      type="number"
                      onChange={onPriceChange(header)}
                    />
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      )}

      <KiteButton onClick={handleSavePrices}>Save</KiteButton>
    </div>
  );
};

export default AddProductPrices;
