import {
  IForm,
  IFormsRequest,
  MNEFormsRequest,
  RForm,
  RFormsRequest,
  MForm,
} from 'types';
import { formsRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetForms = (requestConfig: IFormsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IForm[]>({
    queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
    apiCall: formsRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};
export const RCuseGetForms = (requestConfig: RFormsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<RForm[]>({
    queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
    apiCall: formsRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};

export const MNEuseGetForms = (requestConfig: MNEFormsRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<MForm[]>({
    queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
    apiCall: formsRequest,
    requestConfig,
    opts: {
      staleTime: Infinity,
    },
  });
};

/*if( params?.type === 'RC') {
  return useGenerateQuery<RForm[]>({
    queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
    apiCall: formsRequest,
    requestConfig,
   });

} else{
 return useGenerateQuery<IForm[]>({
  queryKey: params ? queryKeys(params).filter.forms : queryKeys().all.forms,
  apiCall: formsRequest,
  requestConfig,
 });
}
}
*/
