import { useMutation, useQueryClient } from 'react-query';
import { IOfferData } from 'types';
import { offerRequest, queryKeys } from 'utils';

export const usePostOffer = (
  onSuccess?: (offer: IOfferData) => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();
  const allOffersKey = queryKeys().all.offers;
  // const featuredOffersKey = queryKeys({ featured: true, published: true })
  //   .filter.offers;
  // const moreOffersKey = queryKeys({ featured: false, published: true }).filter
  //   .offers;
  const productFamilyKey = queryKeys().all.productFamilies;

  const {
    mutate: postOffer,
    isLoading: postOfferLoading,
    error: postOfferError,
    data: postOfferRes,
  } = useMutation(
    (newOffer: Partial<IOfferData>) =>
      offerRequest({
        method: 'POST',
        endpoint: 'device-offer-mapper',
        data: {
          ...newOffer,
        },
      }),
    {
      onMutate: async (newOffer: Partial<IOfferData>) => {
        await queryClient.cancelQueries(allOffersKey);
        const prevOffers =
          queryClient.getQueryData<IOfferData[]>(allOffersKey) || [];
        // const newOffers = [
        //   ...prevOffers.filter((b) => b.id !== newOffer.id),
        //   newOffer,
        // ];
        queryClient.setQueryData(allOffersKey, newOffer);
        return { prevOffers };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allOffersKey);
        queryClient.setQueryData(allOffersKey, context?.prevOffers);
        if (onError) {
          onError();
        }
      },
      onSuccess: (res: IOfferData) => {
        if (onSuccess) {
          onSuccess(res);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(productFamilyKey);
        queryClient.invalidateQueries(allOffersKey);
        // queryClient.invalidateQueries(featuredBundlesKey);
        // queryClient.invalidateQueries(moreBundlesKey);
      },
    }
  );

  return {
    postOffer,
    postOfferLoading,
    postOfferError,
    postOfferRes,
  };
};
