// Packages
import React, { MutableRefObject, useMemo } from 'react';

// Redux

// Components
import { PricingTable, OutputTotals } from 'components';
import { KiteAlert } from '@kite/react-kite';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils

// Types

// Styles
import './UcReviewEstimateProductsTab.scss';
import ResponsibilityMatrixPage1 from '../../../images/SE-UC-GD006_v3-Responsibility-Matrix-Guide_Page_1.png';
import ResponsibilityMatrixPage2 from '../../../images/SE-UC-GD006_v3-Responsibility-Matrix-Guide_Page_2.png';

export interface IUcReviewEstimateReviewTabProps {
  refForPrint: MutableRefObject<HTMLDivElement | null>;
  tabName?: string;
}

/** Rate Center tab on the UC Review page */

const UcReviewEstimateProductsTab = ({
  refForPrint,
  tabName,
}: IUcReviewEstimateReviewTabProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId = '' } = useParams();
  const { estimateScenarios, currentEstimate } = useQueryData();
  const { generateProductTotals } = useGenerateProductTotals();

  refForPrint =
    tabName === 'RC Review Page'
      ? (null as unknown as MutableRefObject<HTMLDivElement | null>)
      : refForPrint;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const pushedScenario = useMemo(() => {
    return estimateScenarios?.find(
      (s) => s.id === currentEstimate?.pushedScenarioId
    );
  }, [estimateScenarios, currentEstimate]);

  // const isEzPass = useMemo(
  //   () => currentEstimate?.locations.find((l) => l.id === locationId)?.isEzPass,
  //   [currentEstimate, locationId]
  // );

  const scenarioTotals = generateProductTotals({
    term: pushedScenario?.term || '36',
    scenarioId: currentEstimate?.pushedScenarioId || '',
  });

  const locationTotals = generateProductTotals({
    locationId,
    term: pushedScenario?.term || '36',
  });

  const isEstimateRC = useMemo(() => {
    const rcSelections = currentEstimate?.scenarios
      .map((s) => {
        return s.selections.find(
          (sel) => sel.familyCategory === 'RingCentral Products'
        );
      })
      .find((s) => s !== undefined);
    return rcSelections ? true : false;
  }, [currentEstimate]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Early Return
  // =============================================
  if (!pushedScenario) {
    return (
      <div className="uc-review-estimate-products-tab">
        <KiteAlert
          type="info"
          title="Information"
          description="Estimate product configurations will populate after Scenario is marked as Primary."
        />
      </div>
    );
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-estimate-products-tab" ref={refForPrint}>
      {/* {isEzPass && (
        <KiteAlert
          type="info"
          title="Express Connect"
          description="SE has marked this location as Express Connect in UC Questions"
        />
      )} */}
      <h1>{pushedScenario?.name} Configuration</h1>
      <OutputTotals
        scenarioTotals={scenarioTotals}
        locationTotals={locationTotals}
      />
      <PricingTable disableBanner={true} isExpanded={true} />
      {isEstimateRC && (
        <div id="rcPdfImages">
          <img src={ResponsibilityMatrixPage1} className="rcImage" alt="" />
          <img src={ResponsibilityMatrixPage2} className="rcImage" alt="" />
        </div>
      )}
    </div>
  );
};

export default UcReviewEstimateProductsTab;
