// Packages
import React, { useMemo } from 'react';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';
import { PhoneNumberTable } from 'components';

// Hooks

// Utils
import { useParams } from 'react-router-dom';
import { useQueryData, useRefreshPhoneNumbers } from 'hooks';

// Types
import { ILocation } from 'types';

// Styles
import './RateCenterValidationTable.scss';

export interface IRateCenterValidationTableProps {
  location: ILocation;
  className?: string;
  showRefreshButton?: boolean;
  toggleAddTnModal?: (locationId: string) => void;
  onDeletePhoneNumber?: (numberId: string) => void;
  toggleRateCenterModal?: (locationId: string) => void;
}

/** Displays rate center validation table in the estimate location card and uc review page */

const RateCenterValidationTable = ({
  location,
  className = '',
  showRefreshButton = true,
  toggleAddTnModal,
  onDeletePhoneNumber,
  toggleRateCenterModal,
}: IRateCenterValidationTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentEstimate } = useQueryData();
  const { estimateId = '' } = useParams();
  const { refreshPhoneNumbers, refreshPhoneNumbersLoading } =
    useRefreshPhoneNumbers(estimateId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const isEstimateRC = useMemo(() => {
    const rcSelections = currentEstimate?.scenarios
      .map((s) => {
        return s.selections.find(
          (sel) => sel.familyCategory === 'RingCentral Products'
        );
      })
      .find((s) => s !== undefined);
    return rcSelections ? true : false;
  }, [currentEstimate]);

  const filteredPhoneNumbers = useMemo(() => {
    if (!location.phoneNumbers) {
      return [];
    }
    if (isEstimateRC) {
      return location.phoneNumbers.filter((p) => p.portInEnabled !== null);
    } else {
      return location.phoneNumbers.filter((p) => p.portInEnabled === null);
    }
  }, [isEstimateRC, location.phoneNumbers]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const refreshData = () => {
    refreshPhoneNumbers({
      locationId: location.id,
      isEstimateRC,
    });
  };
  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className={`rate-center-validation-table ${className}`}>
      <div className="rate-center-validation-table__phone-title-container">
        <h3>Rate Center Validation</h3>
        <div className="rate-center-validation-table__phone-title-container__btn-group">
          {Boolean(filteredPhoneNumbers.length) && showRefreshButton && (
            <KiteButton
              type="secondary"
              leftIcon="refresh"
              size="small"
              onClick={refreshData}
              loading={refreshPhoneNumbersLoading}
            >
              Refresh
            </KiteButton>
          )}
          {toggleAddTnModal && (
            <KiteButton
              onClick={() => toggleAddTnModal(location.id)}
              type="outline"
              size="medium"
            >
              Add TNs
            </KiteButton>
          )}
          {!isEstimateRC && toggleRateCenterModal && (
            <KiteButton
              onClick={() => toggleRateCenterModal(location.id)}
              type="outline"
              size="medium"
            >
              Search Rate Center
            </KiteButton>
          )}
        </div>
      </div>
      {!filteredPhoneNumbers.length ? (
        <span>No phone numbers found for this location.</span>
      ) : (
        <>
          {Boolean(filteredPhoneNumbers.length) && showRefreshButton && (
            <h4>
              * Please refresh previously entered NPA-NXX number(s) to update
              validation status displayed.
            </h4>
          )}
          <PhoneNumberTable
            phoneNumbers={filteredPhoneNumbers}
            onDeletePhoneNumber={onDeletePhoneNumber}
            showNpaNxx
            isEstimateRCFlag={isEstimateRC}
          />
        </>
      )}
    </div>
  );
};

export default RateCenterValidationTable;
