// Packages
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { Drawer } from '@kite/react-kite-plus';
import { KiteLoader } from '@kite/react-kite';
import {
  UCQHistoryRow,
  UCQHistoryModal,
  CopyHistoryRow,
  CopyHistoryModal,
} from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useAnalytics,
  useGetForms,
  useGetSubmissionHistory,
  useGetTeamMembersData,
  useGetCopyHistoriesByEstimateId,
  useGetEstimatesData,
} from 'hooks';

// Utils
import {
  formatTeamMember,
  getFormIdByFormType,
  UCQFORMS,
  UCQHistoryForms,
} from 'utils';
// Types
import {
  ICopyCloneHistory,
  IField,
  IFormsRequest,
  ISalesTeamMember,
  ISubmissionHistory,
} from 'types';

// Styles
import './UCQHistoryDrawer.scss';

export interface IUCQHistoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

type THistoryItem = { day: string; changes: JSX.Element[] };

/** Displays submission history for an estimate */

const UCQHistoryDrawer = ({ isOpen, onClose }: IUCQHistoryDrawerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { estimateId } = useParams();
  const {
    data: historyData,
    isLoading,
    isError,
  } = useGetSubmissionHistory({ params: { estimateId } });

  const {
    data: copyHistoryData,
    isLoading: isCopyHistoryLoading,
    isError: isCopyHistoryError,
  } = useGetCopyHistoriesByEstimateId(estimateId || '', 'COPY');

  const {
    data: cloneHistoryData,
    isLoading: isCloneHistoryLoading,
    isError: isCloneHistoryError,
  } = useGetCopyHistoriesByEstimateId(estimateId || '', 'CLONE');

  const { data: allEstimatesData, isLoading: allEstimatesLoading } =
    useGetEstimatesData();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentHistory, setCurrentHistory] =
    useState<ISubmissionHistory | null>(null);
  const [selectedField, setSelectedField] = useState<IField | null>(null);

  const [copyHistoryModalIsOpen, setCopyHistoryModalIsOpen] = useState(false);
  const [currentCopyHistory, setCurrentCopyHistory] =
    useState<ICopyCloneHistory>({} as ICopyCloneHistory);

  const editedByPids = useMemo(() => {
    return Array.from(new Set(historyData?.map((hd) => hd.editedBy) || []));
  }, [historyData]);

  // Fetches all AD data for associated PIDs within history
  const activeDirectoryData = useGetTeamMembersData({
    pids: editedByPids,
    enabled: !!historyData,
  });

  const formsQueryParams: IFormsRequest['params'] = useMemo(() => {
    return { location: 1, type: UCQFORMS };
  }, []);

  // const { data: formsData = [] } = useGetForms({
  //   params: { type: UCQFORMS },
  // });
  const { data: formsData = [] } = useGetForms({
    params: formsQueryParams,
  });

  const teamMembers = useMemo(() => {
    return activeDirectoryData.reduce(
      (acc: { [pid: string]: ISalesTeamMember | undefined }, queryResult) => {
        const { isLoading, data } = queryResult;
        if (isLoading || !data) {
          return acc;
        }
        const { pid } = data;
        acc[pid] = formatTeamMember({ activeDirectoryData: data });
        return acc;
      },
      {}
    );
  }, [activeDirectoryData]);

  const currentHistoryTeamMember = useMemo(() => {
    if (currentHistory) {
      return teamMembers[currentHistory.editedBy];
    }
  }, [currentHistory, teamMembers]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleModalChange = useCallback(
    (params: {
        isOpen: boolean;
        history: ISubmissionHistory | null;
        headerField: IField | null;
      }) =>
      () => {
        const { isOpen, history, headerField } = params;
        setCurrentHistory(history);
        setModalIsOpen(isOpen);
        setSelectedField(headerField);
        trackSelectAction(`History Modal ${isOpen ? 'Open' : 'Close'}`, {
          opType: 'buttonClick',
        });
      },
    [trackSelectAction]
  );

  const handleCopyHistoryModalChange = useCallback(
    (params: { isOpen: boolean; history: ICopyCloneHistory }) => () => {
      const { isOpen, history } = params;
      setCurrentCopyHistory(history);
      setCopyHistoryModalIsOpen(isOpen);
    },
    []
  );
  // =============================================
  // Render Methods
  // =============================================
  const formattedHistoryData = useMemo(() => {
    return historyData
      ?.filter(
        (h) =>
          UCQHistoryForms.includes(h.formId) || h.formName === 'Network Draft'
      )
      .reduce((acc: THistoryItem[], history: ISubmissionHistory) => {
        const { id, createdAt, editedBy: pid, formName, fieldOrder } = history;
        if (formName !== 'Discovery Questions/Notes') {
          const dayName = new Date(createdAt).toLocaleString('en-us', {
            weekday: 'long',
          });
          // Include year and remove when rendering so that we dont add to an existing day when the year turns over
          const formattedDayName =
            dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
          const existingDay = acc.find((obj) => obj.day === formattedDayName);

          const reqdfields = formsData
            .filter((f) => f.name === history?.formName)
            .map((f) => f.fields)
            .flat()
            .filter((f) => f.header && f.inputType === 'check-box');

          const x = reqdfields.filter((f) => f.order <= fieldOrder);

          const headerField = x.length ? x[x.length - 1] : null;

          const teamMember = teamMembers[pid];

          const historyRow = (
            <UCQHistoryRow
              key={id}
              teamMember={teamMember}
              historyData={history}
              headerField={headerField}
              onClick={handleModalChange({
                isOpen: true,
                history,
                headerField,
              })}
            />
          );

          // Check if there is already a section for that day
          if (existingDay) {
            existingDay.changes.push(historyRow);
          } else {
            // Create a new day header and array of changes
            acc.push({
              day: formattedDayName,
              changes: [historyRow],
            });
          }
        }
        return acc;
      }, []);
  }, [handleModalChange, historyData, teamMembers, formsData]);

  const renderHistoryList = useCallback(() => {
    if (isLoading) {
      return <KiteLoader />;
    }

    if (isError) {
      return <span>Something went wrong loading submission history.</span>;
    }

    if (formattedHistoryData) {
      return formattedHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [isLoading, isError, formattedHistoryData]);

  const formattedCopyHistoryData = useMemo(() => {
    const copyHistory = copyHistoryData
      ?.filter((c) => c.formIds.includes(getFormIdByFormType['UC']))
      ?.reduce((acc: THistoryItem[], history: ICopyCloneHistory) => {
        const { id, createdAt, triggeredBy: pid } = history;
        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const teamMember = teamMembers[pid];

        const historyRow = (
          <CopyHistoryRow
            key={id}
            historyData={history}
            teamMember={teamMember}
            onClick={handleCopyHistoryModalChange({
              isOpen: true,
              history,
            })}
          />
        );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }

        return acc;
      }, []);
    return copyHistory;
  }, [handleCopyHistoryModalChange, copyHistoryData, teamMembers]);

  const renderCopyHistoryList = useCallback(() => {
    if (isCopyHistoryLoading) {
      return <KiteLoader />;
    }

    if (isCopyHistoryError) {
      return <span>Something went wrong loading copy responses history.</span>;
    }

    if (formattedCopyHistoryData) {
      return formattedCopyHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [isCopyHistoryLoading, isCopyHistoryError, formattedCopyHistoryData]);

  const formattedCloneHistoryData = useMemo(() => {
    const cloneHistory = cloneHistoryData
      ?.filter((c) => c.formIds.includes(getFormIdByFormType['UC']))
      ?.reduce((acc: THistoryItem[], history: ICopyCloneHistory) => {
        const { id, createdAt, triggeredBy: pid } = history;
        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const teamMember = teamMembers[pid];

        const historyRow = (
          <CopyHistoryRow
            key={id}
            historyData={history}
            teamMember={teamMember}
            onClick={handleCopyHistoryModalChange({
              isOpen: true,
              history,
            })}
          />
        );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }

        return acc;
      }, []);
    return cloneHistory;
  }, [handleCopyHistoryModalChange, cloneHistoryData, teamMembers]);

  const renderCloneHistoryList = useCallback(() => {
    if (isCloneHistoryLoading || allEstimatesLoading) {
      return <KiteLoader />;
    }

    if (isCloneHistoryError) {
      return <span>Something went wrong loading clone responses history.</span>;
    }

    if (formattedCloneHistoryData && allEstimatesData) {
      return formattedCloneHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [
    isCloneHistoryLoading,
    isCloneHistoryError,
    formattedCloneHistoryData,
    allEstimatesData,
    allEstimatesLoading,
  ]);

  const showCopyResponsesHistory = Boolean(
    copyHistoryData &&
      copyHistoryData.filter((c) =>
        c.formIds.includes(getFormIdByFormType['UC'])
      ).length
  );

  const showCloneResponsesHistory = Boolean(
    cloneHistoryData &&
      cloneHistoryData.filter((c) =>
        c.formIds.includes(getFormIdByFormType['UC'])
      ).length
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <Drawer
      className="ucq-history-drawer"
      title=""
      isOpen={isOpen}
      onClose={onClose}
    >
      {showCloneResponsesHistory ? (
        <>
          <h1>Clone Responses History</h1>
          {renderCloneHistoryList()}
        </>
      ) : null}
      {showCopyResponsesHistory ? (
        <>
          <h1>Copy Responses History</h1>
          {renderCopyHistoryList()}
        </>
      ) : null}
      <h1>History</h1>
      {renderHistoryList()}
      <UCQHistoryModal
        isOpen={modalIsOpen}
        onClose={handleModalChange({
          isOpen: false,
          history: null,
          headerField: null,
        })}
        currentHistoryTeamMember={currentHistoryTeamMember}
        currentHistory={currentHistory}
        headerField={selectedField}
      />
      {showCopyResponsesHistory || showCloneResponsesHistory ? (
        <CopyHistoryModal
          isOpen={copyHistoryModalIsOpen}
          onClose={handleCopyHistoryModalChange({
            isOpen: false,
            history: {} as ICopyCloneHistory,
          })}
          currentHistoryTeamMember={currentHistoryTeamMember}
          currentHistory={currentCopyHistory}
        />
      ) : null}
    </Drawer>
  );
};

export default UCQHistoryDrawer;
