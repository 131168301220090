// Packages
import React, { useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteLoader } from '@kite/react-kite';
import { MneEneBadgeStatus } from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import { useGetSubmissions } from 'hooks';

// Utils
import { nameToUrlParam } from 'utils';

// Types
import { IEstimate, MForm } from 'types';

// Styles
import './ENELayout.scss';

import MNEFormNav from 'components/mneQuestions/MNEFormNav';
import MNEForm from 'components/mneQuestions/MNEForm';
import { ENEHeader } from 'components/eneQuestions';
import { ENEFORMS } from 'utils/defaultsAndConstants/constants';
import { IENEHeaderProps } from 'components/eneQuestions/ENEHeader/ENEHeader';

export interface IENELayoutProps {
  /** Form data from query */
  forms?: MForm[];
  /** Estimate data from query */
  estimate?: IEstimate;
  /** Determines if form data is currently loading */
  formsLoading: boolean;
}

/** Layout for MNE Questions page */

const ENELayout = ({ forms = [], estimate, formsLoading }: IENELayoutProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const tabOptions = useMemo(
    () => Array.from(new Set(forms.map((form) => form.type))),
    [forms]
  );

  // initial tab is being set in useEffect below
  const [tab, setTab] = useState('');

  const {
    formName = 'ene-opportunity-type',
    locationId = 'general',
    estimateId,
  } = useParams();

  const submissionParams = useMemo(() => {
    if (estimate && locationId) {
      return {
        locationId: locationId === 'general' ? undefined : locationId,
        estimateId: estimate.id,
      };
    }
  }, [estimate, locationId]);

  const { data: currentSubmissions, isLoading: currentSubmissionsLoading } =
    useGetSubmissions({ params: submissionParams });

  // Need consistent allSubmissions to handle reminders badge logic for SME
  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const filteredForms = useMemo(
    () => forms.filter((form) => form.type === tab),
    [forms, tab]
  );

  const currentFormId = useMemo(
    () => forms?.find((f) => nameToUrlParam(f.name) === formName)?.id || '',
    [formName, forms]
  );

  const currentForm = useMemo(
    () => forms?.find((form) => form?.id === currentFormId),
    [currentFormId, forms]
  );

  const ucqFormIdsList = useMemo(() => forms?.map((f) => f.id), [forms]);

  const recentSubmission = useMemo(() => {
    if (currentSubmissions && currentSubmissions.length) {
      const filteredCurrentSubmissions = currentSubmissions.filter(
        (item) => item.formId && ucqFormIdsList.includes(item.formId)
      );

      return filteredCurrentSubmissions.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      })[0];
    }
  }, [currentSubmissions, ucqFormIdsList]);

  const lastUpdatedBy: IENEHeaderProps['lastUpdatedBy'] = useMemo(() => {
    if (recentSubmission) {
      const { editedBy: pid, updatedAt: dateUpdated } = recentSubmission;
      return { pid, dateUpdated };
    }

    return {
      pid: '',
      dateUpdated: '',
    };
  }, [recentSubmission]);

  const isLoading = formsLoading || currentSubmissionsLoading;

  // =============================================
  // Interaction Handlers
  // =============================================

  // const handleTabChange = useCallback(
  //   (value: string) => {
  //     setTab(value);
  //     const firstFormName = forms.filter((form) => form.type === value)[0].name;
  //     navigate(
  //       `/ene-questions/${estimateId}/${locationId}/${nameToUrlParam(
  //         firstFormName
  //       )}`
  //     );
  //     trackSelectAction(`ENE Questions Select Tab: ${value}`, {
  //       opType: 'buttonClick',
  //     });
  //   },
  //   [estimateId, forms, locationId, navigate, trackSelectAction]
  // );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  useEffect(() => {
    if (currentForm) {
      setTab(currentForm.type);
    } else {
      setTab(tabOptions[0]);
    }
  }, [currentForm, tabOptions]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ene-layout">
      <div className="ene-layout__upper">
        <ENEHeader
          opptyName={estimate?.name || ''}
          locations={estimate?.locations || []}
          lastUpdatedBy={lastUpdatedBy}
          questionType={ENEFORMS}
        />
        <div className="ene-layout__upper-sub">
          <h2>ENE Questions</h2>
          {/* <KiteTabs
            tabs={tabOptions}
            currentTab={tab}
            onSelectTab={handleTabChange}
          /> */}
          <div></div>
          {formName !== 'ene-opportunity-type' &&
          formName !== 'network-draft' ? (
            <MneEneBadgeStatus submissions={currentSubmissions} type="ENE" />
          ) : null}
        </div>
      </div>
      <div className="ene-layout__content">
        {isLoading && <KiteLoader className="ene-layout__form-loader" />}
        {currentForm && currentSubmissions && (
          <>
            <MNEFormNav
              forms={filteredForms}
              submissions={currentSubmissions}
              questionType={ENEFORMS}
            />
            <MNEForm
              forms={forms}
              form={currentForm}
              formSubmissions={currentSubmissions}
              allSubmissions={allSubmissions}
              questionType={ENEFORMS}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ENELayout;
