// Packages
import React, { useCallback, useMemo } from 'react';

// Redux

// Components
import { SortableTable } from '@kite/react-kite-plus';
import { KiteIcon } from '@kite/react-kite';

// Hooks

// Utils
import { useAnalytics } from 'hooks';

// Types
import { IPhoneNumber } from 'types';

// Styles
import './PhoneNumberTable.scss';

export interface IPhoneNumberTableProps {
  phoneNumbers: Partial<IPhoneNumber>[];
  onDeletePhoneNumber?: (numberId: string) => void;
  showNpaNxx?: boolean;
  isEstimateRCFlag: boolean;
}

/** Sortable table for displaying phone number data */

const PhoneNumberTable = ({
  phoneNumbers,
  onDeletePhoneNumber,
  showNpaNxx,
  isEstimateRCFlag,
}: IPhoneNumberTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleDeletePhoneNumber = useCallback(
    (id: string) => () => {
      if (onDeletePhoneNumber) {
        onDeletePhoneNumber(id);
        trackSelectAction(`Phone Number Table: Delete ${id}`, {
          opType: 'buttonClick',
        });
      }
    },
    [onDeletePhoneNumber, trackSelectAction]
  );

  // =============================================
  // Render Methods
  // =============================================
  const columns = useMemo(() => {
    const npaNxxCols = showNpaNxx
      ? [
          { sortKey: 'npa', label: 'NPA', size: 0.5 },
          { sortKey: 'nxx', label: 'NXX', size: 0.5 },
        ]
      : [];

    if (isEstimateRCFlag) {
      return [
        ...npaNxxCols,
        { sortKey: 'portInEnabled', label: 'Supported' },
        {
          sortKey: 'delete',
          label: ' ',
          sortEnabled: false,
          size: 0.2,
          render: (number: IPhoneNumber) => {
            return (
              onDeletePhoneNumber && (
                <KiteIcon
                  name="trash"
                  onClick={handleDeletePhoneNumber(number.id)}
                />
              )
            );
          },
        },
      ];
    }

    return [
      ...npaNxxCols,
      { sortKey: 'rcAbbre', label: 'Rate Center', size: showNpaNxx ? 1 : 1.5 },
      { sortKey: 'locState', label: 'State' },
      { sortKey: 'carrier', label: 'Carrier' },
      { sortKey: 'footPrint', label: 'Footprint' },
      { sortKey: 'bandwidth', label: 'Bandwidth' },
      { sortKey: 'bandwidthTrunkOnly', label: 'Trunk Only' },
      {
        sortKey: 'delete',
        label: ' ',
        sortEnabled: false,
        size: 0.2,
        render: (number: IPhoneNumber) => {
          return (
            onDeletePhoneNumber && (
              <KiteIcon
                name="trash"
                onClick={handleDeletePhoneNumber(number.id)}
              />
            )
          );
        },
      },
    ];
  }, [
    handleDeletePhoneNumber,
    onDeletePhoneNumber,
    showNpaNxx,
    isEstimateRCFlag,
  ]);
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return <SortableTable tableData={phoneNumbers} columns={columns} />;
};

export default PhoneNumberTable;
