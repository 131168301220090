import { useCallback, useMemo } from 'react';
import { ISubmission } from 'types';

import { eneISWFields, offNetRateCard, mneISWFields } from 'utils';

export const useGenerateISWPricing = (
  submissions: ISubmission[],
  type: 'MNE' | 'ENE'
) => {
  const countTotalQuantity = useCallback((subs: ISubmission[]) => {
    return subs.reduce((acc: number, { answer }) => {
      if (answer) {
        const answers = JSON.parse(answer);
        answers.forEach((a: string[]) => (acc += Number(a[1] || 0)));
      }
      return acc;
    }, 0);
  }, []);

  const { ISWPrice, numberofSEdrops, liftRental } = useMemo(() => {
    const {
      addPhoneDropsFieldId,
      deviceMountsFieldId,
      phoneFieldId,
      clientProvidedDropFiedId,
      dropsFieldIDsISW,
      additionalPortsRequiredFieldId,
      onNetFieldId,
      deviceMountsAbove24FtFieldId,
    } = type === 'MNE' ? mneISWFields : eneISWFields;

    //Step 1 : Get All the drops required

    const dropsReq =
      countTotalQuantity(
        submissions.filter((s) => dropsFieldIDsISW.includes(s.fieldId))
      ) +
      Number(
        submissions.find((s) => s.fieldId === additionalPortsRequiredFieldId)
          ?.answer || 0
      ) +
      //Check if phone drops are to be added?
      Number(
        submissions.some((s) => s.fieldId === addPhoneDropsFieldId && s.answer)
          ? countTotalQuantity(
              submissions.filter((s) => phoneFieldId === s.fieldId)
            )
          : 0
      );

    //Step 2 : Calculate The number of drops

    const SEdrops =
      dropsReq -
      Number(
        submissions.find((s) => s.fieldId === clientProvidedDropFiedId)
          ?.answer || 0
      );

    const numberofSEdrops = SEdrops < 0 ? 0 : SEdrops;

    //Step 3 : Check Lift Rental required or not

    const liftRental =
      submissions.find((s) => s.fieldId === deviceMountsFieldId)?.answer ===
        'Yes' ||
      submissions.find((s) => s.fieldId === deviceMountsAbove24FtFieldId)
        ?.answer === 'Yes'
        ? 1100
        : 0;

    //Step 4 : Check If location is OnNet
    if (submissions.find((s) => s.fieldId === onNetFieldId)?.answer === 'Yes') {
      const NRC = numberofSEdrops * 250; //-4
      return {
        ISWPrice: NRC > 0 ? NRC + liftRental : liftRental,
        numberofSEdrops,
        liftRental,
      };
    } else {
      return {
        ISWPrice: offNetRateCard[numberofSEdrops] + liftRental || liftRental,
        numberofSEdrops,
        liftRental,
      };
    }
  }, [submissions, countTotalQuantity, type]);

  return { ISWPrice, numberofSEdrops, liftRental };
};
