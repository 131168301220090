import {
  hospitalityId,
  pmsTypeFieldId,
  pmsVendorFieldId,
  regularMNEId,
  wpnRequiredFieldId,
} from 'utils/defaultsAndConstants/constants';

export type IFieldConfig = {
  /** field which others are dependent on */
  [Property in string]: {
    /** when the field is answered, these fields should be disabled */
    fieldsToBeDisabled: string[];
    /** CAN BE IMPLEMENTED IN FUTURE, when the field is answered, these fields should be enabled */
    fieldsToBeEnabled: string[];
    /** flag to indicate general fields (not location level) */
    isGeneralField?: boolean;
  };
};

export const fieldConfig: Partial<IFieldConfig> = {
  [wpnRequiredFieldId]: {
    fieldsToBeDisabled: [pmsVendorFieldId, pmsTypeFieldId],
    fieldsToBeEnabled: [],
  },
  [regularMNEId]: {
    fieldsToBeDisabled: [hospitalityId],
    fieldsToBeEnabled: [],
    isGeneralField: true,
  },
  [hospitalityId]: {
    fieldsToBeDisabled: [regularMNEId],
    fieldsToBeEnabled: [],
    isGeneralField: true,
  },
};
