// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactFlow, {
  Node,
  Controls,
  NodeTypes,
  MiniMap,
  useReactFlow,
} from 'react-flow-renderer';

// Redux

// Components
import {
  BuildingNode,
  IDFNode,
  MDFNode,
  EditNodeDrawer,
  NewConnectionModal,
} from '..';

// Hooks
import {
  useUpdateDistros,
  RCuseFlowNodes,
  useScreenWidth,
  useDeleteDistros,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { getHighestNodeId } from 'utils';

// Types
import { IDistro, IRCQDesignFlowNode } from 'types';

// Styles
import './RCQDesignFlow.scss';

export interface IRCQDesignFlowProps {
  /** Distro data to render as flow chart */
  distros: Partial<IDistro>[];
  /** Callback to generate a new distro object */
  generateDistro?: (config: {
    nodeType: IDistro['nodeType'];
    distros: Partial<IDistro>[];
    bdgId?: string | undefined;
    distroData?: Partial<IDistro> | undefined;
  }) => Partial<IDistro>;
  /** Determines if nodes within chart can be draggable */
  draggable?: boolean;
  /** Initial x/y position for flow chart */
  defaultPosition?: [number, number];
  /** If true, chart will auto-fit to fill width of container (conflicts with defaultPosition) */
  isFitView?: boolean;
  /** Determines if chart should be interactive (edit mode vs. read-only) */
  actionsAreEnabled?: boolean;
}

/** Main component for MDF/IDF flow chart, renders an instance of react-flow-renderer along with drawer/modal for editing + adding a new distro (gets converted to react-flow node) */

const RCQDesignFlow = ({
  distros,
  generateDistro,
  draggable = false,
  defaultPosition = [300, 100],
  isFitView,
  actionsAreEnabled = true,
}: IRCQDesignFlowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { estimateId = '' } = useParams();

  const { updateDistros } = useUpdateDistros({
    estimateId,
  });
  const { deleteDistros } = useDeleteDistros({ estimateId });

  const { setViewport, getViewport, fitView } = useReactFlow();

  const [newConnection, setNewConnection] = useState<
    IDistro['nodeType'] | null
  >(null);
  const [editDrawer, setEditDrawer] = useState<IDistro['nodeType'] | null>(
    null
  );
  const [currentNode, setCurrentNode] =
    useState<Node<IRCQDesignFlowNode> | null>(null);

  const editNode = useCallback((node: Node<IRCQDesignFlowNode>) => {
    setCurrentNode(node);
    setEditDrawer(node?.data.nodeType || null);
  }, []);

  const addNode = useCallback((node: Node<IRCQDesignFlowNode>) => {
    setCurrentNode(node);
    setNewConnection(node?.data.nodeType || null);
  }, []);

  // adjust this to fit mdf/idf nodes within container
  const nodeSize = useMemo(() => ({ width: 200, height: 267.22 }), []);

  // This is where the state lives for react flow, handles formatting/positioning distros to flow nodes
  const { nodes, edges, onEdgesChange, onNodesChange, onConnect } =
    RCuseFlowNodes({ distros, nodeSize, addNode, editNode });

  const flowRef = useRef<HTMLDivElement>(null);

  const screenWidth = useScreenWidth();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const nodeTypes: NodeTypes = useMemo(
    () => ({ idf: IDFNode, mdf: MDFNode, bdg: BuildingNode }),
    []
  );

  const currentNodeId = currentNode?.id;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleClose = useCallback(() => {
    setEditDrawer(null);
    setNewConnection(null);
    setCurrentNode(null);
  }, []);

  const handleSaveDistros = useCallback(
    (distros: Partial<IDistro>[]) => {
      if (distros.length) {
        updateDistros(distros);
      }
    },
    [updateDistros]
  );

  const handleDeleteDistros = useCallback(
    (distroIds: string[]) => {
      handleClose();
      deleteDistros(distroIds);
    },
    [deleteDistros, handleClose]
  );

  const connectNewIdf = useCallback(
    (formData: { [key: string]: string }) => {
      if (!currentNode || !generateDistro) {
        return;
      }

      const sourceDistro = { ...currentNode.data };

      delete sourceDistro.addNode;
      delete sourceDistro.editNode;

      const isNewBdg = formData.sameBuilding === 'No';
      const targetId = `i${getHighestNodeId('idf', distros) + 1}`;

      const newIdfDistroData: Partial<IDistro> = {
        connectorType: formData.connectorType as IDistro['connectorType'],
        locationId: formData.locationId || sourceDistro.locationId,
        estimateId,
        switchPorts: 0,
        phones: 0,
      };

      const newIdfConfig = {
        nodeType: 'idf' as IDistro['nodeType'],
        distros,
        distroData: newIdfDistroData,
      };

      const updatedSourceDistroData = {
        ...sourceDistro,
        // Null defaults to false on the API call so set to undefined to avoid incorrect false
        fieldOpsAccurate: sourceDistro.fieldOpsAccurate ?? undefined,
        interconnectType:
          (formData.interconnectType as IDistro['interconnectType']) ||
          sourceDistro.interconnectType,
        toNodeIds: [...(sourceDistro.toNodeIds || []), targetId],
      };

      delete updatedSourceDistroData.addNode;
      delete updatedSourceDistroData.editNode;

      const updatedSourceConfig = {
        nodeType: sourceDistro.nodeType as IDistro['nodeType'],
        distros,
        distroData: updatedSourceDistroData,
        bdgId: sourceDistro.parentNodeId,
      };

      const newBdgDistroData: Partial<IDistro> = {
        name: formData.buildingName,
        locationId: formData.locationId,
        estimateId,
      };

      const newBdgConfig = {
        nodeType: 'bdg' as IDistro['nodeType'],
        distros,
        distroData: newBdgDistroData,
      };

      const newIdf = generateDistro(
        isNewBdg
          ? newIdfConfig
          : { ...newIdfConfig, bdgId: sourceDistro.parentNodeId }
      );

      const updatedSource = generateDistro(updatedSourceConfig);

      const newBdg = generateDistro(newBdgConfig);

      const newDistros = isNewBdg
        ? [newBdg, newIdf, updatedSource]
        : [newIdf, updatedSource];

      handleSaveDistros(newDistros);
      setCurrentNode(null);

      // Auto-scroll functionality (zoom to new node)
      if (isNewBdg) {
        const previousBdg = nodes.find(
          (n) => n.id === `B${getHighestNodeId('bdg', distros)}`
        );

        if (previousBdg) {
          const {
            position: { x, y },
            height,
          } = previousBdg;
          if (height) {
            setViewport(
              { x: x + 300, y: -(y - 50 + height), zoom: 1 },
              { duration: 800 }
            );
          }
        }
      } else {
        const parentBdg = nodes.find((n) => n.id === sourceDistro.parentNodeId);

        if (parentBdg) {
          const {
            height = 0,
            position: { y: parentY },
          } = parentBdg;
          const {
            position: { y: sourceY },
          } = currentNode;
          const { x: currentX } = getViewport();

          if (height) {
            setViewport(
              {
                x: currentX,
                y: -(height + parentY - (height - sourceY) + 50),
                zoom: 1,
              },
              { duration: 800 }
            );
          }
        }
      }
    },

    [
      currentNode,
      distros,
      estimateId,
      generateDistro,
      getViewport,
      nodes,
      handleSaveDistros,
      setViewport,
    ]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    const updatedCurrent = nodes.find((n) => n.id === currentNodeId);
    if (updatedCurrent) {
      setCurrentNode(updatedCurrent);
    }
  }, [currentNodeId, nodes]);

  useEffect(() => {
    if (isFitView) {
      fitView();
    }
  }, [fitView, isFitView, screenWidth]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rcq-design-flow" ref={flowRef}>
      <EditNodeDrawer
        isOpen={!!editDrawer}
        nodeType={editDrawer}
        onClose={handleClose}
        onSave={handleSaveDistros}
        onDelete={handleDeleteDistros}
        currentNode={currentNode}
      />
      <NewConnectionModal
        isOpen={!!newConnection}
        onHide={handleClose}
        currentNode={currentNode}
        onSubmit={connectNewIdf}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={draggable}
        panOnScroll={actionsAreEnabled}
        panOnDrag={actionsAreEnabled}
        defaultPosition={isFitView ? undefined : defaultPosition}
        minZoom={0.1}
        maxZoom={2}
        fitView={isFitView}
        zoomOnDoubleClick={false}
        zoomOnScroll={actionsAreEnabled}
        zoomOnPinch={actionsAreEnabled}
        preventScrolling={false}
      >
        {actionsAreEnabled && (
          <>
            <Controls showInteractive={false} />
            <MiniMap nodeStrokeWidth={3} nodeStrokeColor="black" />
          </>
        )}
      </ReactFlow>
    </div>
  );
};

export default RCQDesignFlow;
