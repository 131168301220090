import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IField, ISubmission } from 'types';
import { queryKeys, submissionsRequest } from 'utils';

interface IUpdateSubmission {
  estimateId: string;
  locationId: string | null;
  // Current fields being answered. Used to check if estimate autobuild status may have changed based on submission value.
  fields?: IField[];
}

export const useUpdateSubmissions = ({
  estimateId,
  locationId,
  fields,
}: IUpdateSubmission) => {
  const queryClient = useQueryClient();

  const autoBuildAnswers = useMemo(
    () =>
      new Set(
        fields?.reduce((acc: string[], field) => {
          if (field.noAutobuildOptions?.length) {
            acc.push(...field.noAutobuildOptions);
          }
          return acc;
        }, [])
      ),
    [fields]
  );

  const queryKey = useMemo(() => {
    if (estimateId && locationId) {
      return queryKeys({ estimateId, locationId }).filter.submissions;
    }
    if (estimateId && !locationId) {
      return queryKeys({ estimateId }).filter.submissions;
    }
    return queryKeys().all.submissions;
  }, [estimateId, locationId]);

  const submissionQueryKeys = [
    queryKeys({ estimateId, locationId: locationId || '' }).filter.submissions,
    queryKeys({ estimateId }).filter.submissions,
    queryKeys().all.submissions,
    queryKeys({ estimateId }).filter.submissionHistory,
  ];

  // Match & update any pre-existing submissions for optimistic changes
  const updateOldSubmissions = useCallback(
    (oldSubs: Partial<ISubmission>[], newSubs: Partial<ISubmission>[]) => {
      const oldSubIds = oldSubs.map((oldSub) => oldSub.id);
      const updatedSubs = oldSubs.map((oldSub) => {
        const { id } = oldSub;
        const match = newSubs.find((newSub) => newSub.id === id);
        if (match) {
          return {
            ...oldSub,
            answer: match.answer,
          };
        }
        return oldSub;
      });
      const filteredNewSubs = newSubs.filter(
        (newSub) => !oldSubIds.includes(newSub.id)
      );
      return [...updatedSubs, ...filteredNewSubs];
    },
    []
  );

  // Counts total Devices for both access point and camera
  const countTotalDevice = useCallback((subs: ISubmission[]) => {
    return subs.reduce((acc: number, { answer }) => {
      if (answer) {
        const answers = JSON.parse(answer);
        answers.forEach((a: string[]) => {
          if (a[1] && a[0]) {
            acc += Number(a[1]);
          }
        });
      }
      return acc;
    }, 0);
  }, []);

  const mneAPCameraFieldIds = [
    '4f3c8c52-de64-401f-a9a1-c0d0f541c7c4', // mneAccessPointFieldId
    '06c2cc92-8e27-439a-b6fd-d23848e97e40', // mneCameraFieldId
  ];

  const eneAPFieldIds = [
    'a3c3f305-4590-495a-b1b0-cf8eba468aa8', // eneAccessPointFieldId
  ];

  const {
    mutate: updateSubmissions,
    isLoading: updateSubmissionsLoading,
    isSuccess: updateSubmissionsSuccess,
    isError: updateSubmissionsError,
  } = useMutation(
    (submissions: Partial<ISubmission>[]) =>
      submissionsRequest({
        endpoint: 'submissions',
        method: 'PUT',
        data: submissions,
      }),
    {
      onMutate: async (newSubmissions: Partial<ISubmission>[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousSubmissions: Partial<ISubmission>[] | undefined =
          queryClient.getQueryData(queryKey);
        // Optimistically set data
        queryClient.setQueryData(
          queryKey,
          (oldSubmissions: Partial<ISubmission>[] | undefined) =>
            oldSubmissions
              ? updateOldSubmissions(oldSubmissions, newSubmissions)
              : newSubmissions
        );

        return { previousSubmissions };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousSubmissions);
      },
      onSuccess: (res: ISubmission[], _, context) => {
        // mne,ene filter only access point and camera
        const APCameraSubmissionMNE = res.filter((s) =>
          mneAPCameraFieldIds.includes(s.fieldId)
        );

        const APSubmissionENE = res.filter((s) =>
          eneAPFieldIds.includes(s.fieldId)
        );

        // total no. of devices for access points, cameras for MNE & ENE
        const totalDevicesMNE = countTotalDevice(APCameraSubmissionMNE);
        const totalDevicesENE = countTotalDevice(APSubmissionENE);
        const isAutoBuildChange =
          res.find(s =>
            s.fieldId === 'e58dd169-e734-4346-8acc-f16ce83c5091')?.answer === 'Yes'
          || !!res.find(
            (s) =>
              (s.answer &&
                (totalDevicesMNE > 30 ||
                  totalDevicesENE > 30 ||
                  Array.from(autoBuildAnswers).some((autoBuild) => {
                    return s.answer?.includes(autoBuild);
                  }))) ||
              !!context?.previousSubmissions?.find(
                (s) =>
                  s.answer &&
                  (totalDevicesMNE <= 30 ||
                    totalDevicesENE <= 30 ||
                    Array.from(autoBuildAnswers).some((autoBuild) => {
                      return s.answer?.includes(autoBuild);
                    }))
              )
          );

        if (isAutoBuildChange) {
          queryClient.invalidateQueries(queryKeys().all.estimates);
          queryClient.invalidateQueries(
            queryKeys({ estimateId }).details.estimate
          );
        }
      },
      onSettled: () => {
        submissionQueryKeys.forEach((key) =>
          queryClient.invalidateQueries(key)
        );
      },
    }
  );

  return {
    updateSubmissions,
    updateSubmissionsLoading,
    updateSubmissionsSuccess,
    updateSubmissionsError,
  };
};
