// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Redux

// Components
import { KiteInput, KiteLoader } from '@kite/react-kite';
import { RcReviewFieldOpsUpload, RcReviewLocationHeader } from 'components';

// Hooks
import {
  useDeleteFieldOpsPicture,
  useGetLocationById,
  usePostFieldOpsPicture,
  useScrollToTop,
  useUpdateFieldOpsPictures,
} from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils

// Types
import { IPostFileToS3Response, IFieldOpsPicture } from 'types';

// Styles
import './RcReviewPicturesTab.scss';

export interface IRcReviewPicturesTabProps {
  opptyName: string;
  locationName: string;
  locationId: string;
}

type TFieldOpsPicNotesMap = { [id: string]: string };

/** Tab on the read only page that displays images and allows the user to upload new images */

const RcReviewPicturesTab = ({
  opptyName,
  locationName,
  locationId,
}: IRcReviewPicturesTabProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    data: locationData,
    isLoading,
    isError,
  } = useGetLocationById(locationId);

  const initialNotes = useMemo(() => {
    if (locationData && locationData.fieldOpsPictures) {
      return locationData.fieldOpsPictures.reduce(
        (acc: TFieldOpsPicNotesMap, picture: IFieldOpsPicture) => {
          acc[picture.id] = picture.notes;
          return acc;
        },
        {}
      );
    }
    return {};
  }, [locationData]);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useScrollToTop({ ref: scrollRef, refNestLevel: 0, locationId });

  const [notes, setNotes] = useState<TFieldOpsPicNotesMap>(initialNotes);
  const { postFieldOpsPicture } = usePostFieldOpsPicture(locationId);
  const { deleteFieldOpsPicture } = useDeleteFieldOpsPicture(locationId);
  const { updateFieldOpsPictures } = useUpdateFieldOpsPictures(locationId);

  const debounceSave = useDebouncedCallback(
    (fieldOpsPics: IFieldOpsPicture[]) => updateFieldOpsPictures(fieldOpsPics),
    350
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const notesAreDirty = useMemo(
    () => Object.entries(initialNotes).some(([id, note]) => notes[id] !== note),
    [initialNotes, notes]
  );

  // Passed to the upload component to create a new picture in the DB if S3 upload is successful
  const handleImageUpload = useCallback(
    (fieldOpsPicId: string, newFileData: IPostFileToS3Response | null) => {
      if (newFileData) {
        postFieldOpsPicture({
          title: newFileData.fileName,
          uri: newFileData.url,
          locationId,
          notes: '',
        });
      } else {
        // Delete picture if null is passed for newFileData
        deleteFieldOpsPicture(fieldOpsPicId);
      }
    },
    [locationId, postFieldOpsPicture, deleteFieldOpsPicture]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleNotesChange = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const updatedNotesValues = { ...notes, [id]: e.target.value };
      setNotes(updatedNotesValues);
      const pictureToUpdate = locationData?.fieldOpsPictures?.find(
        (pic) => pic.id === id
      );
      pictureToUpdate &&
        debounceSave([{ ...pictureToUpdate, notes: e.target.value }]);
    },
    [debounceSave, locationData, notes]
  );

  // =============================================
  // Render Methods
  // =============================================

  const renderFieldPictures = useMemo(() => {
    const { fieldOpsPictures } = locationData || {};
    if (!fieldOpsPictures || fieldOpsPictures.length === 0) {
      return <RcReviewFieldOpsUpload onImageUpload={handleImageUpload} />;
    }

    const uploadedImages = fieldOpsPictures?.map(
      (picture: IFieldOpsPicture, i: number) => {
        return (
          <div className="rc-review-pictures-tab__image" key={picture.id}>
            <h4>Picture {i + 1}</h4>
            <RcReviewFieldOpsUpload
              onImageUpload={handleImageUpload}
              fieldOpsPicture={picture}
            />
            <KiteInput
              onChange={handleNotesChange(picture.id)}
              value={notes[picture.id]}
              maxWidth="100%"
              label="Notes for this picture"
            />
          </div>
        );
      }
    );
    // Limit image uploads to 25
    uploadedImages.length < 25 &&
      uploadedImages.push(
        <div key={'uploadNewPicture'}>
          <h4>Picture {uploadedImages.length + 1}</h4>
          <RcReviewFieldOpsUpload onImageUpload={handleImageUpload} />
        </div>
      );
    return uploadedImages;
  }, [locationData, handleImageUpload, notes, handleNotesChange]);

  const renderContent = useMemo(() => {
    if (isLoading) return <KiteLoader className="rc-review-page__loader" />;
    if (isError) return <div>Something went wrong</div>;
    return renderFieldPictures;
  }, [renderFieldPictures, isLoading, isError]);

  // =============================================
  // Effects
  // =============================================

  useEffect(() => {
    if (notesAreDirty && !debounceSave.isPending()) setNotes(initialNotes);
  }, [debounceSave, initialNotes, notesAreDirty]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-pictures-tab">
      <RcReviewLocationHeader
        opptyName={opptyName}
        locationName={locationName}
      />
      <div className="rc-review-pictures-tab__content" ref={scrollRef}>
        {renderContent}
      </div>
    </div>
  );
};

export default RcReviewPicturesTab;
