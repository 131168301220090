// Packages
import React, { useCallback, useMemo, useState } from 'react';

// Redux

// Components
import { KiteAlert, KiteInput, KiteModal, KiteSelect } from '@kite/react-kite';
import { PhoneNumberTable } from 'components';

// Hooks
import {
  useAnalytics,
  useGetRateCenterSearch,
  usePostPhoneNumbers,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { stateIsoCodes } from 'utils';

// Types

// Styles
import './RateCenterSearchModal.scss';

export interface IRateCenterSearchModalProps {
  canShow: boolean;
  onClose: (locationId: string) => void;
  locationId: string;
}

/** Modal for rate center search */

const RateCenterSearchModal = ({
  canShow,
  onClose,
  locationId,
}: IRateCenterSearchModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const {
    rateCenterSearch,
    rateCenterSearchRes,
    rateCenterSearchLoading,
    rateCenterSearchError,
    resetRateCenterSearch,
  } = useGetRateCenterSearch();

  const [rcInputValue, setRcInputValue] = useState('');
  const [stateInputValue, setStateInputValue] = useState('');

  const [rcInputValueErr, setRcInputValueErr] = useState('');
  const [stateInputValueErr, setStateInputValueErr] = useState('');

  const { estimateId = '' } = useParams();

  const { postPhoneNumbers, postPhoneNumbersLoading, postPhoneNumbersError } =
    usePostPhoneNumbers(estimateId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleCloseModal = useCallback(() => {
    onClose(locationId);
    setRcInputValue('');
    setStateInputValue('');
    resetRateCenterSearch();
  }, [onClose, resetRateCenterSearch, locationId]);

  const searchRateCenter = useCallback(() => {
    if (!rcInputValue.length || !stateInputValue.length) {
      !rcInputValue.length && setRcInputValueErr('Please enter a value');
      !stateInputValue.length && setStateInputValueErr('Please enter a value');
      return;
    } else {
      setRcInputValueErr('');
      setStateInputValueErr('');
      const rcAbbre = rcInputValue.toUpperCase();
      const locState = stateInputValue.toUpperCase();
      rateCenterSearch({
        rcAbbre,
        locState,
      });
      trackSelectAction(`Rate Center Search: Lookup ${rcAbbre}/${locState}`, {
        opType: 'buttonClick',
      });
    }
  }, [rateCenterSearch, rcInputValue, stateInputValue, trackSelectAction]);

  const addPhoneNumber = useCallback(() => {
    if (rateCenterSearchRes[0]) {
      postPhoneNumbers([{ ...rateCenterSearchRes[0], locationId }]);
      trackSelectAction(
        `Rate Center Search: Save Result (${rateCenterSearchRes[0]})`,
        { opType: 'buttonClick' }
      );

      handleCloseModal();
    }
  }, [
    handleCloseModal,
    locationId,
    postPhoneNumbers,
    rateCenterSearchRes,
    trackSelectAction,
  ]);

  const handlePrimaryCtaClick = useCallback(() => {
    if (rateCenterSearchRes && rateCenterSearchRes.length) {
      return addPhoneNumber;
    } else return searchRateCenter;
  }, [addPhoneNumber, rateCenterSearchRes, searchRateCenter]);

  const handleInputChange = useCallback(
    (setState: (value: string) => void) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setState(e.target.value);
      },
    []
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderSearchData = useMemo(() => {
    if (rateCenterSearchError || postPhoneNumbersError) {
      return (
        <div className="rate-center-search-modal__message">
          Something went wrong
        </div>
      );
    } else if (rateCenterSearchRes && !rateCenterSearchRes.length) {
      return (
        <div className="rate-center-search-modal__message">
          No results found
        </div>
      );
    } else if (rateCenterSearchRes) {
      return (
        <PhoneNumberTable
          phoneNumbers={rateCenterSearchRes}
          showNpaNxx={false}
          isEstimateRCFlag={false}
        />
      );
    }
  }, [postPhoneNumbersError, rateCenterSearchError, rateCenterSearchRes]);

  const stateOptions = useMemo(() => {
    const stateOption = stateIsoCodes.map((code) => {
      return (
        <option value={code} key={`US-${code}`}>
          {code}
        </option>
      );
    });
    return [<option key="default" value="" />, ...stateOption];
  }, []);

  const ctaCopy = useMemo(() => {
    if (rateCenterSearchRes && rateCenterSearchRes.length) {
      return 'Submit';
    } else return 'Search';
  }, [rateCenterSearchRes]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="rate-center-search-modal"
      canShow={canShow}
      onHide={handleCloseModal}
      title="Search Rate Center"
      ctaCopy={ctaCopy}
      ctaLoading={rateCenterSearchLoading || postPhoneNumbersLoading}
      ctaAction={handlePrimaryCtaClick()}
      secondaryCtaCopy="Close"
      secondaryCtaAction={handleCloseModal}
    >
      <KiteAlert description="Must be on Charter Network or VPN to search rate center" />
      <div className="rate-center-search-modal__input-group">
        <KiteInput
          label="Rate Center"
          value={rcInputValue}
          onChange={handleInputChange(setRcInputValue)}
          errorMessage={rcInputValueErr}
        />
        <KiteSelect
          id="Rate Center State Select"
          name="Rate Center State Select"
          label="State"
          value={stateInputValue}
          onChange={handleInputChange(setStateInputValue)}
          errorMessage={stateInputValueErr}
        >
          {stateOptions}
        </KiteSelect>
      </div>
      {renderSearchData}
    </KiteModal>
  );
};

export default RateCenterSearchModal;
