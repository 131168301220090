import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ISubmission } from 'types';
import { queryKeys, submissionsRequest } from 'utils';

interface IUpdateSubmission {
  estimateId: string;
  sourceEstimateId?: string;
}

export const useCopySubmissions = ({
  estimateId,
  sourceEstimateId = '',
}: IUpdateSubmission) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    return queryKeys({ estimateId }).filter.submissions;
  }, [estimateId]);

  const submissionQueryKeys = [
    queryKeys({ estimateId }).filter.submissions,
    queryKeys({ estimateId }).filter.submissionHistory,
  ];

  // Match & update any pre-existing submissions for optimistic changes
  const updateOldSubmissions = useCallback(
    (oldSubs: Partial<ISubmission>[], newSubs: Partial<ISubmission>[]) => {
      const oldSubIds = oldSubs.map((oldSub) => oldSub.id);
      const updatedSubs = oldSubs.map((oldSub) => {
        const { id } = oldSub;
        const match = newSubs.find((newSub) => newSub.id === id);
        if (match) {
          return {
            ...oldSub,
            answer: match.answer,
          };
        }
        return oldSub;
      });
      const filteredNewSubs = newSubs.filter(
        (newSub) => !oldSubIds.includes(newSub.id)
      );
      return [...updatedSubs, ...filteredNewSubs];
    },
    []
  );

  const {
    mutate: copySubmissions,
    isLoading: copySubmissionsLoading,
    isSuccess: copySubmissionsSuccess,
    isError: copySubmissionsError,
  } = useMutation(
    (submissions: Partial<ISubmission>[]) =>
      submissionsRequest({
        endpoint: 'submissions',
        method: 'PUT',
        data: submissions,
      }),
    {
      onMutate: async (newSubmissions: Partial<ISubmission>[]) => {
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Get previous data in case of mutation error (see onError below)
        const previousSubmissions: Partial<ISubmission>[] | undefined =
          queryClient.getQueryData(queryKey);
        // Optimistically set data
        queryClient.setQueryData(
          queryKey,
          (oldSubmissions: Partial<ISubmission>[] | undefined) =>
            oldSubmissions
              ? updateOldSubmissions(oldSubmissions, newSubmissions)
              : newSubmissions
        );

        return { previousSubmissions };
      },
      onError: async (err, _, context) => {
        console.log(err);
        // Cancel any currently running queries for key
        await queryClient.cancelQueries(queryKey);
        // Reset query data to pre-mutation if mutation error
        queryClient.setQueryData(queryKey, context?.previousSubmissions);
      },
      onSuccess: (res: ISubmission[], _, context) => {
        queryClient.invalidateQueries(queryKeys().all.estimates);
        queryClient.invalidateQueries(
          queryKeys({ estimateId }).details.estimate
        );
        if (sourceEstimateId) {
          queryClient.invalidateQueries(
            queryKeys({ estimateId: sourceEstimateId }).details.estimate
          );
        }
      },
      onSettled: () => {
        submissionQueryKeys.forEach((key) =>
          queryClient.invalidateQueries(key)
        );
      },
    }
  );

  return {
    copySubmissions,
    copySubmissionsLoading,
    copySubmissionsSuccess,
    copySubmissionsError,
  };
};
