// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Redux

// Components

import { KiteButton, KiteCheckbox, KiteModal } from '@kite/react-kite';

// Hooks

// Utils
import { TermMonths } from 'utils';

// Types

// Styles
import './MneEneInstallOptions.scss';

import { ISubmission } from 'types';
import { useQueryData, useUpdateLocation } from 'hooks';

export interface IMneEneInstallOptionsProps {
  /** Determines if modal is open */
  canShow: boolean;

  onToggle: () => void;

  iswPrice: string | number;

  disabled: boolean;

  termField: ISubmission;
  type?: 'MNE' | 'ENE';
}

const MneEneInstallOptions = ({
  onToggle,
  canShow,
  iswPrice = 0,
  disabled = false,
  termField,
  type,
}: IMneEneInstallOptionsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { currentEstimate, currentLocation } = useQueryData();
  const { updateLocation } = useUpdateLocation(currentEstimate?.id);

  const [paymentOption, setpaymentOption] = useState(
    type === 'MNE'
      ? currentLocation?.mnePaymentOption
      : currentLocation?.enePaymentOption
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const months = Object.keys(TermMonths).map(Number);
  const rate = 0.008333;
  const resetPaymentOption = paymentOption === '' ? 'One-time' : paymentOption;

  const installMonthOptions = useMemo(() => {
    return disabled
      ? []
      : months
          .filter(
            (month) => !termField?.answer || month <= Number(termField?.answer)
          )
          .map((month) => {
            const payment = Math.round(
              (Number(iswPrice) * rate * (1 + rate) ** month) /
                ((1 + rate) ** month - 1)
            ).toFixed(2);

            const total = Math.round(month * Number(payment));
            return { payment, total };
          });
  }, [months, iswPrice, disabled, termField]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleSaveOption = useCallback(() => {
    if (currentLocation) {
      type === 'MNE'
        ? updateLocation({
            ...currentLocation,
            mnePaymentOption: resetPaymentOption,
          })
        : updateLocation({
            ...currentLocation,
            enePaymentOption: resetPaymentOption,
          });

      onToggle();
    }
  }, [type, currentLocation, updateLocation, onToggle, resetPaymentOption]);

  const handleCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;

      setpaymentOption(paymentOption === name ? '' : name);
    },
    [paymentOption]
  );

  const renderInstallmentTable = useCallback(() => {
    return (
      <table className="install-option-table">
        <thead>
          <tr>
            <td></td>
            <td>Total</td>
            <td>Monthly</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <KiteCheckbox
                key={'one-time'}
                id={'one-time'}
                label={'One-time'}
                name={'One-time'}
                checked={paymentOption === 'One-time'}
                onChange={handleCheckBox}
              />
            </td>
            <td>${iswPrice}</td>
            <td>NA</td>
          </tr>
          {months
            .filter(
              (month) =>
                !termField?.answer || month <= Number(termField?.answer)
            )
            .map((month, index) => {
              return (
                <>
                  <tr key={month}>
                    <td>
                      <KiteCheckbox
                        key={month}
                        id={month.toString()}
                        label={`${month.toString()} Month`}
                        name={month.toString()}
                        checked={paymentOption === month.toString()}
                        onChange={handleCheckBox}
                      />
                    </td>
                    <td>${installMonthOptions[index]?.total}</td>
                    <td>${installMonthOptions[index]?.payment}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    );
  }, [
    months,
    termField,
    paymentOption,
    handleCheckBox,
    iswPrice,
    installMonthOptions,
  ]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  useEffect(() => {
    setpaymentOption(
      type === 'MNE'
        ? currentLocation?.mnePaymentOption
        : currentLocation?.enePaymentOption
    );
  }, [currentLocation, type]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="install-option">
      <KiteButton
        type="standalone-link"
        onClick={onToggle}
        disabled={disabled || termField?.answer === 'MTM'}
        className={classNames({
          'install-option__toggle': true,
          'install-option__toggle--disabled':
            disabled || termField?.answer === 'MTM',
        })}
      >
        Payment Options
      </KiteButton>
      <KiteModal canShow={canShow} onHide={onToggle} className="install-option">
        <h3 className="install-option__title kite-h3">Select Payment Option</h3>
        {renderInstallmentTable()}
        <div>
          <button
            className="install-option-close-button"
            onClick={handleSaveOption}
          >
            Select
          </button>
        </div>
      </KiteModal>
    </div>
  );
};

export default MneEneInstallOptions;
