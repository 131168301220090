import axios, { AxiosError } from 'axios';
import { ISalesforceLocation, ISalesforceOpportunity } from 'types';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface ISalesforceLoginState {
  location: string;
}
interface IInitSalesforceLoginArgs {
  state: ISalesforceLoginState;
}

export const initSalesforceLogin = async ({
  state,
}: IInitSalesforceLoginArgs) => {
  const { sfUrl, sfClientId } = env;

  const redirectUri = encodeURIComponent(
    `${window.location.origin}/salesforce-login`
  );
  const statePath = encodeURIComponent(JSON.stringify(state));
  const redirectUrl = `${sfUrl}/services/oauth2/authorize?response_type=code&client_id=${sfClientId}&redirect_uri=${redirectUri}&scope=&state=${statePath}`;

  window.location.href = redirectUrl;
};

export interface IGetSalesforceAccessTokenArgs {
  code: string;
  state: string;
}

export interface IGetSalesforceAccessTokenData {
  message: string;
  data: {
    access_token: string;
    refresh_token: string;
    signature: string;
    scope: string;
    id_token: string;
    instance_url: string;
    id: string;
    token_type: string;
    issued_at: string;
  };
}

export const getSalesforceAccessToken = async ({
  code,
  state,
}: IGetSalesforceAccessTokenArgs): Promise<IGetSalesforceAccessTokenData> => {
  const url = `${env.apiUrl}/salesforce/callback?code=${code}&state=${state}`;

  try {
    const res = await axios({
      url,
      method: 'GET',
    });

    setSaleforceAxiosDefaults({
      accessToken: res.data.data.access_token,
      refreshToken: res.data.data.refresh_token,
      issuedAt: res.data.data.issued_at,
    });

    return res.data;
  } catch (error: any) {
    throw Error(error?.message || 'Something went wrong');
  }
};

interface ISalesforceAxiosArgs {
  accessToken: string;
  refreshToken: string;
  issuedAt: string;
}

export const setSaleforceAxiosDefaults = ({
  accessToken,
  refreshToken,
  issuedAt,
}: ISalesforceAxiosArgs) => {
  axios.defaults.headers.common['X-SF-AUTH'] = `Bearer ${accessToken}`;
  axios.defaults.headers.common['X-SF-RF'] = refreshToken;
  axios.defaults.headers.common['X-SF-IA'] = issuedAt;
};

const loginRedirect = () => {
  const { pathname, search } = window.location;
  const location = search ? `${pathname}${search}` : pathname;
  initSalesforceLogin({
    state: { location },
  });
};

const commonSfErrorCodes = {
  401: (_: any) => loginRedirect(),
  403: (error: any) =>
    throwQueryError({
      error,
      message: 'User has insufficient permissions in Salesforce',
    }),
};

export const getSfOppById = async (
  oppId: string
): Promise<ISalesforceOpportunity> => {
  const url = `${env.apiUrl}/salesforce/opportunities/${oppId}`;

  return axios({
    method: 'GET',
    url,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((error: AxiosError) => {
      const errorCode: number = error?.response?.status || 0;

      const allCodes = {
        ...commonSfErrorCodes,
        404: (error: AxiosError) =>
          throwQueryError({
            error,
            message: 'Opportunity not found',
          }),
      };

      const errorHandler = allCodes[errorCode as keyof typeof allCodes];

      errorHandler
        ? errorHandler(error)
        : throwQueryError({
            error,
            message: `Something went wrong retrieving estimate from Salesforce with opportunity Id: ${oppId} `,
          });
    });
};

export const getSfLocations = async (
  billingId: string
): Promise<ISalesforceLocation[]> => {
  const url = `${env.apiUrl}/salesforce/locations/${billingId}`;

  return axios({
    method: 'GET',
    url,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((error: AxiosError) => {
      const errorCode: number = error?.response?.status || 0;

      const allCodes = {
        ...commonSfErrorCodes,
        404: (error: any) =>
          throwQueryError({
            error,
            message: 'Location not found',
          }),
      };

      const errorHandler = allCodes[errorCode as keyof typeof allCodes];

      errorHandler
        ? errorHandler(error)
        : throwQueryError({
            error,
            message: `Something went wrong retrieving locations from Salesforce with billing account Id: ${billingId} `,
          });
    });
};

interface IAddProducts {
  opportunityId?: string;
  scenarioId: string;
  GenerateSRsClickRequired?: string;
}

export const addProductsToSfOpp = async (productToAdd: IAddProducts) => {
  const url = `${env.apiUrl}/salesforce/add-products`;
  productToAdd.GenerateSRsClickRequired = 'Yes';

  return await axios({
    url,
    method: 'POST',
    data: productToAdd,
  })
    .then((res) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      const errorCode: number = error?.response?.status || 0;

      const errorHandler =
        commonSfErrorCodes[errorCode as keyof typeof commonSfErrorCodes];

      errorHandler
        ? errorHandler(error)
        : throwQueryError({
            error,
            message: `Something went wrong adding products to Salesforce`,
          });
    });
};
