// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Redux

// Components
import { RcReviewPictureModal } from 'components';
import { KiteAlert, KiteButton, KiteIcon } from '@kite/react-kite';

// Hooks
import { useUploadToS3 } from 'hooks';

// Utils
import { deleteFileFromS3 } from 'utils';

// Types
import { IPostFileToS3Response, IFieldOpsPicture } from 'types';

// Styles
import './RcReviewFieldOpsUpload.scss';

export interface IRcReviewFieldOpsUploadProps {
  onImageUpload: (
    id: string,
    newFileData: IPostFileToS3Response | null
  ) => void;
  fieldOpsPicture?: IFieldOpsPicture;
}

/** Image upload component on the review-rc field ops pictures tab */

const RcReviewFieldOpsUpload = ({
  onImageUpload,
  fieldOpsPicture,
}: IRcReviewFieldOpsUploadProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState({
    url: '',
    name: '',
  });

  const { id } = fieldOpsPicture || { id: 'newFieldOpsPicture' };

  const initValue: IPostFileToS3Response | null = useMemo(() => {
    if (fieldOpsPicture) {
      return {
        url: fieldOpsPicture.uri,
        fileName: fieldOpsPicture.title,
      };
    }
    return null;
  }, [fieldOpsPicture]);

  const [file, setFile] = useState(initValue);
  const [err, setErr] = useState(false);

  const onSuccess = useCallback(
    (newFileData: IPostFileToS3Response) => {
      setErr(false);
      id !== 'newFieldOpsPicture' && setFile(newFileData);
      onImageUpload(id, newFileData);
    },
    [id, onImageUpload]
  );

  const onError = useCallback(() => setErr(true), []);

  const { uploadToS3, uploadToS3Loading } = useUploadToS3({
    onSuccess,
    onError,
  });

  const fileDropRef = useRef<HTMLInputElement>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const getFileKey = useCallback((fileData: IPostFileToS3Response | null) => {
    if (fileData) {
      const { url } = fileData;
      return url.replace(/.+\.com\//, '');
    }
  }, []);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFile = e.target.files?.item(0);
      const fileKey = getFileKey(file);
      if (newFile) {
        // if has fileKey will replace file in s3, else will create new
        uploadToS3({ file: newFile, fileKey });
      }
    },
    [file, getFileKey, uploadToS3]
  );

  const onFileDelete = useCallback(() => {
    const fileKey = getFileKey(file);
    if (fileKey) {
      deleteFileFromS3({ fileKey });
    }
    setFile(null);
    onImageUpload(id, null);
  }, [file, getFileKey, id, onImageUpload]);

  const handleFileDropClick = useCallback(() => {
    if (fileDropRef.current) {
      fileDropRef.current.click();
    }
  }, []);

  const handlePreviewExpand = useCallback(
    (url: string, name: string) => () => {
      setImageModalData({ url, name });
      setImageModalIsOpen(true);
    },
    []
  );

  const onModalClose = useCallback(() => {
    setImageModalData({ url: '', name: '' });
    setImageModalIsOpen(false);
  }, []);

  // =============================================
  // Render Methods
  // =============================================
  const uploadButton = useMemo(() => {
    return (
      <div className="rc-review-field-ops-upload__file-upload-btns">
        <KiteButton
          minWidth="180px"
          maxWidth="180px"
          onClick={handleFileDropClick}
          loading={uploadToS3Loading}
          disabled={!!file}
          type={'primary'}
        >
          <KiteIcon name={'image-preview'} margin="0 .25rem 0 0" />
          Attach Image
        </KiteButton>
        {file && (
          <KiteButton
            onClick={onFileDelete}
            maxWidth="min-content"
            minWidth="min-content"
            type="standalone-link"
          >
            <KiteIcon name="trash" />
          </KiteButton>
        )}
        {!file ? <em>No image attached</em> : <em>{file.fileName}</em>}
      </div>
    );
  }, [file, handleFileDropClick, onFileDelete, uploadToS3Loading]);

  const renderPreview = useCallback(
    (url?: string, name?: string) => {
      return (
        <div className="rc-review-field-ops-upload__preview">
          <img
            src={url}
            alt={name}
            onError={(e) => (e.currentTarget.style.display = 'none')}
          />
          {url && name && (
            <KiteButton
              className="rc-review-field-ops-upload__preview__expand-icon"
              onClick={handlePreviewExpand(url, name)}
            >
              <KiteIcon name="expand-f" margin="auto" size="40%" />
            </KiteButton>
          )}
        </div>
      );
    },
    [handlePreviewExpand]
  );

  const input = useMemo(() => {
    return (
      <div className="rc-review-field-ops-upload__file-upload">
        <input
          type="file"
          id={id}
          accept="image/*"
          ref={fileDropRef}
          onChange={onFileUpload}
          style={{ display: 'none' }}
        />
        {uploadButton}
        {renderPreview(file?.url, file?.fileName)}
        {err && (
          <KiteAlert
            type="alert"
            title="Upload Error"
            description="Something went wrong uploading your image. Any changes will not be saved. Please try again later."
          />
        )}
      </div>
    );
  }, [id, file, onFileUpload, uploadButton, renderPreview, err]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setFile(initValue);
  }, [initValue]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-field-ops-upload">
      {input}
      <RcReviewPictureModal
        isOpen={imageModalIsOpen}
        onClose={onModalClose}
        url={imageModalData.url}
        name={imageModalData.name}
      />
    </div>
  );
};

export default RcReviewFieldOpsUpload;
