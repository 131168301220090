import { useQueryData } from 'hooks';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { IEstimate, IEstimateUpdate, IScenario } from 'types';
import {
  convertSelectionForApi,
  estimateRequest,
  queryKeys,
  sandboxData,
} from 'utils';
export interface IUseUpdateEstimate {
  onNewScenarioSuccess?: (newScenarioId?: string) => void;
  onEditScenarioSuccess?: () => void;
}
export const useUpdateEstimate = (config?: IUseUpdateEstimate) => {
  const { onNewScenarioSuccess, onEditScenarioSuccess } = config || {};

  const { estimateId } = useParams();
  const { updateScenarioId } = useQueryData();
  const queryClient = useQueryClient();

  const isSandbox = estimateId === 'sandbox';
  const allEstimatesKey = queryKeys().all.estimates;
  const estimateKey = queryKeys({ estimateId }).details.estimate;
  const scenariosKey = queryKeys({ estimateId }).filter.estimateScenarios;

  const sandboxHandler = useCallback(
    async (updatedEstimate: IEstimateUpdate) => {
      sandboxData.estimate = updatedEstimate as IEstimate;
      return sandboxData.estimate;
    },
    []
  );

  const {
    mutate: updateEstimate,
    isLoading: updateEstimateLoading,
    isSuccess: updateEstimateSuccess,
    isError: updateEstimateError,
  } = useMutation(
    isSandbox
      ? sandboxHandler
      : (estimate: IEstimateUpdate) =>
          estimateRequest({
            endpoint: `estimates/${estimate.id}`,
            method: 'PUT',
            data: {
              ...estimate,
              scenarios: estimate.scenarios.map((s) => {
                const scenario = s as IScenario;
                return {
                  ...scenario,
                  selections: scenario.selections?.map((selection) =>
                    convertSelectionForApi(selection)
                  ),
                };
              }),
              submissions: estimate.submissions?.map((s) => {
                const sub = s;
                delete sub.fieldOrder;
                delete sub.formId;
                return sub;
              }),
            },
          }),
    {
      onMutate: async (updatedEstimate: IEstimateUpdate) => {
        await queryClient.cancelQueries(allEstimatesKey);
        await queryClient.cancelQueries(estimateKey);

        const oldEstimates =
          queryClient.getQueryData<IEstimate[]>(allEstimatesKey) || [];
        const oldEstimate = queryClient.getQueryData<IEstimate>(estimateKey);

        const newEstimates = [
          ...oldEstimates.filter((e) => e.id !== updatedEstimate.id),
          updatedEstimate,
        ];

        const newEstimate = { ...oldEstimate, ...updatedEstimate } as IEstimate;

        queryClient.setQueryData(allEstimatesKey, newEstimates);
        queryClient.setQueryData(estimateKey, newEstimate);

        return { oldEstimates, oldEstimate };
      },
      onSettled: () => {
        queryClient.invalidateQueries(estimateKey);
        queryClient.invalidateQueries(allEstimatesKey);
        queryClient.invalidateQueries(scenariosKey);
      },
      onError: async (err, _, context) => {
        console.log(err);

        await queryClient.cancelQueries(allEstimatesKey);
        await queryClient.cancelQueries(estimateKey);

        queryClient.setQueryData(allEstimatesKey, context?.oldEstimates);
        queryClient.setQueryData(estimateKey, context?.oldEstimate);
      },
      onSuccess: (res: IEstimate, _, context) => {
        const { oldEstimate } = context;
        const oldScenarioIds = new Set(oldEstimate?.scenarios.map((s) => s.id));
        const newScenarios = res.scenarios;
        const newScenarioId = newScenarios.find(
          (s) => !oldScenarioIds.has(s.id)
        )?.id;

        if (onNewScenarioSuccess && newScenarioId) {
          updateScenarioId(newScenarioId);
          onNewScenarioSuccess(newScenarioId);
        }
        if (onEditScenarioSuccess) {
          onEditScenarioSuccess();
        }
      },
    }
  );

  return {
    updateEstimate,
    updateEstimateLoading,
    updateEstimateSuccess,
    updateEstimateError,
  };
};
