// Packages
import React from 'react';

// Redux

// Components
import { KiteModal } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './RcReviewPictureModal.scss';

export interface IRcReviewPictureModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  name: string;
}

/** Modal that displays full sized picture in the field ops pictures tab */

const RcReviewPictureModal = ({
  isOpen,
  onClose,
  url,
  name,
}: IRcReviewPictureModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="rc-review-picture-modal"
      canShow={isOpen}
      onHide={onClose}
    >
      {React.createElement('img', {
        src: url,
        alt: name,
      })}
    </KiteModal>
  );
};

export default RcReviewPictureModal;
