// Packages
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
// Components
import { KiteIcon } from '@kite/react-kite';
// Hooks
import { useQueryData, useGetSubmissions } from 'hooks';
import { useParams } from 'react-router-dom';
// Utils
// Types
import { IField, ISalesTeamMember, ISubmissionHistory } from 'types';
// Styles
import './MNEHistoryRow.scss';
import { MneQuestionIconsMap } from 'utils/defaultsAndConstants/iconMaps';
export interface IMNEHistoryRowProps {
  historyData?: ISubmissionHistory | null;
  teamMember?: ISalesTeamMember;
  onClick?: () => void;
  headerField: IField | null;
  onSelectField?: (selectedField: IField) => void;
}
/** Row that displays a change in the RCQHistory drawer */
const MNEHistoryRow = ({
  historyData,
  teamMember,
  onClick,
  headerField,
  onSelectField,
}: IMNEHistoryRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [imgError, setImgError] = useState(false);
  const { currentEstimate } = useQueryData();
  const {
    editedBy: pid,
    formName = '',
    createdAt = '',
    locationId,
    fieldOrder,
  } = historyData || ({} as ISubmissionHistory);
  const urlParams = useParams();

  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId: urlParams.estimateId },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationName = useMemo(
    () =>
      currentEstimate?.locations.find((l) => l.id === locationId)?.name ||
      'General',
    [locationId, currentEstimate]
  );
  const { firstName, lastName } = teamMember || {
    firstName: pid,
    lastName: '',
  };
  const displayName = `${firstName} ${
    lastName.length ? lastName.split('')[0] + '.' : ''
  }`;
  const formatCreatedAt = dayjs(createdAt).format('hh:mm A');
  // =============================================
  // Interaction Handlers
  // =============================================
  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="rcq-history-row__avatar--generic"
          size="30px"
        />
      );
    return (
      <img
        src={teamMember.imageURI}
        alt={`${teamMember.fullName} avatar`}
        className="rcq-history-row__avatar"
        onError={() => setImgError(true)}
      />
    );
  }, [teamMember, imgError]);
  const rowContent = useMemo(() => {
    const hospitality = allSubmissions?.find(
      (p) => p.fieldId === 'e58dd169-e734-4346-8acc-f16ce83c5091'
    );

    const currentFormName = hospitality?.answer
      ? 'MNE Hospitality'
      : 'MNE Discovery Questions/Notes';

    if (
      formName === currentFormName ||
      formName === 'MNE Opportunity Type' ||
      formName === 'Network Draft'
    ) {
      return (
        <div className="mne-history-row__content">
          <div className="mne-history-row__title">
            {avatarImage} <span>{displayName}</span>{' '}
            <span
              className="mne-history-row__title--location"
              title={locationName}
            >
              {locationName}
            </span>
          </div>
          <div className="mne-history-row__sub-title">
            <KiteIcon
              className="mne-history-row__sub-title__icon"
              name={
                fieldOrder === 100 || fieldOrder === 101
                  ? 'info-circle-f'
                  : MneQuestionIconsMap[
                      formName === 'Network Draft'
                        ? formName
                        : headerField?.header!
                    ]
              }
            />
            <span>
              {fieldOrder === 100 || fieldOrder === 101
                ? 'Notes'
                : headerField?.header}
            </span>

            <span className="mne-history-row__sub-title--question-change">
              {headerField?.order && fieldOrder !== 101
                ? `Question ${fieldOrder - headerField?.order + 1} changed`
                : fieldOrder === 101
                ? 'Notes changed'
                : ''}
            </span>
            <span className="mne-history-row__sub-title--time">
              {formatCreatedAt}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [
    avatarImage,
    locationName,
    displayName,
    fieldOrder,
    formName,
    formatCreatedAt,
    headerField,
    allSubmissions,
  ]);
  // =============================================
  // Effects
  // =============================================
  // =============================================
  // Return
  // =============================================
  return (
    rowContent && (
      <div className="mne-history-row">
        {onClick ? <button onClick={onClick}>{rowContent}</button> : rowContent}
      </div>
    )
  );
};
export default MNEHistoryRow;
