// Packages
import React from 'react';
import classNames from 'classnames';

// Redux

// Components
import { Link } from 'react-router-dom';

// Hooks
import { useParams } from 'react-router-dom';
import { useTrackNavClick } from 'hooks';

// Utils

// Types
import { ILocation } from 'types';

// Styles
import './UcReviewSideNav.scss';

export interface IUcReviewSideNavProps {
  locations: ILocation[];
  defaultTab: String;
}

/** SideNav for UcReviewPage that lists locations */

const UcReviewSideNav = ({ locations, defaultTab }: IUcReviewSideNavProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId, locationId } = useParams();
  const { trackNavClick } = useTrackNavClick();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  // =============================================
  // Render Methods
  // =============================================
  const renderLocations = locations.map((location) => {
    return (
      <div
        className={classNames({
          'uc-review-side-nav__location-cell': true,
          'uc-review-side-nav__location-cell--active':
            locationId === location.id,
        })}
        key={location.id}
      >
        
        <Link
          to={`/review-uc/${estimateId}/${location.id + `?tab=${defaultTab}`}`}
          onClick={trackNavClick(
            `Read Only Nav: Location Change, id: ${location.id}`
          )}
        >
          {location.name}
        </Link>
      </div>
    );
  });
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-side-nav">
      <h2>Locations</h2>
      {renderLocations}
    </div>
  );
};

export default UcReviewSideNav;
