// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput } from '@kite/react-kite';
import { TextArea } from '@kite/react-kite-plus';
import { UCQCopyButton, UCQPrecheck } from '..';

// Hooks

// Utils
import { handleNumInputKeydown } from 'utils';

// Types
import { IUCQInputProps } from 'types';

// Styles
import '../UCQFieldStyles.scss';

export interface IUCQBasicInputProps extends IUCQInputProps {
  inputType: 'text' | 'number' | 'text-area';
}

/** Custom input for 'text', 'text-area', 'text-copy', 'number-text', or 'number-copy' inputTypes on UCQ Field */
const UCQBasicInput = ({
  fieldInput,
  onFieldChange,
  isCopy,
  inputType,
}: IUCQBasicInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { id, inputName, isPrecheck, value, options } = fieldInput;
  const [text, setText] = useState(value || '');
  const [precheckValue, setPrecheckValue] = useState(
    value === 'No' ? 'No' : 'Yes'
  );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // 'options' should have range for number-text input type
  const [min, max] = options || [];
  const isVisible = isPrecheck ? precheckValue === 'Yes' : true;

  // =============================================
  // Interaction Handlers
  // =============================================
  const onPrecheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPrecheckValue(e.target.value);
      if (e.target.value === 'No') {
        onFieldChange(id, 'No');
      } else {
        if (text === 'No') {
          setText('');
        }
        onFieldChange(id, text === 'No' ? null : text);
      }
    },
    [id, onFieldChange, text]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      setText(value);
      onFieldChange(id, value);
    },
    [id, onFieldChange]
  );

  // =============================================
  // Render Methods
  // =============================================
  const input = useMemo(() => {
    const inputProps =
      inputType === 'number' ? { type: 'number', min, max } : {};

    const placeholder =
      inputType === 'number' ? 'Enter Quantity' : 'Enter Text';

    const onKeyDown =
      inputType === 'number' ? handleNumInputKeydown : undefined;

    const inputComponent =
      inputType === 'text-area' ? (
        <TextArea
          name={inputName}
          id={id}
          value={text}
          onChange={onChange}
          rows="5"
        />
      ) : (
        <KiteInput
          name={inputName}
          id={id}
          maxWidth="none"
          value={text}
          onChange={onChange}
          onKeyDown={onKeyDown}
          inputProps={inputProps}
          placeholder={placeholder}
          disabled={id === '706bc43b-96a3-4f73-9737-d9a118964c69'}
        />
      );
    if (isCopy) {
      return (
        <div className="ucq-field__copy-container">
          {inputComponent}
          <UCQCopyButton value={text} />
        </div>
      );
    }

    return inputComponent;
  }, [id, inputName, inputType, isCopy, max, min, onChange, text]);

  // =============================================
  // Effects
  // =============================================
  // Set max num if input is higher than max
  useEffect(() => {
    if (inputType === 'number') {
      const textNum = parseInt(text);
      const maxNum = parseInt(max);
      if (text && textNum > maxNum) {
        setText(max);
        onFieldChange(id, max);
      }
    }
  }, [id, inputType, max, onFieldChange, text]);

  useEffect(() => {
    setText(value || '');
  }, [value]);

  useEffect(() => {
    setPrecheckValue(value === 'No' ? 'No' : 'Yes');
  }, [value]);

  // =============================================
  // Early Return (no precheck)
  // =============================================
  if (!isPrecheck) {
    return input;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-field__precheck-container">
      <UCQPrecheck
        fieldInput={fieldInput}
        precheckValue={precheckValue}
        onChange={onPrecheckChange}
      />
      {isVisible && input}
    </div>
  );
};

export default UCQBasicInput;
