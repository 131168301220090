// Packages
import React, { MutableRefObject, useMemo } from 'react';

// Redux

// Components
import { SmeApprovalBadge, SparcBadge, IswBadge } from 'components';

// Hooks

// Utils
import { isEstimateAutobuild } from 'utils';

// Types
import { IEstimate, ISmeAnswer } from 'types';

// Styles
import './UCQReminders.scss';

export interface IUCQRemindersProps {
  /** Determines if submission answers should trigger need for SME approval */
  requiresSmeApproval: boolean;
  /** Current estimate */
  estimate?: IEstimate;
  /** Determines whether to show the "Reminders" header above badges */
  showHeader?: boolean;
  /** SME triggering fields */
  smeInputAnswers?: MutableRefObject<ISmeAnswer[] | null>;
  /** Determines if submission answers should trigger need for DRT Pre Contract */
  requiresSparcPreContract: boolean;
}

/** Displays badges (SME Approval, Sparc, ISW) for UCQ page */

const UCQReminders = ({
  requiresSmeApproval = false,
  estimate,
  showHeader = true,
  smeInputAnswers,
  requiresSparcPreContract = false,
}: IUCQRemindersProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const contractType: 'pre' | 'post' = useMemo(
    () => (isEstimateAutobuild(estimate) ? 'post' : 'pre'),
    [estimate]
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-reminders">
      {showHeader && <span className="ucq-reminders__label">Reminders</span>}
      <div className="ucq-reminders__badges">
        <SmeApprovalBadge
          approvalNeeded={requiresSmeApproval}
          smeInputAnswers={smeInputAnswers}
        />
        {requiresSparcPreContract && <SparcBadge />}
        {contractType === 'pre' && <IswBadge />}
      </div>
    </div>
  );
};

export default UCQReminders;
