import React, { useCallback, useMemo } from 'react';

// Components
import { KiteAlert, KiteCard } from '@kite/react-kite';
import { SmartExpansionPanel } from '@kite/react-kite-plus';
import { CustomInput } from 'components';

// Hooks
import { useQueryDataContext } from 'hooks';

// Utils
import { informationAlerts, getMinMaxValue } from 'utils';

// Types
import { IProduct, IProductFamily, ISelectionController } from 'types';

// Styles
import './QuantityCard.scss';

interface IQuantityCard extends Omit<ISelectionController, 'selections'> {
  productFamily: IProductFamily;
  /** Determines whether expansion panel or card is displayed */
  expandable?: boolean;
  /** Minimum value the input can have */
  minValue?: string;
  /** Maximum value the input can have */
  maxValue?: string;
}

export const QuantityCard = ({
  productFamily,
  expandable = false,
  minValue,
  maxValue,
  onChange,
}: IQuantityCard) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    currentSelections: selections,
    allSelections,
    allLocations,
  } = useQueryDataContext();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const familyNotes = useMemo(() => {
    if (productFamily.description) {
      return (
        <KiteAlert
          type="info"
          title="Note"
          description={productFamily.description}
        />
      );
    }
  }, [productFamily.description]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderNotes = useCallback((product: IProduct) => {
    const notes = product.notes.map((note, i) => (
      <li key={`${product.name} note ${i}`}>{note}</li>
    ));
    return <ul>{notes}</ul>;
  }, []);

  const renderAlert = useCallback(
    (product: IProduct) => {
      const isBAPProduct = [
        'Business Analytics Pro (12 Months)',
        'Business Analytics Pro (24 Months)',
        'Business Analytics Pro (36 Months)',
      ].includes(product.name);

      const seatSelection = allSelections.some(
        (p) => p.familyName === 'RC Seats' && p.quantity > 0
      );

      const alertInfo =
        !isBAPProduct || !seatSelection
          ? informationAlerts(productFamily.name, selections)
          : null;

      if (
        productFamily.name === 'RC Add Ons' &&
        product.name.includes('Business Analytics Pro (')
      ) {
        const selectedInOtherLocation = allSelections.find(
          (s) =>
            s.familyName === 'RC Add Ons' &&
            s.name.includes('Business Analytics Pro') &&
            s.quantity > 0 &&
            s.locationId !== selections[0]?.locationId
        );

        if (selectedInOtherLocation) {
          const locationName =
            allLocations.find(
              (l) => l.id === selectedInOtherLocation.locationId
            )?.name || 'Unknown Location';
          return (
            <KiteAlert
              title="Business Analytics Pro Already Selected"
              description={`Business Analytics Pro is already selected for location "${locationName}"`}
              level="page"
              type="info"
              margin="10px"
            />
          );
        }
      }

      const quantity =
        selections.find((p) => p.productId === product.id)?.quantity || 0;

      if (
        product.id === 'fdafdad6-72c3-447b-923a-8f7b5e2efc72' &&
        quantity > 30
      ) {
        return (
          <KiteAlert
            title="Quantity Limit Exceeded"
            description="A SPARC is required for Inside Wiring > 30 drops"
            level="page"
            type="alert"
            margin="10px"
          />
        );
      }

      if (alertInfo && quantity) {
        return (
          <KiteAlert
            title={alertInfo.title}
            description={alertInfo.description}
            level={alertInfo.level}
            type={alertInfo.type}
            margin="10px"
          />
        );
      }
      return null;
    },
    [selections, productFamily.name, allSelections, allLocations]
  );

  const renderCardContent = useCallback(() => {
    return productFamily?.products.map((product, i) => {
      const minMaxSelection = getMinMaxValue(productFamily.name, product.name);
      return (
        <div key={`${product.name} ${i}`} className="quantity-card__content">
          {renderAlert(product)}
          <h4>{product.name}</h4>

          <div className="quantity-card__content-body">
            {renderNotes(product)}
            <CustomInput
              productFamily={productFamily}
              product={product}
              maxWidth="84px"
              minValue={minMaxSelection.minValue ?? minValue}
              maxValue={minMaxSelection.maxValue ?? maxValue}
              onChange={onChange}
            />
          </div>
        </div>
      );
    });
  }, [productFamily, renderAlert, renderNotes, minValue, maxValue, onChange]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  if (expandable) {
    return (
      <SmartExpansionPanel
        className="quantity-card quantity-card--expandable"
        title={productFamily.name}
      >
        {familyNotes}
        {renderCardContent()}
      </SmartExpansionPanel>
    );
  }

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="quantity-card quantity-card--static">
      {familyNotes}
      {renderCardContent()}
    </KiteCard>
  );
};
