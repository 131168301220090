// Packages
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteButton, KiteCheckbox, KiteModal } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './SelectSolutionModal.scss';

export interface ISelectSolutionModalProps {
  /** Determines if modal is open */
  canShow: boolean;
  /** Solution that is currently selected */
  solution: string;
  /** Callback to handle save functionality*/
  onSave: (selectedSolution: string) => void;
  /** Callback to handle save functionality*/
  onToggle: () => void;
  /** Modal should be disabled */
  disabled?: boolean;
}

const SelectSolutionModal = ({
  canShow,
  solution = '',
  onSave,
  onToggle,
  disabled = false,
}: ISelectSolutionModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const label = 'Select Solution';

  const [selectedSolution, setSelectedSolution] = useState(solution);
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleCheck = useCallback(
    (checkedSolution: string) => {
      if (selectedSolution === '') {
        setSelectedSolution(checkedSolution);
      } else {
        setSelectedSolution(
          selectedSolution === checkedSolution ? '' : checkedSolution
        );
      }
    },
    [selectedSolution]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setSelectedSolution(solution);
  }, [canShow, solution]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="select-solution-modal">
      <KiteButton
        className={classNames({
          'select-solution-modal__toggle': true,
          'select-solution-modal__toggle--disabled': disabled,
        })}
        type="primary"
        maxWidth="min-content"
        minWidth="min-content"
        onClick={onToggle}
        disabled={disabled}
      >
        {label}
      </KiteButton>
      <KiteModal
        canShow={canShow}
        onHide={onToggle}
        title={'Select Solution'}
        ctaCopy="Save"
        ctaAction={() => onSave(selectedSolution)}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={onToggle}
      >
        <KiteCheckbox
          label={'MNE Solution'}
          id={'MNE'}
          name={'MNE Solution'}
          checked={selectedSolution === 'MNE'}
          onChange={() => handleCheck('MNE')}
        />
        <KiteCheckbox
          label={'ENE Solution'}
          id={'ENE'}
          name={'ENE Solution'}
          checked={selectedSolution === 'ENE'}
          onChange={() => handleCheck('ENE')}
        />
      </KiteModal>
    </div>
  );
};

export default SelectSolutionModal;
