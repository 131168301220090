// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Redux

// Components
import {
  KiteAlert,
  KiteButton,
  KiteCheckbox,
  KiteModal,
} from '@kite/react-kite';

// Hooks
import { useQueryData } from 'hooks';

// Utils
import { deepCopy } from 'utils';

// Types
import { ILocation } from 'types';

// Styles
import './CopyModal.scss';

export interface ICopyModalProps {
  /** Determines form name to display in header */
  formName: string;
  /** Determines if modal is open */
  canShow: boolean;
  /** Locations to copy to */
  locations: ILocation[];
  /** Callback to handle copy functionality */
  onCopy: () => void;
  /** Callback to toggle modal visibility */
  onToggle: () => void;
  /** Current form if submissions are being copied */
  onHandleLocationSelections: (selections: any) => void;
  /** Modal should be disabled if scenario does not have more than one location */
  disabled?: boolean;
  /** Determines if error needs to be displayed */
  displayError?: boolean;
}

export interface ISelectedOption extends ILocation {
  checked: boolean;
}

/** Copy modal with button, allows user to copy location submission answers  */

const CopyModal = ({
  formName,
  onToggle,
  canShow,
  onCopy,
  onHandleLocationSelections,
  locations,
  disabled = false,
  displayError = false,
}: ICopyModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const label = 'Copy Responses';
  const { currentLocation } = useQueryData();
  const [checkboxOptions, setCheckboxOptions] = useState<ISelectedOption[]>([]);
  const [checkAllLocations, setCheckAllLocations] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const createOption = useCallback((option: ILocation, checked: boolean) => {
    return {
      ...option,
      checked,
    };
  }, []);

  // =============================================
  // Interaction Handlers
  // =============================================

  const onCancel = useCallback(() => {
    onToggle();
  }, [onToggle]);

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      const options = deepCopy(checkboxOptions);
      if (name === 'all locations') {
        options.forEach((o) => (o.checked = !checkAllLocations));
        setCheckAllLocations(!checkAllLocations);
      } else {
        const indexToUpdate = options.findIndex(
          (l: ISelectedOption) => l.name === name
        );
        options[indexToUpdate].checked = !options[indexToUpdate].checked;
        if (checkAllLocations) setCheckAllLocations(false);
        if (options.every((o) => o.checked)) setCheckAllLocations(true);
      }
      setCheckboxOptions(options);
      const selections = options
        .filter((o) => o.checked)
        .map(({ checked, ...keepAttrs }) => keepAttrs);
      onHandleLocationSelections(selections);
    },
    [checkboxOptions, checkAllLocations, onHandleLocationSelections]
  );

  // =============================================
  // Render Methods
  // =============================================

  const locationsToDisplay = useMemo(
    () =>
      checkboxOptions.map((l) => (
        <KiteCheckbox
          key={l.id}
          id={l.id}
          label={l.name}
          name={l.name}
          checked={l.checked || false}
          onChange={handleCheckboxChange}
        />
      )),
    [handleCheckboxChange, checkboxOptions]
  );

  // =============================================
  // Effects
  // =============================================

  useEffect(() => {
    if (canShow) {
      setCheckAllLocations(false);
      const locationOptions: ISelectedOption[] =
        locations.map((l) => {
          return createOption(l, false);
        }) || [];
      setCheckboxOptions(locationOptions);
    }
  }, [canShow, locations, createOption]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="copy-modal">
      <KiteButton
        className={classNames({
          'copy-modal__toggle': true,
          'copy-modal__toggle--disabled': disabled,
        })}
        type="primary"
        maxWidth="min-content"
        minWidth="min-content"
        onClick={onCancel}
        disabled={disabled}
      >
        {label}
      </KiteButton>
      <KiteModal
        canShow={canShow}
        onHide={onCancel}
        title={label}
        ctaCopy="Copy to location(s)"
        ctaAction={onCopy}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={onCancel}
      >
        <div className="copy-modal__header">
          <h2>{formName}</h2>
        </div>
        <div className="copy-modal__locations">
          <h3>Source :</h3>
          <p>{currentLocation?.name}</p>
          <h3>Locations :</h3>
          <p>Please select at least one (1) location</p>
          <KiteCheckbox
            key={'allLocations'}
            id={'allLocations'}
            label={'Select all locations'}
            name={'all locations'}
            checked={checkAllLocations}
            onChange={handleCheckboxChange}
          />
          {locationsToDisplay}
        </div>
        {displayError && (
          <KiteAlert
            type="alert"
            description="Please select at least one (1) location"
          />
        )}
      </KiteModal>
    </div>
  );
};

export default CopyModal;