// Packages
import React, { useEffect, useMemo, useState, useRef } from 'react';

// Redux

// Components
import {
  RcReviewHeader,
  RcReviewSideNav,
  RcReviewSubmissions,
  RcReviewPicturesTab,
  RcReviewValidation,
  RcReviewCpwTab,
  RcReviewRateCenterTab,
  RcReviewEstimateProductsTab,
} from 'components';
import { KiteLoader, KiteTabs } from '@kite/react-kite';

// Hooks
import {
  useAnalytics,
  useGetDistros,
  useGetEstimateDetails,
  useGetEstimateScenarios,
  useGetFields,
  useGetForms,
  useGetProfiles,
  useGetSubmissions,
  useTrackNavClick,
} from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// Utils
import { CPWFORMS, isEstimateAutobuild, RCQFORMS } from 'utils';

// Types
import { IForm, ILocation } from 'types';

// Styles
import './RcReviewPage.scss';

export type TTabOptions =
  | 'RC Questions'
  | 'Field Validation'
  | 'CPW Quote'
  | 'Field Pictures'
  | 'Rate Center Validation'
  | 'Estimate Products';

const RcReviewPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { trackNavClick } = useTrackNavClick();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState<TTabOptions>('RC Questions');
  const { estimateId, locationId } = useParams();
  const {
    estimateData,
    estimateLoading,
    error: estimateError,
  } = useGetEstimateDetails(estimateId);

  useGetEstimateScenarios({
    params: { estimateId },
  });
  useGetProfiles();

  const { name: estimateName, locations, customerName } = estimateData || {};

  const formParams = useMemo(() => {
    if (currentTab === 'CPW Quote') {
      return {
        params: {
          type: CPWFORMS,
        },
      };
    } else {
      return {
        params: {
          type: RCQFORMS,
        },
      };
    }
  }, [currentTab]);

  const {
    data: formsData,
    isLoading: formsLoading,
    error: formsError,
  } = useGetForms(formParams);

  const submissionParams = useMemo(() => {
    if (currentTab === 'CPW Quote' && formsData) {
      return { params: { estimateId, locationId, formId: formsData[0].id } };
    } else {
      return { params: { estimateId } };
    }
  }, [currentTab, estimateId, formsData, locationId]);

  const {
    data: submissions,
    isLoading: submissionsLoading,
    error: submissionsError,
  } = useGetSubmissions(submissionParams);

  const {
    data: fields,
    isLoading: fieldsLoading,
    error: fieldsError,
  } = useGetFields();

  const { data: distrosData, isLoading: distrosAreLoading } = useGetDistros({
    params: { estimateId },
  });

  const mainContentRef = useRef<HTMLDivElement | null>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentLocation = useMemo(() => {
    return locations?.find((loc: ILocation) => loc.id === locationId);
  }, [locations, locationId]);

  const isLoading =
    estimateLoading ||
    formsLoading ||
    submissionsLoading ||
    distrosAreLoading ||
    fieldsLoading;

  const isAutoBuild = isEstimateAutobuild(estimateData);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleTabChange = (value: string) => {
    setCurrentTab(value as TTabOptions);
    trackNavClick(`Read Only Nav: Tab Change, ${value}`)();
    navigate({ search: `?tab=${value}` });
  };

  const handlePrint = useReactToPrint({
    content: () => mainContentRef.current,
  });

  // =============================================
  // Render Methods
  // =============================================
  const tabOptions: TTabOptions[] = [
    'RC Questions',
    'Field Validation',
    'CPW Quote',
    'Rate Center Validation',
    'Field Pictures',
    'Estimate Products',
  ];

  const renderMainContent = useMemo(() => {
    if (currentTab === 'RC Questions') {
      return formsData && submissions && currentLocation ? (
        <RcReviewSubmissions
          opptyName={estimateName || ''}
          location={currentLocation}
          forms={formsData}
          submissions={submissions}
          distros={distrosData || []}
          refForPrint={mainContentRef}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Field Validation') {
      return currentLocation ? (
        <RcReviewValidation
          opptyName={estimateName || ''}
          location={currentLocation}
          submissions={submissions || []}
          fields={fields || []}
          distros={distrosData || []}
          refForPrint={mainContentRef}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'CPW Quote') {
      return currentLocation ? (
        <RcReviewCpwTab
          opptyName={estimateName || ''}
          locationName={currentLocation.name}
          formData={
            formsData ? formsData[0] : ({ fields: [] } as Partial<IForm>)
          }
          businessName={customerName || ''}
          address1={currentLocation.address1}
          address2={currentLocation.address2}
          submissions={submissions || []}
          refForPrint={mainContentRef}
          isLoading={isLoading}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Field Pictures') {
      return currentLocation ? (
        <RcReviewPicturesTab
          opptyName={estimateName || ''}
          locationName={currentLocation.name}
          locationId={currentLocation.id}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Rate Center Validation') {
      return currentLocation ? (
        <RcReviewRateCenterTab
          opptyName={estimateName || ''}
          location={currentLocation}
          refForPrint={mainContentRef}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Estimate Products') {
      return estimateData ? (
        <RcReviewEstimateProductsTab refForPrint={mainContentRef} />
      ) : (
        <div>Something went wrong</div>
      );
    } else {
      return null;
    }
  }, [
    currentTab,
    formsData,
    submissions,
    currentLocation,
    estimateName,
    distrosData,
    fields,
    customerName,
    isLoading,
    estimateData,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('RcReviewPage'), [trackPageView]);

  useEffect(() => {
    const tabParam = search.split('?tab=')[1]?.replaceAll('%20', ' ');
    if (tabParam && tabParam !== currentTab) {
      setCurrentTab(tabParam as TTabOptions);
    } else if (!tabParam) {
      // Set search if currentTab is not in the url
      navigate({ search: `?tab=${currentTab}` });
    }
  }, [search, currentLocation, navigate, currentTab]);

  // =============================================
  // Early Return
  // =============================================
  if (isLoading && currentTab !== 'CPW Quote') {
    // Handle loading state separately in CPW tab to avoid entire tab rerender
    return <KiteLoader className="rc-review-page__loader" />;
  }

  if (estimateError || formsError || submissionsError || fieldsError) {
    return <div>Something went wrong</div>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-page">
      <RcReviewHeader
        opptyName={estimateName || ''}
        isAutoBuild={isAutoBuild}
        handlePrint={handlePrint}
      />
      <div className="rc-review-page__content">
        {locations && locations.length > 1 && (
          <RcReviewSideNav locations={locations} />
        )}
        <div className="rc-review-page__content--main">
          <KiteTabs
            tabs={tabOptions}
            currentTab={currentTab}
            onSelectTab={handleTabChange}
          />
          {renderMainContent}
        </div>
      </div>
    </div>
  );
};

export default RcReviewPage;
