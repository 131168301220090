// Packages
import React, { useCallback, useMemo, MutableRefObject, useRef } from 'react';

// Redux

// Components
import { DesignFlowNotes } from 'components/rcQuestions';
import { MneReviewLocationHeader } from 'components';
// Hooks
import { useParams } from 'react-router-dom';
import {
  MNEuseGetForms,
  useFlowNodes,
  useFormRules,
  useGenerateISWPricing,
  //useRenderReviewUcFields,
  useQueryData,
  useRenderReviewMneFields,
  useScrollToTopRC,
} from 'hooks';

// Utils

// Types
import {
  IField,
  MForm,
  ISubmission,
  IDistro,
  ILocation,
  IFieldValues,
} from 'types';

// Styles
import './UcReviewMneQuestions.scss';
import FieldValidationHeader from 'components/FieldValidationHeader';
import {
  ENEFORMS,
  eneSEEthernetDrop,
  MNEFORMS,
  mneSEEthernetDrop,
} from 'utils/defaultsAndConstants/constants';
import { UCQDesignFlow } from 'components/ucQuestions';

export interface IUcReviewMneQuestionsProps {
  opptyName: string;
  location: ILocation;
  forms: MForm[];
  submissions: ISubmission[];
  distros: IDistro[];
  tabName?: string;
  refForPrint: MutableRefObject<HTMLDivElement | null>;
  showAlert: Boolean;
}

/** Displays submission data on the UcReviewPage */

const UcReviewMneQuestions = ({
  opptyName,
  location,
  forms,
  submissions,
  distros,
  refForPrint,
  tabName,
  showAlert,
}: IUcReviewMneQuestionsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId } = useParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useScrollToTopRC({ ref: scrollRef, refNestLevel: 0, locationId });

  const fields = useMemo(
    () =>
      forms.reduce((acc: IField[], form) => {
        acc.push(...form.fields);
        return acc;
      }, []),
    [forms]
  );

  const { currentEstimate } = useQueryData();

  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  const fieldValues: IFieldValues = useMemo(() => {
    return fields.reduce((acc: IFieldValues, field) => {
      acc[field.id] =
        submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
      return acc;
    }, {});
  }, [fields, submissions]);

  const { data: locationForms } = MNEuseGetForms({
    params: {
      location: 1,
      type: tabName === 'MNE Questions' ? MNEFORMS : ENEFORMS,
    },
  });

  const { numberofSEdrops } = useGenerateISWPricing(
    submissions.filter((s) => s.locationId === location.id),
    tabName === 'MNE Questions' ? 'MNE' : 'ENE'
  );

  const estimateLocationIds = useMemo(
    () => currentEstimate?.locations.map((l) => l.id) || [],
    [currentEstimate]
  );

  const locationFields = useMemo(
    () =>
      locationForms?.map((locationForm) => locationForm.fields).flat() || [],
    [locationForms]
  );

  const locationFieldValues = useMemo(() => {
    return estimateLocationIds.reduce(
      (
        acc: { [locationId: string]: { [fieldId: string]: string | null } },
        locationId
      ) => {
        const fieldValues = locationFields.reduce(
          (acc: { [fieldId: string]: string | null }, field) => {
            const subMatch =
              submissions.find(
                (sub) =>
                  sub.locationId &&
                  sub.locationId === locationId &&
                  sub.fieldId === field.id
              )?.answer || null;
            acc[field.id] = subMatch;
            return acc;
          },
          {}
        );
        acc[locationId] = fieldValues;

        return acc;
      },
      {}
    );
  }, [estimateLocationIds, locationFields, submissions]);

  const { ruleMap, ruleKeys, locationRuleMap } = useFormRules({
    fieldValues,
    formRules,
    locationFieldValues,
  });

  const { renderForm, formatFields, renderGridFields } =
    useRenderReviewMneFields({
      location,
      ruleMap,
      ruleKeys,
      tabName,
      locationRuleMap,
    });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const nodeSize = useMemo(() => ({ width: 200, height: 267.22 }), []);
  const { height: networkDraftHeight } = useFlowNodes({
    distros,
    nodeSize,
  });

  // =======================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderDesignFlow = useCallback(
    (name: string, fieldId: string) => {
      return !distros.length ? null : (
        <div
          className="mne-review-submissions__form mne-review-submissions__form--design-flow"
          key={fieldId}
        >
          <FieldValidationHeader label={name} />
          {!!distros.length && (
            <div
              style={{
                height: networkDraftHeight,
              }}
            >
              <UCQDesignFlow
                distros={distros}
                draggable={false}
                isFitView
                actionsAreEnabled={false}
              />
            </div>
          )}
          <DesignFlowNotes isOpen />
        </div>
      );
    },
    [distros, networkDraftHeight]
  );

  const renderDiscoveryForms = useCallback(
    (params: {
      formSubmissions: ISubmission[];
      generalDiscFields: IField[];
      currentFormGenSubs: number;
    }) => {
      const { formSubmissions, generalDiscFields, currentFormGenSubs } = params;
      const renderGeneralDiscFields = formatFields(
        generalDiscFields,
        formSubmissions
      );

      return (
        <div
          className="mne-review-submissions__form"
          key="Discovery Questions/Notes"
        >
          {currentFormGenSubs !== 0 &&
            renderGeneralDiscFields.gridFields[0].elements.length >= 0 && (
              <>
                <FieldValidationHeader
                  label={
                    tabName === 'MNE Questions'
                      ? 'MNE Opportunity Type'
                      : 'ENE Opportunity Type'
                  }
                />
                {renderGridFields(renderGeneralDiscFields.gridFields)}
                {renderGeneralDiscFields.fullWidthFields}
              </>
            )}
        </div>
      );
    },
    [formatFields, renderGridFields, tabName]
  );

  const renderLocDiscoveryForms = useCallback(
    (params: {
      locationSpecificFields: IField[];
      formSubmissions: ISubmission[];
      labelName: string;
    }) => {
      const { formSubmissions, locationSpecificFields, labelName } = params;
      const renderLocationDiscFields = formatFields(
        locationSpecificFields,
        formSubmissions
      );

      return (
        <div
          className="mne-review-submissions__form"
          key="Discovery Questions/Notes"
        >
          {renderLocationDiscFields.gridFields[0].elements.length >= 0 && (
            <>
              <FieldValidationHeader label={labelName} />
              {renderGridFields(renderLocationDiscFields.gridFields)}
              {renderLocationDiscFields.fullWidthFields}
            </>
          )}
        </div>
      );
    },
    [formatFields, renderGridFields]
  );

  const renderAllFormSubmissions = useMemo(() => {
    const formSubmissions = () => {
      const { id: generalDiscFormId = '', fields: generalDiscFields = [] } =
        forms.find(
          (f) =>
            f.name === 'MNE Opportunity Type' ||
            f.name === 'ENE Opportunity Type'
        ) ?? {};
      return forms
        .sort((f1, f2) => f1.order - f2.order)
        .map(({ id, name, fields }) => {
          console.log('name=>', name);
          if (name === 'Network Draft') {
            return renderDesignFlow(name, id);
          } else if (
            name === 'MNE Opportunity Type' ||
            name === 'ENE Opportunity Type'
          ) {
            const currentFormSubs = submissions?.filter(
              (sub) => sub.formId === generalDiscFormId
            );
            if (currentFormSubs.length === 0)
              return renderForm(name, generalDiscFields, currentFormSubs);
            return renderDiscoveryForms({
              generalDiscFields,
              formSubmissions: currentFormSubs,
              currentFormGenSubs: currentFormSubs.length,
            });
          }
          let labelName = '';
          const hospitality: any = submissions?.find(
            (p) => p.fieldId === 'e58dd169-e734-4346-8acc-f16ce83c5091'
          );
          if (tabName === 'MNE Questions' && hospitality?.answer === 'Yes') {
            labelName = 'MNE Hospitality';
          } else if (
            tabName === 'MNE Questions' &&
            (hospitality === undefined ||
              hospitality?.answer === 'No' ||
              hospitality?.answer === '')
          ) {
            labelName = 'MNE Discovery Questions/Notes';
          } else if (tabName === 'ENE Questions') {
            labelName = 'ENE Discovery Questions/Notes';
          }

          if (
            (name === 'MNE Discovery Questions/Notes' &&
              (hospitality === undefined ||
                hospitality?.answer === 'No' ||
                hospitality?.answer === '')) ||
            name === 'ENE Discovery Questions/Notes'
          ) {
            const currentFormSubsWithNoHospitality = submissions?.filter(
              (sub) =>
                sub.formId === id &&
                locationId === sub.locationId &&
                sub.formId !== '751f9001-3062-43e8-9782-e18634b55a20'
            );
            if (currentFormSubsWithNoHospitality.length === 0)
              return renderForm(name, fields, currentFormSubsWithNoHospitality);

            currentFormSubsWithNoHospitality.push({
              id: '',
              answer: String(numberofSEdrops),
              createdAt: '',
              updatedAt: '',
              comment: '',
              fieldId:
                tabName === 'MNE Questions'
                  ? mneSEEthernetDrop
                  : eneSEEthernetDrop,
              locationId: locationId!,
              estimateId: currentEstimate?.id!,
              editedBy: '',
            });

            return renderLocDiscoveryForms({
              locationSpecificFields: fields,
              formSubmissions: currentFormSubsWithNoHospitality,
              labelName: labelName,
            });
          } else if (
            name === 'MNE Hospitality' &&
            hospitality?.answer === 'Yes'
          ) {
            const currentFormSubsWithHospitality = submissions?.filter(
              (sub) =>
                sub.formId === id &&
                locationId === sub.locationId &&
                sub.formId === '751f9001-3062-43e8-9782-e18634b55a20'
            );
            if (currentFormSubsWithHospitality.length === 0)
              return renderForm(name, fields, currentFormSubsWithHospitality);
            return renderLocDiscoveryForms({
              locationSpecificFields: fields,
              formSubmissions: currentFormSubsWithHospitality,
              labelName: labelName,
            });
          }
        });
    };
    return formSubmissions;
  }, [
    forms,
    submissions,
    renderDiscoveryForms,
    locationId,
    renderForm,
    renderDesignFlow,
    renderLocDiscoveryForms,
    tabName,
    currentEstimate,
    numberofSEdrops,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="mne-review-submissions"
      id="mne-review-submissions"
      ref={refForPrint}
    >
      <div className="mne-review-submissions__header-container">
        <MneReviewLocationHeader
          opptyName={opptyName}
          locationName={location.name}
          hasHistory
          hasProgress
          isMNE={tabName === 'MNE Questions'}
          forms={forms}
        />
      </div>
      <div
        className="mne-review-submissions__estimate-review-contaier"
        ref={scrollRef}
      >
        {renderAllFormSubmissions()}
      </div>
      <div></div>
    </div>
  );
};

export default UcReviewMneQuestions;
