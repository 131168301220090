import { offerRequest, queryKeys } from 'utils';

import { IOfferData } from 'types';
import { IOfferRequest } from 'utils/api/offerRequest';

import { useGenerateQuery } from '../useGenerateQuery';

export const useGetOffers = (requestConfig: IOfferRequest = {}) => {
  const { params } = requestConfig;
  const { data, isLoading, error } = useGenerateQuery<IOfferData[]>({
    queryKey: params ? queryKeys(params).filter.offers : queryKeys().all.offers,
    apiCall: offerRequest,
    requestConfig,
    opts: { refetchOnWindowFocus: false },
  });

  return { data, isLoading, error };
};
