import { getApiUrl } from './getApiUrl';
import { getReportsUsers } from './getReportsUsers';
import { getSfClientId } from './getSfClientId';
import { getSfUrl } from './getSfUrl';

export type IEnv = 'develop' | 'stage' | 'prod' | 'local' | 'test';
const location = window.location.host.split('.')[0];

const setupEnv = (env: IEnv) => {
  const sfClientId = getSfClientId(env);
  const reportsUsers = getReportsUsers(env).split(',') || [];

  return {
    apiUrl: getApiUrl(env),
    sfUrl: getSfUrl(env),
    sfClientId,
    environment: env,
    location,
    reportsUsers,
  };
};

// Handle the envs here, but let getApiUrl handle the URL mapping
const getEnv = () => {
  if (process.env.JEST_WORKER_ID) {
    return setupEnv('test');
  }

  if (location === 'solution-pricing-tool') {
    return setupEnv('prod');
  }

  if (location === 'stage-solution-pricing-tool' || location === 'stage-spt') {
    return setupEnv('stage');
  }

  if (
    location === 'develop-solution-pricing-tool' ||
    location === 'develop-spt' ||
    location.includes('feature') ||
    location.includes('fix')
  ) {
    return setupEnv('develop');
  }

  return setupEnv('local');
  /**
  Use these steps to test stage-only functionality like pushing products to SF
  Make sure 'local' string in return above is set to 'stage' then:
   1. Login to SF through actual deployed SPT stage url (i.e. go to estimate details page & hit "refresh from SF" btn)
   2. Copy "jid" object from local storage in stage url
   3. Manually enter "jid" k/v pair from stage into localhost local storage
   4. Hard reload + empty cache (w/ devtools open right click browser refresh button)
   5. Although you can now push to SF, any changes to local **BE** functionality that isn't merged into stage will not be reflected (FE changes will work)
  */
};

export const env = getEnv();
