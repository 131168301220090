// Packages
import React, { useCallback, useMemo } from 'react';

// Redux

// Components
import { KiteCard, KiteIcon } from '@kite/react-kite';
import { ProgressBar } from 'components';

// Hooks
import { NavLink, useParams } from 'react-router-dom';
import { RCuseLocationCompletePercent, useTrackNavClick } from 'hooks';

// Utils
import { nameToUrlParam, RCrenderFormIcon } from 'utils';

// Types
import { RForm, ISubmission } from 'types';

// Styles
import './RCQFormNav.scss';

export interface IRCQFormNavProps {
  /** All forms for current location (or estimate if "general discovery") */
  forms: RForm[];
  /** All submissions for current location (or estimate if "general discovery") */
  submissions: ISubmission[];
}

/** Renders list that is used to navigate between forms */
const RCQFormNav = ({ forms = [], submissions = [] }: IRCQFormNavProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackNavClick } = useTrackNavClick();
  const { estimateId, locationId = '', formName } = useParams();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const getRoute = useCallback(
    (targetFormName: string) => {
      const nameParam = nameToUrlParam(targetFormName);
      return `../${estimateId}/${locationId}/${nameParam}`;
    },
    [estimateId, locationId]
  );

  const { completePercent, requiredIds, requiredSubmissions } =
    RCuseLocationCompletePercent({
      submissions,
      locationId,
    });

  const isSelected = useCallback(
    (name: string) => nameToUrlParam(name) === formName,
    [formName]
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderCompleteBadge = useCallback(
    (form: RForm, isComplete: boolean) => {
      return (
        <div
          className="rcq-form-nav__complete-badge"
          id={isComplete ? 'complete' : form.id}
        >
          {isComplete ? (
            <KiteIcon name="checkmark" size="13px" color="white" />
          ) : null}
        </div>
      );
    },
    []
  );

  const formNavItems = useMemo(() => {
    return forms.map((form) => {
      const { id, name, fields } = form;
      const requiredFields = fields.filter((field) =>
        requiredIds.includes(field.id)
      );
      const formSubmissions = requiredSubmissions.filter(
        (sub) => sub.formId === id
      );
      const isComplete = requiredFields.length === formSubmissions.length;
      const selectedLabel = isSelected(name) ? 'selected' : '';
      const completeLabel = isComplete ? 'complete' : 'incomplete';
      return (
        <li
          key={id}
          id={selectedLabel}
          aria-label={`form ${selectedLabel} ${completeLabel}`}
          role="navigation"
        >
          <NavLink
            to={getRoute(name)}
            onClick={trackNavClick(`RC Questions Navigate to Form: ${name}`)}
          >
            {renderCompleteBadge(form, isComplete)}
            {RCrenderFormIcon(form.name, '16px')}
            <span>{name}</span>
          </NavLink>
        </li>
      );
    });
  }, [
    forms,
    getRoute,
    isSelected,
    renderCompleteBadge,
    requiredIds,
    requiredSubmissions,
    trackNavClick,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="rcq-form-nav">
      <ProgressBar
        className="rcq-form-nav__progress"
        percentage={completePercent}
        title="Progress"
      />
      <ul>{formNavItems}</ul>
    </KiteCard>
  );
};

export default RCQFormNav;
