import { useMemo } from 'react';
import { ISubmission, MForm } from 'types';
import { useMNEQuestionsTotal } from './useMNEQuestionsTotal';

const useMNELocationCompletePercent = ({
  submissions,
  locationId,
  questionType,
  currentFormId = '',
  forms = [],
}: {
  submissions: ISubmission[];
  locationId: string;
  questionType: string;
  currentFormId?: string;
  forms?: MForm[];
}) => {
  const {
    requiredSubmissions,
    requiredLocationFieldIds,
    requiredEstimateFieldIds,
  } = useMNEQuestionsTotal({ submissions, questionType });

  const requiredIds = useMemo(() => {
    const allRequiredIds =
      locationId === 'general'
        ? requiredEstimateFieldIds
        : requiredLocationFieldIds[locationId] || [];
    return allRequiredIds;
  }, [locationId, requiredEstimateFieldIds, requiredLocationFieldIds]);

  const currentForm = useMemo(() => {
    return forms.find((form) => form.id === currentFormId);
  }, [forms, currentFormId]);

  const currentFormRequiredFields = useMemo(() => {
    return (
      currentForm?.fields.filter(
        (field) => field.required && requiredIds.includes(field.id)
      ) || []
    );
  }, [currentForm, requiredIds]);

  const currentFormRequiredSubmissions = useMemo(() => {
    return requiredSubmissions.filter(
      (sub) =>
        sub.formId === currentFormId &&
        currentFormRequiredFields.some((field) => field.id === sub.fieldId)
    );
  }, [requiredSubmissions, currentFormId, currentFormRequiredFields]);

  const totalFields = useMemo(() => {
    return currentFormRequiredFields.length;
  }, [currentFormRequiredFields]);

  const completedFields = useMemo(() => {
    return currentFormRequiredSubmissions.length;
  }, [currentFormRequiredSubmissions]);

  const completePercent = useMemo(() => {
    if (totalFields === 0) return 0;
    return Math.round((completedFields / totalFields) * 100);
  }, [totalFields, completedFields]);

  return {
    completePercent,
    requiredSubmissions: currentFormRequiredSubmissions,
    requiredIds,
  };
};

export default useMNELocationCompletePercent;
