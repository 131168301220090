// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// Redux

// Components
import { KiteButton, KiteInput } from '@kite/react-kite';

// Hooks
import { useDeleteFieldOpsCost, useUpdateFieldOpsCosts } from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';

// Utils
import { deepCopy, formatPrice } from 'utils';

// Types
import { IFieldOpsCost, ILocation, TFieldOpsCostType } from 'types';

// Styles
import './RcReviewCpwCostTable.scss';

export interface IRcReviewCpwCostTableProps {
  locationData: ILocation;
}

interface IFieldOpsCostData {
  isw: IFieldOpsCost[];
  other: IFieldOpsCost[];
}

/** ISW drop costs and other interior cost tables in the CPW tab */

const RcReviewCpwCostTable = ({ locationData }: IRcReviewCpwCostTableProps) => {
  const { id: locationId } = locationData;
  const initialFieldOpsCosts = useMemo(() => {
    const baseData = {
      id: 'initialData',
      locationId,
      desc: '',
      order: 0,
      lineItemPayout: null,
      cat6From: null,
      quantity: null,
      laborHours: null,
    };

    const initCosts: IFieldOpsCostData = {
      isw: [{ type: 'isw-drop', ...baseData }],
      other: [{ type: 'other-costs', ...baseData }],
    };

    if (locationData.fieldOpsCosts) {
      const formattedCosts = locationData.fieldOpsCosts.reduce(
        (acc: IFieldOpsCostData, cost) => {
          if (cost.type === 'isw-drop') acc.isw.push(cost);
          if (cost.type === 'other-costs') acc.other.push(cost);
          return acc;
        },
        { isw: [], other: [] }
      );

      if (formattedCosts.isw.length === 0)
        formattedCosts.isw.push(initCosts.isw[0]);
      if (formattedCosts.other.length === 0)
        formattedCosts.other.push(initCosts.other[0]);

      return formattedCosts;
    } else return initCosts;
  }, [locationData, locationId]);

  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [allCosts, setAllCosts] = useState<IFieldOpsCostData>({
    ...initialFieldOpsCosts,
  });

  const { updateFieldOpsCost, updateFieldOpsCostLoading } =
    useUpdateFieldOpsCosts(locationId);

  const { deleteFieldOpsCost } = useDeleteFieldOpsCost(locationId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const checkIsDirty = useCallback(
    (fieldOpsCosts: IFieldOpsCost[]) => {
      if (
        [...initialFieldOpsCosts.isw, ...initialFieldOpsCosts.other].length !==
        fieldOpsCosts.length
      )
        return true; // Dirty if a new cost row has been added
      // Compare initial costs to current cost input values
      return Object.entries(initialFieldOpsCosts).some(([_type, costs]) => {
        return costs.some((initialCost: IFieldOpsCost) => {
          const currentCost = fieldOpsCosts.find(
            ({ id }) => id === initialCost.id
          );
          const initialKeys = Object.keys(initialCost);
          return initialKeys.some(
            (key: string) =>
              currentCost &&
              initialCost[key as keyof typeof initialCost] !==
                currentCost[key as keyof typeof currentCost]
          );
        });
      });
    },
    [initialFieldOpsCosts]
  );

  const debounceSave = useDebouncedCallback(
    (fieldOpsCosts: Partial<IFieldOpsCost>[]) =>
      updateFieldOpsCost(fieldOpsCosts),
    350
  );

  const fieldOpsCostsToSave = useCallback(
    (fieldOpsCosts: IFieldOpsCost[]) => {
      if (!checkIsDirty(fieldOpsCosts) || updateFieldOpsCostLoading) {
        return [];
      }
      return fieldOpsCosts.map((cost) => {
        if (cost.id === 'initialData' || cost.id === 'newData') {
          return {
            ...cost,
            id: undefined, // Update Id to undefined to create a new entry
          };
        } else return cost;
      });
    },
    [checkIsDirty, updateFieldOpsCostLoading]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleAddRow = useCallback(
    (type: TFieldOpsCostType) => () => {
      const newFieldOpsCost = {
        locationId,
        id: 'newData',
        type,
        desc: '',
        order:
          type === 'isw-drop' ? allCosts.isw.length : allCosts.other.length,
        lineItemPayout: null,
        cat6From: null,
        quantity: null,
        laborHours: null,
      };

      const costsToUpdate = deepCopy(allCosts);

      if (type === 'isw-drop') {
        costsToUpdate.isw.push(newFieldOpsCost);
        setAllCosts(costsToUpdate);
      } else {
        costsToUpdate.other.push(newFieldOpsCost);
        setAllCosts(costsToUpdate);
      }
      const costsToSave = fieldOpsCostsToSave([
        ...costsToUpdate.isw,
        ...costsToUpdate.other,
      ]);
      debounceSave(costsToSave);
    },
    [locationId, allCosts, fieldOpsCostsToSave, debounceSave]
  );

  const handleDeleteRow = useCallback(
    (type: TFieldOpsCostType) => () => {
      const rowToDelete =
        type === 'isw-drop' ? allCosts.isw.at(-1) : allCosts.other.at(-1);
      rowToDelete &&
        'id' in rowToDelete &&
        deleteFieldOpsCost([rowToDelete.id]);
    },
    [allCosts, deleteFieldOpsCost]
  );

  const handleInputChange = useCallback(
    (
        value: string | number,
        id: string,
        costs: IFieldOpsCost[],
        type: TFieldOpsCostType
      ) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const fieldOpsCostToUpdate = costs.find((cost) => cost.id === id);
        const valueToSave =
          parseFloat(e.target.value) >= 0
            ? Math.round(parseFloat(e.target.value) * 100) / 100
            : e.target.value;

        if (fieldOpsCostToUpdate) {
          const updatedCost = {
            ...fieldOpsCostToUpdate,
            [value as keyof typeof fieldOpsCostToUpdate]: valueToSave,
          };

          const updatedCosts = deepCopy(allCosts);

          // Set input/state values
          if (type === 'isw-drop') {
            updatedCosts.isw[costs.indexOf(fieldOpsCostToUpdate)] = updatedCost;
            setAllCosts(updatedCosts);
          } else {
            updatedCosts.other[costs.indexOf(fieldOpsCostToUpdate)] =
              updatedCost;
            setAllCosts(updatedCosts);
          }
          // Format updated costs and save
          const costsToSave = fieldOpsCostsToSave([
            ...updatedCosts.isw,
            ...updatedCosts.other,
          ]);
          debounceSave(costsToSave);
        }
      },
    [allCosts, debounceSave, fieldOpsCostsToSave]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderButtons = useCallback(
    (type: TFieldOpsCostType) => {
      const isDisabled = (isDelete?: boolean) => {
        // Disabled if a cost is currently being added
        if (
          [...allCosts.isw, ...allCosts.other].length !==
          [...initialFieldOpsCosts.isw, ...initialFieldOpsCosts.other].length
        )
          return true;
        // Disable delete button if not costs exist
        if (type === 'isw-drop' && isDelete) {
          return allCosts.isw[0]?.id === 'initialData';
        } else if (isDelete) {
          return allCosts.other[0]?.id === 'initialData';
        }
      };

      return (
        <div className="rc-review-cpw-cost-table__btn-group">
          <KiteButton
            type="outline"
            size="medium"
            margin="0 8px 0 0 "
            onClick={handleAddRow(type)}
            disabled={isDisabled()}
          >
            Add Row
          </KiteButton>
          <KiteButton
            type="outline"
            size="medium"
            onClick={handleDeleteRow(type)}
            disabled={isDisabled(true)}
          >
            Delete Row
          </KiteButton>
        </div>
      );
    },
    [allCosts, handleAddRow, handleDeleteRow, initialFieldOpsCosts]
  );

  const renderRows = useCallback(
    (costs: IFieldOpsCost[], type: TFieldOpsCostType) => {
      return costs.map((cost: IFieldOpsCost, i) => {
        return (
          <tr key={cost.id}>
            {type === 'isw-drop' ? (
              <td>
                <KiteInput
                  value={cost.cat6From || ''}
                  onChange={handleInputChange('cat6From', cost.id, costs, type)}
                />
              </td>
            ) : (
              <td>{i + 1}</td>
            )}
            <td className="rc-review-cpw-cost-table__desc">
              <KiteInput
                value={cost.desc}
                onChange={handleInputChange('desc', cost.id, costs, type)}
              />
            </td>
            <td className="rc-review-cpw-cost-table__cost-input">
              <KiteInput
                value={cost.lineItemPayout || 0}
                type="number"
                inputProps={{ step: '0.01', min: 0, max: 'none' }}
                onChange={handleInputChange(
                  'lineItemPayout',
                  cost.id,
                  costs,
                  type
                )}
              />
            </td>
            <td>
              <KiteInput
                value={cost.quantity || 0}
                type="number"
                onChange={handleInputChange('quantity', cost.id, costs, type)}
              />
            </td>
            <td className="rc-review-cpw-cost-table__cost-input--total">
              {formatPrice((cost.lineItemPayout || 0) * (cost.quantity || 0))}
            </td>
            <td>
              <KiteInput
                value={cost.laborHours || 0}
                type="number"
                inputProps={{ step: '0.01', min: 0, max: 'none' }}
                onChange={handleInputChange('laborHours', cost.id, costs, type)}
              />
            </td>
          </tr>
        );
      });
    },
    [handleInputChange]
  );

  const renderCostEstimate = useCallback(
    (costs: IFieldOpsCost[], type: TFieldOpsCostType | 'total') => {
      const totalCost = costs.reduce((sum: number, cost) => {
        return sum + (cost.lineItemPayout || 0) * (cost.quantity || 0);
      }, 0);
      const title = () => {
        if (type === 'isw-drop') return 'ISW Drop Cost Estimate';
        if (type === 'other-costs') return 'Other Interior Costs';
        if (type === 'total') return 'Total Cost Estimate';
      };
      return (
        <div className="rc-review-cpw-cost-table__sum">
          <h3>{title()}</h3>
          <span className="rc-review-cpw-cost-table__sum--amount">
            {formatPrice(Math.round(totalCost * 100) / 100)}
          </span>
        </div>
      );
    },
    []
  );

  const renderTotalManHours = useCallback((costs: IFieldOpsCost[]) => {
    const totalHours = costs.reduce((sum: number, cost) => {
      return sum + (cost.laborHours || 0);
    }, 0);
    return (
      <div className="rc-review-cpw-cost-table__sum">
        <h3>Projected Man Hours</h3>
        <span className="rc-review-cpw-cost-table__sum--amount">
          {Math.round(totalHours * 100) / 100}
        </span>
      </div>
    );
  }, []);

  const renderIswDropTable = useMemo(() => {
    return (
      <div className="rc-review-cpw-cost-table__section">
        <div className="rc-review-cpw-cost-table__section__header">
          <h2>
            ISW Drop (should encompass all cost from Wall Jack to MDF/IDF)
          </h2>
          <p>
            Exapmples: CAT6 from Wall jack to any endpoint, patchpannel / 66
            Block / RJ 21
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th>CAT6 From</th>
              <th className="rc-review-cpw-cost-table__desc">
                Task Description For Line Item Required
              </th>
              <th>Line Item Payout</th>
              <th>QTY</th>
              <th>Total Cost</th>
              <th>Allocated Time/Hours</th>
            </tr>
          </thead>
          <tbody>{renderRows(allCosts.isw, 'isw-drop')}</tbody>
        </table>
        <div className="rc-review-cpw-cost-table__section__footer">
          {renderButtons('isw-drop')}
          <div className="rc-review-cpw-cost-table__section__footer__totals">
            {renderCostEstimate(allCosts.isw, 'isw-drop')}
            {renderTotalManHours(allCosts.isw)}
          </div>
        </div>
      </div>
    );
  }, [
    allCosts.isw,
    renderButtons,
    renderCostEstimate,
    renderRows,
    renderTotalManHours,
  ]);

  const renderOtherTable = useMemo(() => {
    return (
      <div className="rc-review-cpw-cost-table__section">
        <div className="rc-review-cpw-cost-table__section__header">
          <h2>Other Interior Costs - Additional Proposed Scope of Work</h2>
          <p>Other Interior Costs - Additional Proposed Scope of Work</p>
        </div>
        <table>
          <thead>
            <tr>
              <th>Line Item</th>
              <th className="rc-review-cpw-cost-table__desc">
                Task Description For Line Item Required
              </th>
              <th>Line Item Payout</th>
              <th>QTY</th>
              <th>Total Cost</th>
              <th>Allocated Time/Hours</th>
            </tr>
          </thead>
          <tbody>{renderRows(allCosts.other, 'other-costs')}</tbody>
        </table>
        <div className="rc-review-cpw-cost-table__section__footer">
          {renderButtons('other-costs')}
          <div className="rc-review-cpw-cost-table__section__footer__totals">
            <div className="rc-review-cpw-cost-table__section__footer__totals--cost">
              {renderCostEstimate(allCosts.other, 'other-costs')}
              {renderCostEstimate(allCosts.isw, 'isw-drop')}
              {renderCostEstimate(
                [...allCosts.isw, ...allCosts.other],
                'total'
              )}
            </div>
            {renderTotalManHours([...allCosts.isw, ...allCosts.other])}
          </div>
        </div>
      </div>
    );
  }, [
    renderRows,
    allCosts,
    renderButtons,
    renderCostEstimate,
    renderTotalManHours,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (!debounceSave.isPending()) {
      setAllCosts(initialFieldOpsCosts);
    }
  }, [debounceSave, initialFieldOpsCosts]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-cpw-cost-table">
      {renderIswDropTable}
      {renderOtherTable}
    </div>
  );
};

export default RcReviewCpwCostTable;
