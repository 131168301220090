import { useMutation, useQueryClient } from 'react-query';
import { ILocationBase } from 'types';
import { IDetailedAddress, locationRequest, queryKeys } from 'utils';

export interface ILocationUpdateMultiple extends ILocationBase {
  estimateId: string;
  shipTo?: IDetailedAddress | null;
}

export const usePostLocations = (estimateId?: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateMultipleLocations,
    isError: updateMultipleLocationsError,
    isLoading: updateMultipleLocationsLoading,
  } = useMutation(
    (updateMultipledLocations: ILocationUpdateMultiple[]) => {
      return locationRequest({
        endpoint: `locations/MultipleLocations`,
        method: 'POST',
        data: updateMultipledLocations,
      });
    },
    {
      onSuccess: () => {
        // refetch all estimate data since location data is nested
        queryClient.invalidateQueries(queryKeys().all.estimates);
      },
    }
  );

  return {
    updateMultipleLocations,
    updateMultipleLocationsError,
    updateMultipleLocationsLoading,
  };
};