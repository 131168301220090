import React, { useLayoutEffect } from 'react';
import { IForm, RForm, MForm } from 'types';

interface IUseScrollOnMountConfig<T> {
  ref: React.RefObject<T>;
  // Used to trigger scroll on deeper nested config pages' parent elements. Pass in 0 to trigger scroll directly on ref
  refNestLevel: number;
  // Used to trigger scroll on stepper config pages, i.e. MNE, ENE, SBB
  stepNumber?: number;
  // Used to trigger scroll on form changes for UCQ page
  form?: IForm;
  // Used to trigger scroll on location change on the Review UC page
  locationId?: string;
}
interface IRCUseScrollOnMountConfig<T> {
  ref: React.RefObject<T>;
  // Used to trigger scroll on deeper nested config pages' parent elements. Pass in 0 to trigger scroll directly on ref
  refNestLevel: number;
  // Used to trigger scroll on stepper config pages, i.e. MNE, ENE, SBB
  stepNumber?: number;
  // Used to trigger scroll on form changes for UCQ page
  form?: RForm;
  // Used to trigger scroll on location change on the Review UC page
  locationId?: string;
}

interface IMNEUseScrollOnMountConfig<T> {
  ref: React.RefObject<T>;
  // Used to trigger scroll on deeper nested config pages' parent elements. Pass in 0 to trigger scroll directly on ref
  refNestLevel: number;
  // Used to trigger scroll on stepper config pages, i.e. MNE, ENE, SBB
  stepNumber?: number;
  // Used to trigger scroll on form changes for UCQ page
  form?: MForm;
  // Used to trigger scroll on location change on the Review UC page
  locationId?: string;
}
const getElementByParentLevel = (
  element: HTMLElement | null,
  level: number
) => {
  while (level-- > 0) {
    if (element?.parentElement) {
      element = element.parentElement;
    }
  }
  return element;
};

export const useScrollToTop = <T extends HTMLElement>({
  ref,
  refNestLevel,
  stepNumber = 0,
  form,
  locationId,
}: IUseScrollOnMountConfig<T>) => {
  useLayoutEffect(() => {
    const elementToScroll = getElementByParentLevel(ref.current, refNestLevel);
    if (elementToScroll) {
      elementToScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [form, stepNumber, ref, refNestLevel, locationId]);
};
export const useScrollToTopRC = <T extends HTMLElement>({
  ref,
  refNestLevel,
  stepNumber = 0,
  form,
  locationId,
}: IRCUseScrollOnMountConfig<T>) => {
  useLayoutEffect(() => {
    const elementToScroll = getElementByParentLevel(ref.current, refNestLevel);
    if (elementToScroll) {
      elementToScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [form, stepNumber, ref, refNestLevel, locationId]);
};

export const useScrollToTopMNE = <T extends HTMLElement>({
  ref,
  refNestLevel,
  stepNumber = 0,
  form,
  locationId,
}: IMNEUseScrollOnMountConfig<T>) => {
  useLayoutEffect(() => {
    const elementToScroll = getElementByParentLevel(ref.current, refNestLevel);
    if (elementToScroll) {
      elementToScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [form, stepNumber, ref, refNestLevel, locationId]);
};
