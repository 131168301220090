import { IZipCodeRequest } from 'types';
import { queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';
import { ISWZipAndCostCodes } from 'types/Location';
import { zipCodeRequest } from 'utils/api/zipCodeRequest';

export const useGetZipCodes = (requestConfig: IZipCodeRequest) => {
  const { params } = requestConfig;
  return useGenerateQuery<ISWZipAndCostCodes[]>({
    queryKey: params
      ? queryKeys(params).filter.zipCodes
      : queryKeys().all.zipCodes,
    apiCall: zipCodeRequest,
    requestConfig,
    opts: {
      enabled: !!params,
      staleTime: Infinity,
    },
  });
};
