// Packages
import React, { useMemo, useCallback, useEffect } from 'react';

// Redux

// Components
import PriceTotalBadge from 'components/PriceTotalBadge';
import { KiteRadioButton, KiteIcon } from '@kite/react-kite';

// Hooks
import { useGenerateProductTotals, useQueryData } from 'hooks';
import { useLocation } from 'react-router-dom';

// Utils
import { NavigationIconMap } from 'utils';

// Types
import { IScenario } from 'types';

// Styles
import './ScenarioSummary.scss';

/** Summary of Estimate scenarios to be pushed to salesforce */

const ScenarioSummary = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    scenarioId,
    estimateScenarios,
    updateScenarioId,
    updateLocationId,
    currentEstimate,
    currentScenario,
    locationId,
    scenarioTabLocationIds,
  } = useQueryData();
  const { generateProductTotals } = useGenerateProductTotals();
  const { pathname } = useLocation();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const scenarioTotal = useCallback(
    (scenario: IScenario) =>
      generateProductTotals({
        selections: scenario.selections,
        scenarioId: scenario.id,
        term: scenario.term,
      }),
    [generateProductTotals]
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const onRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: scenarioId } = e.target;
      updateScenarioId(scenarioId);

      const selectedScenario = estimateScenarios.find(
        (scenario) => scenario.id === scenarioId
      );
      if (selectedScenario) {
        updateLocationId(selectedScenario.locations[0]?.id);
      }
    },
    [estimateScenarios, updateLocationId, updateScenarioId]
  );

  // =============================================
  // Render Methods
  // =============================================
  const productIcons = useCallback((scenario: IScenario) => {
    if (!scenario.selections.length) return null;

    const iconsToDisplay = Array.from(
      new Set(
        scenario.selections.map((selection) =>
          Object.keys(NavigationIconMap).find(
            (iconKey) => iconKey === selection.familyCategory
          )
        )
      )
    );

    const icons = iconsToDisplay.map((icon, index) => {
      return (
        <KiteIcon
          className="scenario-summary__description-icon"
          name={NavigationIconMap[icon as keyof typeof NavigationIconMap]}
          key={index}
        />
      );
    });

    return (
      <p className="scenario-summary__description-products">
        Products: {icons}
      </p>
    );
  }, []);

  const sortedScenarios = useMemo(() => {
    return estimateScenarios.sort((a, b) => {
      const aTime = new Date(a.updatedAt).getTime();
      const bTime = new Date(b.updatedAt).getTime();
      return bTime - aTime;
    });
  }, [estimateScenarios]);

  const radioButtons = useMemo(() => {
    return sortedScenarios.map((scenario) => {
      return (
        <div className="scenario-summary__radio-group" key={scenario.id}>
          <KiteRadioButton
            name={scenario.name}
            checked={scenarioId === scenario.id}
            id={scenario.id}
            label={scenario.name}
            onChange={onRadioChange}
            value={scenario.id}
          />
          <div className="scenario-summary__description">
            <p>Term: {scenario.term} Months</p>
            <PriceTotalBadge
              priceTotal={scenarioTotal(scenario).solutionMrc as string}
            />
            {productIcons(scenario)}
            <p>Active Locations: {scenario.locations.length}</p>
          </div>
        </div>
      );
    });
  }, [sortedScenarios, scenarioId, productIcons, onRadioChange, scenarioTotal]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // If navigating from dashboard -> detail, there may not be a valid scenario/location id in redux
    // This will properly set the two values if missing, based on pushed scenario id / recent scenario tab location
    // Fallback is first id
    if (!scenarioId && currentEstimate) {
      const { pushedScenarioId } = currentEstimate;
      updateScenarioId(pushedScenarioId || estimateScenarios[0].id);
    }

    if (currentScenario && !locationId) {
      updateLocationId(
        scenarioTabLocationIds[scenarioId] || currentScenario.locations[0]?.id
      );
    }
  }, [
    currentEstimate,
    currentScenario,
    estimateScenarios,
    locationId,
    scenarioId,
    scenarioTabLocationIds,
    updateLocationId,
    updateScenarioId,
  ]);

  // =============================================
  // Early Return
  // =============================================
  if (pathname.includes('review-uc')) return null;

  // =============================================
  // Return
  // =============================================
  return <div className="scenario-summary">{radioButtons}</div>;
};

export default ScenarioSummary;
