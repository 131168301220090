import { useMutation, useQueryClient } from 'react-query';
import { IRefreshPhoneNumber } from 'types';
import { queryKeys, refreshPhoneNumbersRequest } from 'utils';

export const useRefreshPhoneNumbers = (estimateId: string) => {
  const queryClient = useQueryClient();
  const queryKey = queryKeys({ estimateId }).details.estimate;

  const {
    mutate: refreshPhoneNumbers,
    isLoading: refreshPhoneNumbersLoading,
    isSuccess: refreshPhoneNumbersSuccess,
    isError: refreshPhoneNumbersError,
  } = useMutation(
    (refreshPhones: IRefreshPhoneNumber) =>
      refreshPhoneNumbersRequest(refreshPhones),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    refreshPhoneNumbers,
    refreshPhoneNumbersLoading,
    refreshPhoneNumbersSuccess,
    refreshPhoneNumbersError,
  };
};
