import { IEnv } from './env';

export const getApiUrl = (env: IEnv): string => {
  const develop = 'https://develop-spt-api.se-shared.spectrumtoolbox.com/v1';
  const stage = 'https://stage-spt-fastify-api.spectrumtoolbox.com/v1';
  const prod = 'https://spt-fastify-api.spectrumtoolbox.com/v1';

  const envUrlMap = {
    develop,
    stage,
    prod,
    local: process.env.REACT_APP_FASTIFY_URL || develop,
    test: 'test',
  };

  return envUrlMap[env];
};
