import axios from 'axios';
import {
  IRequestConfig,
  IEstimate,
  IEstimateUpdate,
  IRequestParams,
  TTermLengthMonths,
  INewEstimate,
} from 'types';
import { paramsSerializer } from 'utils';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IEstimateParams extends IRequestParams {
  isSandbox?: boolean;
  userId?: string;
  status?: IEstimate['status'];
  termMonth?: TTermLengthMonths;
  opportunityId?: string;
  archived?: boolean;
}

export interface IEstimateRequest
  extends IRequestConfig<IEstimate | INewEstimate | IEstimateUpdate> {
  params?: IEstimateParams;
}

export const estimateRequest = async (config?: IEstimateRequest) => {
  const { endpoint = 'estimates', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) => {
      if (error.response.status === 409) {
        throw new Error(JSON.stringify({
          status: error.response.status,
          id: error.response.data.id
        }));
      }
      if (error.response.status === 403) {
        window.location.reload();
      }

      throwQueryError({
        error,
        message: `Something went wrong with estimates ${method}`,
      })
    });
};

export const estimateDataRequest = async (config?: IEstimateRequest) => {
  const { endpoint = 'estimates/estimatesData', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) => {
      if (error.response.status === 409) {
        throw new Error(JSON.stringify({
          status: error.response.status,
          id: error.response.data.id
        }));
      }
      if (error.response.status === 403) {
        window.location.reload();
      }

      throwQueryError({
        error,
        message: `Something went wrong with estimates ${method}`,
      })
    });
};
