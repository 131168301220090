// Packages
import React, { useCallback, useMemo, MutableRefObject, useRef } from 'react';

// Redux

// Components
import {
  UcReviewNetworkDraftTable,
  UcReviewLocationHeader,
  ShipToForm,
  FieldValidationHeader,
} from 'components';

// Hooks
import {
  useFormRules,
  useGetRules,
  useRenderReviewUcFields,
  useScrollToTop,
} from 'hooks';

// Utils

// Types
import { IDistro, IField, IFieldValues, ILocation, ISubmission } from 'types';

// Styles
import './UcReviewValidation.scss';

export interface IUcReviewValidationProps {
  opptyName: string;
  location: ILocation;
  submissions: ISubmission[];
  fields: IField[];
  distros: IDistro[];
  refForPrint: MutableRefObject<HTMLDivElement | null>;
  tabName?: string;
}

/** UC question field validation tab on the read only page */

const UcReviewValidation = ({
  opptyName,
  location,
  submissions,
  fields,
  distros,
  refForPrint,
  tabName,
}: IUcReviewValidationProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { data: formRules = [] } = useGetRules();
  const fieldValues: IFieldValues = useMemo(() => {
    return fields.reduce((acc: IFieldValues, field) => {
      acc[field.id] =
        submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
      return acc;
    }, {});
  }, [fields, submissions]);

  const { ruleMap, ruleKeys } = useFormRules({ fieldValues, formRules });

  const {
    renderForm,
    renderSubmissionValidationSection,
    renderProductValidation,
  } = useRenderReviewUcFields({
    location,
    submissions,
    isValidation: true,
    ruleMap,
    ruleKeys,
    tabName,
  });

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useScrollToTop({ ref: scrollRef, refNestLevel: 0, locationId: location.id });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const allFormSections = useMemo(() => {
    return [
      {
        name: 'General',
        id: 'generalValidation',
        fields: fields
          .filter(({ validationType }) => validationType === 'general')
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
      },
      {
        name: 'Contact Info',
        id: 'contactInfoValidation',
        fields: fields
          .filter(({ validationType }) => validationType === 'contact-info')
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
      },
      {
        name: 'Validation',
        id: 'validation',
        fields: fields.filter(
          ({ validationType }) => validationType === 'validation'
        ),
      },
      {
        name: 'Network Draft',
        id: 'networkDraftValidation',
        fields: [],
      },
    ];
  }, [fields]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const productValidationSection = useMemo(() => {
    return (
      <div className="uc-review-submissions__form" key="products">
        <FieldValidationHeader label="Product Validation" iconName="shopping" />
        <div className="uc-review-validation__products">
          {renderProductValidation()}
        </div>
      </div>
    );
  }, [renderProductValidation]);

  const renderNetworkDraftTable = useCallback(() => {
    return !distros.length ? null : (
      <div
        className="uc-review-validation__form uc-review-validation__form__design-flow"
        key={'networkDraftValidation'}
      >
        <FieldValidationHeader label="Network Draft" />
        <UcReviewNetworkDraftTable distros={distros} />
      </div>
    );
  }, [distros]);

  const renderValidation = useCallback(
    (
      name: string,
      id: string,
      fields: IField[],
      submissions: ISubmission[]
    ) => {
      if (name === 'Validation') {
        return renderSubmissionValidationSection({ name, id, submissions });
      } else if (name === 'Network Draft') {
        return renderNetworkDraftTable();
      } else {
        return renderForm(name, fields, submissions);
      }
    },
    [renderForm, renderNetworkDraftTable, renderSubmissionValidationSection]
  );

  const renderContent = useCallback(() => {
    const formSections = allFormSections.map(({ name, id, fields }) => {
      const currentSubs =
        fields.length > 0
          ? submissions.filter(
              ({ validationType }) =>
                validationType === fields[0].validationType
            )
          : [];

      return renderValidation(name, id, fields, currentSubs);
    });
    return [
      <ShipToForm key="shipping" />,
      ...formSections,
      productValidationSection,
    ];
  }, [
    allFormSections,
    productValidationSection,
    renderValidation,
    submissions,
  ]);
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-validation" ref={refForPrint}>
      <UcReviewLocationHeader
        opptyName={opptyName}
        locationName={location.name}
      />

      <div className="uc-review-validation__content" ref={scrollRef}>
        {renderContent()}
      </div>
    </div>
  );
};

export default UcReviewValidation;
