import { useCallback, useMemo } from 'react';
import { IEstimate, ISubmission } from 'types';
import { enePowerPortFields, mnePowerPortFields } from 'utils';
import { useGetZipCodes } from './apiHooks/useGetZipCodes';
import {
  eneOnNetFieldId,
  mneOnNetFieldId,
} from 'utils/defaultsAndConstants/constants';

export interface IuseGenerateISWBudgetProps {
  submissions?: ISubmission[];
  type: 'MNE' | 'ENE';
  estimateData: IEstimate;
  currentLocationId: string;
}
export const useGenerateISWBudget = ({
  submissions = [],
  type = 'MNE',
  estimateData,
  currentLocationId = '',
}: IuseGenerateISWBudgetProps) => {
  const countTotalQuantity = useCallback((subs: ISubmission[]) => {
    return subs.reduce((acc: number, { answer }) => {
      if (answer) {
        const answers = JSON.parse(answer);
        answers.forEach((a: string[]) => (acc += Number(a[1] || 0)));
      }
      return acc;
    }, 0);
  }, []);

  const estimateZipcodes: string[] = estimateData
    ? [
        ...estimateData?.locations.map((l) => l.zipcode),
        ...estimateData?.locations.map((l) => `OOF - ${l.state}`),
      ]
    : [];

  const { data: iswZipCodes } = useGetZipCodes({
    params: { zipcodes: estimateZipcodes },
  });

  const iswCost: String =
    iswZipCodes?.find(
      (z) =>
        z.zipcode ===
          estimateData.locations.find(
            (l) =>
              l.id === currentLocationId &&
              l.zipcode !== '' &&
              null !== l.zipcode
          )?.zipcode! ||
        z.zipcode ===
          `OOF - ${estimateData.locations.find(
            (l) => l.id === currentLocationId
          )?.state!}`
    )?.iswCost || '330';

  const { iswBudget } = useMemo(() => {
    const { accessPointCameraPhoneFieldIds } =
      type === 'MNE' ? mnePowerPortFields : enePowerPortFields;

    const isOnNet =
      submissions.find((sub) =>
        type === 'MNE'
          ? sub.fieldId.includes(mneOnNetFieldId)
          : sub.fieldId.includes(eneOnNetFieldId)
      )?.answer === 'Yes';

    const totalBudgetDeviceCount: number = countTotalQuantity(
      submissions.filter((s) =>
        accessPointCameraPhoneFieldIds.includes(s.fieldId)
      )
    );

    const iswOnNetBudget = totalBudgetDeviceCount * Number(iswCost);
    const iswOffNetBudget =
      totalBudgetDeviceCount === 0
        ? 0
        : Number(totalBudgetDeviceCount * Number(iswCost) + 356);

    const iswBudget = (
      isOnNet
        ? iswOnNetBudget
        : iswOffNetBudget < 1346 && iswOffNetBudget !== 0
        ? 1346
        : iswOffNetBudget
    ).toFixed(2);

    return { iswBudget };
  }, [submissions, type, countTotalQuantity, iswCost]);

  return iswBudget;
};
