import { useCallback, useState } from 'react';

import { estimateRequest, queryKeys, sandboxData } from 'utils';

import { IEstimate, IEstimateRequest } from 'types';

import { useGenerateQuery } from '../useGenerateQuery';
import { useQueryData } from 'hooks';

let fetchCount = 0;

export const useGetEstimateDetails = (estimateId?: string, userId?: string) => {
  const {
    scenarioId: currentScenarioId,
    scenarioTabLocationIds,
    updateScenarioId,
    updateLocationId,
  } = useQueryData();

  const [errorMessage, setErrorMessage] = useState('');
  const isSandbox = estimateId === 'sandbox';

  const requestConfig: IEstimateRequest = {
    method: 'GET',
    endpoint: `estimates/${estimateId}`,
    params: {
      userId,
    },
  };

  const sandboxHandler = useCallback(async () => sandboxData.estimate, []);

  const {
    data: estimateData,
    isLoading,
    isSuccess: estimateSuccess,
  } = useGenerateQuery<IEstimate>({
    queryKey: queryKeys({ estimateId }).details.estimate,
    apiCall: isSandbox ? sandboxHandler : estimateRequest,
    requestConfig,
    opts: {
      enabled: !!estimateId,
      refetchOnWindowFocus: false,
      onSuccess: (res: IEstimate) => {
        const firstScenario = res?.scenarios.sort((a, b) => {
          const { createdAt: aCreated } = a;
          const { createdAt: bCreated } = b;
          return new Date(aCreated).getTime() - new Date(bCreated).getTime();
        })[0];

        const updatedLocationId = (Object.keys(scenarioTabLocationIds).length !== 0 && scenarioTabLocationIds[firstScenario?.id]) ?
          scenarioTabLocationIds[firstScenario?.id] : res.locations[0]?.id;

        const scenarioIds = new Set(res.scenarios.map((s) => s.id));

        const isFreshQuery = !currentScenarioId && !fetchCount;
        const needsReduxUpdate =
          currentScenarioId && !scenarioIds.has(currentScenarioId);

        if (isFreshQuery || needsReduxUpdate) {

          updateScenarioId(firstScenario.id);
          updateLocationId(updatedLocationId ||
            firstScenario?.locations[0]?.id
          );
        }

        fetchCount++;
      },
      onError: () =>
        setErrorMessage(
          'Something went wrong retrieving your estimate details.'
        ),
    },
  });

  return {
    estimateData,
    estimateLoading: isLoading,
    estimateSuccess,
    error: errorMessage,
  };
};
