import axios from 'axios';
import { IRequestConfig, IPhoneNumberBody, IRefreshPhoneNumber } from 'types';
import { paramsSerializer, throwQueryError } from 'utils';
import { env } from './env';

export const phoneNumbersRequest = async (
  config?: IRequestConfig<IPhoneNumberBody[]>
) => {
  const {
    endpoint = 'phone-numbers',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with phone numbers ${method}`,
      })
    );
};

export const refreshPhoneNumbersRequest = async (data: IRefreshPhoneNumber) => {
  const url = `${env.apiUrl}/phone-numbers/refresh`;

  return axios({
    url,
    method: 'POST',
    data,
  })
    .then((res) => res.data.message)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with phone numbers refresh`,
      })
    );
};
