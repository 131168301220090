// Packages
import React, { useCallback, useMemo, MutableRefObject, useRef } from 'react';

// Redux

// Components
import { DesignFlowNotes, RCQDesignFlow } from 'components/rcQuestions';
import { RcReviewLocationHeader } from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import {
  RCuseFlowNodes,
  useFormRules,
  useRenderReviewRcFields,
  useScrollToTop,
} from 'hooks';

// Utils

// Types
import {
  IField,
  IForm,
  ISubmission,
  IDistro,
  ILocation,
  IFieldValues,
} from 'types';

// Styles
import './RcReviewSubmissions.scss';
import RCFieldValidationHeader from 'components/RCFieldValidationHeader';

export interface IRcReviewSubmissionsProps {
  opptyName: string;
  location: ILocation;
  forms: IForm[];
  submissions: ISubmission[];
  distros: IDistro[];
  refForPrint: MutableRefObject<HTMLDivElement | null>;
}

/** Displays submission data on the RcReviewPage */

const RcReviewSubmissions = ({
  opptyName,
  location,
  forms,
  submissions,
  distros,
  refForPrint,
}: IRcReviewSubmissionsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId } = useParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useScrollToTop({ ref: scrollRef, refNestLevel: 0, locationId });

  const fields = useMemo(
    () =>
      forms.reduce((acc: IField[], form) => {
        acc.push(...form.fields);
        return acc;
      }, []),
    [forms]
  );

  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  const fieldValues: IFieldValues = useMemo(() => {
    return fields.reduce((acc: IFieldValues, field) => {
      acc[field.id] =
        submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
      return acc;
    }, {});
  }, [fields, submissions]);

  const { ruleMap, ruleKeys } = useFormRules({ fieldValues, formRules });

  const { renderForm, formatFields, renderGridFields } =
    useRenderReviewRcFields({ location, ruleMap, ruleKeys });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const nodeSize = useMemo(() => ({ width: 200, height: 267.22 }), []);
  const { height: networkDraftHeight } = RCuseFlowNodes({
    distros,
    nodeSize,
  });

  // =======================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderDesignFlow = useCallback(
    (name: string, fieldId: string) => {
      return !distros.length ? null : (
        <div
          className="rc-review-submissions__form rc-review-submissions__form--design-flow"
          key={fieldId}
        >
          <RCFieldValidationHeader label={name} />
          {!!distros.length && (
            <div
              style={{
                height: networkDraftHeight,
              }}
            >
              <RCQDesignFlow
                distros={distros}
                draggable={false}
                isFitView
                actionsAreEnabled={false}
              />
            </div>
          )}
          <DesignFlowNotes isOpen />
        </div>
      );
    },
    [distros, networkDraftHeight]
  );

  const renderDiscoveryForms = useCallback(
    (params: {
      locationSpecificFields: IField[];
      generalDiscFields: IField[];
      formSubmissions: ISubmission[];
    }) => {
      const { formSubmissions, generalDiscFields, locationSpecificFields } =
        params;
      const renderLocationDiscFields = formatFields(
        locationSpecificFields,
        formSubmissions
      );
      const renderGeneralDiscFields = formatFields(
        generalDiscFields,
        formSubmissions
      );
      return (
        <div className="rc-review-submissions__form" key="Discovery">
          <RCFieldValidationHeader label="Discovery" />
          {renderGeneralDiscFields.gridFields[0].elements.length > 0 && (
            <>
              <h3>General</h3>
              {renderGridFields(renderGeneralDiscFields.gridFields)}
              {renderGeneralDiscFields.fullWidthFields}
            </>
          )}
          {renderLocationDiscFields.gridFields[0].elements.length > 0 && (
            <>
              <h3>Location Specific</h3>
              {renderGridFields(renderLocationDiscFields.gridFields)}
              {renderLocationDiscFields.fullWidthFields}
            </>
          )}
        </div>
      );
    },
    [formatFields, renderGridFields]
  );

  const renderAllFormSubmissions = useMemo(() => {
    const formSubmissions = () => {
      let generalDiscFormId = '';
      let generalDiscFields = [] as IField[];
      return forms.map(({ id, name, fields }) => {
        if (name === 'Network Draft') {
          return renderDesignFlow(name, id);
        } else if (name === 'General Discovery') {
          // General Discovery and location specific discovery rendered under Discovery
          generalDiscFormId = id;
          generalDiscFields = fields;
          return '';
        } else if (name === 'Discovery') {
          const currentFormSubs = submissions?.filter(
            (sub) =>
              (sub.formId === id && locationId === sub.locationId) ||
              sub.formId === generalDiscFormId
          );
          if (currentFormSubs.length === 0) return '';
          return renderDiscoveryForms({
            locationSpecificFields: fields,
            generalDiscFields,
            formSubmissions: currentFormSubs,
          });
        } else {
          const currentFormSubs = submissions?.filter(
            (sub) => sub.formId === id && locationId === sub.locationId
          );
          if (currentFormSubs.length === 0) return '';
          return renderForm(name, fields, currentFormSubs);
        }
      });
    };
    return formSubmissions;
  }, [
    forms,
    renderDesignFlow,
    submissions,
    renderDiscoveryForms,
    locationId,
    renderForm,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="rc-review-submissions"
      id="rc-review-submissions"
      ref={refForPrint}
    >
      <div className="rc-review-submissions__header-container">
        <RcReviewLocationHeader
          opptyName={opptyName}
          locationName={location.name}
          hasHistory
          hasProgress
          //hasBadges
        />
      </div>
      <div className="rc-review-submissions__content" ref={scrollRef}>
        {renderAllFormSubmissions()}
      </div>
    </div>
  );
};

export default RcReviewSubmissions;
