// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Components
import { KiteLoader, KiteInput } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';

// Hooks
import { useAnalytics, useGetReports, usePagination } from 'hooks';

// Types
import { IEstimate, TFilterValue } from 'types';

// Styles
import './Reports.scss';
import CSVButton from 'components/CSVButton';
interface IEstTableData {
  salesTeam: string;
  estimateName: string;
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  //lastRefreshed: string;
  customerName: string;
  status: string;
  // estimateId: string;
  opportunityId: string;
  locationCount: string;
  selections: string;
}

const ReportDashboard = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [filterValue] = useState<TFilterValue>();
  const [dataLength, setDataLength] = useState(0);
  const { trackPageView } = useAnalytics();

  interface ApiParams {
    archived: boolean;
    fromDate?: string;
    toDate?: string;
  }

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [apiParams, setApiParams] = useState<ApiParams>({ archived: false });

  const pageLimit = 10;
  const { paginationComponent, paginateData } = usePagination(
    pageLimit,
    dataLength
  );

  const { data: allEstimatesData, isLoading: allEstimatesLoading } =
    useGetReports({ params: apiParams });

  const getDateRange = async (numberOfDays: number) => {
    return new Promise((resolve) => {
      const today = new Date();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - numberOfDays + 1);
      const endDate = new Date();
      resolve({
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      });
    });
  };

  useEffect(() => {
    const fetchInitialDates: any = async () => {
      const dates: any = await getDateRange(7);
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
    };
    fetchInitialDates();
  }, []);

  useEffect(() => {
    const newParams: ApiParams = { archived: false };

    if (startDate) newParams.fromDate = startDate;
    if (endDate) newParams.toDate = endDate;

    setApiParams(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const filterEstimates = useCallback(
    (estimates?: IEstimate[], filter?: TFilterValue) => {
      if (!estimates) {
        return [];
      }

      if (filter === 'Unqualified Opportunity') {
        return estimates.filter((e) => e.status === filter);
      }

      if (filter === 'Qualified Opportunity') {
        return estimates.filter((e) => e.status !== 'Unqualified Opportunity');
      }

      return estimates;
    },
    []
  );

  const estimatesToDisplay = useMemo(
    () => paginateData(filterEstimates(allEstimatesData, filterValue)),
    [filterEstimates, filterValue, paginateData, allEstimatesData]
  );

  // =============================================
  // Render Methods
  // =============================================
  const generateTableData = useCallback((estimates: IEstimate[]) => {
    interface IEstTableData {
      createdBy: string;
      estimateName: string;
      salesTeam: string;
      createdDate: string;
      updatedDate: string;
      //lastRefreshed: string;
      customerName: string;
      status: string;
      opportunityId: string;
      // estimateId: string;
      pushedToSf: string | undefined;
      locationCount: string;
      selections?: string;
    }

    const tableData: IEstTableData[] = estimates.map((est) => {
      type TProductList = string;
      const selections =
        est.scenarios && est.scenarios.length > 0
          ? est.scenarios
              .flatMap((scenario): (TProductList | undefined)[] =>
                scenario.selections
                  ? scenario.selections.map((sel) => sel.familyName)
                  : []
              )
              .filter((familyName): familyName is TProductList =>
                Boolean(familyName)
              )
              .reduce<Record<TProductList, number>>((acc, familyName) => {
                acc[familyName] = (acc[familyName] || 0) + 1;
                return acc;
              }, {})
          : {};

      const formattedSelections = Object.entries(selections)
        .map(([familyName, count]) =>
          count > 1 ? `${count}x ${familyName}` : familyName
        )
        .join('; ');

      const finalSelectionsDisplay =
        formattedSelections.length > 0 ? formattedSelections : 'No Selections';
      const locationCount = est.locations.length.toString();

      return {
        salesTeam: Object.entries(est.salesTeam)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', '),
        createdBy: est.createdBy,
        createdDate: dayjs(est.createdAt).format('MMM D, YYYY h:mm A'),
        updatedDate: dayjs(est.updatedAt).format('MMM D, YYYY h:mm A'),
        // lastRefreshed: dayjs(est.lastRefreshed).format('MMM D, YYYY h:mm A'),
        estimateName: est.name,
        customerName: est.customerName,
        status: est.status,
        opportunityId: est.sfOpportunityId,
        // estimateId: est.id,
        pushedToSf: est.pushedToSf ? 'Yes' : 'No',
        locationCount,
        selections: finalSelectionsDisplay,
      };
    });

    const columns = [
      { label: 'Sales Team', sortKey: 'salesTeam' },
      { label: 'Created By', sortKey: 'createdBy' },
      { label: 'Created Date', sortKey: 'createdDate' },
      { label: 'Updated Date', sortKey: 'updatedDate' },
      { label: 'Estimate Name', sortKey: 'estimateName' },
      //{ label: 'Last Refreshed', sortKey: 'lastRefreshed' },
      { label: 'Customer Name', sortKey: 'customerName' },
      { label: 'Status', sortKey: 'status' },
      { label: 'Opportunity ID', sortKey: 'opportunityId' },
      // { label: 'Estimate ID', sortKey: 'estimateId' },
      { label: 'Pushed To SF', sortKey: 'pushedToSf' },
      { label: 'Location Count', sortKey: 'locationCount' },
      { label: 'Selections', sortKey: 'selections' },
    ];

    return { tableData, columns };
  }, []);

  const generateCSV = useCallback(() => {
    const { tableData } = generateTableData(
      allEstimatesData ? allEstimatesData : []
    );

    if (!tableData || tableData.length === 0) {
      console.error('No data available to generate CSV');
      return;
    }
    const csvRows = [];
    const headers = Object.keys(tableData[0]);
    csvRows.push(headers.join(','));
    for (const row of tableData) {
      const values = headers.map((header) => {
        const escaped = String(row[header as keyof IEstTableData]).replace(
          /"/g,
          '""'
        );
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    const csvContent = csvRows.join('\n');

    return csvContent;
  }, [generateTableData, allEstimatesData]);

  const renderTable = useCallback(() => {
    if (allEstimatesLoading) {
      return <KiteLoader />;
    }

    if (!estimatesToDisplay?.length) {
      return <p>Sorry, there are no results matching your query</p>;
    }

    const { tableData, columns } = generateTableData(estimatesToDisplay);

    return (
      <div className="estimate-dashboard__table">
        <SortableTable tableData={tableData} columns={columns} stripedRows />
        {paginationComponent}
      </div>
    );
  }, [
    estimatesToDisplay,
    generateTableData,
    paginationComponent,
    allEstimatesLoading,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('ReportDashboard'), [trackPageView]);

  useEffect(() => {
    setDataLength(filterEstimates(allEstimatesData).length);
  }, [allEstimatesData, filterEstimates]);

  // =============================================
  // Return
  // =============================================
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      if (name === 'start') {
        setStartDate(value);
      } else if (name === 'end') {
        setEndDate(value);
      }
    },
    [setStartDate, setEndDate]
  );

  return (
    <>
      <main className="report-dashboard">
        <header>
          <h1>Usage Reports</h1>
          <div className="report-field__time-range date-align">
            <div>
              <span className="report-field__subtext">From</span>
            </div>
            <div>
              <KiteInput
                name="start"
                id="start"
                maxWidth="none"
                value={startDate}
                onChange={onChange}
                inputProps={{ type: 'date' }}
              />
            </div>
            <div>
              <span className="report-field__subtext">To</span>
            </div>
            <div>
              <KiteInput
                name="end"
                id="end"
                maxWidth="none"
                value={endDate}
                onChange={onChange}
                inputProps={{ type: 'date' }}
              />
            </div>
            <CSVButton jsonData={generateCSV()} fileName="Reports"></CSVButton>
          </div>
        </header>
        {renderTable()}
      </main>
    </>
  );
};

export default ReportDashboard;
