import { KiteCheckbox } from '@kite/react-kite';
import React, { useCallback, useEffect, useState } from 'react';
import { IFieldInput, ISubmission } from 'types';

import './MNECheckbox.scss';
import { generalSectionId } from 'utils/defaultsAndConstants/constants';

export interface MNECheckboxProps {
  fieldInput: IFieldInput;
  onFieldChange: (
    id: string,
    value: ISubmission['answer'],
    callback?: () => void
  ) => void;
  isrequired?: boolean;
  isGeneralDisabled?: boolean;
}

const MNECheckbox = ({
  fieldInput,
  onFieldChange,
  isrequired = false,
  isGeneralDisabled = false,
}: MNECheckboxProps) => {
  const { id, inputName, value, label } = fieldInput;

  const [isChecked, setIsChecked] = useState(value?.toLowerCase() === 'yes');

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(!isChecked);
      onFieldChange(id, String(e.target.checked ? 'Yes' : ''));
    },
    [id, onFieldChange, isChecked, setIsChecked]
  );

  useEffect(() => {
    setIsChecked(value?.toLowerCase() === 'yes');
  }, [value]);

  return (
    <>
      <div className="mne-checkbox">
        <KiteCheckbox
          id={id}
          name={inputName}
          checked={isChecked}
          onChange={handleChange}
          label={label}
          className="mne-checkbox"
          disabled={isGeneralDisabled && id === generalSectionId}
        />
        <div className="mne-checkbox__marker">
          {isrequired && !value && (
            <div className="mne-field__incomplete-marker"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default MNECheckbox;
