// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import '../RCQFieldStyles.scss';

export interface IRCQCopyButtonProps {
  /** Current value of field input to be copied */
  value: string;
}

/** Copies the current value of a field input */

const RCQCopyButton = ({ value = '' }: IRCQCopyButtonProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleCopy = useCallback(() => {
    if (value) {
      navigator.clipboard.writeText(value);
    }
  }, [value]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteButton
      onClick={handleCopy}
      disabled={!value}
      minWidth="min-content"
      maxWidth="100%"
    >
      Copy to Clipboard
    </KiteButton>
  );
};

export default RCQCopyButton;
