// Packages
import React, { useCallback, useMemo, useRef, useState } from 'react';

// Redux

// Components
import { KiteAlert, KiteCard } from '@kite/react-kite';
import { CopyModal, SelectSolutionModal } from 'components';

// Hooks
import {
  useQueryData,
  MNEuseRenderFields,
  useScrollToTopMNE,
  useUpdateLocation,
  useSelectedSolutionCheck,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { renderFormIcon } from 'utils';

// Types
import { ISubmission, MForm } from 'types';

// Styles
import './MNEForm.scss';
import classNames from 'classnames';
import SalesBOMModal from 'components/SalesBOMModel/SalesBOMModel';

export interface IMNEFormProps {
  /**All forms */
  forms: MForm[];
  /** Current form data */
  form: MForm;
  /** Submission data for current form */
  formSubmissions: ISubmission[];

  allSubmissions?: ISubmission[];

  questionType: string;
}

/** Renders currently selected form within UCQuestions page*/

const MNEForm = ({
  forms,
  form,
  formSubmissions,
  allSubmissions,
  questionType,
}: IMNEFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { name, fields, id } = form;
  const { currentEstimate, currentLocation } = useQueryData();

  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [displayCopyError, setDisplayCopyError] = useState(false);
  const [selectedLocations, setSelectedLocation] = useState([]);

  const { locationId = '' } = useParams();

  const {
    fieldComponents,
    requiredFieldIds,
    requiredSubmissions,
    updateSubmissionsError,
    onCopy,
    copySubmissionsError,
  } = MNEuseRenderFields({
    fields,
    submissions: formSubmissions,
    formId: id,
    forms,
    questionType,
  });

  const { disableSelectSolutionBtn } = useSelectedSolutionCheck({
    currentLocation,
  });

  const [showSelectSolutionModal, setShowSelectSolutionModal] = useState(false);

  const { updateLocation } = useUpdateLocation(currentEstimate?.id);

  const hasIncomplete = requiredSubmissions.length !== requiredFieldIds.length;

  /*
  removed this code as part of performance enhancement for submissions api calls
  const { data: importSubmissions } = useGetSubmissions({
    params: { estimateId, locationId: importLocationId },
  });*/

  const ref = useRef<HTMLFormElement>(null);
  useScrollToTopMNE({ ref, refNestLevel: 0, form });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const otherLocations = useMemo(
    () => (currentEstimate?.locations || []).filter((l) => l.id !== locationId),
    [currentEstimate, locationId]
  );

  const showCopyBtn =
    name !== 'network-draft' &&
    locationId !== 'general' &&
    !!otherLocations.length;

  const submissionsToCopy = useMemo(() => {
    return !copyModalOpen ? [] : formSubmissions.filter((s) => s.formId === id);
  }, [copyModalOpen, formSubmissions, id]);

  const showSalesBOMBtn =
    name !== 'network-draft' &&
    name !== 'MNE Hospitality' &&
    locationId !== 'general' &&
    !document.getElementById('iswPrice')?.innerText?.match(/\$0/g)?.length;

  const showSelectOptionBtn =
    name !== 'network-draft' && locationId !== 'general';

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    // could add manual save here if needed
    e.preventDefault();
    // saveSubmissions(submissionsToSave);
  }, []);

  const toggleCopyModal = useCallback(() => {
    setSelectedLocation([]);
    setCopyModalOpen(!copyModalOpen);
  }, [copyModalOpen]);

  const handleLocationSelections = useCallback(
    (selections: any) => {
      setSelectedLocation(selections);
      if (displayCopyError) {
        setDisplayCopyError(false);
      }
    },
    [displayCopyError]
  );

  const handleCopy = useCallback(() => {
    if (selectedLocations.length) {
      if (submissionsToCopy.length) {
        onCopy(submissionsToCopy, selectedLocations);
      }
      toggleCopyModal();
    } else {
      setDisplayCopyError(true);
    }
  }, [onCopy, submissionsToCopy, toggleCopyModal, selectedLocations]);

  const toggleSelectSolutionModal = useCallback(() => {
    setShowSelectSolutionModal(!showSelectSolutionModal);
  }, [showSelectSolutionModal]);

  const handleSaveSolution = useCallback(
    (selectedSolution: string) => {
      if (currentLocation) {
        updateLocation({ ...currentLocation, selectedSolution });
      }
      toggleSelectSolutionModal();
    },
    [toggleSelectSolutionModal, currentLocation, updateLocation]
  );

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="mne-form">
      <div className="mne-form__header-container">
        <div className="mne-form__header">
          {renderFormIcon(form.name, '24px')}
          <h2>{name}</h2>
          <span className="mne-form__header-complete">{`${requiredSubmissions.length}/${requiredFieldIds.length}`}</span>
        </div>
        <div className="mne-form__header-btns">
          {hasIncomplete && (
            <div className="mne-field__incomplete">
              Incomplete Answer:{' '}
              <div className="mne-field__incomplete-marker"></div>
            </div>
          )}
          {showSalesBOMBtn && (
            <SalesBOMModal formName={name} formSubmissions={formSubmissions} />
          )}
          {showSelectOptionBtn && (
            <SelectSolutionModal
              canShow={showSelectSolutionModal}
              solution={currentLocation?.selectedSolution || ''}
              onSave={handleSaveSolution}
              onToggle={toggleSelectSolutionModal}
              disabled={disableSelectSolutionBtn}
            />
          )}
          {showCopyBtn && (
            <CopyModal
              formName={name}
              canShow={copyModalOpen}
              locations={otherLocations}
              onCopy={handleCopy}
              onToggle={toggleCopyModal}
              onHandleLocationSelections={handleLocationSelections}
              disabled={hasIncomplete}
              displayError={displayCopyError}
            />
          )}
        </div>
      </div>
      {updateSubmissionsError && (
        <KiteAlert
          type="alert"
          title="Save Error"
          description="Something went wrong saving your answers. Any changes will not be saved. Please refresh and try again later."
        />
      )}
      {copySubmissionsError && (
        <KiteAlert
          type="alert"
          title="Copy Responses Error"
          description="Something went wrong with copying your answers. Please refresh and try again later."
        />
      )}
      <form
        ref={ref}
        onSubmit={handleSubmit}
        className={classNames({
          'mne-form__fields': name !== 'Network Draft',
          'mne-form__fields--flow-chart': name === 'Network Draft',
        })}
      >
        {fieldComponents}
      </form>
    </KiteCard>
  );
};

export default MNEForm;
