// Packages
import React, { useCallback, useEffect, useRef, useMemo } from 'react';

// Redux

// Components
import { EipInput, QuantityCard } from 'components';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils

// Types

//React Router
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Styles
import './UcAddonsPage.scss';
import { professionalServicesId } from 'utils';

const UcAddonsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies, estimateScenarios } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });
  const { estimateId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const handleToggle = useMemo(() => {
    return estimateScenarios.some((scenario) => {
      return scenario.selections.some(
        (selection) => selection.familyCategory === 'RingCentral Products'
      );
    });
  }, [estimateScenarios]);
  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateComponents = useCallback(() => {
    const ucProductFamilies = allProductFamilies.filter(
      (f) => f.category === 'UC Products'
    );

    return ucProductFamilies.map((family) => {
      return (
        <div>
          <QuantityCard
            key={`${family.name} quantity card`}
            productFamily={family}
            expandable={true}
          />
          {family.id === professionalServicesId && (
            <>
              <div>
                <EipInput type="UC" />
              </div>
            </>
          )}
        </div>
      );
    });
  }, [allProductFamilies]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UcAddonsPage'), [trackPageView]);

  useEffect(() => {
    if (handleToggle) {
      navigate(`/estimate-builder/${estimateId}/rc-addons`, { replace: true });
    }
  }, [navigate, handleToggle, location.key, estimateId]);

  // =============================================
  // Early Return
  // =============================================

  if (handleToggle) return <></>;

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="uc-addons-page">
      <h3>UC Addons Configuration Options</h3>
      {generateComponents()}
    </div>
  );
};

export default UcAddonsPage;
