// Packages
import React, {
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

// Redux

// Components
import {
  KiteAlert,
  KiteButton,
  KiteIcon,
  KiteInput,
  KiteModal,
} from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';
import { useAnalytics, usePostPhoneNumbers, useQueryData } from 'hooks';

// Utils
import { getRateCenterValidation, getRateCenterRCValidation } from 'utils';

// Types
import { IPhoneNumberBody } from 'types';

// Styles
import './AddTnModal.scss';

export interface IAddTnModalProps {
  canShow: boolean;
  locationId: string;
  onClose: (locationId: string) => void;
}

type TRatePhones = Omit<IPhoneNumberBody, 'locationId'>;

const originalFormData = { npa: '', nxx: '' };

/** Modal for adding a new phone number to a location */

const AddTnModal = ({ canShow, locationId, onClose }: IAddTnModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { estimateId = '' } = useParams();
  const { currentEstimate } = useQueryData();
  const [formData, setFormData] = useState([originalFormData]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { postPhoneNumbers, postPhoneNumbersLoading } =
    usePostPhoneNumbers(estimateId);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const newState = [...formData];
      newState.splice(index, 1, { ...formData[index], [name]: value });
      setFormData(newState);
    },
    [formData]
  );

  const onDeleteNumber = useCallback(
    (index: number) => () => {
      const newState = [...formData];
      newState.splice(index, 1);
      setFormData(newState);
      trackSelectAction('Add TN Modal: Delete TN', { opType: 'buttonClick' });
    },
    [formData, trackSelectAction]
  );

  const onHideModal = useCallback(() => {
    onClose(locationId);
    setFormData([originalFormData]);
  }, [locationId, onClose]);

  const handleCloseModal = useCallback(() => {
    onHideModal();
    setAlertMessage('');
    setLoading(false);
  }, [onHideModal]);

  const checkValidationResponse = useCallback(
    (validationData: TRatePhones[] | undefined) => {
      if (!validationData) {
        // validationData is undefined, unable to access API
        return 'Unable to submit TNs at this time.';
      } else if (!validationData.length) {
        // No numbers were able to be validated
        setLoading(false);
        return 'None of the provided TNs were able to be validated.';
      } else if (validationData.length !== formData.length) {
        // Some numbers were validated
        // Identify which numbers were not able to be validated
        const notValidated = formData
          .reduce((acc: string[], { npa, nxx }) => {
            const validated = validationData.find(
              (number) => number.npa === npa && number.nxx === nxx
            );
            if (!validated) {
              acc.push(`${npa}-${nxx}`);
            }
            return acc;
          }, [])
          .join(', ');

        return `The following TNs were not able to be validated: ${notValidated}`;
      }
      return '';
    },
    [formData]
  );

  const isEstimateRC = useMemo(() => {
    const rcSelections = currentEstimate?.scenarios
      .map((s) => {
        return s.selections.find(
          (sel) => sel.familyCategory === 'RingCentral Products'
        );
      })
      .find((s) => s !== undefined);
    return rcSelections ? true : false;
  }, [currentEstimate]);

  const handleSubmit = useCallback(async () => {
    // e.preventDefault();
    setLoading(true);

    // Check that all fields are either empty or have 3 characters
    const hasValidNumbers = formData.every(
      ({ npa, nxx }) =>
        (npa + nxx).length === 0 || (npa.length === 3 && nxx.length === 3)
    );
    if (!hasValidNumbers) {
      setLoading(false);
      return setAlertMessage(
        'Please make sure all numbers are formatted correctly'
      );
    }

    const validationData: TRatePhones[] | undefined = isEstimateRC
      ? await getRateCenterRCValidation(formData)
      : await getRateCenterValidation(formData);

    const validationError = checkValidationResponse(validationData);
    setAlertMessage(validationError);

    // Transform data and add locationId
    const data: IPhoneNumberBody[] =
      validationData?.map((tn) => {
        if (isEstimateRC) {
          const tn1: IPhoneNumberBody = {
            locationId: '',
            npa: '',
            nxx: '',
            rcAbbre: '',
            locState: '',
            carrier: '',
            footPrint: '',
            bandwidth: '',
            bandwidthTrunkOnly: '',
          };
          tn1.npa = tn.npa;
          tn1.nxx = tn.nxx;
          tn1.portInEnabled = (tn.portInEnabled === 'true' ? 'Yes' : 'No');
          tn1.locationId = locationId;
          return tn1;
        }

        return { ...tn, locationId };
      }) || [];

    // Save TN info from Rate Center in DB
    data.length && postPhoneNumbers(data);
    setLoading(postPhoneNumbersLoading);

    if (!validationError) {
      trackSelectAction('Add TN Modal: Save', { opType: 'buttonClick' });
      handleCloseModal();
    }
  }, [
    locationId,
    formData,
    postPhoneNumbersLoading,
    postPhoneNumbers,
    handleCloseModal,
    checkValidationResponse,
    trackSelectAction,
    isEstimateRC,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const displayFields = useCallback(() => {
    return formData.map((data, index) => (
      <div
        key={`npa-nxx input ${index}`}
        className="add-tn-modal__form-input-container"
      >
        <div className="add-tn-modal__form-input-wrapper">
          <KiteInput
            label="NPA"
            name="npa"
            margin="0"
            value={data.npa}
            onChange={handleChange(index)}
          />
          <KiteInput
            label="NXX"
            name="nxx"
            margin="0"
            value={data.nxx}
            onChange={handleChange(index)}
          />
        </div>
        <KiteIcon
          name="trash"
          margin="16px 0 0 12px"
          onClick={onDeleteNumber(index)}
        />
      </div>
    ));
  }, [formData, handleChange, onDeleteNumber]);

  const handleAddNumber = useCallback(() => {
    setFormData((state) => [...state, originalFormData]);
  }, []);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setAlertMessage('');
  }, [formData]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteModal
      className="add-tn-modal"
      canShow={canShow}
      onHide={onHideModal}
      disableBackgroundClose={true}
      title="Add TNs"
      ctaAction={handleSubmit}
      ctaCopy="Submit"
      secondaryCtaAction={handleCloseModal}
      secondaryCtaCopy="Cancel"
      ctaLoading={loading}
    >
      <KiteAlert description="Must be on Charter Network or VPN to add TNs" />

      <form className="add-tn-modal__form">
        {displayFields()}
        <KiteButton
          buttonType="button"
          onClick={handleAddNumber}
          leftIcon="plus"
          type="outline"
          size="full"
          margin="0 0 1rem 0"
        >
          Add Number
        </KiteButton>
      </form>

      {alertMessage && <KiteAlert type="alert" description={alertMessage} />}
    </KiteModal>
  );
};

export default AddTnModal;
