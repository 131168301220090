import { IMneEneBadgeStatusProps } from 'components/MneEneBadgeStatus/MneEneBadgeStatus';
import { useCallback, useMemo } from 'react';
import { ISubmission } from 'types';
import { deviceConfig, enePowerPortFields, mnePowerPortFields } from 'utils';

export const useCalculatePowerAndPorts = ({
  submissions = [],
  type = 'MNE',
}: IMneEneBadgeStatusProps) => {
  // Counts total ports for both switches, other devices -( ap, camera, phone)
  // pass isSwitch as true in case of switches
  const countTotalPorts = useCallback(
    (subs: ISubmission[], isSwitch: boolean) => {
      return subs.reduce((acc: number, { answer }) => {
        if (answer) {
          const answers = JSON.parse(answer);
          answers.forEach((a: string[]) => {
            if (a[1] && a[0]) {
              acc += Number(a[1]) * (isSwitch ? deviceConfig[a[0]]!.ports : 1);
            }
          });
        }
        return acc;
      }, 0);
    },
    []
  );

  // Counts total power for both switches, other devices -( ap, camera, phone)
  const countTotalPower = useCallback((subs: ISubmission[]) => {
    return subs.reduce((acc: number, { answer }) => {
      if (answer) {
        const answers = JSON.parse(answer);
        answers.forEach((a: string[]) => {
          if (a[1] && a[0]) {
            acc += Number(a[1]) * deviceConfig[a[0]]!.power;
          }
        });
      }
      return acc;
    }, 0);
  }, []);

  // MNE/ENE Power, Ports Utilization
  const { isPortOK, isPowerOK, portDiff, powerDiff } = useMemo(() => {
    // based on type, select the respective field ids
    const {
      accessPointCameraPhoneFieldIds,
      additionalPortsRequiredFieldId,
      byoEndPointsFieldId,
      byoSwitchPortsFieldId,
      byoSwitchWattsFieldId,
      poeRequiredForUcRcFiedId,
      switchFieldId,
    } = type === 'MNE' ? mnePowerPortFields : enePowerPortFields;

    // Phones need to be included or not
    const isPoeRequiredForUcRc =
      Boolean(
        submissions.find((s) => s.fieldId === poeRequiredForUcRcFiedId)?.answer
      ) || false;

    // filter only switch submissions
    const switchSubmissions = submissions.filter(
      (s) => s.fieldId === switchFieldId
    );

    // mne, isPoeRequiredForUcRc = true then filter only access point, cameras, phones submissions
    // mne, isPoeRequiredForUcRc = false then filter only access point, cameras submissions
    // ene, isPoeRequiredForUcRc = true then filter only access point, phones submissions
    // ene, isPoeRequiredForUcRc = false then filter only access point submissions
    const accessPointCameraPhoneSubmissions = isPoeRequiredForUcRc
      ? submissions.filter((s) =>
          accessPointCameraPhoneFieldIds.includes(s.fieldId)
        )
      : submissions.filter((s) =>
          accessPointCameraPhoneFieldIds.slice(0, -1).includes(s.fieldId)
        );

    // <----- Ports ----->
    //total no.of ports for switch
    const totalSwitchPorts = countTotalPorts(switchSubmissions, true);

    // total no. of ports for access points, cameras, phones
    const totalAccessPointCameraPhonePorts = countTotalPorts(
      accessPointCameraPhoneSubmissions,
      false
    );

    // additional ports required by client
    const additionalPortsRequired =
      Number(
        submissions.find((s) => s.fieldId === additionalPortsRequiredFieldId)
          ?.answer
      ) || 0;

    // byo switch ports provided by client
    const byoSwitchPorts =
      Number(
        submissions.find((s) => s.fieldId === byoSwitchPortsFieldId)?.answer
      ) || 0;

    const portsRequired =
      totalAccessPointCameraPhonePorts + additionalPortsRequired;
    const portsAvailable = totalSwitchPorts + byoSwitchPorts;
    const portDiff = portsAvailable - portsRequired;
    const isPortOK = portsAvailable >= portsRequired ? true : false;
    // x----- Ports -----x

    // <----- Power ----->
    // total power generated by switches
    const totalSwitchPower = countTotalPower(switchSubmissions);
    // power generated by byo switches
    const byoSwitchWatts =
      Number(
        submissions.find((s) => s.fieldId === byoSwitchWattsFieldId)?.answer
      ) || 0;

    // power consumed by byo end points (watts)
    const byoEndPointsPower =
      Number(
        submissions.find((s) => s.fieldId === byoEndPointsFieldId)?.answer
      ) || 0;

    // total power consumed by access points, cameras, phones
    const totalAccessPointCameraPhonePower = countTotalPower(
      accessPointCameraPhoneSubmissions
    );

    const powerGenerated = totalSwitchPower + byoSwitchWatts;
    const powerConsumed = totalAccessPointCameraPhonePower + byoEndPointsPower;
    const powerDiff = powerGenerated - powerConsumed;

    const isPowerOK =
      (!!powerGenerated || !!powerConsumed) &&
      powerConsumed >= powerGenerated * 0.8
        ? false
        : true;
    // x----- Power -----x

    return {
      isPortOK,
      isPowerOK,
      portDiff,
      powerDiff,
    };
  }, [submissions, type, countTotalPorts, countTotalPower]);

  return { isPortOK, isPowerOK, portDiff, powerDiff };
};
