import axios from 'axios';
import {
  ICopyCloneHistory,
  IPostCopyCloneHistory,
  IRequestConfig,
  IRequestParams,
} from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface ICopyCloneHistoryRequestParams extends IRequestParams {
  estimateId?: string;
}

export interface ICopyCloneHistoryRequest
  extends IRequestConfig<
    ICopyCloneHistory | ICopyCloneHistory[] | IPostCopyCloneHistory[]
  > {
  params?: ICopyCloneHistoryRequestParams;
}

export const copyCloneHistoryRequest = async (
  config?: ICopyCloneHistoryRequest
) => {
  const {
    endpoint = 'copy-clone-histories',
    method = 'GET',
    data,
    params,
  } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with copy clone histories ${method}`,
      })
    );
};
