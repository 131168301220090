import { ICopyCloneHistory } from 'types';
import { copyCloneHistoryRequest, queryKeys } from 'utils';
import { useGenerateQuery } from './useGenerateQuery';

export const useGetCopyHistoriesByEstimateId = (
  estimateId: string,
  actionType: string
) => {
  const requestConfig = {
    method: 'GET',
    endpoint: `copy-clone-histories/${actionType}/${estimateId}`,
    params: {},
  };
  return useGenerateQuery<ICopyCloneHistory[]>({
    queryKey:
      actionType === 'COPY'
        ? queryKeys({ estimateId }).filter.copyHistories
        : queryKeys({ estimateId }).filter.cloneHistories,
    apiCall: copyCloneHistoryRequest,
    requestConfig,
  });
};
