// Packages
import React from 'react';

// Redux

// Components
import { ButtonLink } from 'components';

// Hooks
import { useLocation, useParams } from 'react-router-dom';

// Styles
import './ENEProgressButton.scss';

interface IENEProgressButtonParams {
  type?: 'outline' | 'primary';
}
/** RC Questions button displaying question progress */

const ENEProgressButton = ({ type = 'outline' }: IENEProgressButtonParams) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();
  const { estimateId = '' } = useParams();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // Team not sure if they want to show this or not
  // const progressBar = useMemo(
  //   () => (
  //     <div
  //       className={classNames({
  //         'rc-progress-button__progress': true,
  //         'rc-progress-button__progress--full': completePercent === 100,
  //       })}
  //       style={{ width: `${completePercent}%` }}
  //     ></div>
  //   ),
  //   [completePercent]
  // );
  // const linkContent = useMemo(() => {
  //   return (
  //     <>
  //       {`RC Questions (${completePercent}%)`}
  //       {progressBar}
  //     </>
  //   );
  // }, [completePercent, progressBar]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <ButtonLink
      className="ene-progress-button"
      type={type}
      to={`/ene-questions/${estimateId}/general/ene-opportunity-type`}
      state={{ fromDomain: pathname }}
      linkContent="ENE Questions"
    />
  );
};

export default ENEProgressButton;
