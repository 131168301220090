import { useMemo } from 'react';
import { ILocation } from 'types';
import { eneFormIDs, mneFirewallFieldId, mneFormIDs } from 'utils';
import { TTabOptions } from 'pages/UcReviewPage/UcReviewPage';
import { useGetSubmissions, useUpdateLocation } from './apiHooks';

interface ISelectedSolutionParams {
  currentLocation: ILocation | undefined;
  tabOptions?: TTabOptions[];
}

export const useSelectedSolutionCheck = ({
  currentLocation,
  tabOptions,
}: ISelectedSolutionParams) => {
  const { data: currentEstimateSubmissions } = useGetSubmissions({
    params: { estimateId: currentLocation?.estimateId },
  });
  const { updateLocation } = useUpdateLocation(currentLocation?.estimateId);

  const {
    disableSelectSolutionBtn,
    solutionBadgeLabel,
    newTabOptions,
    stopProgressBarAt95,
    isBothMneEneAnswered,
  } = useMemo(() => {
    const currentLocationAndGeneralSubmissions =
      currentEstimateSubmissions?.filter(
        (s) => s.locationId === currentLocation?.id || s.locationId === null
      ) || [];

    const isMneQuestionsAnswered = Boolean(
      currentLocationAndGeneralSubmissions.find(
        (s) => s.formId && mneFormIDs.includes(s.formId) && s.answer
      )
    );

    const isEneQuestionsAnswered = Boolean(
      currentLocationAndGeneralSubmissions.find(
        (s) => s.formId && eneFormIDs.includes(s.formId) && s.answer
      )
    );

    const isFirewallIncludedWithOrder = Boolean(
      currentLocationAndGeneralSubmissions.find(
        (s) => s.fieldId === mneFirewallFieldId
      )?.answer === 'Yes, Fortinet firewall'
    );

    if (
      currentLocation &&
      currentLocation.selectedSolution &&
      currentEstimateSubmissions &&
      (!isMneQuestionsAnswered || !isEneQuestionsAnswered)
    ) {
      updateLocation({ ...currentLocation, selectedSolution: '' });
    }

    const isBothMneEneAnswered =
      isMneQuestionsAnswered && isEneQuestionsAnswered;

    // Logic to disable/enable Select Solution Button
    const disableSelectSolutionBtn = isBothMneEneAnswered
      ? isFirewallIncludedWithOrder
      : true;

    // Logic to stop progress bar at 95%
    const stopProgressBarAt95 =
      isBothMneEneAnswered &&
      !isFirewallIncludedWithOrder &&
      !currentLocation?.selectedSolution;

    // Logic for solution badge label
    const solutionBadgeLabel = currentLocation?.selectedSolution
      ? `${currentLocation.selectedSolution} Solution`
      : isFirewallIncludedWithOrder
      ? 'ENE Edge & MNE Devices'
      : isBothMneEneAnswered && !currentLocation?.selectedSolution
      ? 'Solution Not Selected'
      : isMneQuestionsAnswered
      ? 'MNE Solution'
      : isEneQuestionsAnswered
      ? 'ENE Solution'
      : 'Solution Not Selected';

    // Logic for Review Page
    let newTabOptions = tabOptions;

    if (tabOptions) {
      if (!isFirewallIncludedWithOrder) {
        if (isBothMneEneAnswered) {
          if (currentLocation?.selectedSolution === 'MNE') {
            newTabOptions = tabOptions.filter(
              (item) => item !== 'ENE Questions'
            );
          } else if (currentLocation?.selectedSolution === 'ENE') {
            newTabOptions = tabOptions.filter(
              (item) => item !== 'MNE Questions'
            );
          }
        } else if (!isMneQuestionsAnswered && !isEneQuestionsAnswered) {
          newTabOptions = tabOptions.filter(
            (item) => item !== 'ENE Questions' && item !== 'MNE Questions'
          );
        } else if (isMneQuestionsAnswered) {
          newTabOptions = tabOptions.filter((item) => item !== 'ENE Questions');
        } else if (isEneQuestionsAnswered) {
          newTabOptions = tabOptions.filter((item) => item !== 'MNE Questions');
        }
      }
    }

    return {
      disableSelectSolutionBtn,
      solutionBadgeLabel,
      newTabOptions,
      stopProgressBarAt95,
      isBothMneEneAnswered,
    };
  }, [currentEstimateSubmissions, currentLocation, tabOptions, updateLocation]);

  return {
    disableSelectSolutionBtn,
    solutionBadgeLabel,
    newTabOptions,
    stopProgressBarAt95,
    isBothMneEneAnswered,
  };
};
