// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components

import {
  KiteAlert,
  KiteButton,
  KiteCheckbox,
  KiteModal,
  KiteSelect,
} from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './CloneEstimateModal.scss';

import {
  IEstimate,
  ILocation,
  IPostCopyCloneHistory,
  ISubmission,
} from 'types';
import { useParams } from 'react-router-dom';
import {
  useGetEstimateDetails,
  useGetEstimatesData,
  useQueryData,
} from 'hooks';

import CloneEstimateSuggestionInput from 'components/CloneEstimateSuggestionInput';
import { ISelectedEstimate } from 'types/utilTypes';
import { useCopySubmissions, usePostCopyCloneHistory } from 'hooks/apiHooks';
import { getFormIdByFormType } from 'utils';

export interface ICloneEstimateModalProps {
  /** Determines if modal is open */
  canShow: boolean;
  onToggle: () => void;
  sourceEstimate: IEstimate;
}

const CloneEstimateModal = ({
  onToggle,
  canShow,
  sourceEstimate,
}: ICloneEstimateModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const [sourceLocation, setSourceLocation] = useState('');
  const [targetLocation, setTargetLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [cloneMessage, setCloneMessage] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [questionType, setQuestionType] = useState<any>({
    UC: false,
    RC: false,
    MNE: false,
    ENE: false,
  });
  const [targetEstimate, setTargetEstimate] =
    useState<ISelectedEstimate | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const { estimateId = '' } = useParams();
  const { currentEstimate = {} as IEstimate, userId } = useQueryData();
  const { data: allEstimatesData } = useGetEstimatesData({
    params: { archived: false },
  });
  const { estimateData: targetEstimateData } = useGetEstimateDetails(
    targetEstimate?.estimateId!
  );

  const { copySubmissions } = useCopySubmissions({
    estimateId: targetEstimate?.estimateId!,
    sourceEstimateId: currentEstimate.id,
  });

  const { postCopyCloneHistory } = usePostCopyCloneHistory();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const sourceEstimateName = currentEstimate.name;

  const otherEstimates = useMemo(
    () => allEstimatesData?.filter((e) => e.id !== estimateId) || [],
    [allEstimatesData, estimateId]
  );

  const selectedQuestionType = Object.keys(questionType).filter(
    (key) => questionType[key] === true
  );

  const formIDs: { [key: string]: string[] } = {
    UC: [getFormIdByFormType['UC']],
    RC: [getFormIdByFormType['RC']],
    MNE: [getFormIdByFormType['MNE'], getFormIdByFormType['MNEH']],
    ENE: [getFormIdByFormType['ENE']],
  };

  // =============================================
  // Interaction Handlers
  // =============================================

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      if (e.target.value.length && !showSuggestions) {
        setShowSuggestions(true);
      } else if (!e.target.value.length && showSuggestions) {
        setShowSuggestions(false);
      }
    },
    [showSuggestions]
  );

  const onChangeLocationValue = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setSourceLocation(value);
      setErrorMessage(false);
      setCloneMessage(false);
    },
    []
  );

  const onChangeTargetLocationValue = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setTargetLocation(value);
      setErrorMessage(false);
      setCloneMessage(false);
    },
    []
  );

  const handleQuestionsType = useCallback(
    (type: 'UC' | 'RC' | 'MNE' | 'ENE') => {
      setQuestionType({ ...questionType, [type]: !questionType[type] });
      setErrorMessage(false);
      setCloneMessage(false);
    },
    [questionType]
  );

  const cloneEstimate = useCallback(() => {
    const srcFormIds = selectedQuestionType.map((type) => formIDs[type]);

    const srcLocSub =
      currentEstimate.submissions?.filter((l) =>
        Boolean(
          l.locationId === sourceLocation &&
            srcFormIds.find((f) => f.includes(l.formId!))
        )
      ) || [];

    let destinationLocations: ILocation[] = [];

    destinationLocations.push(
      targetEstimateData?.locations.find((l) => l.id === targetLocation)!
    );

    const copiedSubmissions = srcLocSub?.reduce(
      (acc: Partial<ISubmission>[], { fieldId, answer }) => {
        destinationLocations?.forEach((l) => {
          const sub = targetEstimateData?.submissions!.find(
            (sub) =>
              sub.fieldId === fieldId &&
              sub.locationId &&
              sub.locationId === l.id // targetloc with samefieldId submission
          );
          if (sub) {
            const newSub: Partial<ISubmission> = {
              // if target field is answered make it nullify
              ...sub,
              formId: undefined,
              answer,
              editedBy: userId,
              estimateId: targetEstimate?.estimateId,
            };
            acc.push(newSub);
          } else {
            // create sub with targetLoc id
            acc.push({
              estimateId: targetEstimate?.estimateId,
              locationId: l.id,
              fieldId,
              answer,
              editedBy: userId,
            });
          }
        });

        return acc;
      },
      []
    );
    const importedIds = new Set(copiedSubmissions?.map((s) => s.id));
    const removedSubmissions = targetEstimateData?.submissions!.reduce(
      (acc: Partial<ISubmission>[], s) => {
        if (
          !importedIds.has(s.id) &&
          destinationLocations?.find((l) => l.id === s.locationId) &&
          srcFormIds.find((f) => f.includes(s.formId!))
        ) {
          const newSub: Partial<ISubmission> = {
            ...s,
            formId: undefined,
            answer: '',
            editedBy: userId,
            estimateId: targetEstimate?.estimateId,
          };
          acc.push(newSub);
        }
        return acc;
      },
      []
    );
    const updatedSubmissions = [...copiedSubmissions!, ...removedSubmissions!];

    copySubmissions(updatedSubmissions!);

    if (currentEstimate && targetEstimate && sourceLocation && targetLocation) {
      const cloneHistoryEntry: IPostCopyCloneHistory[] = [
        {
          sourceEstimateId: currentEstimate.id,
          sourceLocationId: sourceLocation,
          targetEstimateId: targetEstimate.estimateId,
          targetLocationId: targetLocation,
          formIds: srcFormIds.flat(),
          actionType: 'CLONE',
          triggeredBy: userId,
        },
      ];
      postCopyCloneHistory(cloneHistoryEntry);
    }
  }, [
    selectedQuestionType,
    targetEstimate,
    copySubmissions,
    currentEstimate,
    targetEstimateData,
    targetLocation,
    sourceLocation,
    userId,
    formIDs,
    postCopyCloneHistory,
  ]);

  const handleCloneEstimate = useCallback(() => {
    if (
      sourceLocation &&
      targetEstimate &&
      targetLocation &&
      selectedQuestionType.length
    ) {
      setErrorMessage(false);
      cloneEstimate();
      setCloneMessage(true);
    } else {
      setErrorMessage(true);
    }
  }, [
    sourceLocation,
    targetEstimate,
    targetLocation,
    cloneEstimate,
    selectedQuestionType,
  ]);

  const allSourceLocations = useMemo(() => {
    return (
      sourceEstimate.locations.map((l) => (
        <option key={l.id} value={l.id}>
          {l.name}
        </option>
      )) || []
    );
  }, [sourceEstimate.locations]);

  const allTargetLocations = useMemo(() => {
    return (
      targetEstimateData?.locations.map((l) => (
        <option key={l.id} value={l.id}>
          {l.name}
        </option>
      )) || []
    );
  }, [targetEstimateData]);

  const onCancel = useCallback(() => {
    setSearchValue('');
    onToggle();
  }, [onToggle]);

  const handleSelectedEstimate = useCallback((estimate: ISelectedEstimate) => {
    setTargetEstimate({
      estimateId: estimate.estimateId,
      estimateName: estimate.estimateName,
    });
    setTargetLocation('');
    setShowSuggestions(false);
    setSearchValue('');
  }, []);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  useEffect(() => {
    if (canShow) {
      setSourceLocation('');
      setTargetLocation('');
      setQuestionType({});
      setTargetEstimate(null);
      setCloneMessage(false);
    }
  }, [canShow]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="copy-estimate">
      <KiteButton type="primary" onClick={onToggle} size="full">
        Clone Answers
      </KiteButton>
      <KiteModal
        canShow={canShow}
        onHide={onToggle}
        title="Clone Answers"
        ctaCopy="Clone"
        ctaAction={handleCloneEstimate}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={onCancel}
      >
        <div className="copy-estimate-name">
          <span className="copy-estimate-name-title">
            Source Estimate : &nbsp;
          </span>
          {sourceEstimateName}
        </div>
        <KiteSelect
          id="location-dropdown"
          label="Please select source location"
          name="current-location"
          onChange={onChangeLocationValue}
          value={sourceLocation}
          placeholder={sourceLocation ? '' : 'Make Selection'}
          className="copy-estimate-select"
        >
          {allSourceLocations}
        </KiteSelect>
        <div className="copy-estimate-questions-type">
          <KiteCheckbox
            key="uc-questions"
            id="uc-questions"
            label="UC Questions"
            name="UC Questions"
            checked={questionType.UC}
            onChange={() => handleQuestionsType('UC')}
          />
          <KiteCheckbox
            key="rc-questions"
            id="rc-questions"
            label="RC Questions"
            name="RC Questions"
            checked={questionType.RC}
            onChange={() => handleQuestionsType('RC')}
          />
          <KiteCheckbox
            key="mne-questions"
            id="mne-questions"
            label="MNE Questions"
            name="MNE Questions"
            checked={questionType.MNE}
            onChange={() => handleQuestionsType('MNE')}
          />
          <KiteCheckbox
            key="ene-questions"
            id="ene-questions"
            label="ENE Questions"
            name="ENE Questions"
            checked={questionType.ENE}
            onChange={() => handleQuestionsType('ENE')}
          />
        </div>

        <CloneEstimateSuggestionInput
          searchValue={searchValue}
          onSearch={onSearch}
          showSuggestions={showSuggestions}
          toggleShowSuggestions={setShowSuggestions}
          suggestions={otherEstimates
            .map((e) => ({
              estimateId: e.id,
              estimateName: e.name,
            }))
            .filter((e) =>
              e.estimateName.toLowerCase().includes(searchValue.toLowerCase())
            )}
          onSelect={handleSelectedEstimate}
        />

        <div className="copy-estimate-name">
          <span className="copy-estimate-name-title">
            Target Estimate : &nbsp;
          </span>
          {targetEstimate?.estimateName}
        </div>

        <KiteSelect
          id="taregt-location"
          label="Please select target location to clone"
          name="target-location"
          onChange={onChangeTargetLocationValue}
          placeholder={targetLocation ? '' : 'Make Selection'}
          value={targetLocation}
          className="copy-estimate-select"
        >
          {allTargetLocations}
        </KiteSelect>
        {errorMessage && (
          <KiteAlert type="alert" description="Please select all values" />
        )}
        {cloneMessage && (
          <KiteAlert
            type="info"
            description="Submissions cloned successfully"
          />
        )}
      </KiteModal>
    </div>
  );
};

export default CloneEstimateModal;
