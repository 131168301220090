// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteAlert, KiteCard } from '@kite/react-kite';
import { CopyModal } from 'components';

// Hooks
import { RCuseRenderFields, useQueryData } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
import { RCrenderFormIcon } from 'utils';

// Types
import { RForm, ISubmission } from 'types';

// Styles
import './RCQForm.scss';
import { useScrollToTopRC } from 'hooks/useScrollToTop';

export interface IRCQFormProps {
  /** Current form data */
  form: RForm;
  /** Submission data for current form */
  formSubmissions: ISubmission[];
  /** Change handler for field focus, updates help text in layout */
  onFocusChange: (helpString: string) => void;

  allSubmissions?: ISubmission[];
}

/** Renders currently selected form within RCQuestions page*/

const RCQForm = ({
  form,
  onFocusChange,
  formSubmissions,
  allSubmissions,
}: IRCQFormProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { name, fields, id } = form;
  const { currentEstimate } = useQueryData();

  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [displayCopyError, setDisplayCopyError] = useState(false);
  const [selectedLocations, setSelectedLocation] = useState([]);

  const { locationId = '', formName } = useParams();

  // const handleChange = (helpString: string) => {};

  const {
    fieldComponents,
    currentFocusId,
    requiredFieldIds,
    requiredSubmissions,
    updateSubmissionsError,
    onCopy,
    copySubmissionsError,
  } = RCuseRenderFields({
    fields,
    submissions: formSubmissions,
    formId: id,
    onFocusChange,
  });

  const hasIncomplete = requiredSubmissions.length !== requiredFieldIds.length;

  /*
  removed this code as part of performance enhancement for submissions api calls
  const { data: importSubmissions } = useGetSubmissions({
    params: { estimateId, locationId: importLocationId },
  });*/

  const ref = useRef<HTMLFormElement>(null);
  useScrollToTopRC({ ref, refNestLevel: 0, form });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const otherLocations = useMemo(
    () => (currentEstimate?.locations || []).filter((l) => l.id !== locationId),
    [currentEstimate, locationId]
  );

  const showCopyBtn =
    formName !== 'network-draft' &&
    locationId !== 'general' &&
    !!otherLocations.length;

  const submissionsToCopy = useMemo(() => {
    return !copyModalOpen ? [] : formSubmissions.filter((s) => s.formId === id);
  }, [copyModalOpen, formSubmissions, id]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    // could add manual save here if needed
    e.preventDefault();
    // saveSubmissions(submissionsToSave);
  }, []);

  const toggleCopyModal = useCallback(() => {
    setSelectedLocation([]);
    setCopyModalOpen(!copyModalOpen);
  }, [copyModalOpen]);

  const handleLocationSelections = useCallback(
    (selections: any) => {
      setSelectedLocation(selections);
      if (displayCopyError) {
        setDisplayCopyError(false);
      }
    },
    [displayCopyError]
  );

  const handleCopy = useCallback(() => {
    if (selectedLocations.length) {
      if (submissionsToCopy.length) {
        onCopy(submissionsToCopy, selectedLocations);
      }
      toggleCopyModal();
    } else {
      setDisplayCopyError(true);
    }
  }, [onCopy, submissionsToCopy, toggleCopyModal, selectedLocations]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    // Render help text in parent (RCQLayout) component
    const helpHtml =
      fields.find((f) => f.id === currentFocusId)?.helpText || '';
    onFocusChange(helpHtml);
  }, [currentFocusId, fields, onFocusChange]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="rcq-form">
      <div className="rcq-form__header-container">
        <div className="rcq-form__header">
          {RCrenderFormIcon(form.name, '24px')}
          <h2>{name}</h2>
          <span className="rcq-form__header-complete">{`${requiredSubmissions.length}/${requiredFieldIds.length}`}</span>
        </div>
        <div className="rcq-form__header-btns">
          {hasIncomplete && (
            <div className="rcq-field__incomplete">
              Incomplete Answer:{' '}
              <div className="rcq-field__incomplete-marker"></div>
            </div>
          )}
          {showCopyBtn && (
            <CopyModal
              formName={name}
              canShow={copyModalOpen}
              locations={otherLocations}
              onCopy={handleCopy}
              onToggle={toggleCopyModal}
              onHandleLocationSelections={handleLocationSelections}
              disabled={false}
              displayError={displayCopyError}
            />
          )}
        </div>
      </div>
      {updateSubmissionsError && (
        <KiteAlert
          type="alert"
          title="Save Error"
          description="Something went wrong saving your answers. Any changes will not be saved. Please refresh and try again later."
        />
      )}
      {copySubmissionsError && (
        <KiteAlert
          type="alert"
          title="Copy Responses Error"
          description="Something went wrong with copying your answers. Please refresh and try again later."
        />
      )}
      <form
        ref={ref}
        onSubmit={handleSubmit}
        className={classNames({
          'rcq-form__fields': true,
          'rcq-form__fields--flow-chart': name === 'Discovery Questions/Notes',
        })}
      >
        {fieldComponents}
      </form>
    </KiteCard>
  );
};

export default RCQForm;
