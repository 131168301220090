// Packages
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { Drawer } from '@kite/react-kite-plus';
import { KiteLoader } from '@kite/react-kite';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useAnalytics,
  useGetSubmissionHistory,
  useGetTeamMembersData,
  useGetCopyHistoriesByEstimateId,
  useGetEstimatesData,
} from 'hooks';

// Utils
import { formatTeamMember } from 'utils';
// Types
import {
  IField,
  ISalesTeamMember,
  ISubmissionHistory,
  ICopyCloneHistory,
} from 'types';

// Styles
import './MNEHistoryDrawer.scss';
import MNEHistoryRow from '../MNEHistoryRow';
import MNEHistoryModal from '../MNEHistoryModal';
import { MNEuseGetForms } from 'hooks/apiHooks/useGetForms';
import { ENEHistoryRow } from 'components/eneQuestions';
import {
  ENEQHistoryForms,
  getFormIdByFormType,
  MNEFORMS,
  MNEQHistoryForms,
} from 'utils/defaultsAndConstants/constants';
import CopyHistoryRow from 'components/CopyHistoryRow';
import CopyHistoryModal from 'components/CopyHistoryModal';

export interface IMNEHistoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  questionType: string;
}

type THistoryItem = { day: string; changes: JSX.Element[] };

/** Displays submission history for an estimate */

const MNEHistoryDrawer = ({
  isOpen,
  onClose,
  questionType,
}: IMNEHistoryDrawerProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { estimateId } = useParams();
  const {
    data: historyData,
    isLoading,
    isError,
  } = useGetSubmissionHistory({ params: { estimateId } });

  const {
    data: copyHistoryData,
    isLoading: isCopyHistoryLoading,
    isError: isCopyHistoryError,
  } = useGetCopyHistoriesByEstimateId(estimateId || '', 'COPY');

  const {
    data: cloneHistoryData,
    isLoading: isCloneHistoryLoading,
    isError: isCloneHistoryError,
  } = useGetCopyHistoriesByEstimateId(estimateId || '', 'CLONE');

  const { data: allEstimatesData, isLoading: allEstimatesLoading } =
    useGetEstimatesData();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentHistory, setCurrentHistory] =
    useState<ISubmissionHistory | null>(null);
  const [selectedField, setSelectedField] = useState<IField | null>(null);

  const editedByPids = useMemo(() => {
    return Array.from(new Set(historyData?.map((hd) => hd.editedBy) || []));
  }, [historyData]);

  const [copyHistoryModalIsOpen, setCopyHistoryModalIsOpen] = useState(false);
  const [currentCopyHistory, setCurrentCopyHistory] =
    useState<ICopyCloneHistory>({} as ICopyCloneHistory);

  // Fetches all AD data for associated PIDs within history
  const activeDirectoryData = useGetTeamMembersData({
    pids: editedByPids,
    enabled: !!historyData,
  });

  const { data: formsData = [] } = MNEuseGetForms({
    params: { type: questionType },
  });

  const teamMembers = useMemo(() => {
    return activeDirectoryData.reduce(
      (acc: { [pid: string]: ISalesTeamMember | undefined }, queryResult) => {
        const { isLoading, data } = queryResult;
        if (isLoading || !data) {
          return acc;
        }
        const { pid } = data;
        acc[pid] = formatTeamMember({ activeDirectoryData: data });
        return acc;
      },
      {}
    );
  }, [activeDirectoryData]);

  const currentHistoryTeamMember = useMemo(() => {
    if (currentHistory) {
      return teamMembers[currentHistory.editedBy];
    }
  }, [currentHistory, teamMembers]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleModalChange = useCallback(
    (params: {
        isOpen: boolean;
        history: ISubmissionHistory | null;
        headerField: IField | null;
      }) =>
      () => {
        const { isOpen, history, headerField } = params;
        setCurrentHistory(history);
        setModalIsOpen(isOpen);
        setSelectedField(headerField);
        trackSelectAction(`History Modal ${isOpen ? 'Open' : 'Close'}`, {
          opType: 'buttonClick',
        });
      },
    [trackSelectAction]
  );

  const handleCopyHistoryModalChange = useCallback(
    (params: { isOpen: boolean; history: ICopyCloneHistory }) => () => {
      const { isOpen, history } = params;
      setCurrentCopyHistory(history);
      setCopyHistoryModalIsOpen(isOpen);
    },
    []
  );

  // =============================================
  // Render Methods
  // =============================================
  const formattedHistoryData = useMemo(() => {
    return historyData
      ?.filter((h) =>
        questionType === MNEFORMS
          ? MNEQHistoryForms.includes(h.formId) ||
            h.formName === 'Network Draft'
          : ENEQHistoryForms.includes(h.formId) ||
            h.formName === 'Network Draft'
      )
      .reduce((acc: THistoryItem[], history: ISubmissionHistory) => {
        const { id, createdAt, editedBy: pid, fieldOrder } = history;

        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const reqdfields = formsData
          .filter((f) => f.name === history?.formName)
          .map((f) => f.fields)
          .flat()
          .filter((f) => f.header);

        const x = reqdfields.filter((f) => f.order <= fieldOrder);

        const headerField = x.length ? x[x.length - 1] : null;

        const teamMember = teamMembers[pid];
        const historyRow =
          questionType === MNEFORMS ? (
            <MNEHistoryRow
              key={id}
              teamMember={teamMember}
              historyData={history}
              headerField={headerField}
              onClick={handleModalChange({
                isOpen: true,
                history,
                headerField,
              })}
            />
          ) : (
            <ENEHistoryRow
              key={id}
              teamMember={teamMember}
              historyData={history}
              headerField={headerField}
              onClick={handleModalChange({
                isOpen: true,
                history,
                headerField,
              })}
            />
          );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }
        return acc;
      }, []);
  }, [handleModalChange, historyData, teamMembers, formsData, questionType]);

  const renderHistoryList = useCallback(() => {
    if (isLoading) {
      return <KiteLoader />;
    }

    if (isError) {
      return <span>Something went wrong loading submission history.</span>;
    }

    if (formattedHistoryData) {
      return formattedHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [isLoading, isError, formattedHistoryData]);

  const formattedCopyHistoryData = useMemo(() => {
    const copyHistory = copyHistoryData
      ?.filter((c) =>
        questionType === MNEFORMS
          ? c.formIds.includes(getFormIdByFormType['MNE']) ||
            c.formIds.includes(getFormIdByFormType['MNEH'])
          : c.formIds.includes(getFormIdByFormType['ENE'])
      )
      .reduce((acc: THistoryItem[], history: ICopyCloneHistory) => {
        const { id, createdAt, triggeredBy: pid } = history;
        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const teamMember = teamMembers[pid];

        const historyRow = (
          <CopyHistoryRow
            key={id}
            historyData={history}
            teamMember={teamMember}
            onClick={handleCopyHistoryModalChange({
              isOpen: true,
              history,
            })}
          />
        );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }

        return acc;
      }, []);
    return copyHistory;
  }, [
    handleCopyHistoryModalChange,
    copyHistoryData,
    teamMembers,
    questionType,
  ]);

  const renderCopyHistoryList = useCallback(() => {
    if (isCopyHistoryLoading) {
      return <KiteLoader />;
    }

    if (isCopyHistoryError) {
      return <span>Something went wrong loading copy responses history.</span>;
    }

    if (formattedCopyHistoryData) {
      return formattedCopyHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [isCopyHistoryLoading, isCopyHistoryError, formattedCopyHistoryData]);

  const formattedCloneHistoryData = useMemo(() => {
    const cloneHistory = cloneHistoryData
      ?.filter((c) =>
        questionType === MNEFORMS
          ? c.formIds.includes(getFormIdByFormType['MNE']) ||
            c.formIds.includes(getFormIdByFormType['MNEH'])
          : c.formIds.includes(getFormIdByFormType['ENE'])
      )
      ?.reduce((acc: THistoryItem[], history: ICopyCloneHistory) => {
        const { id, createdAt, triggeredBy: pid } = history;
        const dayName = new Date(createdAt).toLocaleString('en-us', {
          weekday: 'long',
        });
        // Include year and remove when rendering so that we dont add to an existing day when the year turns over
        const formattedDayName =
          dayName + ', ' + dayjs(createdAt).format('MMMM Do YYYY');
        const existingDay = acc.find((obj) => obj.day === formattedDayName);

        const teamMember = teamMembers[pid];

        const historyRow = (
          <CopyHistoryRow
            key={id}
            historyData={history}
            teamMember={teamMember}
            onClick={handleCopyHistoryModalChange({
              isOpen: true,
              history,
            })}
          />
        );

        // Check if there is already a section for that day
        if (existingDay) {
          existingDay.changes.push(historyRow);
        } else {
          // Create a new day header and array of changes
          acc.push({
            day: formattedDayName,
            changes: [historyRow],
          });
        }

        return acc;
      }, []);
    return cloneHistory;
  }, [
    handleCopyHistoryModalChange,
    cloneHistoryData,
    teamMembers,
    questionType,
  ]);

  const renderCloneHistoryList = useCallback(() => {
    if (isCloneHistoryLoading || allEstimatesLoading) {
      return <KiteLoader />;
    }

    if (isCloneHistoryError) {
      return <span>Something went wrong loading clone responses history.</span>;
    }

    if (formattedCloneHistoryData && allEstimatesData) {
      return formattedCloneHistoryData?.map(
        (historyItem: THistoryItem, i: number) => (
          <div key={i}>
            <h2>{historyItem.day.slice(0, -4)}</h2>
            {historyItem.changes}
          </div>
        )
      );
    }
  }, [
    isCloneHistoryLoading,
    isCloneHistoryError,
    formattedCloneHistoryData,
    allEstimatesLoading,
    allEstimatesData,
  ]);

  const showCopyResponsesHistory = Boolean(
    copyHistoryData &&
      copyHistoryData.filter((c) =>
        questionType === MNEFORMS
          ? c.formIds.includes(getFormIdByFormType['MNE']) ||
            c.formIds.includes(getFormIdByFormType['MNEH'])
          : c.formIds.includes(getFormIdByFormType['ENE'])
      ).length
  );

  const showCloneResponsesHistory = Boolean(
    cloneHistoryData &&
      cloneHistoryData.filter((c) =>
        questionType === MNEFORMS
          ? c.formIds.includes(getFormIdByFormType['MNE']) ||
            c.formIds.includes(getFormIdByFormType['MNEH'])
          : c.formIds.includes(getFormIdByFormType['ENE'])
      ).length
  );
  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <Drawer
      className="mne-history-drawer"
      title=""
      isOpen={isOpen}
      onClose={onClose}
    >
      {showCloneResponsesHistory ? (
        <>
          <h1>Clone Responses History</h1>
          {renderCloneHistoryList()}
        </>
      ) : null}
      {showCopyResponsesHistory ? (
        <>
          <h1>Copy Responses History</h1>
          {renderCopyHistoryList()}
        </>
      ) : null}
      <h1>History</h1>
      {renderHistoryList()}
      <MNEHistoryModal
        isOpen={modalIsOpen}
        onClose={handleModalChange({
          isOpen: false,
          history: null,
          headerField: null,
        })}
        currentHistoryTeamMember={currentHistoryTeamMember}
        currentHistory={currentHistory}
        headerField={selectedField}
        questionType={questionType}
      />
      {showCopyResponsesHistory || showCloneResponsesHistory ? (
        <CopyHistoryModal
          isOpen={copyHistoryModalIsOpen}
          onClose={handleCopyHistoryModalChange({
            isOpen: false,
            history: {} as ICopyCloneHistory,
          })}
          currentHistoryTeamMember={currentHistoryTeamMember}
          currentHistory={currentCopyHistory}
        />
      ) : null}
    </Drawer>
  );
};

export default MNEHistoryDrawer;
