// Packages
import React, { MutableRefObject } from 'react';

// Redux

// Components
import { RcReviewLocationHeader, RateCenterValidationTable } from 'components';

// Hooks

// Utils

// Types
import { ILocation } from 'types';

// Styles
import './RcReviewRateCenterTab.scss';

export interface IRcReviewRateCenterTabProps {
  opptyName: string;
  location: ILocation;
  refForPrint: MutableRefObject<HTMLDivElement | null>;
}

/** Rate Center tab on the RC Review page */

const RcReviewRateCenterTab = ({
  opptyName,
  location,
  refForPrint,
}: IRcReviewRateCenterTabProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-rate-center-tab" ref={refForPrint}>
      <RcReviewLocationHeader
        opptyName={opptyName}
        locationName={location.name}
      />
      <RateCenterValidationTable
        location={location}
        showRefreshButton={false}
      />
    </div>
  );
};

export default RcReviewRateCenterTab;
