// Packages
import React, { useEffect, useRef } from 'react';

// Redux

// Components
import { KiteCard } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './RCQHelp.scss';

export interface IRCQHelpProps {
  /** HTML string of help content to display */
  htmlString: string;
}

/** Displays help content for currently focused question (field) */

const RCQHelp = ({ htmlString }: IRCQHelpProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const helpContentRef = useRef<HTMLDivElement>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (helpContentRef?.current) {
      helpContentRef.current.innerHTML = htmlString;
    }
  }, [helpContentRef, htmlString]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className="rcq-help">
      <div className="rcq-help__header">
        <h2>Help</h2>
      </div>
      <div className="rcq-help__content" ref={helpContentRef}></div>
    </KiteCard>
  );
};

export default RCQHelp;
