import {
  ILocationSelection,
  ISubmission,
  TRcProductRateCards,
  TUcProductRateCards,
} from 'types';
import {
  NONE,
  rcSeatsProductFamilies,
  ucSeatsProductFamilies,
} from 'utils/defaultsAndConstants';

export const getSeatsRateCard = (currentProducts: ILocationSelection[]) => {
  const seats = currentProducts.reduce((seats, product) => {
    if (ucSeatsProductFamilies.includes(product.familyName)) {
      seats += product.quantity;
    }
    return seats;
  }, 0);

  let rateCard: TUcProductRateCards = '1-10 Seats';

  if (seats > 49) {
    rateCard = '50-99 Seats';
  } else if (seats > 10) {
    rateCard = '11-49 Seats';
  }

  return { seats, rateCard };
};

export const getSeatsRateCardRC = (
  currentProducts: ILocationSelection[],
  locationId: string,
  currentSubmissions: ISubmission[] | undefined
) => {
  const selectedQue = currentSubmissions?.filter(
    (p) =>
      (p.fieldId === 'a6ae4cb7-4517-4c67-abf9-16c88d875833' ||
        p.fieldId === '0f21bd0a-8ad4-4592-b890-a63acdac5f9e') &&
      p.locationId === locationId
  );

  const answerQue = selectedQue?.filter(
    (a) =>
      a.answer === 'Does not have MNE / ENE Switch' ||
      a.answer === 'Does not have MNE / ENE Edge'
  );

  const seats = currentProducts.reduce((seats, product) => {
    if (rcSeatsProductFamilies.includes(product.familyName)) {
      seats += product.quantity;
    }
    return seats;
  }, 0);

  const LocationMNE_ENE =
    currentProducts.filter(
      (s1) =>
        s1.locationId === locationId &&
        s1.productId !== NONE &&
        (s1.familyId === '309708f7-3c49-4ed9-a308-67d50a0b9d40' || //MNE Switches
          s1.familyId === '492a55d9-71ba-46b5-af56-e36c3dee663a' || //MNE Edge
          s1.familyId === '6c078905-836e-4584-b4ce-6bd485de18f3' || //ENE Switches
          s1.familyId === '904c4248-6751-4209-ac33-87b13726f9e4') //ENE Edge
    ).length > 0 ||
    (answerQue?.length === 0 && selectedQue?.length! > 1);

  let rateCardRC: TRcProductRateCards = LocationMNE_ENE
    ? '1-499 Seats w. MNE / ENE'
    : '1-499 Seats';

  if (seats > 999) {
    rateCardRC = LocationMNE_ENE ? '1000+ Seats w. MNE / ENE' : '1000+ Seats';
  } else if (seats > 499) {
    rateCardRC = LocationMNE_ENE
      ? '500-999 Seats w. MNE / ENE'
      : '500-999 Seats';
  }

  return { seats, rateCardRC };
};
