// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput, KiteSelect } from '@kite/react-kite';

// Hooks

// Utils
import { handleNumInputKeydown, isValidJsonString } from 'utils';

// Types
import { IMNEInputProps } from 'types';

// Styles
import '../MNEFieldStyles.scss';
import MNERepeatButton from '../MNERepeatButton';

type TSelectQuantity = [select: string, quantity: string, others?: string];
interface IMNESelectQuantityInputProps {
  sq: TSelectQuantity;
  index: number;
  repeatable: boolean;
  isLast: boolean;
  isOnly: boolean;
  onRepeat: () => void;
  onDelete: (index: number) => () => void;
  onChange: (sq: TSelectQuantity, index: number) => void;
  label: string;
  id: string;
  inputName: string;
  value: string | null;
  options: string[] | null;
}

/** Custom input for 'select-quantity' inputType with repeatable inputs on UCQ Field */
const MNESelectQuantity = ({ fieldInput, onFieldChange }: IMNEInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    value,
    id,
    inputName,
    options,
    repeatable = true,
    label,
  } = fieldInput;

  const blankSQ: TSelectQuantity = useMemo(() => ['', ''], []);
  const defaultValue: TSelectQuantity[] = useMemo(() => {
    if (value && value !== 'No') {
      const sqValues: TSelectQuantity[] = isValidJsonString(value)
        ? JSON.parse(value)
        : value;
      return sqValues;
    }
    return [blankSQ];
  }, [value, blankSQ]);

  const [selectQuantities, setselectQuantities] = useState(defaultValue);

  // const [precheckValue, setPrecheckValue] = useState(
  //   value === 'No' ? 'No' : 'Yes'
  // );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // const isVisible = isPrecheck ? precheckValue === 'Yes' : true;
  const getCompletedSqs = useCallback(
    (sqs: TSelectQuantity[]) => sqs.filter((sq) => sq.every(Boolean)),
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  // const onPrecheckChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setPrecheckValue(e.target.value);
  //     if (e.target.value === 'No') {
  //       onFieldChange(id, 'No');
  //     } else {
  //       const hasLocalTQ = getCompletedSqs(selectQuantities).length;
  //       onFieldChange(id, hasLocalTQ ? JSON.stringify(selectQuantities) : null);
  //     }
  //   },
  //   [getCompletedSqs, id, onFieldChange, selectQuantities]
  // );

  const onChange = useCallback(
    (sq: TSelectQuantity, index: number) => {
      const sqCopy = Array.from(selectQuantities);
      sqCopy.splice(index, 1, sq);
      setselectQuantities(sqCopy);
      const completedSqs = getCompletedSqs([sq]);
      if (completedSqs.length) {
        onFieldChange(id, JSON.stringify(sqCopy));
      }
    },
    [getCompletedSqs, id, onFieldChange, selectQuantities]
  );

  const onRepeat = useCallback(() => {
    const newSQs = [...selectQuantities, blankSQ];
    setselectQuantities(newSQs);
  }, [blankSQ, selectQuantities]);

  const onDelete = useCallback(
    (index: number) => () => {
      const sqCopy = Array.from(selectQuantities);
      sqCopy.splice(index, 1);
      setselectQuantities(sqCopy);
      const completedSqs = getCompletedSqs(sqCopy);
      onFieldChange(id, completedSqs.length ? JSON.stringify(sqCopy) : null);
    },
    [getCompletedSqs, id, onFieldChange, selectQuantities]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderSQs = useCallback(() => {
    return selectQuantities?.map((sq, i) => {
      return (
        <MNESelectQuantityInput
          key={i}
          sq={sq}
          index={i}
          onDelete={onDelete}
          onRepeat={onRepeat}
          isLast={i === selectQuantities.length - 1}
          repeatable={repeatable}
          onChange={onChange}
          isOnly={selectQuantities.length === 1}
          label={label}
          id={id}
          inputName={inputName}
          options={options}
          value={value}
        />
      );
    });
  }, [
    selectQuantities,
    onDelete,
    onRepeat,
    repeatable,
    id,
    inputName,
    label,
    options,
    value,
    onChange,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setselectQuantities(defaultValue);
  }, [defaultValue]);

  // useEffect(() => {
  //   setPrecheckValue(value === 'No' ? 'No' : 'Yes');
  // }, [value]);

  // =============================================
  // Early Return (no precheck)
  // =============================================
  // if (!isPrecheck) {
  //   return <>{renderSQs()}</>;
  // }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-field__precheck-container">
      {/* <UCQPrecheck
        fieldInput={fieldInput}
        precheckValue={precheckValue}
        onChange={onPrecheckChange}
      /> */}
      {renderSQs()}
    </div>
  );
};

// CHILD FOR `MNESelectQuantity` BELOW (renders multiple inputs)
const MNESelectQuantityInput = ({
  sq,
  onDelete,
  onRepeat,
  isLast,
  index,
  repeatable,
  onChange,
  isOnly,
  label,
  options,
  value,
  id,
  inputName,
}: IMNESelectQuantityInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [selectQuantity, setSelectQuantity] = useState(sq);
  const [select, quantity, otherValue] = selectQuantity;
  const buttonText =
    label === 'Phone/Adapter'
      ? `Add Device`
      : `Add ${label.replace(/^\d+\. /, '')}`;
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const selectOptions = useMemo(() => {
    return (
      options?.map((o) => (
        <option key={o} value={o}>
          {o}
        </option>
      )) || []
    );
  }, [options]);

  // =============================================
  // Effects
  // =============================================
  // Make sure correct values are in inputs on sq delete
  useEffect(() => {
    setSelectQuantity(sq);
  }, [sq]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;

      const newSQ: TSelectQuantity = [...selectQuantity];
      if (name === 'select') {
        if (value.toLowerCase() === 'other') {
          newSQ[2] = '';
        }
        if (value.toLowerCase() !== 'other' && newSQ[2]) {
          newSQ.pop();
        }
        newSQ[0] = value;
      } else if (name === 'quantity') {
        if (parseInt(value) === 0) {
          newSQ[1] = '';
        } else {
          newSQ[1] = value;
        }
      } else if (name === 'other') {
        newSQ[2] = value;
      }
      setSelectQuantity(newSQ);
      onChange(newSQ, index);
    },
    [index, onChange, selectQuantity]
  );

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-field__select-quantity-container">
      <KiteSelect
        id={id}
        name="select"
        value={select}
        onChange={handleChange}
        maxWidth="450px"
        placeholder={select ? '' : 'Make Selection'}
      >
        {selectOptions}
      </KiteSelect>
      {select === 'Other' && (
        <KiteInput
          name="other"
          value={otherValue}
          onChange={handleChange}
          maxWidth="450px"
          placeholder="Others"
        />
      )}
      <KiteInput
        value={quantity}
        name="quantity"
        onChange={handleChange}
        onKeyDown={handleNumInputKeydown}
        maxWidth="100px"
        placeholder="Quantity"
        inputProps={{ type: 'number', min: 1 }}
      />
      {repeatable && (
        <MNERepeatButton
          text={buttonText}
          onRepeat={onRepeat}
          onDelete={onDelete(index)}
          isLast={isLast}
          isOnly={isOnly}
          isAllFieldsAdded={
            selectQuantity.every((value) => value !== '') ? true : false
          }
        />
      )}
    </div>
  );
};
export default MNESelectQuantity;
