import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { IField, ISubmission, ISmeAnswer } from 'types';

interface IUseUcqApprovalProps {
  fields?: IField[];
  submissions?: ISubmission[];
}

type ISmeTriggers = { [inputName: string]: string[] };

export const useUcqApproval = ({
  fields = [],
  submissions = [],
}: IUseUcqApprovalProps) => {
  const { locationId: selectedLocationId } = useParams();
  const smeInputAnswers = useRef<ISmeAnswer[] | null>(null);

  // key/value pair of relevant input name w/ sme approval trigger values
  // make sure inputName matches value in fields db
  const smeTriggers: ISmeTriggers = {
    'Omni Channel or Hoteling': ['Yes'],
    'SME approval': ['Not Required', 'Approved', 'Denied', 'In Progress'],
  };

  // key/value pair of relevant input name w/ sparc approval trigger values
  // make sure inputName matches value in fields db
  const sparcTriggers: ISmeTriggers = {
    'Location requirements': [
      'Yes - ICB is required',
      'Yes - Gov/Ed',
      "Yes - ICB + SPR or Special T's & C's",
      "Yes - Gov/Ed + SPR or Special T's & C's",
      "Yes - Gov/Ed + ICB,SPR or Special T's & C's",
    ],
    'Single building or campus': [
      'Single Address, Single BLDG, MDF + IDF(s) w/ interconnect(s)',
      'Single Address, Multiple BLDG(s), 1 MDF + IDF(s) w/ interconnect(s)',
      'Multiple Address, Multiple BLDG(s), MDF + IDF(s) w/ interconnect(s) = Campus',
    ],
    'Dual wan': ['High Availability - Note DRT', 'Dual WAN + HA - Note DRT'],
  };

  const requiresSmeApproval = useMemo(() => {
    // Convert trigger inputNames to fieldIds in order to check submission answers
    const smeFieldIds = fields.reduce((acc: string[], field) => {
      if (Object.keys(smeTriggers).includes(field.inputName)) {
        acc.push(field.id);
      }
      return acc;
    }, []);

    const smeAnswers = submissions.reduce((acc: ISmeAnswer[], s) => {
      if (smeFieldIds.includes(s.fieldId)) {
        const { answer, locationId } = s;
        // Convert fieldId back to inputName
        const inputName = fields.find(
          (field) => field.id === s.fieldId
        )?.inputName;

        // SME trigger is location-specific
        if (answer && inputName && locationId === selectedLocationId) {
          acc.push({ inputName, answer });
        }
      }
      return acc;
    }, []);
    smeInputAnswers.current = smeAnswers;
    // Check if any submission answers match the smeTriggers at the inputName
    return smeAnswers.some(({ inputName, answer }) =>
      smeTriggers[inputName].includes(answer)
    );
  }, [fields, submissions, selectedLocationId, smeTriggers]);

  const requiresSparcPreContract = useMemo(() => {
    // Convert trigger inputNames to fieldIds in order to check submission answers
    const sparcFieldIds = fields.reduce((acc: string[], field) => {
      if (Object.keys(sparcTriggers).includes(field.inputName)) {
        acc.push(field.id);
      }
      return acc;
    }, []);

    const sparcAnswers = submissions.reduce((acc: ISmeAnswer[], s) => {
      if (sparcFieldIds.includes(s.fieldId)) {
        const { answer, locationId } = s;
        // Convert fieldId back to inputName
        const inputName = fields.find(
          (field) => field.id === s.fieldId
        )?.inputName;

        // Sparc trigger is location-specific
        if (answer && inputName && locationId === selectedLocationId) {
          acc.push({ inputName, answer });
        }
      }
      return acc;
    }, []);
    // Check if any submission answers match the sparcTriggers at the inputName
    return sparcAnswers.some(({ inputName, answer }) =>
      sparcTriggers[inputName].includes(answer)
    );
  }, [fields, submissions, selectedLocationId, sparcTriggers]);

  return { requiresSmeApproval, smeInputAnswers, requiresSparcPreContract };
};
