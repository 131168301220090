// External Packages
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

// Components
import { NavLink } from 'components';

// Hooks
import { useLocation, useParams } from 'react-router-dom';
import { useQueryData } from 'hooks';

// Utils
import { nameToUrlParam, NavigationIconMap } from 'utils';

// Types
import { TProductCategory, TTableCategory } from 'types';

// Styles
import './LeftNavAdmin.scss';

// Types
type TableListValue = {
  name: TProductCategory;
  iconName: string;
  active: boolean;
  enabled: boolean;
};

type TableList = {
  [Property in TTableCategory]: TableListValue;
};

const LeftNavAdmin = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { pathname } = useLocation();
  const { productCategory } = useParams();

  const { allProductFamilies } = useQueryData();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const tableListDefaults = useMemo(() => {
    const navProducts: TableList = {
      Data: {
        name: 'Data',
        iconName: NavigationIconMap.Data,
        active: productCategory === nameToUrlParam('Data'),
        enabled: true,
      },
      Trunks: {
        name: 'Trunks',
        iconName: NavigationIconMap.Trunks,
        active: productCategory === nameToUrlParam('Trunks'),
        enabled: true,
      },
      'UC Products': {
        name: 'UC Products',
        iconName: NavigationIconMap['UC Products'],
        active: productCategory === nameToUrlParam('UC Products'),
        enabled: true,
      },
      MNE: {
        name: 'MNE',
        iconName: NavigationIconMap.MNE,
        active: productCategory === nameToUrlParam('MNE'),
        enabled: true,
      },
      ENE: {
        name: 'ENE',
        iconName: NavigationIconMap.ENE,
        active: productCategory === nameToUrlParam('ENE'),
        enabled: true,
      },
      'SBB 1-19 Pub/Priv': {
        name: 'SBB 1-19 Pub/Priv',
        iconName: NavigationIconMap['SBB 1-19 Pub/Priv'],
        active: productCategory === nameToUrlParam('SBB 1-19 Pub/Priv'),
        enabled: true,
      },
      'RingCentral Products': {
        name: 'RingCentral Products',
        iconName: NavigationIconMap['RingCentral Products'],
        active: productCategory === nameToUrlParam('RingCentral Products'),
        enabled: true,
      },
    };

    return navProducts;
  }, [productCategory]);

  const getRoute = useCallback(
    (category: TProductCategory) => {
      const firstProduct =
        allProductFamilies.find(
          (p) => p.name !== 'Location' && p.category === category
        )?.name || '';

      return `/admin/product-pricing/${nameToUrlParam(
        category
      )}/${nameToUrlParam(firstProduct)}`;
    },
    [allProductFamilies]
  );
  // =============================================
  // Render Methods
  // =============================================
  const categoriesToDisplay = useMemo(() => {
    return Object.values(tableListDefaults).map((type: TableListValue) => {
      if (!type.enabled) return null;
      return (
        <li
          key={`${type.name} navlink`}
          className={classNames({
            'left-nav-admin__category-button': true,
            'left-nav-admin__category-button--active': type.active,
          })}
        >
          <NavLink
            to={getRoute(type.name)}
            text={type.name}
            iconName={type.iconName}
            active={productCategory === nameToUrlParam(type.name)}
          />
        </li>
      );
    });
  }, [getRoute, productCategory, tableListDefaults]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div data-testid="left-nav-admin" className="left-nav-admin">
      <NavLink
        to="/admin/product-pricing/data/dfi-speed"
        iconName="activity"
        text="Product Pricing"
        active={pathname.includes('/admin/product-pricing')}
      />

      <ul className="left-nav-admin__categories">{categoriesToDisplay}</ul>

      <NavLink
        to="/admin/bundles"
        iconName={NavigationIconMap.Bundles}
        text="Bundles"
        active={pathname.includes('/admin/bundles')}
      />
      <NavLink
        to="/admin/offer"
        iconName={NavigationIconMap.Offer}
        text="Offer Mapping"
        active={pathname.includes('/admin/offer')}
      />
      <NavLink
        to="/admin/business-rules"
        iconName={NavigationIconMap['Business Rules']}
        text="Business Rules"
        active={pathname.includes('/admin/business-rules')}
      />
      {/* Leaving out for now (in progress) */}
      {/* <NavLink
        to="/admin/add-products"
        iconName={NavigationIconMap['Add Products']}
        text="Add Products"
        active={pathname.includes('/admin/add-products')}
      /> */}
    </div>
  );
};

export default LeftNavAdmin;
