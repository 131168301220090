import { IEstimate, IEstimateRequest } from 'types';
import { queryKeys } from 'utils';
import { useGenerateQuery } from '../useGenerateQuery';
import { estimateDataRequest } from 'utils/api/estimateRequest';

export const useGetEstimatesData = (requestConfig: IEstimateRequest = {}) => {
  const { params } = requestConfig;
  return useGenerateQuery<IEstimate[]>({
    queryKey: params
      ? queryKeys(params).filter.estimates
      : queryKeys().all.estimates,
    apiCall: estimateDataRequest,
    requestConfig,
    opts: {
      staleTime: params ? 15000 : Infinity,
    },
  });
};
