import axios from 'axios';
import { IRequestConfig, IForm, IRequestParams, RForm, MForm } from 'types';
import { paramsSerializer } from 'utils/format/paramSerializer';
import { env } from './env';
import { throwQueryError } from './throwQueryError';

interface IFormsRequestParams extends IRequestParams {
  location?: number;
  type?: string;
}

export interface IFormsRequest extends IRequestConfig<IForm> {
  params?: IFormsRequestParams;
}

interface RFormsRequestParams extends IRequestParams {
  location?: number;
  type?: string;
}
export interface RFormsRequest extends IRequestConfig<RForm> {
  params?: RFormsRequestParams;
}

interface MNEFormsRequestParams extends IRequestParams {
  location?: number;
  type?: string;
}
export interface MNEFormsRequest extends IRequestConfig<MForm> {
  params?: MNEFormsRequestParams;
}

export const formsRequest = async (config?: IFormsRequest) => {
  const { endpoint = 'forms', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with forms ${method}`,
      })
    );
};
export const RformsRequest = async (config?: RFormsRequest) => {
  const { endpoint = 'forms', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with forms ${method}`,
      })
    );
};

export const MNEformsRequest = async (config?: MNEFormsRequest) => {
  const { endpoint = 'forms', method = 'GET', data, params } = config || {};
  const url = `${env.apiUrl}/${endpoint}`;

  return axios({
    url,
    method,
    params,
    paramsSerializer,
    data,
  })
    .then((res) => res.data.data)
    .catch((error) =>
      throwQueryError({
        error,
        message: `Something went wrong with forms ${method}`,
      })
    );
};
