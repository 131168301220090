// Packages
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';

// Redux

// Components
import { QuantityCard, EipInput } from 'components';

// Hooks
import { useAnalytics, useQueryDataContext, useScrollToTop } from 'hooks';

// Utils

import { rcNotes } from 'utils';
// Types

import { TRcNotesStars } from 'types';

//React Router
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Styles
import './RcAddonsPage.scss';

const RcAddonsPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { allProductFamilies, estimateScenarios } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });
  const { estimateId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const handleToggle = useMemo(() => {
    return estimateScenarios.some((scenario) => {
      return scenario.selections.some(
        (selection) => selection.familyCategory === 'UC Products'
      );
    });
  }, [estimateScenarios]);
  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const generateComponents = useCallback(() => {
    const rcProductFamilies = allProductFamilies.filter(
      (f) => f.category === 'RingCentral Products'
    );

    return rcProductFamilies.map((family) => {
      return (
        <div key={`${family.name} quantity card`}>
          {family.name === 'RC Seats' && <h3>Seats and Add ons</h3>}
          {family.name === 'RC Phones / Devices' && (
            <>
              <h3>Phones Devices and Services</h3>
              <div>
                <EipInput />
              </div>
            </>
          )}
          <QuantityCard
            key={`${family.name} quantity card`}
            productFamily={family}
            expandable={true}
          />
        </div>
      );
    });
  }, [allProductFamilies]);

  //RC Notes
  const [rcNotesDisplay] = useState<{
    [Property in TRcNotesStars]: boolean;
  }>({
    '*': true,
  });

  const rcNotesCom = useMemo(() => {
    const notes = Object.entries(rcNotesDisplay).reduce(
      (acc: JSX.Element[], [key, display]) => {
        const noteKey = key as TRcNotesStars;
        if (display) {
          rcNotes[noteKey].forEach((note, i) => {
            acc.push(<li key={`note ${key} ${i}`}>{note}</li>);
          });
        }
        return acc;
      },
      []
    );
    return notes;
  }, [rcNotesDisplay]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('RcAddonsPage'), [trackPageView]);

  useEffect(() => {
    if (handleToggle) {
      navigate(`/estimate-builder/${estimateId}/uc-addons`, { replace: true });
    }
  }, [navigate, handleToggle, location.key, estimateId]);

  // =============================================
  // Early Return
  // =============================================

  if (handleToggle) return <></>;

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="rc-addons-page">
      <h3>Ring Central Addons Configuration Options</h3>
      {generateComponents()}
      <p className="rc-addons-page__note-text">{rcNotesCom}</p>
    </div>
  );
};

export default RcAddonsPage;
