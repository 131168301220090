import { useMutation } from 'react-query';
import { IPostCopyCloneHistory } from 'types';
import { copyCloneHistoryRequest } from 'utils';

export const usePostCopyCloneHistory = () => {
  const {
    mutate: postCopyCloneHistory,
    isLoading: postCopyCloneHistoryLoading,
    error: postCopyCloneHistoryError,
    data: postCopyCloneHistoryRes,
  } = useMutation((newCopyCloneHistory: IPostCopyCloneHistory[]) =>
    copyCloneHistoryRequest({
      method: 'POST',
      data: newCopyCloneHistory,
    })
  );

  return {
    postCopyCloneHistory,
    postCopyCloneHistoryLoading,
    postCopyCloneHistoryError,
    postCopyCloneHistoryRes,
  };
};
