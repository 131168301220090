// Packages
import React, { useCallback, useMemo } from 'react';
import { NodeProps, useNodes } from 'react-flow-renderer';

// Redux

// Components
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { NodeConnectorHandle, NodeTotalBadge, NodeLabel } from '..';

// Hooks
import { useGetEstimateDetails } from 'hooks';
import { useParams } from 'react-router-dom';

// Utils

// Types
import { IRCQDesignFlowNode } from 'types';

// Styles
import './MDFNode.scss';

/** Renders an MDF (type: 'mdf') distro node within react-flow chart */

const MDFNode = ({
  id,
  data: {
    name,
    locationId,
    isDemarc,
    interconnectType,
    editNode,
    addNode,
    switchPorts = 0,
    phones = 0,
  },
}: NodeProps<IRCQDesignFlowNode>) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId } = useParams();

  const { estimateData } = useGetEstimateDetails(estimateId);

  const nodes = useNodes<IRCQDesignFlowNode>();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const thisNode = useMemo(() => nodes.find((n) => n.id === id), [id, nodes]);

  const locationName = useMemo(() => {
    const location = estimateData?.locations.find((l) => l.id === locationId);
    if (location) {
      return location.name;
    }
    return '';
  }, [estimateData, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleAdd = useCallback(() => {
    if (thisNode && addNode) {
      addNode(thisNode);
    }
  }, [thisNode, addNode]);

  const handleEdit = useCallback(() => {
    if (thisNode && editNode) {
      editNode(thisNode);
    }
  }, [thisNode, editNode]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mdf-node">
      <div className="mdf-node__name">
        <span>{name || 'No Name Provided'}</span>
        <KiteButton type="standalone-link" onClick={handleEdit}>
          <KiteIcon name="edit" />
        </KiteButton>
      </div>
      <div className="mdf-node__labels">
        <strong>MDF</strong>
        <NodeLabel label={id.toUpperCase()} type="mdf" />
        {isDemarc && <NodeLabel label="DEMARC" type="demarc" />}
      </div>
      <div className="mdf-node__location">{locationName}</div>
      <div className="mdf-node__badges">
        <NodeTotalBadge total={switchPorts} iconName="ethernet" />
        <NodeTotalBadge total={phones} iconName="phone-office" />
      </div>
      <div className="mdf-node__interconnect">
        {interconnectType || 'Interconnect: N/A'}
      </div>
      <NodeConnectorHandle
        onAddNode={handleAdd}
        disabled={interconnectType === 'Single Building'}
      />
    </div>
  );
};

export default MDFNode;
