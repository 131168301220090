// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteInput, KiteSelect } from '@kite/react-kite';

// Hooks

// Utils
import { handleNumInputKeydown, isValidJsonString } from 'utils';

// Types
import { IMNEInputProps } from 'types';

// Styles
import '../MNEFieldStyles.scss';
import MNERepeatButton from '../MNERepeatButton';

import { licenseTypeOptions } from 'utils';
import { eneLicenseTypeOptions } from 'utils/defaultsAndConstants/constants';

type TSelectSwitch = [
  select: string,
  quantity: string,
  licenseType: string,
  others?: string
];

interface IMNESelectSwitchInputProps {
  sq: TSelectSwitch;
  index: number;
  repeatable: boolean;
  isLast: boolean;
  isOnly: boolean;
  onRepeat: () => void;
  onDelete: (index: number) => () => void;
  onChange: (sq: TSelectSwitch, index: number) => void;
  label: string;
  id: string;
  inputName: string;
  value: string | null;
  options: string[] | null;
}

/** Custom input for 'select-license' inputType with repeatable inputs on UCQ Field */
const MNESelectSwitch = ({ fieldInput, onFieldChange }: IMNEInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const {
    value,
    id,
    inputName,
    options,
    repeatable = true,
    label,
  } = fieldInput;

  const blankSQ: TSelectSwitch = useMemo(() => ['', '', ''], []);
  const defaultValue: TSelectSwitch[] = useMemo(() => {
    if (value && value !== 'No') {
      const sqValues: TSelectSwitch[] = isValidJsonString(value)
        ? JSON.parse(value)
        : value;
      return sqValues;
    }
    return [blankSQ];
  }, [value, blankSQ]);

  const [selectSwitch, setselectSwitch] = useState(defaultValue);

  const getCompletedSqs = useCallback(
    (sqs: TSelectSwitch[]) => sqs.filter((sq) => sq.every(Boolean)),
    []
  );

  const onChange = useCallback(
    (sq: TSelectSwitch, index: number) => {
      const sqCopy = Array.from(selectSwitch);
      sqCopy.splice(index, 1, sq);
      setselectSwitch(sqCopy);
      const completedSqs = getCompletedSqs([sq]);
      if (completedSqs.length) {
        onFieldChange(id, JSON.stringify(sqCopy));
      }
    },
    [getCompletedSqs, id, onFieldChange, selectSwitch]
  );

  const onRepeat = useCallback(() => {
    const newSQs = [...selectSwitch, blankSQ];
    setselectSwitch(newSQs);
  }, [blankSQ, selectSwitch]);

  const onDelete = useCallback(
    (index: number) => () => {
      const sqCopy = Array.from(selectSwitch);
      sqCopy.splice(index, 1);
      setselectSwitch(sqCopy);
      const completedSqs = getCompletedSqs(sqCopy);
      onFieldChange(id, completedSqs.length ? JSON.stringify(sqCopy) : null);
    },
    [getCompletedSqs, id, onFieldChange, selectSwitch]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderSQs = useCallback(() => {
    return selectSwitch?.map((sq, i) => {
      return (
        <MNESelectQuantityInput
          key={i}
          sq={sq}
          index={i}
          onDelete={onDelete}
          onRepeat={onRepeat}
          isLast={i === selectSwitch.length - 1}
          repeatable={repeatable}
          onChange={onChange}
          isOnly={selectSwitch.length === 1}
          label={label}
          id={id}
          inputName={inputName}
          options={options}
          value={value}
        />
      );
    });
  }, [
    selectSwitch,
    onDelete,
    onRepeat,
    repeatable,
    id,
    inputName,
    label,
    options,
    value,
    onChange,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setselectSwitch(defaultValue);
  }, [defaultValue]);

  // =============================================
  // Return
  // =============================================
  return <div className="mne-field__precheck-container">{renderSQs()}</div>;
};

// CHILD FOR `MNESelectQuantity` BELOW (renders multiple inputs)
const MNESelectQuantityInput = ({
  sq,
  onDelete,
  onRepeat,
  isLast,
  index,
  repeatable,
  onChange,
  isOnly,
  label,
  options,
  value,
  id,
  inputName,
}: IMNESelectSwitchInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [selectSwitch, setSelectSwitch] = useState(sq);
  const [select, quantity, licenseType, otherValue] = selectSwitch;
  const buttonText = `Add ${label.replace(/^\d+\. /, '')}`;
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const selectOptions = useMemo(() => {
    return (
      options?.map((o) => (
        <option key={o} value={o}>
          {o}
        </option>
      )) || []
    );
  }, [options]);

  const selectLicenseType = useMemo(() => {
    if (inputName.toLocaleUpperCase() === 'ENEGENERALSWITCH') {
      return eneLicenseTypeOptions?.map((lt) => (
        <option key={lt} value={lt}>
          {lt}
        </option>
      ));
    }
    return licenseTypeOptions?.map((lt) => (
      <option key={lt} value={lt}>
        {lt}
      </option>
    ));
  }, [inputName]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;

      const newSQ: TSelectSwitch = [...selectSwitch];
      if (name === 'select-switch') {
        if (value.toLowerCase() === 'other') {
          newSQ[3] = '';
        }
        if (value.toLowerCase() !== 'other' && newSQ[3]) {
          newSQ.pop();
        }
        newSQ[0] = value;
      } else if (name === 'quantity') {
        if (parseInt(value) === 0) {
          newSQ[1] = '';
        } else {
          newSQ[1] = value;
        }
      } else if (name === 'select-license-type') {
        newSQ[2] = value;
      } else if (name === 'other') {
        newSQ[3] = value;
      }
      setSelectSwitch(newSQ);
      onChange(newSQ, index);
    },
    [index, onChange, selectSwitch]
  );

  // =============================================
  // Effects
  // =============================================
  // Make sure correct values are in inputs on TQ delete
  useEffect(() => {
    setSelectSwitch(sq);
  }, [sq]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-field__select-switch-container">
      <KiteSelect
        id={id}
        name="select-switch"
        value={select}
        onChange={handleChange}
        maxWidth="450px"
        placeholder={select ? '' : 'Make Selection'}
      >
        {selectOptions}
      </KiteSelect>
      {select === 'Other' && (
        <KiteInput
          name="other"
          value={otherValue}
          onChange={handleChange}
          maxWidth="450px"
          placeholder="Others"
        />
      )}
      <KiteInput
        name="quantity"
        value={quantity}
        onChange={handleChange}
        onKeyDown={handleNumInputKeydown}
        maxWidth="100px"
        placeholder="Quantity"
        inputProps={{ type: 'number', min: 1 }}
      />
      <KiteSelect
        id={id}
        name="select-license-type"
        value={licenseType}
        onChange={handleChange}
        maxWidth="450px"
        placeholder={licenseType ? '' : 'License Type'}
      >
        {selectLicenseType}
      </KiteSelect>
      {repeatable && (
        <MNERepeatButton
          text={buttonText}
          onRepeat={onRepeat}
          onDelete={onDelete(index)}
          isLast={isLast}
          isOnly={isOnly}
          isAllFieldsAdded={
            selectSwitch.every((value) => value !== '') ? true : false
          }
        />
      )}
    </div>
  );
};
export default MNESelectSwitch;
