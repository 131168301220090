import {
  IProductFamily,
  TProductList,
  IProduct,
  ILocationSelection,
} from 'types';

const getSpeedInteger = (
  input: string,
  currentProducts: ILocationSelection[],
  allProductFamilies: IProductFamily[]
) => {
  const productFamily = allProductFamilies.find((prod) => prod.name === input);
  const product = currentProducts.find((p) => p.familyId === productFamily?.id);
  if (!product) return 0;

  const productName = product.name;

  const letter = productName.slice(productName.length - 1);
  if (productName.includes('Mbps')) {
    return parseInt(productName.split(' ')[0]);
  }
  const slash = productName.split('/')[0].split(' ');
  const speedInt = slash.length
    ? parseInt(slash[slash.length - 1])
    : parseInt(productName.slice(0, productName.length - 1));

  if (letter === 'G') return speedInt * 1000;
  return speedInt;
};

const calculateOtherBandwidth = (
  currentProducts: ILocationSelection[],
  allProductFamilies: IProductFamily[]
) => {
  const products: TProductList[] = [
    'DFI Speed',
    'Ethernet Speed',
    'Enterprise Internet',
  ];

  const total = products.reduce((acc, curr) => {
    const value = getSpeedInteger(curr, currentProducts, allProductFamilies);
    acc += value;
    return acc;
  }, 0);

  return total;
};

const recommendedBandwidth = (
  selectionOptions: number[],
  currentProducts: ILocationSelection[],
  allProductFamilies: IProductFamily[]
) => {
  const calculated = calculateOtherBandwidth(
    currentProducts,
    allProductFamilies
  );

  const value =
    selectionOptions.find((v) => v >= calculated) ||
    selectionOptions[selectionOptions.length - 1];

  const recommended =
    value % 1000 === 0 ? `${value / 1000} Gbps` : `${value} Mbps`;
  return recommended;
};

interface IGetRecommended {
  network?: IProductFamily;
  selectionOptions: number[];
  allProducts: IProduct[];
  currentSelections: ILocationSelection[];
  allProductFamilies: IProductFamily[];
}

export const getRecommended = ({
  network,
  selectionOptions,
  allProducts,
  currentSelections,
  allProductFamilies,
}: IGetRecommended) => {
  const products = allProducts.filter((p) => p.productFamilyId === network?.id);
  const options: string[] = ['None', ...products.map((option) => option.name)];

  if (!network) return { options, recommended: 'None' };

  const recommended = recommendedBandwidth(
    selectionOptions,
    currentSelections,
    allProductFamilies
  );

  const optionIndex = options?.indexOf(recommended);

  optionIndex > -1 &&
    (options[optionIndex] = `${options[optionIndex]} (Recommended)`);

  return { options, recommended };
};
