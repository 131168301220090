// Packages
import React, { useEffect, useMemo, useState, useRef } from 'react';

// Redux

// Components
import {
  UcReviewHeader,
  UcReviewSideNav,
  UcReviewSubmissions,
  UcReviewPicturesTab,
  UcReviewValidation,
  UcReviewCpwTab,
  UcReviewRateCenterTab,
  UcReviewEstimateProductsTab,
  UcReviewRcQuestions,
  UcReviewMneQuestions,
} from 'components';
import { KiteLoader, KiteTabs } from '@kite/react-kite';

// Hooks
import {
  useAnalytics,
  useGetDistros,
  useGetEstimateDetails,
  useGetEstimateScenarios,
  useGetFields,
  useGetForms,
  RCuseGetForms,
  useGetProfiles,
  useQueryData,
  useGetSubmissions,
  useTrackNavClick,
  MNEuseGetForms,
  useSelectedSolutionCheck,
} from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// Utils
import { CPWFORMS, isEstimateAutobuild, UCQFORMS, RCQFORMS } from 'utils';

// Types
import { IForm, ILocation } from 'types';

// Styles
import './UcReviewPage.scss';
import { ENEFORMS, MNEFORMS } from 'utils/defaultsAndConstants/constants';
//import { useGetForms,RCuseGetForms } from 'hooks/apiHooks/useGetForms';

export type TTabOptions =
  | 'UC Questions'
  | 'RC Questions'
  | 'MNE Questions'
  | 'ENE Questions'
  | 'Field Validation'
  | 'CPW Quote'
  | 'Field Pictures'
  | 'Rate Center Validation'
  | 'Estimate Products'
  | 'RC Review Page';

const UcReviewPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const { trackNavClick } = useTrackNavClick();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { currentEstimate } = useQueryData();
  const [initLoading, setInitLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<TTabOptions>('UC Questions');
  const { estimateId, locationId } = useParams();
  const {
    estimateData,
    estimateLoading,
    error: estimateError,
  } = useGetEstimateDetails(estimateId);

  useGetEstimateScenarios({
    params: { estimateId },
  });
  useGetProfiles();

  const { name: estimateName, locations, customerName } = estimateData || {};

  const formParams = useMemo(() => {
    if (currentTab === 'CPW Quote') {
      return {
        params: {
          type: CPWFORMS,
        },
      };
    } else {
      return {
        params: {
          type: UCQFORMS,
        },
      };
    }
  }, [currentTab]);

  const {
    data: formsData,
    isLoading: formsLoading,
    error: formsError,
  } = useGetForms(formParams);

  const RformParams = useMemo(() => {
    if (currentTab === 'RC Questions' || currentTab === 'RC Review Page') {
      return {
        params: {
          type: RCQFORMS,
        },
      };
    }
  }, [currentTab]);

  const {
    data: RformsData,
    isLoading: RformsLoading,
    error: RformsError,
  } = RCuseGetForms(RformParams);

  const MformParams = useMemo(() => {
    if (currentTab === 'MNE Questions') {
      return {
        params: {
          type: MNEFORMS,
        },
      };
    } else if (currentTab === 'ENE Questions') {
      return {
        params: {
          type: ENEFORMS,
        },
      };
    }
  }, [currentTab]);

  const {
    data: MformsData,
    isLoading: MformsLoading,
    error: MformsError,
  } = MNEuseGetForms(MformParams);

  const submissionParams = useMemo(() => {
    if (currentTab === 'CPW Quote' && formsData) {
      return { params: { estimateId, locationId, formId: formsData[0].id } };
    } else {
      return { params: { estimateId } };
    }
  }, [currentTab, estimateId, formsData, locationId]);

  const {
    data: submissions,
    isLoading: submissionsLoading,
    error: submissionsError,
  } = useGetSubmissions(submissionParams);

  const {
    data: fields,
    isLoading: fieldsLoading,
    error: fieldsError,
  } = useGetFields();

  const { data: distrosData, isLoading: distrosAreLoading } = useGetDistros({
    params: { estimateId },
  });

  const mainContentRef = useRef<HTMLDivElement | null>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const currentLocation = useMemo(() => {
    return locations?.find((loc: ILocation) => loc.id === locationId);
  }, [locations, locationId]);

  const isLoading =
    estimateLoading ||
    formsLoading ||
    RformsLoading ||
    MformsLoading ||
    submissionsLoading ||
    distrosAreLoading ||
    fieldsLoading;

  const isAutoBuild = isEstimateAutobuild(estimateData);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleTabChange = (value: string) => {
    setInitLoading(false);
    setCurrentTab(value as TTabOptions);
    trackNavClick(`Read Only Nav: Tab Change, ${value}`)();
    navigate({ search: `?tab=${value}` });
  };

  const handlePrint = useReactToPrint({
    content: () => mainContentRef.current,
  });

  // =============================================
  // Render Methods
  // =============================================
  let tabOptions: TTabOptions[] = [
    'UC Questions',
    'MNE Questions',
    'ENE Questions',
    'Field Validation',
    'CPW Quote',
    'Rate Center Validation',
    'Field Pictures',
    'Estimate Products',
  ];

  const getPushedScenarioId = useMemo(() => {
    const pushedScenarioId = currentEstimate?.pushedScenarioId;
    const pushedScenario = currentEstimate?.scenarios.find(
      (s) => s.id === pushedScenarioId
    );
    const productDetails = pushedScenario?.selections.map(
      (item, index, reultArr) => {
        return item.familyCategory;
      }
    );

    return productDetails;
  }, [currentEstimate]);

  const productDetails = getPushedScenarioId;

  if (productDetails && productDetails !== undefined) {
    let isRCAddsOnSeected: boolean = false;
    let isFieldValidationSelected: boolean = false;

    for (let i = 0; i < productDetails.length; i++) {
      if (productDetails[i] === 'RingCentral Products') {
        isRCAddsOnSeected = true;
      } else if (
        productDetails[i] === 'Data' ||
        productDetails[i] === 'MNE' ||
        productDetails[i] === 'ENE'
      ) {
        isFieldValidationSelected = true;
      }
    }

    if (isRCAddsOnSeected && isFieldValidationSelected) {
      tabOptions = [
        'RC Review Page',
        'RC Questions',
        'MNE Questions',
        'ENE Questions',
        'Field Validation',
        'Rate Center Validation',
        'Estimate Products',
      ];
    } else if (isRCAddsOnSeected) {
      tabOptions = [
        'RC Review Page',
        'RC Questions',
        'MNE Questions',
        'ENE Questions',
        'Rate Center Validation',
        'Estimate Products',
      ];
    }
  }

  const { newTabOptions } = useSelectedSolutionCheck({
    currentLocation,
    tabOptions,
  });

  if (!submissionsLoading && currentLocation) {
    tabOptions = newTabOptions || tabOptions;
  }

  const renderMainContent = useMemo(() => {
    if (currentTab === 'UC Questions') {
      return formsData && submissions && currentLocation ? (
        <UcReviewSubmissions
          opptyName={estimateName || ''}
          location={currentLocation}
          forms={formsData}
          submissions={submissions}
          distros={distrosData || []}
          refForPrint={mainContentRef}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'RC Questions') {
      return RformsData && submissions && currentLocation ? (
        <UcReviewRcQuestions
          opptyName={estimateName || ''}
          location={currentLocation}
          forms={RformsData}
          submissions={submissions}
          distros={distrosData || []}
          refForPrint={mainContentRef}
          tabName={currentTab}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'MNE Questions') {
      return MformsData && submissions && currentLocation ? (
        <UcReviewMneQuestions
          opptyName={estimateName || ''}
          location={currentLocation}
          forms={MformsData}
          submissions={submissions}
          distros={distrosData || []}
          refForPrint={mainContentRef}
          tabName={currentTab}
          showAlert={false}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'ENE Questions') {
      return MformsData && submissions && currentLocation ? (
        <UcReviewMneQuestions
          opptyName={estimateName || ''}
          location={currentLocation}
          forms={MformsData}
          submissions={submissions}
          distros={distrosData || []}
          refForPrint={mainContentRef}
          tabName={currentTab}
          showAlert={false}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Field Validation') {
      return currentLocation ? (
        <UcReviewValidation
          opptyName={estimateName || ''}
          location={currentLocation}
          submissions={submissions || []}
          fields={fields || []}
          distros={distrosData || []}
          refForPrint={mainContentRef}
          tabName={currentTab}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'CPW Quote') {
      return currentLocation ? (
        <UcReviewCpwTab
          opptyName={estimateName || ''}
          locationName={currentLocation.name}
          formData={
            formsData ? formsData[0] : ({ fields: [] } as Partial<IForm>)
          }
          businessName={customerName || ''}
          address1={currentLocation.address1}
          address2={currentLocation.address2}
          submissions={submissions || []}
          refForPrint={mainContentRef}
          isLoading={isLoading}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Field Pictures') {
      return currentLocation ? (
        <UcReviewPicturesTab
          opptyName={estimateName || ''}
          locationName={currentLocation.name}
          locationId={currentLocation.id}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Rate Center Validation') {
      return currentLocation ? (
        <UcReviewRateCenterTab
          opptyName={estimateName || ''}
          location={currentLocation}
          refForPrint={mainContentRef}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'Estimate Products') {
      return estimateData ? (
        <UcReviewEstimateProductsTab
          refForPrint={mainContentRef}
          tabName={currentTab}
        />
      ) : (
        <div>Something went wrong</div>
      );
    } else if (currentTab === 'RC Review Page') {
      return RformsData && submissions && currentLocation ? (
        <>
          <UcReviewRcQuestions
            opptyName={estimateName || ''}
            location={currentLocation}
            forms={RformsData}
            submissions={submissions}
            distros={distrosData || []}
            refForPrint={mainContentRef}
            tabName={currentTab}
          />
        </>
      ) : (
        <div>Something went wrong</div>
      );
    } else {
      return null;
    }
  }, [
    currentTab,
    formsData,
    RformsData,
    MformsData,
    submissions,
    currentLocation,
    estimateName,
    distrosData,
    fields,
    customerName,
    isLoading,
    estimateData,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UcReviewPage'), [trackPageView]);

  useEffect(() => {
    const prodDetails = getPushedScenarioId;
    const isRCAddsOn = prodDetails?.find(
      (item) => item === 'RingCentral Products'
    );

    const tabParam = initLoading
      ? isRCAddsOn && isRCAddsOn === 'RingCentral Products'
        ? 'RC Review Page'
        : 'UC Questions'
      : search.split('?tab=')[1]?.replaceAll('%20', ' ');
    if (tabParam && tabParam !== currentTab) {
      setCurrentTab(tabParam as TTabOptions);
    } else if (!tabParam) {
      // Set search if currentTab is not in the url
      navigate({ search: `?tab=${currentTab}` });
    }
  }, [
    search,
    currentLocation,
    navigate,
    currentTab,
    initLoading,
    getPushedScenarioId,
  ]);

  // =============================================
  // Early Return
  // =============================================
  if (isLoading && currentTab !== 'CPW Quote') {
    // Handle loading state separately in CPW tab to avoid entire tab rerender
    return <KiteLoader className="uc-review-page__loader" />;
  }

  if (
    estimateError ||
    formsError ||
    RformsError ||
    MformsError ||
    submissionsError ||
    fieldsError
  ) {
    return <div>Something went wrong</div>;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="uc-review-page">
      <UcReviewHeader
        opptyName={estimateName || ''}
        isAutoBuild={isAutoBuild}
        handlePrint={handlePrint}
      />
      <div className="uc-review-page__content">
        {locations && locations.length > 1 && (
          <UcReviewSideNav locations={locations} defaultTab={tabOptions[0]} />
        )}
        <div className="uc-review-page__content--main">
          <KiteTabs
            tabs={tabOptions}
            currentTab={currentTab}
            onSelectTab={handleTabChange}
          />
          {renderMainContent}
        </div>
      </div>
    </div>
  );
};

export default UcReviewPage;
