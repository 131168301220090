// Packages
import React, { useCallback } from 'react';

// Redux

// Components
import { KiteButton } from '@kite/react-kite';
import { AutobuildPill } from 'components';

// Hooks
import { useAnalytics } from 'hooks';

// Utils

// Types

// Styles
import './RcReviewHeader.scss';

export interface IRcReviewHeaderProps {
  opptyName: string;
  isAutoBuild: boolean;
  handlePrint?: () => void;
}

/** Header for the RcReadOnyPage */

const RcReviewHeader = ({
  opptyName,
  isAutoBuild,
  handlePrint,
}: IRcReviewHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const onPrint = useCallback(() => {
    if (handlePrint) {
      handlePrint();
      trackSelectAction('Read Only Print', { opType: 'buttonClick' });
    }
  }, [handlePrint, trackSelectAction]);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-header">
      <div className="rc-review-header__left">
        <p className="rc-review-header__left__name-label">OPPTY NAME</p>
        <div className="rc-review-header__left__name">
          <h1>{opptyName}</h1>
          <div className="rc-review-header__left__name__autobuild-pill">
            <AutobuildPill isAutobuild={isAutoBuild} />
          </div>
        </div>
      </div>
      <KiteButton leftIcon="print" size="medium" onClick={onPrint}>
        Print
      </KiteButton>
    </div>
  );
};

export default RcReviewHeader;
