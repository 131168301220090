import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KiteAlert, KiteTooltip } from '@kite/react-kite';
import {
  IField,
  IFieldValues,
  ISubmission,
  IFieldInput,
  ILocation,
  IPostCopyCloneHistory,
} from 'types';
import {
  UCQSelect,
  UCQDate,
  UCQTextQuantity,
  UCQUpload,
  UCQBasicInput,
  MNECheckbox,
} from 'components';
import { useUCQuestionsTotal } from './useUCQuestionsTotal';
import { UCQDesignFlowLayout } from 'components/ucQuestions';
import {
  useAnalytics,
  useDeleteSubmissions,
  useFormRules,
  useQueryData,
  useUpdateSubmissions,
  useCopySubmissions,
  useUpdateLocation,
  usePostCopyCloneHistory,
} from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useParams } from 'react-router-dom';
import { collatorSort } from 'utils';
import { noLabelForms } from 'utils/defaultsAndConstants/constants';

interface IUseRenderFieldsParams {
  fields: IField[];
  submissions: ISubmission[];
  formId?: string;
  isCpw?: boolean;
}

export const useRenderFields = ({
  fields,
  submissions,
  formId,
  isCpw,
}: IUseRenderFieldsParams) => {
  // Initial field values (used to compare changes)
  // Updates on fields/submissions changes
  const initFieldValues = useMemo(
    () =>
      fields.reduce((acc: IFieldValues, field) => {
        const submission = submissions?.find(
          (sub) => sub.fieldId === field.id
        )?.answer;
        acc[field.id] = submission || null;
        return acc;
      }, {}),
    [fields, submissions]
  );

  const { estimateId = '', locationId = '' } = useParams();
  const { userId, currentLocation, currentEstimate } = useQueryData();
  const { trackSelectAction } = useAnalytics();

  /* * * * * * * *
   *             *
   * STATE/HOOKS *
   *             *
   * * * * * * * */

  // Key-value pairs of fieldId + current value
  const [fieldValues, setFieldValues] = useState(initFieldValues);
  // Id of currently focused field
  const [currentFocusId, setCurrentFocusId] = useState<string>('');

  // Flat array of all form rules
  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  // Return from rule engine
  // ruleKeys is array of all fieldIds that rely on a rule
  // ruleMap is key-value pairs of fieldId + canShow (boolean)
  const { ruleMap, ruleKeys } = useFormRules({ fieldValues, formRules });

  // Get required field Ids and submissions based on current submissions + form
  const { completePercent, requiredSubmissions, requiredFieldIds } =
    useUCQuestionsTotal({ submissions, formId });

  /* * * * * *
   *         *
   * HELPERS *
   *         *
   * * * * * */

  // Use requiredFieldIds to get fieldValues only for required fields
  const requiredFieldValues = useMemo(
    () =>
      Object.entries(fieldValues).filter(([fieldId, value]) => {
        if (new Set(requiredFieldIds).has(fieldId) && value) {
          return true;
        }
        return false;
      }),
    [fieldValues, requiredFieldIds]
  );

  // Compares initFieldValues w/ current fieldValues to determine if form state has changed
  const checkIsDirty = useCallback(
    (fieldValues: IFieldValues) =>
      Object.entries(initFieldValues).some(
        ([fieldId, value]) => fieldValues[fieldId] !== value
      ),
    [initFieldValues]
  );

  // Get array of all fields that are different from original value
  // Returns as [fieldId, value][]
  const getFieldsToUpdate = useCallback(
    (fieldValues: IFieldValues) => {
      const isDirty = checkIsDirty(fieldValues);
      if (!isDirty) {
        return [];
      }

      return Object.entries(fieldValues).filter(
        ([fieldId, value]) => initFieldValues[fieldId] !== value
      );
    },
    [checkIsDirty, initFieldValues]
  );

  const { updateSubmissions, updateSubmissionsError } = useUpdateSubmissions({
    estimateId,
    locationId,
    fields,
  });

  const { copySubmissions, copySubmissionsError } = useCopySubmissions({
    estimateId,
  });

  const { postCopyCloneHistory } = usePostCopyCloneHistory();

  const { deleteSubmissions } = useDeleteSubmissions({
    estimateId,
    locationId,
  });

  const { updateLocation } = useUpdateLocation(estimateId);

  const getApiLocationId = useCallback(
    () => (locationId === 'general' ? null : locationId),
    [locationId]
  );

  const ezPassFieldId = fields.find((f) => f.inputName === 'EZ Pass')?.id;

  const saveSubmissions = useCallback(
    (submissions: Partial<ISubmission>[]) => {
      if (submissions.length) {
        if (ezPassFieldId && currentLocation) {
          const isEzPass =
            submissions.find((s) => s.fieldId === ezPassFieldId)?.answer ===
            'Yes'
              ? true
              : false;
          updateLocation({ ...currentLocation, isEzPass });
        }

        updateSubmissions(submissions);
      }
    },
    [currentLocation, ezPassFieldId, updateLocation, updateSubmissions]
  );

  // Sort fields by order
  const sortedFields = useMemo(() => {
    return fields.sort((a, b) => a.order - b.order);
  }, [fields]);

  /* * * * * * * * * * * * *
   *                       *
   * INTERACTION HANDLERS  *
   *                       *
   * * * * * * * * * * * * */

  // Set focusId for current field
  // const handleFocus = useCallback(
  //   (fieldId: string) => () => {
  //     setCurrentFocusId(fieldId);
  //   },
  //   []
  // );

  const findSubmissionDuplicates = useCallback(
    (config: { submissions: ISubmission[]; fieldValues: IFieldValues }) => {
      const { submissions, fieldValues } = config;
      const fieldEntries = Object.entries(fieldValues);
      const submissionsToDelete = fieldEntries.reduce(
        (acc: ISubmission[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          acc.push(...subMatches.filter((s) => s.id !== mostRecentSub?.id));
          return acc;
        },
        []
      );
      return submissionsToDelete;
    },
    []
  );

  // Change handler for updating inputs
  const onFieldChange = useCallback(
    (id: string, newValue: ISubmission['answer'], callback?: () => void) => {
      const newFieldValues = { ...fieldValues, [id]: newValue };
      const fieldsToUpdate = getFieldsToUpdate(newFieldValues);
      const submissionsToSave = fieldsToUpdate.reduce(
        (acc: Partial<ISubmission>[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          if (mostRecentSub) {
            const newSub: Partial<ISubmission> = {
              ...mostRecentSub,
              formId: undefined,
              answer: newValue ?? null,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer: newValue ?? null,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );
      saveSubmissions(submissionsToSave);

      if (callback) {
        callback();
      }

      trackSelectAction(
        `Submission Change (Field Id: ${id}, Answer: ${newValue})`,
        { opType: 'inputFieldUpdate' }
      );
    },
    [
      fieldValues,
      getFieldsToUpdate,
      saveSubmissions,
      trackSelectAction,
      submissions,
      userId,
      estimateId,
      getApiLocationId,
    ]
  );

  const debounceFieldChange = useDebouncedCallback(onFieldChange, 350);

  const onImport = useCallback(
    (importSubmissions: ISubmission[]) => {
      const importedSubmissions = importSubmissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          const sub = submissions.find((sub) => sub.fieldId === fieldId);
          if (sub) {
            const newSub: Partial<ISubmission> = {
              ...sub,
              formId: undefined,
              answer,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );
      const importedIds = new Set(importedSubmissions.map((s) => s.id));
      const removedSubmissions = submissions.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if ((!formId || s.formId === formId) && !importedIds.has(s.id)) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [
        ...importedSubmissions,
        ...removedSubmissions,
      ];
      debounceFieldChange.cancel();
      updateSubmissions(updatedSubmissions);
    },
    [
      debounceFieldChange,
      estimateId,
      formId,
      getApiLocationId,
      submissions,
      updateSubmissions,
      userId,
    ]
  );

  const onCopy = useCallback(
    (copy1Submissions: ISubmission[], loctions: ILocation[]) => {
      const sourceFormId = new Set(copy1Submissions.map((s) => s.formId!));

      const copiedSubmissions = copy1Submissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          loctions.forEach((l) => {
            const sub = currentEstimate?.submissions!.find(
              (sub) => sub.fieldId === fieldId && sub.locationId === l.id
            );
            if (sub) {
              const newSub: Partial<ISubmission> = {
                ...sub,
                formId: undefined,
                answer,
                editedBy: userId,
              };
              acc.push(newSub);
            } else {
              acc.push({
                estimateId,
                locationId: l.id,
                fieldId,
                answer,
                editedBy: userId,
              });
            }
          });

          return acc;
        },
        []
      );
      const importedIds = new Set(copiedSubmissions.map((s) => s.id));
      const removedSubmissions = currentEstimate?.submissions!.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if (
            !importedIds.has(s.id) &&
            s.formId &&
            sourceFormId.has(s.formId) &&
            loctions.find((l) => l.id === s.locationId)
          ) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [...copiedSubmissions, ...removedSubmissions!];
      debounceFieldChange.cancel();
      const maxChunkSize = 2500;
      let index = 0;

      //breaking down updatedSubmissions into multiple chunks
      //to avoid PAYLOAD TOO LARGE ERROR
      while (index < updatedSubmissions.length) {
        const nextChunkSize = updatedSubmissions.length - index;
        const currentChunkSize = Math.min(nextChunkSize, maxChunkSize);

        const chunk = updatedSubmissions.slice(index, index + currentChunkSize);
        copySubmissions(chunk);

        index += currentChunkSize;
      }

      const copyHistoryEntries: IPostCopyCloneHistory[] = loctions.map((l) => {
        return {
          sourceEstimateId: currentEstimate?.id!,
          sourceLocationId: currentLocation?.id!,
          targetEstimateId: l.estimateId,
          targetLocationId: l.id,
          formIds: Array.from(sourceFormId.values()),
          actionType: 'COPY',
          triggeredBy: userId,
        };
      });
      postCopyCloneHistory(copyHistoryEntries);
    },
    [
      debounceFieldChange,
      estimateId,
      copySubmissions,
      userId,
      currentEstimate,
      currentLocation,
      postCopyCloneHistory,
    ]
  );

  /* * * * * * * * * *
   *                 *
   * RENDER METHODS  *
   *                 *
   * * * * * * * * * */

  // Parse html string into field label
  const renderLabel = useCallback((field: IFieldInput) => {
    // "defaultValue" string prevents incomplete marker from temporarily rendering
    const { label, subtext, value = 'defaultValue', helpText } = field;
    const isComplete = !!value || label === 'Notes';
    return (
      <div className="ucq-field__label">
        <div className="ucq-field__label-question">
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
          {!isComplete && <div className="ucq-field__incomplete-marker"></div>}
          {helpText && (
            <div>
              <KiteTooltip className="ucq-field__tooltip">
                <div
                  style={{
                    maxHeight: '200px',
                    maxWidth: '300px',
                    overflow: 'scroll',
                  }}
                >
                  <span
                    className="ucq-help__content"
                    dangerouslySetInnerHTML={{
                      __html: helpText ? helpText : '',
                    }}
                  ></span>
                </div>
              </KiteTooltip>
            </div>
          )}
        </div>
        {subtext && <span className="ucq-field__subtext">{subtext}</span>}
      </div>
    );
  }, []);

  // Map input type to component
  // `field` argument requires mapping submission value to field ojbect (IFieldInput)
  const renderInput = useCallback(
    (field: IFieldInput, isDisabled?: boolean) => {
      switch (field.inputType) {
        case 'date':
          return (
            <UCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="date"
            />
          );
        case 'time':
          return (
            <UCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="time"
            />
          );
        case 'file':
          return (
            <UCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="tco"
            />
          );
        case 'select':
          return (
            <UCQSelect
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              isDisabled={isDisabled}
            />
          );
        case 'check-box':
          return (
            <MNECheckbox
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              isrequired={field.required}
            />
          );
        case 'text':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
            />
          );
        case 'text-area':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text-area"
            />
          );
        case 'number-text':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
            />
          );
        case 'image':
          return (
            <UCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="image"
            />
          );
        case 'text-quantity':
          return (
            <UCQTextQuantity
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );
        case 'text-copy':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
              isCopy={true}
            />
          );
        case 'number-copy':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
              isCopy={true}
            />
          );
        default:
          return null;
      }
    },
    [debounceFieldChange]
  );

  // Generate array of functional form field components

  const fieldComponents = useMemo(() => {
    let Qindex = 0;

    return sortedFields.map((field) => {
      const { header, label, order, id, inputType, inputName } = field;
      const isCheckBox = inputType === 'check-box' ? true : false;
      let { warningMessage } = field;
      if (inputType === 'distro') {
        return (
          <UCQDesignFlowLayout
            key={id}
            onFieldChange={onFieldChange}
            fieldId={id}
          />
        );
      }

      // If order is not whole integer, it is a sub question & won't display number next to label
      const isSubQuestion = order && order % 1 !== 0;
      if (isCheckBox && header) {
        Qindex = 0;
      } else {
        if (!isSubQuestion) {
          Qindex++;
        }
      }

      const formattedLabel =
        isSubQuestion ||
        order === 100 ||
        (formId && noLabelForms.includes(formId))
          ? label
          : `${!isCpw && Qindex !== 0 ? Qindex + '. ' : ''}${label}`;
      const isRule = ruleKeys.includes(id); // Check if field relies on a rule..
      const canShow = isRule ? ruleMap[id] : true; // Check field map to get current rule state (true/false)
      const value = fieldValues[id]; // Submission value for field
      const fieldInput = { ...field, label: formattedLabel, value }; // Converted field w/ formatted label and submission value

      if (
        order === 13 &&
        value &&
        formId === '6494e74e-8fc1-4a65-817f-5bdb0e8bae85'
      ) {
        warningMessage = warningMessage
          ? warningMessage
          : 'If this field has been updated to change if SME approval is required, please review the SME only tab to ensure accuracy and update accordingly';
      }

      return canShow ? (
        <>
          <div
            key={id}
            className="ucq-field"
            style={{
              display: 'grid',
              gridTemplateRows: 'auto auto',

              gridColumn:
                inputType === 'text-quantity' &&
                label === 'Does this site have EXTERNAL intercom(s)?'
                  ? '1/span 2'
                  : inputType === 'text-quantity'
                  ? 'span 2'
                  : inputName === 'notes' ||
                    (header && isCheckBox) ||
                    warningMessage
                  ? '1 / -1'
                  : inputType === 'time'
                  ? '1 / -3'
                  : '',
              ...(header ? { gridColumnStart: '1' } : {}),
            }}
          >
            {header ? (
              isCheckBox ? (
                <div style={{ display: 'inline-flex' }}>
                  {header && <h3 className="ucq-field__header">{header}</h3>}
                  <div style={{ marginLeft: '50px' }}>
                    {renderInput(fieldInput)}
                  </div>
                </div>
              ) : (
                <div>
                  {header && <h3 className="ucq-field__header">{header}</h3>}
                  {warningMessage && (
                    <KiteAlert
                      id={id}
                      level="page"
                      type="caution"
                      description={warningMessage}
                    />
                  )}
                  <div className="ucq-field-input">
                    {fieldInput.inputType !== 'check-box' &&
                      renderLabel(fieldInput)}
                    {renderInput(fieldInput)}
                  </div>
                </div>
              )
            ) : (
              <>
                {warningMessage && (
                  <KiteAlert
                    id={id}
                    level="page"
                    type="caution"
                    description={warningMessage}
                  />
                )}
                <div className="ucq-field-input">
                  {fieldInput.inputType !== 'check-box' &&
                    renderLabel(fieldInput)}
                  {renderInput(fieldInput)}
                </div>
              </>
            )}
          </div>
        </>
      ) : null;
    });
  }, [
    sortedFields,
    isCpw,
    ruleKeys,
    ruleMap,
    fieldValues,
    formId,
    renderLabel,
    renderInput,
    onFieldChange,
  ]);

  /* * * * * * *
   *           *
   *  EFFECTS  *
   *           *
   * * * * * * */

  // Reset field values if initFieldValues changes (new form, submission mutation, etc.)
  useEffect(() => {
    if (!debounceFieldChange.isPending() && checkIsDirty(fieldValues)) {
      const subsToDelete = findSubmissionDuplicates({
        submissions,
        fieldValues: initFieldValues,
      });
      if (subsToDelete.length) {
        deleteSubmissions(subsToDelete.map((s) => s.id));
      }
      setFieldValues(() => initFieldValues);
    }
  }, [
    checkIsDirty,
    debounceFieldChange,
    deleteSubmissions,
    fieldValues,
    findSubmissionDuplicates,
    initFieldValues,
    submissions,
  ]);

  return {
    completePercent,
    currentFocusId,
    fieldComponents,
    fieldValues,
    fieldsToUpdate: getFieldsToUpdate(fieldValues),
    isDirty: checkIsDirty(fieldValues),
    requiredFieldIds,
    requiredFieldValues,
    requiredSubmissions,
    onImport,
    updateSubmissionsError,
    onCopy,
    copySubmissionsError,
  };
};
