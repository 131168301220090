import { useCallback, useEffect, useMemo } from 'react';
import {
  addEdge,
  Connection,
  Edge,
  Node,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { IDistro, IRCQDesignFlowNode } from 'types';
import { formatNodeData, getNodeLayout, sortFlowNodes } from 'utils';

interface IRCUseFlowNodes {
  /** Distros data to use */
  distros: Partial<IDistro>[];
  /** Width and height for a single node */
  nodeSize: { width: number; height: number };
  /** Callback for adding a new distro node */
  addNode?: IRCQDesignFlowNode['addNode'];
  /** Callback for editing an existing node */
  editNode?: IRCQDesignFlowNode['editNode'];
}

export const RCuseFlowNodes = ({
  distros,
  nodeSize,
  addNode,
  editNode,
}: IRCUseFlowNodes) => {
  // Convert distro data to initial react flow node format
  const formattedDistros = useMemo(
    () =>
      formatNodeData({
        distros,
      }),
    [distros]
  );

  // Group nodes by parent building
  const nodesByBdg = useMemo(
    () =>
      formattedDistros.nodes.reduce(
        (acc: { [key: string]: Node<IRCQDesignFlowNode>[] }, node) => {
          const { type, parentNode, id } = node;
          if (type === 'bdg' && !acc[id]) {
            acc[id] = [];
          }

          if (type !== 'bdg' && parentNode && !acc[parentNode]) {
            acc[parentNode] = [];
          }

          if (type === 'bdg') {
            acc[id].push(node);
          }

          if (parentNode) {
            acc[parentNode].push(node);
          }

          return acc;
        },
        {}
      ),
    [formattedDistros.nodes]
  );

  // Group all external edges that don't exist in the building
  const edgesByBdg = useMemo(
    () =>
      Object.entries(nodesByBdg).reduce(
        (acc: { [key: string]: Edge[] }, [bdgId, nodes]) => {
          const nodeIds = nodes.map((n) => n.id);
          const edges = formattedDistros.edges.filter(
            (e) => nodeIds.includes(e.source) && nodeIds.includes(e.target)
          );
          acc[bdgId] = edges;
          return acc;
        },
        {}
      ),
    [formattedDistros.edges, nodesByBdg]
  );

  // Optimizes node layout with "getNodeLayout"
  // Adds addNode/editNode handlers to node data
  const layout = useMemo(() => {
    // Graph height/width, will be set to biggest group of nodes w/h in layoutNodes
    const minWidth = 700;

    return Object.entries(nodesByBdg).reduce(
      (
        acc: {
          nodes: Node<IRCQDesignFlowNode>[];
          edges: Edge[];
          totalHeight: number;
          totalWidth: number;
        },
        [bdgId, nodes],
        i
      ) => {
        const edges = edgesByBdg[bdgId];
        const {
          layoutNodes,
          layoutEdges,
          graph: { height = 0, width = 0 },
        } = getNodeLayout(nodes, edges, i, acc.totalHeight, nodeSize);

        acc.totalHeight += height + 75;

        if (width > acc.totalWidth) {
          acc.totalWidth = width;
        }

        const functionalNodes = layoutNodes.map((n) => {
          if (n.type === 'bdg') {
            return {
              ...n,
              data: {
                ...n.data,
                editNode,
                addNode,
              },
              style: {
                ...n.style,
                height,
                minWidth: width > minWidth ? width : minWidth,
              },
            };
          }
          return { ...n, data: { ...n.data, editNode, addNode } };
        });

        acc.nodes.push(...functionalNodes);
        acc.edges.push(...layoutEdges);

        return acc;
      },
      { nodes: [], edges: [], totalHeight: 0, totalWidth: 700 }
    );
  }, [addNode, edgesByBdg, editNode, nodeSize, nodesByBdg]);

  const [nodes, setNodes, onNodesChange] = useNodesState<IRCQDesignFlowNode>(
    // Sort nodes to be in bdg -> mdf -> idf order + by id number i.e B1 B2 M1 M2 i1 i2)
    sortFlowNodes(layout.nodes)
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>(layout.edges);

  // Needed for react flow if dragging nodes/edges is enabled
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  // Set nodes if distros data changes causes layout nodes change
  useEffect(() => {
    setNodes(layout.nodes);
    setEdges(layout.edges);
  }, [layout, setEdges, setNodes]);

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    height: layout.totalHeight,
    width: layout.totalWidth,
  };
};
