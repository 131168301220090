import { useMutation, useQueryClient } from 'react-query';
import { ILocationBase } from 'types';
import { IDetailedAddress, locationRequest, queryKeys } from 'utils';

export interface ILocationUpdate extends ILocationBase {
  estimateId: string;
  shipTo?: IDetailedAddress | null;
  selectedSolution?: string | null;
  mnePaymentOption?: string | null;
  enePaymentOption?: string | null;
}

export const useUpdateLocation = (estimateId?: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateLocation,
    isError: updateLocationError,
    isLoading: updateLocationLoading,
  } = useMutation(
    (updatedLocation: ILocationUpdate) => {
      return locationRequest({
        endpoint: `locations/${updatedLocation.id}`,
        method: 'PUT',
        data: updatedLocation,
      });
    },
    {
      onSuccess: () => {
        // refetch all estimate data since location data is nested
        queryClient.invalidateQueries(queryKeys().all.estimates);
        if (estimateId) {
          queryClient.invalidateQueries(
            queryKeys({ estimateId }).details.estimate
          );
        }
      },
    }
  );

  return { updateLocation, updateLocationError, updateLocationLoading };
};
