import axios from 'axios';
import { env } from './env';

export interface ITNFormData {
  npa: string;
  nxx: string;
}

export const getRateCenterValidation = async (phoneNumbers: ITNFormData[]) => {
  const url = `${env.apiUrl}/rate-center/val`;

  try {
    const res = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({
        npanxx: phoneNumbers,
      }),
    });

    return res.data.data;
  } catch (error) {
    console.log('error', error);
  }
};

export const getRateCenterRCValidation = async (
  phoneNumbers: ITNFormData[]
) => {
  const url = `${env.apiUrl}/rate-center/ringcentral/val`;

  try {
    const res = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({
        records: phoneNumbers,
      }),
    });

    return res.data.data.result;
  } catch (error) {
    console.log('error', error);
  }
};
