import { ICopyCloneHistory, ISubmission, ISubmissionHistory } from 'types';

export const mockSubmissions: ISubmission[] = [
  {
    id: '',
    createdAt: '',
    updatedAt: '',
    locationId: 'location1',
    estimateId: 'testOne',
    fieldId: '',
    fieldOrder: 1,
    formId: '',
    answer: 'Mock Submission Answer 1',
    comment: 'Mock Submission Comment 1',
    editedBy: 'P3059592',
    fieldOpsAccurate: false,
    fieldOpsComments: 'none',
  },
];

export const mockSubmissionHistory: ISubmissionHistory[] = [
  {
    id: '',
    createdAt: '',
    updatedAt: '',
    estimateId: 'testOne',
    submissionId: '',
    previousValue: '',
    updatedValue: '',
    editedBy: '',
    formId: '',
    formName: '',
    fieldLabel: '',
    fieldOrder: 1,
    locationId: 'location1',
    inputType: 'date',
  },
];

export const mockCopyCloneHistory: ICopyCloneHistory[] = [
  {
    id: '',
    createdAt: '',
    updatedAt: '',
    sourceEstimateId: '',
    sourceLocationId: '',
    targetEstimateId: '',
    targetLocationId: '',
    formIds: [],
    actionType: 'COPY',
    triggeredBy: '',
  },
];
