import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { Link, useLocation } from 'react-router-dom';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';
import { NavSwitch } from 'components';

// Hooks
import { useQueryData, useSelections } from 'hooks';

// Utils
import { ILocationSelection, IProfile, TNavigationCategory } from 'types';

// Styles
import './EstimateNavLink.scss';

export interface IEstimateNavLinkProps {
  /** The route to be passed to react-router-dom */
  to: string;
  /** The name of the KiteIcon you wish to display to the left of the link text */
  iconName: string;
  /** The link text */
  text: TNavigationCategory;
  /** Whether to display the active state of the link */
  active: boolean;
  /** Whether the link is disabled */
  disabled?: boolean;
  /** Whether the navlink page has an error */
  hasError?: boolean;
  /** Determines if link has a switch control */
  hasSwitch?: boolean;
  /** Explicitly determine which nav link is first for styling (border radius) */
  isFirst?: boolean;
}

/** Nav link item used within LeftNav to change page view + toggle product family */
const EstimateNavLink = ({
  iconName,
  text,
  active,
  to,
  disabled,
  hasError,
  hasSwitch,
  isFirst,
}: IEstimateNavLinkProps) => {
  // =============================================
  // State/Hooks
  // =============================================
  const [switchOn, setSwitchOn] = useState(false);
  const [localSelections, setLocalSelections] = useState<{
    [locationId: string]: ILocationSelection[];
  }>({});
  const [localProfiles, setLocalProfiles] = useState<{
    [locationId: string]: Partial<IProfile>[];
  }>({});

  const { pathname } = useLocation();

  const {
    applySelections,
    clearProfiles,
    deleteProfileLoading,
    applyProfilesAndSelections,
    removeSelections,
    updateScenarioProductsLoading,
    updateProfilesLoading,
  } = useSelections();

  const {
    allProfiles,
    currentSelections,
    locationId,
    currentScenario,
    scenarioId,
  } = useQueryData();

  const localKey = `${text}${locationId}${scenarioId}`;

  // =============================================
  // Helpers
  // =============================================
  const category =
    text === 'UC Addons' || text === 'UC Profiles'
      ? 'UC Products'
      : text === 'RC Addons'
      ? 'RingCentral Products'
      : text;

  const categorySelections = useMemo(() => {
    return currentSelections.filter((p) => {
      const isCategory = p.familyCategory === category;
      if (text === 'RC Addons') {
        return isCategory && !p.profileId;
      }
      if (text === 'UC Addons') {
        return isCategory && !p.profileId;
      }
      if (text === 'UC Profiles') {
        return isCategory && p.profileId;
      }

      return isCategory;
    });
  }, [category, currentSelections, text]);

  const categoryProfiles: IProfile[] = useMemo(() => {
    if (text !== 'UC Profiles') {
      return [];
    }

    // get list of profileIds w/ no duplicates from category selections
    const profileIds = Array.from(
      new Set(categorySelections.map((s) => s.profileId))
    );

    return profileIds.reduce((acc: IProfile[], id) => {
      const profile = allProfiles.find((p) => p.id === id);
      if (profile) {
        acc.push(profile);
      }
      return acc;
    }, []);
  }, [allProfiles, categorySelections, text]);

  const isLoading =
    text === 'UC Profiles'
      ? deleteProfileLoading ||
        updateProfilesLoading ||
        updateScenarioProductsLoading
      : updateScenarioProductsLoading;

  const noProducts =
    currentScenario &&
    !categorySelections?.length &&
    !localSelections[localKey]?.length;

  // =============================================
  // Handlers
  // =============================================
  const handleSwitchToggle = useCallback(
    (switchValue: boolean) => () => {
      setSwitchOn(switchValue);

      if (!switchValue) {
        if (text === 'UC Profiles') {
          setLocalProfiles({
            ...localProfiles,
            [localKey]: categoryProfiles,
          });
          setLocalSelections({
            ...localSelections,
            [localKey]: categorySelections.map((s) => ({
              ...s,
              id: `newSelection${v4()}`,
            })),
          });
          // Wait for selections to be removed before clearing profiles
          removeSelections(categorySelections);
          clearProfiles(categoryProfiles);
        } else {
          if (text === 'RC Addons') {
            const noMneNetworkSpeed = !currentSelections.find(
              (s) => s.familyName === 'MNE Network Speed'
            );
            const mneSwitches = currentSelections.filter(
              (s) => s.familyName === 'MNE Switches'
            );
            if (noMneNetworkSpeed && mneSwitches) {
              removeSelections(mneSwitches);
            }
          }
          setLocalSelections({
            ...localSelections,
            [localKey]: categorySelections.map((s) => ({
              ...s,
              id: `newSelection${v4()}`,
            })),
          });
          removeSelections(categorySelections);
        }
      } else {
        if (text === 'UC Profiles') {
          applyProfilesAndSelections({
            profiles: localProfiles[localKey],
            selections: localSelections[localKey],
          });
        } else {
          applySelections(localSelections[localKey]);
        }
      }
    },
    [
      text,
      localProfiles,
      localKey,
      categoryProfiles,
      localSelections,
      categorySelections,
      clearProfiles,
      removeSelections,
      applyProfilesAndSelections,
      applySelections,
      currentSelections,
    ]
  );

  const handleBlur = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
  }, []);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (categorySelections?.length) {
      setSwitchOn(true);
    } else {
      setSwitchOn(false);
    }
  }, [categorySelections]);

  useEffect(() => {
    // Auto-store local selections if none exist, except for on UC Profiles
    // This is needed for auto-toggle feature w/ product dependency check (i.e. ENE/MNE toggling)
    if (
      switchOn &&
      text !== 'UC Profiles' &&
      categorySelections.length &&
      (!localSelections[localKey] || !localSelections[localKey].length)
    ) {
      setLocalSelections({
        ...localSelections,
        [localKey]: categorySelections.map((s) => ({
          ...s,
          id: '',
        })),
      });
    }
  }, [
    switchOn,
    text,
    localKey,
    localSelections,
    categorySelections,
    currentScenario,
  ]);

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={classnames({
        'pqe-nav-link': true,
        'pqe-nav-link--active': active,
        'pqe-nav-link--disabled': disabled,
      })}
      style={isFirst ? { borderTopLeftRadius: 4, borderTopRightRadius: 4 } : {}}
    >
      <Link to={disabled ? pathname : to}>
        <KiteIcon name={iconName} size="24px" margin="0 13px 0 0" />
        <span className="pqe-nav-link__link-text">{text}</span>
        <div className="pqe-nav-link__error-wrapper">
          {hasError && (
            <KiteIcon
              name="caution-circle-f"
              className="pqe-nav-link__error-icon"
              size="24px"
              margin="0 8px"
              color="#feb533"
            />
          )}
        </div>
      </Link>
      {hasSwitch && (
        <NavSwitch
          isOn={switchOn}
          onClick={handleSwitchToggle(!switchOn)}
          onBlur={handleBlur}
          disabled={isLoading || noProducts}
        />
      )}
    </div>
  );
};

export default EstimateNavLink;
