import { StyleSheet } from '@react-pdf/renderer';

const tableCellStep = {
  width: 65,
  padding: 3,

  borderTop: 0.5,
  borderTopColor: 'gray',
  borderRight: 1,
  borderRightColor: 'gray',
  borderBottom: 0.5,
  borderBottomColor: 'gray',

  fontSize: 9,
};

const productCell = {
  padding: 3,

  borderTop: 0.5,
  borderRight: 1,
  borderBottom: 0.5,
  borderLeft: 1,
  borderColor: 'gray',

  fontSize: 9,
};

// Styles
export const styles: { [key: string]: any } = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 75,
    paddingHorizontal: 35,
    position: 'relative',
  },
  // HEADER
  headerMainWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  headerMain: {
    paddingBottom: 24,
    fontSize: 24,
    fontFamily: 'Helvetica-Bold',
  },
  headerLeft: {
    fontSize: 16,
    paddingBottom: 16,
  },
  internalUseWrapper: {
    marginTop: 10,
    marginBottom: 30,
    textAlign: 'center',
  },
  internalUse: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subHeaderWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',

    height: 19,

    textAlign: 'justify',
    fontSize: 13,
  },
  subHeader: {
    position: 'absolute',
    left: 4,

    padding: 3,
  },
  subHeaderBackground: {
    position: 'absolute',

    height: 20,
    width: 520,
    padding: 3,

    backgroundColor: 'grey',
    opacity: 0.3,
  },
  // PRODUCT TABLE
  overviewSummaryBold: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginLeft: 28,
  },
  tableCell: {
    ...tableCellStep,
    height: 26,
    textAlign: 'center',
  },
  tableCellStep1: {
    ...tableCellStep,
    height: 30,
    textAlign: 'center',
  },
  tableCellStep2: {
    ...tableCellStep,
    height: 40,
    textAlign: 'center',
  },
  tableCellStep3: {
    ...tableCellStep,
    height: 50,
    textAlign: 'center',
  },
  tableCellStep4: {
    ...tableCellStep,
    height: 60,
    textAlign: 'center',
  },
  tableCellStep5: {
    ...tableCellStep,
    height: 70,
    textAlign: 'center',
  },
  tableCellStep6: {
    ...tableCellStep,
    height: 80,
    textAlign: 'center',
  },
  tableCellStep7: {
    ...tableCellStep,
    height: 90,
    textAlign: 'center',
  },
  tableCellStep8: {
    ...tableCellStep,
    height: 100,
    textAlign: 'center',
  },
  tableCellStep9: {
    ...tableCellStep,
    height: 110,
    textAlign: 'center',
  },
  tableCellStep10: {
    ...tableCellStep,
    height: 120,
    textAlign: 'center',
  },
  tableCellStep11: {
    ...tableCellStep,
    height: 130,
    textAlign: 'center',
  },
  tableCellStep12: {
    ...tableCellStep,
    height: 140,
    textAlign: 'center',
  },
  tableCellStep13: {
    ...tableCellStep,
    height: 150,
    textAlign: 'center',
  },
  tableCellStep14: {
    ...tableCellStep,
    height: 160,
    textAlign: 'center',
  },
  tableCellStep15: {
    ...tableCellStep,
    height: 170,
    textAlign: 'center',
  },
  tableCellStep16: {
    ...tableCellStep,
    height: 180,
    textAlign: 'center',
  },
  tableCellStep17: {
    ...tableCellStep,
    height: 190,
    textAlign: 'center',
  },
  tableCellStep18: {
    ...tableCellStep,
    height: 200,
    textAlign: 'center',
  },
  tableCellStep19: {
    ...tableCellStep,
    height: 210,
    textAlign: 'center',
  },
  tableCellStep20: {
    ...tableCellStep,
    height: 220,
    textAlign: 'center',
  },
  tableCellStep21: {
    ...tableCellStep,
    height: 230,
    textAlign: 'center',
  },
  tableCellStep22: {
    ...tableCellStep,
    height: 240,
    textAlign: 'center',
  },
  tableCellStep23: {
    ...tableCellStep,
    height: 250,
    textAlign: 'center',
  },
  tableCellStep24: {
    ...tableCellStep,
    height: 260,
    textAlign: 'center',
  },
  tableCellStep25: {
    ...tableCellStep,
    height: 270,
    textAlign: 'center',
  },
  tableCellStep26: {
    ...tableCellStep,
    height: 280,
    textAlign: 'center',
  },
  tableCellStep27: {
    ...tableCellStep,
    height: 290,
    textAlign: 'center',
  },
  tableCellStep28: {
    ...tableCellStep,
    height: 300,
    textAlign: 'center',
  },
  tableCellStep29: {
    ...tableCellStep,
    height: 310,
    textAlign: 'center',
  },
  tableCellStep30: {
    ...tableCellStep,
    height: 320,
    textAlign: 'center',
  },
  tableCellStep31: {
    ...tableCellStep,
    height: 330,
    textAlign: 'center',
  },
  tableCellStep32: {
    ...tableCellStep,
    height: 340,
    textAlign: 'center',
  },
  tableCellStep33: {
    ...tableCellStep,
    height: 350,
    textAlign: 'center',
  },
  tableCellStep34: {
    ...tableCellStep,
    height: 360,
    textAlign: 'center',
  },
  tableCellStep35: {
    ...tableCellStep,
    height: 370,
    textAlign: 'center',
  },
  tableCellStep36: {
    ...tableCellStep,
    height: 380,
    textAlign: 'center',
  },
  tableCellStep37: {
    ...tableCellStep,
    height: 390,
    textAlign: 'center',
  },
  tableCellStep38: {
    ...tableCellStep,
    height: 400,
    textAlign: 'center',
  },
  tableCellStep39: {
    ...tableCellStep,
    height: 410,
    textAlign: 'center',
  },
  tableCellStep40: {
    ...tableCellStep,
    height: 420,
    textAlign: 'center',
  },
  tableCellStep41: {
    ...tableCellStep,
    height: 430,
    textAlign: 'center',
  },
  tableCellStep42: {
    ...tableCellStep,
    height: 440,
    textAlign: 'center',
  },
  tableCellStep43: {
    ...tableCellStep,
    height: 450,
    textAlign: 'center',
  },
  tableCellStep44: {
    ...tableCellStep,
    height: 460,
    textAlign: 'center',
  },
  tableCellStep45: {
    ...tableCellStep,
    height: 470,
    textAlign: 'center',
  },
  tableCellStep46: {
    ...tableCellStep,
    height: 480,
    textAlign: 'center',
  },
  tableCellStep47: {
    ...tableCellStep,
    height: 490,
    textAlign: 'center',
  },
  tableCellStep48: {
    ...tableCellStep,
    height: 500,
    textAlign: 'center',
  },
  tableCellStep49: {
    ...tableCellStep,
    height: 510,
    textAlign: 'center',
  },
  tableCellStep50: {
    ...tableCellStep,
    height: 520,
    textAlign: 'center',
  },
  tableCellStep51: {
    ...tableCellStep,
    height: 530,
    textAlign: 'center',
  },
  tableCellStep52: {
    ...tableCellStep,
    height: 540,
    textAlign: 'center',
  },
  tableCellStep53: {
    ...tableCellStep,
    height: 550,
    textAlign: 'center',
  },
  tableCellStep54: {
    ...tableCellStep,
    height: 560,
    textAlign: 'center',
  },
  tableCellStep55: {
    ...tableCellStep,
    height: 570,
    textAlign: 'center',
  },
  tableCellStep56: {
    ...tableCellStep,
    height: 580,
    textAlign: 'center',
  },
  tableCellStep57: {
    ...tableCellStep,
    height: 590,
    textAlign: 'center',
  },
  tableCellStep58: {
    ...tableCellStep,
    height: 600,
    textAlign: 'center',
  },
  tableCellStep59: {
    ...tableCellStep,
    height: 610,
    textAlign: 'center',
  },
  tableCellStep60: {
    ...tableCellStep,
    height: 620,
    textAlign: 'center',
  },

  productCell: {
    ...productCell,
    height: 26,
    width: 230,
    textAlign: 'justify',
  },
  offerCodeCell: {
    ...productCell,
    height: 26,
    width: 280,
    textAlign: 'justify',
  },
  productCellSubtext: {
    marginLeft: '10',
    textOverflow: 'ellipsis',
  },
  productCellStep1: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 30,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep2: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 40,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep3: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 50,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep4: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 60,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep5: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 70,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep6: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 80,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep7: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 90,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep8: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 100,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep9: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 110,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep10: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 120,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep11: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 130,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep12: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 140,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep13: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 150,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep14: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 160,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep15: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 170,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep16: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 180,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep17: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 190,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep18: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 200,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep19: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 210,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep20: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 220,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep21: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 230,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep22: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 240,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep23: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 250,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep24: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 260,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep25: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 270,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep26: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 280,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep27: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 290,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep28: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 300,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep29: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 310,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep30: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 320,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep31: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 330,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep32: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 340,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep33: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 350,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep34: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 360,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep35: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 370,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep36: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 380,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep37: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 390,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep38: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 400,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep39: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 410,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep40: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 420,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep41: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 430,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep42: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 440,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep43: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 450,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep44: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 460,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep45: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 470,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep46: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 480,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep47: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 490,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep48: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 500,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep49: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 510,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep50: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 520,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep51: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 530,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep52: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 540,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep53: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 550,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep54: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 560,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep55: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 570,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep56: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 580,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep57: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 590,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep58: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 600,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep59: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 610,
    width: 230,
    textAlign: 'justify',
  },
  productCellStep60: {
    ...productCell,
    display: 'flex',
    flexDirection: 'column',

    height: 620,
    width: 230,
    textAlign: 'justify',
  },

  productCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 230,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  profileCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 230,
    padding: 3,

    borderTop: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 1,
    borderBottomColor: 'gray',
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  offerCodeCellHeader: {
    display: 'flex',
    flexDirection: 'row',

    height: 26,
    width: 280,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    textAlign: 'justify',
  },
  productTerm: {
    height: 16,
    fontSize: 9,
    textAlign: 'justify',
    marginLeft: 4,
    marginRight: 4,
  },
  eipProductTerm: {
    height: 16,
    fontSize: 9,
    textAlign: 'justify',
    paddingLeft: 8,
    paddingRight: 8,
  },
  productLabel: {
    height: 16,
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'justify',
  },
  tableCellHeader: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  profilePriceCell: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 1,
    borderBottomColor: 'gray',

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  productWrapper: {
    marginLeft: 24,
    marginTop: 10,
  },
  productRow: {
    flexDirection: 'row',
  },
  productStepRow: {
    flexDirection: 'row',
    marginLeft: 24,
  },
  promoText: {
    marginBottom: 1,
    marginTop: 10,
    fontSize: 10,
    marginLeft: 30,
  },
  // TOTALS TABLE
  totalWrapper: {
    flexDirection: 'row',
    marginLeft: 201,
    marginTop: 12,
  },
  totalTextWrapper: {
    marginRight: 12,

    fontFamily: 'Helvetica-Bold',
    fontSize: 13,
  },
  totalCellHeaderFirst: {
    height: 26,
    width: 65,
    padding: 3,

    borderTop: 1,
    borderTopColor: 'gray',
    borderTopWidth: 1,
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,
    borderLeft: 1,
    borderLeftColor: 'gray',

    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    fontSize: 9,
  },
  totalCellHeader: {
    height: 26,
    width: 65,
    padding: 3,
    borderTop: 1,
    borderTopColor: 'gray',
    borderRight: 1,
    borderRightColor: 'gray',
    borderBottom: 2,

    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    textAlign: 'center',
  },
  totalTableCellFirst: {
    ...productCell,
    height: 26,
    width: 65,
    textAlign: 'center',
  },
  totalTableCell: {
    ...tableCellStep,
    height: 26,
    textAlign: 'center',
  },
  // DISCLAIMERS
  disclaimerWrapper: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: 10,
    width: 500,
  },
  disclaimer: {
    marginBottom: 8,
    fontSize: 10,
    marginLeft: 30,
  },
  // FOOTER
  footerWrapper: {
    position: 'absolute',
    bottom: 40,
    left: 30,
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  footerText: {
    opacity: 0.4,
    fontSize: 10,
    width: 230,
  },
  footerTextUpper: {
    width: 500,
    marginBottom: 10,
    fontSize: 10,
  },
  // WATERMARK
  waterMark: {
    position: 'absolute',
    left: 80,
    top: 320,

    opacity: 0.04,
    fontFamily: 'Helvetica-Bold',

    transform: 'rotate(-46deg)',
  },
  waterMarkText1: {
    marginTop: 190,

    left: 240,
    fontSize: 60,
  },
  waterMarkText2: {
    marginTop: 35,
    left: 230,
    fontSize: 60,
  },
  image: {
    position: 'absolute',
    left: 15,
    top: 248,

    height: '150',
    width: '500',

    transform: 'rotate(-45deg)',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
});
