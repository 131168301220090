// Packages
import React, { useCallback, useState } from 'react';

// Redux

// Components
import { RCQHistoryDrawer, ProgressBar } from 'components';
import { KiteButton } from '@kite/react-kite';

// Hooks
import {
  // useGetEstimateDetails,
  // useGetFields,
  useGetLocationById,
  // useGetSubmissions,
  RCuseLocationCompletePercent,
  // useRcqApproval,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
// import { RCQFORMS } from 'utils';

// Types

// Styles
import './RcReviewLocationHeader.scss';

export interface IRcReviewLocationHeaderProps {
  opptyName: string;
  locationName: string;
  hasBadges?: boolean;
  hasHistory?: boolean;
  hasProgress?: boolean;
}

/** Location header on the read only page  */

const RcReviewLocationHeader = ({
  opptyName,
  locationName,
  hasBadges,
  hasHistory,
  hasProgress,
}: IRcReviewLocationHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId = '' } = useParams();

  // const { estimateData } = useGetEstimateDetails(estimateId);

  const { data: locationData } = useGetLocationById(locationId);

  const { completePercent } = RCuseLocationCompletePercent({
    submissions: locationData?.submissions || [],
    locationId,
  });

  // Need consistent allSubmissions to handle reminders badge logic for SME
  // const { data: allSubmissions } = useGetSubmissions({
  //   params: { estimateId },
  // });

  // const { data: allFields } = useGetFields({ params: { type: RCQFORMS } });

  // const { requiresSmeApproval, smeInputAnswers } = useRcqApproval({
  //   submissions: allSubmissions,
  //   fields: allFields,
  // });

  const [historyDrawerIsOpen, setHistoryDrawerIsOpen] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleHistoryDrawerIsOpen = useCallback(() => {
    setHistoryDrawerIsOpen(!historyDrawerIsOpen);
  }, [historyDrawerIsOpen]);
  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="rc-review-location-header">
      <div className="rc-review-location-header__top">
        <p className="rc-review-location-header__top__oppty-name">
          {opptyName}
        </p>
        {hasHistory && (
          <KiteButton onClick={toggleHistoryDrawerIsOpen} type="outline">
            History
          </KiteButton>
        )}
      </div>
      <div className="rc-review-location-header__lower">
        <h1 className="rc-review-location-header__lower__title">
          {locationName}
        </h1>
        {hasProgress && (
          // <div style={{ paddingLeft: '455px' }}>
          <ProgressBar
            percentage={completePercent || 0}
            title={'RC Question Progress'}
          />
          // </div>
        )}
        {/* {hasBadges && (
          <RCQReminders
            requiresSmeApproval={requiresSmeApproval}
            smeInputAnswers={smeInputAnswers}
            showHeader={false}
            estimate={estimateData}
          />
        )} */}
      </div>
      {historyDrawerIsOpen && (
        <RCQHistoryDrawer
          isOpen={historyDrawerIsOpen}
          onClose={toggleHistoryDrawerIsOpen}
        />
      )}
    </div>
  );
};

export default RcReviewLocationHeader;
