import { TProductList } from 'types';

export type ICustomProductConfigEntry = {
  rule: 'location' | 'scenario' | 'scenarioExclusive';
  dependentOption: {
    [DependentFamily: string]: {
      selectedProductName: string[];
      productFamilyName: string;
      inverse?: boolean;
    }[][];
  }[];
  in?: string[];
};

export type ICustomProductConfig = {
  [Property in TProductList]:
    | ICustomProductConfigEntry
    | ICustomProductConfigEntry[];
};

export const disabledProductConfig: Partial<ICustomProductConfig> = {
  'RC Seats': {
    rule: 'scenario',
    dependentOption: [
      {
        Ultra: [
          [
            {
              selectedProductName: ['Advanced'],
              productFamilyName: 'RC Seats',
            },
          ],
        ],
        Advanced: [
          [
            {
              selectedProductName: ['Ultra'],
              productFamilyName: 'RC Seats',
            },
          ],
        ],
      },
    ],
  },
  'RC Add Ons': [
    {
      rule: 'scenarioExclusive',
      dependentOption: [
        {
          'Business Analytics Pro (12 Months)': [
            [
              {
                selectedProductName: [
                  'Business Analytics Pro (24 Months)',
                  'Business Analytics Pro (36 Months)',
                ],
                productFamilyName: 'RC Add Ons',
              },
            ],
          ],
          'Business Analytics Pro (24 Months)': [
            [
              {
                selectedProductName: [
                  'Business Analytics Pro (12 Months)',
                  'Business Analytics Pro (36 Months)',
                ],
                productFamilyName: 'RC Add Ons',
              },
            ],
          ],
          'Business Analytics Pro (36 Months)': [
            [
              {
                selectedProductName: [
                  'Business Analytics Pro (12 Months)',
                  'Business Analytics Pro (24 Months)',
                ],
                productFamilyName: 'RC Add Ons',
              },
            ],
          ],
        },
      ],
    },
    {
      rule: 'scenario',
      dependentOption: [
        {
          'Business Analytics Pro (12 Months)': [
            [
              {
                selectedProductName: ['Ultra'],
                productFamilyName: 'RC Seats',
              },
            ],
          ],
        },
      ],
    },
  ],
  'RC Additional NRC': {
    rule: 'location',
    dependentOption: [
      {
        'On Site Professional Install': [
          [
            {
              selectedProductName: [],
              productFamilyName: 'ENE Switches',
              inverse: true,
            },
            {
              selectedProductName: [],
              productFamilyName: 'RC Phones / Devices',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE Switches',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE Cameras',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE WiFi',
              inverse: true,
            },
          ],
          [],
          [
            {
              selectedProductName: [],
              productFamilyName: 'RC Seats',
              inverse: true,
            },
          ],
        ],
        'Inside Wiring': [
          [
            {
              selectedProductName: [],
              productFamilyName: 'MNE Switches',
              inverse: true,
            },
          ],
          [
            {
              selectedProductName: [],
              productFamilyName: 'ENE Switches',
              inverse: true,
            },
          ],
        ],
        'UC w RC-Spectrum Enterprise Managed Porting - per 100 TNs': [
          [
            {
              selectedProductName: ['UC w RC-Client Managed Porting'],
              productFamilyName: 'RC Additional NRC',
            },
          ],
        ],
        'UC w RC-Client Managed Porting': [
          [
            {
              selectedProductName: [
                'UC w RC-Spectrum Enterprise Managed Porting - per 100 TNs',
              ],
              productFamilyName: 'RC Additional NRC',
            },
          ],
        ],
      },
    ],
  },
  'Professional Services': {
    rule: 'location',
    dependentOption: [
      {
        'Inside Wiring Service (In Footprint Option)': [
          [
            {
              selectedProductName: [
                'Inside Wiring Service (Out of Footprint Option)',
              ],
              productFamilyName: 'Professional Services',
            },
          ],
        ],
        'Inside Wiring Service (Out of Footprint Option)': [
          [
            {
              selectedProductName: [
                'Inside Wiring Service (In Footprint Option)',
              ],
              productFamilyName: 'Professional Services',
            },
          ],
        ],
      },
    ],
  },
  'RC Phones / Devices': {
    rule: 'scenarioExclusive',
    dependentOption: [
      {
        'Algo 8301 Paging Adapter w REMOTE Install': [
          [
            {
              selectedProductName: [
                'Algo 8301 Paging Adapter w ON SITE Install',
              ],
              productFamilyName: 'RC Phones / Devices',
            },
          ],
        ],
        'Algo 8301 Paging Adapter w ON SITE Install': [
          [
            {
              selectedProductName: [
                'Algo 8301 Paging Adapter w REMOTE Install',
              ],
              productFamilyName: 'RC Phones / Devices',
            },
          ],
        ],
      },
    ],
  },
};
