// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteSelect } from '@kite/react-kite';

import MNERepeatButton from '../MNERepeatButton';

// Hooks

// Utils
import { isValidJsonString } from 'utils';

// Types
import { IFieldInput, IMNEInputProps } from 'types';

// Styles
import '../MNEFieldStyles.scss';

type TMultipleSelection = string;

interface IMultipleSelectionInputProps {
  ms: TMultipleSelection;
  index: number;
  repeatable: boolean;
  isLast: boolean;
  isOnly: boolean;
  onRepeat: () => void;
  onDelete: (index: number) => () => void;
  onChange: (ms: TMultipleSelection, index: number) => void;
  fieldInput: IFieldInput;
}

// CHILD FOR `MNEMultipleSelection` BELOW (renders multiple selections)
const MultipleSelectionInput = ({
  ms,
  onDelete,
  onRepeat,
  isLast,
  index,
  repeatable,
  onChange,
  isOnly,
  fieldInput,
}: IMultipleSelectionInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { options, id, inputName } = fieldInput;

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;

      onChange(value, index);
    },
    [index, onChange]
  );

  const selectOptions = useMemo(() => {
    return (
      options?.map((o: any) => (
        <option key={o} value={o}>
          {o}
        </option>
      )) || []
    );
  }, [options]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-field__multiple_selection-container">
      <KiteSelect
        id={id}
        name={inputName}
        value={ms}
        onChange={(e) => {
          handleChange(e);
        }}
        maxWidth="560px"
        placeholder={'Make Selection'}
      >
        {selectOptions}
      </KiteSelect>

      {repeatable && (
        <MNERepeatButton
          text="Add Edge"
          onRepeat={onRepeat}
          onDelete={onDelete(index)}
          isLast={isLast}
          isOnly={isOnly}
          isAllFieldsAdded={ms !== '' ? true : false}
        />
      )}
    </div>
  );
};

/** Custom input for 'select-add' inputType with repeatable inputs on MNE Field */
const MNEMultipleSelection = ({
  fieldInput,
  onFieldChange,
}: IMNEInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { value, id, repeatable = true } = fieldInput;

  const blankMS: TMultipleSelection = useMemo(() => '', []);
  const defaultValue: TMultipleSelection[] = useMemo(() => {
    if (value && value !== 'No') {
      const msValues: TMultipleSelection[] = isValidJsonString(value)
        ? JSON.parse(value)
        : value;
      return msValues;
    }
    return [blankMS];
  }, [value, blankMS]);

  const [multipleSelections, setMultipleSelections] = useState(defaultValue);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const getCompletedMss = useCallback(
    (mss: TMultipleSelection[]) => mss.filter((ms) => ms !== ''),
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  const onChange = useCallback(
    (ms: TMultipleSelection, index: number) => {
      const msCopy = Array.from(multipleSelections);
      msCopy.splice(index, 1, ms);
      setMultipleSelections(msCopy);
      // Check currently changing ms to see if both fields have value
      const completedMss = getCompletedMss([ms]);
      if (completedMss.length) {
        onFieldChange(id, JSON.stringify(msCopy));
      }
    },
    [getCompletedMss, id, onFieldChange, multipleSelections]
  );

  const onRepeat = useCallback(() => {
    const newMSs = [...multipleSelections, blankMS];

    setMultipleSelections(newMSs);
  }, [blankMS, multipleSelections]);

  const onDelete = useCallback(
    (index: number) => () => {
      const msCopy = Array.from(multipleSelections);
      msCopy.splice(index, 1);
      setMultipleSelections(msCopy);
      const completedMss = getCompletedMss(msCopy);
      onFieldChange(id, completedMss.length ? JSON.stringify(msCopy) : null);
    },
    [getCompletedMss, id, onFieldChange, multipleSelections]
  );

  // =============================================
  // Render Methods
  // =============================================
  const renderMSs = useCallback(() => {
    return multipleSelections.map((ms, i) => {
      return (
        <MultipleSelectionInput
          key={id}
          ms={ms}
          index={i}
          onDelete={onDelete}
          onRepeat={onRepeat}
          isLast={i === multipleSelections.length - 1}
          repeatable={repeatable}
          onChange={onChange}
          isOnly={multipleSelections.length === 1}
          fieldInput={fieldInput}
        />
      );
    });
  }, [
    multipleSelections,
    onDelete,
    onRepeat,
    repeatable,
    id,
    fieldInput,
    onChange,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setMultipleSelections(defaultValue);
  }, [defaultValue]);

  // =============================================
  // Return
  // =============================================
  return <div>{renderMSs()}</div>;
};

export default MNEMultipleSelection;
