import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';

// Components
import { UCProductCard, UcProfileForm } from 'components';
import { KiteAlert, KiteButton, KiteModal } from '@kite/react-kite';

// Hooks
import {
  useAnalytics,
  useDeleteProfiles,
  usePostProfile,
  useSelections,
  useUpdateProfiles,
  useQueryDataContext,
  useScrollToTop,
} from 'hooks';

// Utils
import {
  checkFormErrors,
  originalProfileProductData,
  originalProfileInfoData,
  NONE,
  installProductId,
} from 'utils';

// Types
import {
  TProfileData,
  TProfileProductData,
  IProfile,
  ILocationSelection,
} from 'types';

//React Router
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Styles
import './UcProfilesPage.scss';

const UcProfilesPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackPageView } = useAnalytics();
  const {
    allProfiles,
    allProfileCategories,
    currentScenario,
    currentSelections,
    locationId,
    scenarioId,
    estimateScenarios,
  } = useQueryDataContext();
  const ref = useRef<HTMLDivElement>(null);
  useScrollToTop({ ref, refNestLevel: 1 });
  const { estimateId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { deleteProfiles } = useDeleteProfiles();

  const { addProductSelections, removeSelections } = useSelections();

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [profileData, setProfileData] = useState<TProfileData>(
    originalProfileInfoData
  );
  const [productData, setProductData] = useState<TProfileProductData>(
    originalProfileProductData
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formErrors, setFormErrors] = useState(originalProfileInfoData);
  const [globalError, setGlobalError] = useState('');

  const hasNoSelections = useMemo(() => {
    return Object.values(productData).every((p) => !p || p === NONE);
  }, [productData]);

  const handleFormError = useCallback(() => {
    const { hasErrors: isError, errorObject } = checkFormErrors(
      profileData,
      ['profileName', 'quantity'],
      { quantity: 'number' }
    );
    if (isError) {
      setFormErrors({ ...formErrors, ...errorObject });
    }
    if (hasNoSelections) {
      setGlobalError('Must select at least one product to create a profile.');
    }
    return isError || hasNoSelections;
  }, [formErrors, hasNoSelections, profileData]);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const handleToggle = useMemo(() => {
    return estimateScenarios.some((scenario) => {
      return scenario.selections.some(
        (selection) => selection.familyCategory === 'RingCentral Products'
      );
    });
  }, [estimateScenarios]);

  const productProfiles = useMemo(() => {
    return currentSelections.reduce(
      (acc: { [profileId: string]: ILocationSelection[] }, selection) => {
        const { profileId } = selection;

        if (!profileId) return acc;

        if (!acc[profileId]) {
          acc[profileId] = [];
        }

        acc[profileId].push(selection);
        return acc;
      },
      {}
    );
  }, [currentSelections]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onProductDataChange = useCallback(
    (productValues: Partial<TProfileProductData>) => {
      setProductData({ ...productData, ...productValues });
    },
    [productData]
  );

  const onProfileDataChange = useCallback(
    (profileValues?: Partial<TProfileData>) => {
      if (profileValues) {
        setProfileData({ ...profileData, ...profileValues });
      } else {
        setProfileData(originalProfileInfoData);
      }
    },
    [profileData]
  );

  const handleFormDataChange = useCallback(
    (type: 'profile' | 'product') =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (type === 'profile') {
          onProfileDataChange({ [name]: value });
        }

        if (type === 'product') {
          onProductDataChange({ [name]: value });
        }

        setFormErrors({ ...formErrors, [name]: '' });
      },
    [formErrors, onProductDataChange, onProfileDataChange]
  );

  const resetFormData = useCallback(() => {
    setProductData(originalProfileProductData);
    setProfileData(originalProfileInfoData);
    setFormErrors({
      ...originalProfileInfoData,
      ...originalProfileProductData,
    });
  }, []);

  const toggleEditModal = useCallback(() => {
    if (profileModalOpen) {
      resetFormData();
    }
    setProfileModalOpen(!profileModalOpen);
  }, [profileModalOpen, resetFormData]);

  const handleDeleteProfile = useCallback(() => {
    const { id: profileId } = profileData;

    if (profileId) {
      deleteProfiles([profileId]);

      // Must remove all scenario selections that match profile id in case selections were imported from another location
      const selectionsToRemove =
        currentScenario?.selections.filter((s) => s.profileId === profileId) ||
        [];

      if (selectionsToRemove.length) {
        removeSelections(selectionsToRemove);
      }
    }

    setShowDeleteModal(false);
    resetFormData();
  }, [
    profileData,
    resetFormData,
    deleteProfiles,
    currentScenario,
    removeSelections,
  ]);

  const handleCancelDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onEditProfile = useCallback(
    (profile: IProfile) => {
      const { id, name: profileName, quantity: numQuantity } = profile;
      const quantity = numQuantity?.toString() || '1';
      const profileSelections = currentSelections.filter(
        (s) => s.profileId === id
      );

      setProfileData({
        id,
        profileName,
        quantity,
      });

      const formValues = profileSelections.reduce(
        (acc: Partial<TProfileProductData>, selection) => {
          const category = allProfileCategories.find(
            (pfc) => pfc.id === selection.profileCategoryId
          );

          if (!category?.name) return acc;
          acc[category.name as keyof TProfileProductData] = selection.productId;
          return acc;
        },
        {}
      );

      setProductData({ ...productData, ...formValues });
      toggleEditModal();
    },
    [currentSelections, productData, toggleEditModal, allProfileCategories]
  );

  const onDeleteProfile = useCallback((profile: IProfile) => {
    const { id, name: profileName, quantity: numQuantity } = profile;
    const quantity = numQuantity?.toString() || '1';

    setProfileData({
      id,
      profileName,
      quantity,
    });
    setShowDeleteModal(true);
  }, []);

  const handleUpdateProducts = useCallback(
    (profileId: string) => {
      const quantity = parseInt(profileData.quantity);

      const selectedProducts = Object.entries(productData).reduce(
        (
          acc: {
            productId: string;
            quantity: number;
            profileId?: string;
            profileCategoryId?: string;
          }[],
          [profileCategoryName, productId]
        ) => {
          if (productId) {
            const profileCategoryId = allProfileCategories.find(
              (pfc) => pfc.name === profileCategoryName
            )?.id;
            acc.push({
              productId,
              quantity,
              profileId,
              profileCategoryId,
            });
          }
          return acc;
        },
        []
      );

      // Add an installation charge product if there isn't already one
      const installProduct = currentSelections.find(
        (selection) => selection.productId === installProductId
      );

      if (!installProduct) {
        selectedProducts.push({
          productId: installProductId,
          quantity: 1,
        });
      }

      addProductSelections(selectedProducts);
      resetFormData();
    },
    [
      profileData.quantity,
      productData,
      allProfileCategories,
      currentSelections,
      addProductSelections,
      resetFormData,
    ]
  );

  // Have to chain product update into onSuccess b/c it might finish before profile is created & foreign key error
  const { updateProfiles } = useUpdateProfiles({
    onSuccess: (profiles: IProfile[]) =>
      profiles.forEach((p) => handleUpdateProducts(p.id)),
  });

  const { postProfile } = usePostProfile({
    onSuccess: handleUpdateProducts,
  });

  const onSaveProfile = useCallback(() => {
    const { id, profileName: name, quantity: numQuantity } = profileData;
    const quantity = parseInt(numQuantity);
    const profileId = id || v4();

    if (id) {
      updateProfiles([
        {
          id,
          name,
          quantity,
          locationId,
          scenarioId,
        },
      ]);
    } else {
      postProfile({ id: profileId, name, quantity, locationId, scenarioId });
    }
    toggleEditModal();
  }, [
    profileData,
    locationId,
    scenarioId,
    toggleEditModal,
    updateProfiles,
    postProfile,
  ]);

  const handleSubmitProfile = useCallback(() => {
    if (!handleFormError()) {
      onSaveProfile();
    }
  }, [handleFormError, onSaveProfile]);

  // =============================================
  // Render Methods
  // =============================================
  const renderProfileCards = useCallback(() => {
    const profilesToRender = Object.entries(productProfiles).reduce(
      (
        acc: { profile: IProfile; selections: ILocationSelection[] }[],
        [profileId, selections]
      ) => {
        const profile = allProfiles.find((p) => p.id === profileId);
        if (profile) {
          acc.push({ profile, selections });
        }
        return acc;
      },
      []
    );

    return profilesToRender.map(({ profile, selections }) => {
      return (
        <UCProductCard
          key={profile.id}
          profile={profile}
          selections={selections}
          onEditProfile={onEditProfile}
          onDeleteProfile={onDeleteProfile}
        />
      );
    });
  }, [productProfiles, allProfiles, onEditProfile, onDeleteProfile]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UcProfilesPage'), [trackPageView]);

  useEffect(() => {
    if (handleToggle) {
      navigate(`/estimate-builder/${estimateId}/rc-addons`, { replace: true });
    }
  }, [navigate, handleToggle, location.key, estimateId]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div ref={ref} className="uc-profiles-page">
      <h3>UC Profiles Configuration Options</h3>
      <KiteButton onClick={toggleEditModal}>Add Profile</KiteButton>

      {renderProfileCards()}

      <KiteModal
        title="Delete Profile?"
        modalId="delete-uc-profile"
        canShow={showDeleteModal}
        ctaCopy="Delete Profile"
        ctaAction={handleDeleteProfile}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={handleCancelDelete}
        onHide={handleCancelDelete}
      >
        <span>
          You will lose all configurations for the {profileData.profileName}{' '}
          Profile.
        </span>
      </KiteModal>

      <KiteModal
        title={profileData.id ? 'Edit Profile' : 'Add Profile'}
        modalId="add-uc-profile"
        canShow={profileModalOpen}
        onHide={toggleEditModal}
        ctaCopy={profileData.id ? 'Update Profile' : 'Add Profile'}
        ctaAction={handleSubmitProfile}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={toggleEditModal}
      >
        <UcProfileForm
          formData={productData}
          profileData={profileData}
          formErrors={formErrors}
          onChange={handleFormDataChange}
        />
        {globalError && <KiteAlert description={globalError} type="alert" />}
      </KiteModal>
    </div>
  );
};

export default UcProfilesPage;
