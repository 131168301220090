// Packages
import React, { useCallback, useMemo, MutableRefObject, useRef } from 'react';

// Redux

// Components
import {
  RcReviewLocationHeader,
  UcReviewEstimateProductsTab,
} from 'components';
import { RCQFORMS } from 'utils';
// Hooks
import { useParams } from 'react-router-dom';
import {
  RCuseGetForms,
  useFormRules,
  useQueryData,
  useRenderReviewRcFields,
  useScrollToTopRC,
} from 'hooks';

// Utils

// Types
import {
  IField,
  RForm,
  ISubmission,
  IDistro,
  ILocation,
  IFieldValues,
} from 'types';

// Styles
import './UcReviewRcQuestions.scss';
import FieldValidationHeader from 'components/FieldValidationHeader';
import ResponsibilityMatrixPage1 from '../../../images/SE-UC-GD006_v3-Responsibility-Matrix-Guide_Page_1.png';
import ResponsibilityMatrixPage2 from '../../../images/SE-UC-GD006_v3-Responsibility-Matrix-Guide_Page_2.png';

export interface IUcReviewRcQuestionsProps {
  opptyName: string;
  location: ILocation;
  forms: RForm[];
  submissions: ISubmission[];
  distros: IDistro[];
  tabName?: string;
  refForPrint: MutableRefObject<HTMLDivElement | null>;
}

/** Displays submission data on the UcReviewPage */

const UcReviewRcQuestions = ({
  opptyName,
  location,
  forms,
  submissions,
  distros,
  refForPrint,
  tabName,
}: IUcReviewRcQuestionsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId } = useParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useScrollToTopRC({ ref: scrollRef, refNestLevel: 0, locationId });

  const fields = useMemo(
    () =>
      forms.reduce((acc: IField[], form) => {
        acc.push(...form.fields);
        return acc;
      }, []),
    [forms]
  );

  const { currentEstimate } = useQueryData();

  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  const fieldValues: IFieldValues = useMemo(() => {
    return fields.reduce((acc: IFieldValues, field) => {
      acc[field.id] =
        submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
      return acc;
    }, {});
  }, [fields, submissions]);

  const { data: locationForms } = RCuseGetForms({
    params: { location: 1, type: RCQFORMS },
  });

  const estimateLocationIds = useMemo(
    () => currentEstimate?.locations.map((l) => l.id) || [],
    [currentEstimate]
  );

  const locationFields = useMemo(
    () =>
      locationForms?.map((locationForm) => locationForm.fields).flat() || [],
    [locationForms]
  );

  const locationFieldValues = useMemo(() => {
    return estimateLocationIds.reduce(
      (
        acc: { [locationId: string]: { [fieldId: string]: string | null } },
        locationId
      ) => {
        const fieldValues = locationFields.reduce(
          (acc: { [fieldId: string]: string | null }, field) => {
            const subMatch =
              submissions.find(
                (sub) =>
                  sub.locationId &&
                  sub.locationId === locationId &&
                  sub.fieldId === field.id
              )?.answer || null;
            acc[field.id] = subMatch;
            return acc;
          },
          {}
        );
        acc[locationId] = fieldValues;

        return acc;
      },
      {}
    );
  }, [estimateLocationIds, locationFields, submissions]);

  const { ruleMap, ruleKeys } = useFormRules({
    fieldValues,
    formRules,
    locationFieldValues,
  });
  const { locationRuleMap } = useFormRules({
    fieldValues,
    formRules,
    locationFieldValues,
  });

  const { renderForm, formatFields, renderGridFields } =
    useRenderReviewRcFields({
      location,
      ruleMap,
      ruleKeys,
      tabName,
      locationRuleMap,
    });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================


  // =======================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  // const renderDesignFlow = useCallback(
  //   (name: string, fieldId: string) => {
  //     return !distros.length ? null : (
  //       <div
  //         className="rc-review-submissions__form rc-review-submissions__form--design-flow"
  //         key={fieldId}
  //       >
  //         <FieldValidationHeader label={name} />
  //         {!!distros.length && (
  //           <div
  //             style={{
  //               height: networkDraftHeight,
  //             }}
  //           >
  //             <RCQDesignFlow
  //               distros={distros}
  //               draggable={false}
  //               isFitView
  //               actionsAreEnabled={false}
  //             />
  //           </div>
  //         )}
  //         <DesignFlowNotes isOpen />
  //       </div>
  //     );
  //   },
  //   [distros, networkDraftHeight]
  // );
  // new addition
  const renderDiscoveryForms = useCallback(
    (params: {
      locationSpecificFields: IField[];
      generalDiscFields: IField[];
      formSubmissions: ISubmission[];
    }) => {
      const { formSubmissions, locationSpecificFields, generalDiscFields } =
        params;
      const renderLocationDiscFields = formatFields(
        locationSpecificFields,
        formSubmissions
      );
      const renderGeneralDiscFields = formatFields(
        generalDiscFields,
        formSubmissions
      );

      return (
        <div
          className="rc-review-submissions__form"
          key="Discovery Questions/Notes"
        >
          <FieldValidationHeader label="Discovery Questions/Notes" />
          {renderGeneralDiscFields.gridFields[0].elements.length > 0 && (
            <>
              <h3>General</h3>
              {renderGridFields(renderGeneralDiscFields.gridFields)}
              {renderGeneralDiscFields.fullWidthFields}
            </>
          )}
          {renderLocationDiscFields.gridFields[0].elements.length > 0 && (
            <>
              <h3>Location Specific</h3>
              {renderGridFields(renderLocationDiscFields.gridFields)}
              {renderLocationDiscFields.fullWidthFields}
            </>
          )}
        </div>
      );
    },
    [formatFields, renderGridFields]
  );

  const renderAllFormSubmissions = useMemo(() => {
    const formSubmissions = () => {
      const { id: generalDiscFormId = '', fields: generalDiscFields = [] } =
        forms.find((f) => f.name === 'General') ?? {};
      return forms.map(({ id, name, fields }) => {
        /* if (name === 'Network Draft') {
          return renderDesignFlow(name, id);
        } else 
        if (name === 'General') {
          // General and location specific discovery rendered under Discovery
          generalDiscFormId = id;
          generalDiscFields = fields;
          return '';
        } else */
        if (name === 'Discovery Questions/Notes') {
          const currentFormSubs = submissions?.filter(
            (sub) =>
              (sub.formId === id && locationId === sub.locationId) ||
              sub.formId === generalDiscFormId
          );
          if (currentFormSubs.length === 0)
            return renderForm(name, fields, currentFormSubs);
          return renderDiscoveryForms({
            locationSpecificFields: fields,
            generalDiscFields,
            formSubmissions: currentFormSubs,
          });
        } else {
          const currentFormSubs = submissions?.filter(
            (sub) => sub.formId === id && locationId === sub.locationId
          );
          if (currentFormSubs.length === 0) return '';
          return renderForm(name, fields, currentFormSubs);
        }
      });
    };
    return formSubmissions;
  }, [forms, submissions, renderDiscoveryForms, locationId, renderForm]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="rc-review-submissions"
      id="rc-review-submissions"
      ref={refForPrint}
    >
      <div className="rc-review-submissions__header-container">
        <RcReviewLocationHeader
          opptyName={opptyName}
          locationName={location.name}
          hasHistory
          hasProgress
        />
      </div>
      <div
        className="rc-review-submissions__estimate-review-contaier"
        ref={scrollRef}
      >
        {renderAllFormSubmissions()}
        {tabName === 'RC Review Page' ? (
          <UcReviewEstimateProductsTab
            refForPrint={refForPrint}
            tabName={tabName}
          />
        ) : (
          <></>
        )}
      </div>
      <div id="rcPdfImages">
        <img src={ResponsibilityMatrixPage1} className="rcImage" alt="" />
        <img src={ResponsibilityMatrixPage2} className="rcImage" alt="" />
      </div>
    </div>
  );
};

export default UcReviewRcQuestions;
