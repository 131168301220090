export type DeviceConfig = {
  [Property in string]: {
    power: number;
    ports: number;
  };
};

export const deviceConfig: Partial<DeviceConfig> = {
  //MNE Switches
  'Using MX68 for POE': {
    power: 60,
    ports: 2,
  },
  'MS120-8': {
    power: 0,
    ports: 8,
  },
  'MS120-8 POE': {
    power: 124,
    ports: 8,
  },
  'MS120-24 POE': {
    power: 370,
    ports: 24,
  },
  'MS125-48 POE': {
    power: 370,
    ports: 48,
  },
  'MS210-24-POE': {
    power: 370,
    ports: 24,
  },
  'MS210-48-POE': {
    power: 740,
    ports: 48,
  },
  'MS225-24-POE': {
    power: 370,
    ports: 24,
  },
  'MS225-48-POE': {
    power: 740,
    ports: 48,
  },
  'MS250-24-POE': {
    power: 370,
    ports: 24,
  },
  'MS250-48-POE': {
    power: 740,
    ports: 48,
  },
  'MS350-24X-POE': {
    power: 740,
    ports: 24,
  },
  'MS350-48-POE': {
    power: 740,
    ports: 48,
  },
  'MS355-24X': {
    power: 740,
    ports: 24,
  },
  'MS355-48X': {
    power: 740,
    ports: 48,
  },
  'MS410-16-AGG': {
    power: 0,
    ports: 16,
  },
  'MS425-16-AGG': {
    power: 0,
    ports: 16,
  },
  'MS425-32-AGG': {
    power: 0,
    ports: 32,
  },
  //MNE Access Points
  MR28: {
    power: 15,
    ports: 1,
  },
  MR36H: {
    power: 30,
    ports: 1,
  },
  MR36: {
    power: 15,
    ports: 1,
  },
  MR46: {
    power: 30,
    ports: 1,
  },
  'MR46E (DNQ +XA Reqd)': {
    power: 30,
    ports: 1,
  },
  MR57: {
    power: 40,
    ports: 1,
  },
  'MR76 (DNQ +XA Reqd)': {
    power: 15,
    ports: 1,
  },
  'MR86 (DNQ +XA Reqd)': {
    power: 30,
    ports: 1,
  },
  //MNE Cameras
  'MV2 (with PoE)': {
    power: 13,
    ports: 1,
  },
  MV12N: {
    power: 13,
    ports: 1,
  },
  MV12W: {
    power: 13,
    ports: 1,
  },
  MV32: {
    power: 13,
    ports: 1,
  },
  MV63: {
    power: 26,
    ports: 1,
  },
  MV93: {
    power: 26,
    ports: 1,
  },
  MV52: {
    power: 26,
    ports: 1,
  },
  MV72: {
    power: 26,
    ports: 1,
  },
  MV13M: {
    power: 12.95,
    ports: 1,
  },
  MV33M: {
    power: 12.95,
    ports: 1,
  },
  MV63X: {
    power: 25.5,
    ports: 1,
  },
  MV93X: {
    power: 25.5,
    ports: 1,
  },
  //MNE Phones, ENE Phones
  'Cisco MPP 6851': {
    power: 7,
    ports: 1,
  },
  'Cisco MPP 8811': {
    power: 7,
    ports: 1,
  },
  'Cisco MPP 8851': {
    power: 16,
    ports: 1,
  },
  'Cisco P7832': {
    power: 7,
    ports: 1,
  },
  'Cisco 6821': {
    power: 6.5,
    ports: 1,
  },
  'Cisco 7841': {
    power: 4,
    ports: 1,
  },
  'Cisco 8851': {
    power: 9.8,
    ports: 1,
  },
  'Cisco 8800 KEM': {
    power: 8.5,
    ports: 1,
  },
  'Cisco ATA 192': {
    power: 5,
    ports: 1,
  },
  'Poly Trio 8300': {
    power: 16,
    ports: 1,
  },
  'Poly Trio 8800': {
    power: 30,
    ports: 1,
  },
  'Poly VVX250': {
    power: 4.5,
    ports: 1,
  },
  'Poly VVX450': {
    power: 13,
    ports: 1,
  },
  'EM50 Exp. (for 450)': {
    power: 0,
    ports: 1,
  },
  'Poly Trio C60': {
    power: 25.5,
    ports: 1,
  },
  'Poly D230': {
    power: 6,
    ports: 1,
  },
  'Poly CCX500': {
    power: 13,
    ports: 1,
  },
  'Poly ROVE B1 or B2 DECT Base': {
    power: 6.5,
    ports: 1,
  },
  'SNOM M500 DECT Base': {
    power: 7,
    ports: 1,
  },
  'RC Poly Edge E220': {
    power: 11,
    ports: 1,
  },
  'RC Poly Edge E350': {
    power: 12,
    ports: 1,
  },
  'RC Poly Edge E450': {
    power: 17,
    ports: 1,
  },
  'RC Poly Edge E550': {
    power: 27,
    ports: 1,
  },
  'WBX Poly Edge E220': {
    power: 9,
    ports: 1,
  },
  'WBX Poly Edge E350': {
    power: 11,
    ports: 1,
  },
  'WBX Poly Edge E550': {
    power: 24,
    ports: 1,
  },
  'Algo 8301 Paging Adapter': {
    power: 4,
    ports: 1,
  },
  //ENE Switches
  FS108F: {
    power: 0,
    ports: 8,
  },
  'FS108F-FPOE': {
    power: 130,
    ports: 8,
  },
  FS124F: {
    power: 0,
    ports: 24,
  },
  'FS124F-FPOE': {
    power: 370,
    ports: 24,
  },
  FS148F: {
    power: 0,
    ports: 48,
  },
  'FS148F-FPOE': {
    power: 740,
    ports: 48,
  },
  FS424E: {
    power: 0,
    ports: 24,
  },
  'FS424E-FPOE': {
    power: 421,
    ports: 24,
  },
  FS448E: {
    power: 0,
    ports: 48,
  },
  'FS448E-FPOE': {
    power: 772,
    ports: 48,
  },
  'FS-1024E': {
    power: 0,
    ports: 24,
  },
  //ENE WIFI
  'FAP-231F Indoor': {
    power: 15.5,
    ports: 1,
  },
  'FAP-234F-Outdoor': {
    power: 15.5,
    ports: 1,
  },
  'FAP-431F-Indoor': {
    power: 37.9,
    ports: 1,
  },
  'FAP-432F-Outdoor': {
    power: 37.9,
    ports: 1,
  },
  'FAP-831F-Indoor': {
    power: 33,
    ports: 1,
  },
};
