import { ILocation } from 'types';

export const mockLocation: { [key: string]: ILocation } = {
  location1: {
    id: 'location1',
    isEzPass: false,
    createdAt: '2022-04-15T16:57:12.010Z',
    updatedAt: '2022-04-15T16:57:12.010Z',
    createdBy: 'P3059592',
    address1: '6380 S Fiddlers Green Circle',
    address2: '',
    city: 'Greenwood Village',
    state: 'CO',
    zipcode: '80111',
    estimateId: 'testOne',
    name: 'Mock Location',
    isAutobuild: true,
    sfLocationId: '',
    sfBillingSystem: '',
    sfServiceAreaNumber: '',
    phoneNumbers: [
      {
        bandwidth: 'YES',
        bandwidthTrunkOnly: 'YES',
        carrier: 'TWC',
        createdAt: '2022-10-18T14:21:53.563Z',
        footPrint: 'IN',
        id: '05f4273b-f8ca-4845-9f77-d636b806c071',
        locState: 'NY',
        locationId: '095fcd79-752c-472e-a5c5-c30b5307bd79',
        npa: '718',
        nxx: '702',
        rcAbbre: 'NWYRCYZN15',
        portInEnabled: undefined,
        updatedAt: '2022-10-24T22:49:19.313Z',
      },
      {
        bandwidth: '',
        bandwidthTrunkOnly: '',
        carrier: '',
        createdAt: '2022-10-18T14:21:53.563Z',
        footPrint: '',
        id: '05f4273b-f8ca-4845-9f77-d636b806c072',
        locState: '',
        locationId: '095fcd79-752c-472e-a5c5-c30b5307bd79',
        npa: '718',
        nxx: '702',
        rcAbbre: '',
        portInEnabled: 'Yes',
        updatedAt: '2022-10-24T22:49:19.313Z',
      },
    ],
  },
};
