// Packages
import React, { useCallback, useRef } from 'react';

// Redux

// Components
import { KiteIcon, KiteInput } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './CloneEstimateSuggestionInput.scss';
import { ISelectedEstimate } from 'types/utilTypes';

export interface ICloneEstimateSuggestionInputProps {
  searchValue: string;
  showSuggestions: boolean;
  toggleShowSuggestions: (canShow: boolean) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  suggestions: ISelectedEstimate[];
  className?: string;
  onSelect: (selectedEstimate: ISelectedEstimate) => void;
}

/** Renders an input that displays results from Active Directory search as list of dropdown options */

const CloneEstimateSuggestionInput = ({
  searchValue,
  onSearch,
  toggleShowSuggestions,
  showSuggestions,
  suggestions,
  className = '',
  onSelect,
}: ICloneEstimateSuggestionInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const arrowRef = useRef<HTMLButtonElement>(null);
  const firstSuggestion = useRef<HTMLLIElement | null>(null);
  const lastSuggestion = useRef<HTMLLIElement | null>(null);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const applySuggestionRefs = useCallback(
    (i: number) => (el: HTMLLIElement | null) => {
      if (!i) {
        firstSuggestion.current = el;
      }
      if (suggestions && i === suggestions.length - 1) {
        lastSuggestion.current = el;
      }
      return { firstSuggestion, lastSuggestion };
    },
    [suggestions]
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleArrowClick = useCallback(() => {
    toggleShowSuggestions(!showSuggestions);
  }, [toggleShowSuggestions, showSuggestions]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Enter':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowDown':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'Esc':
          e.preventDefault();
          e.stopPropagation();
          inputRef.current?.focus();
          toggleShowSuggestions(false);
          break;
        default:
          break;
      }
    },
    [firstSuggestion, toggleShowSuggestions]
  );

  const handleArrowKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case 'Enter':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowDown':
          toggleShowSuggestions(true);
          firstSuggestion.current?.focus();
          break;
        case 'ArrowUp':
          toggleShowSuggestions(false);
          break;
        default:
          break;
      }
    },
    [toggleShowSuggestions]
  );

  const handleSuggestionKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>, estimate: ISelectedEstimate) => {
      const { currentTarget } = e;
      const nextSuggestion = currentTarget.nextElementSibling as HTMLElement;
      const previousSuggestion =
        currentTarget.previousElementSibling as HTMLElement;

      const isFirst = currentTarget.id === String(0);
      const isLast =
        suggestions && currentTarget.id === String(suggestions.length - 1);
      const isOnly = isFirst && isLast;

      switch (e.key) {
        case 'Esc':
          e.preventDefault();
          e.stopPropagation();
          toggleShowSuggestions(false);
          inputRef.current?.focus();
          break;
        case 'Enter':
          e.preventDefault();
          onSelect(estimate);
          toggleShowSuggestions(false);
          inputRef.current?.focus();
          break;
        case 'Tab':
          toggleShowSuggestions(false);
          arrowRef.current?.focus();
          break;
        case 'ArrowDown':
          e.preventDefault();
          if (isOnly) {
            break;
          }

          if (isLast) {
            firstSuggestion.current?.focus();
            break;
          }

          nextSuggestion.focus();
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (isOnly) {
            break;
          }
          if (isFirst) {
            lastSuggestion.current?.focus();
            break;
          }

          previousSuggestion.focus();
          break;
        default:
          break;
      }
    },
    [
      firstSuggestion,
      lastSuggestion,
      onSelect,
      suggestions,
      toggleShowSuggestions,
    ]
  );

  // =============================================
  // Render Methods
  // =============================================

  const renderDropdownResults = useCallback(() => {
    if (searchValue.length < 1) {
      return (
        <p className="clone-estimate-suggestion-input__results-message">
          Enter at least one character to start search
        </p>
      );
    }

    if (!suggestions) {
      return (
        <p className="clone-estimate-suggestion-input__results-message">
          Searching target estimates...
        </p>
      );
    }

    return (
      <>
        <ul>
          {suggestions?.map((suggestion, i) => {
            return (
              <li
                key={suggestion.estimateId}
                id={String(i)}
                onClick={() => onSelect(suggestion)}
                onKeyDown={(e) => handleSuggestionKeyDown(e, suggestion)}
                tabIndex={0}
                ref={applySuggestionRefs(i)}
              >
                <div>
                  <p className="estimate-clone-suggestion-input__results-name">
                    {suggestion.estimateName}
                  </p>
                  {/*  */}
                </div>
              </li>
            );
          })}
        </ul>
        {suggestions && (
          <div className="active-dir-suggestion-input__results-total">
            {suggestions.length
              ? `${suggestions.length} Result${
                  suggestions.length > 1 ? 's' : ''
                }`
              : 'No Matches Found'}
          </div>
        )}
      </>
    );
  }, [
    searchValue,
    applySuggestionRefs,
    handleSuggestionKeyDown,
    onSelect,
    suggestions,
  ]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className={`clone-estimate-suggestion-input ${className}`}
      ref={rootRef}
    >
      <div className="clone-estimate-suggestion-input__input">
        <KiteInput
          value={searchValue}
          onChange={onSearch}
          onKeyDown={handleInputKeyDown}
          maxWidth="none"
          inputProps={{ ref: inputRef }}
          label="Please select target estimate"
        />
        <button
          type="button"
          aria-label="Toggle suggestion list"
          aria-expanded={showSuggestions}
          onClick={handleArrowClick}
          onKeyDown={handleArrowKeyDown}
          className="clone-estimate-suggestion-input__arrow"
          ref={arrowRef}
        >
          <KiteIcon name={showSuggestions ? 'chevron-down' : 'chevron-right'} />
        </button>
      </div>
      {showSuggestions && (
        <div className="clone-estimate-suggestion-input__results">
          {renderDropdownResults()}
        </div>
      )}
    </div>
  );
};

export default CloneEstimateSuggestionInput;
