// Packages
import React, { useCallback, useMemo, MutableRefObject, useRef } from 'react';

// Redux

// Components
import { DesignFlowNotes, UCQDesignFlow } from 'components/ucQuestions';
import { UcReviewLocationHeader } from 'components';

// Hooks
import { useParams } from 'react-router-dom';
import {
  useFlowNodes,
  useFormRules,
  useGetForms,
  useQueryData,
  useRenderReviewUcFields,
  useScrollToTop,
} from 'hooks';

// Utils

// Types
import {
  IField,
  IForm,
  ISubmission,
  IDistro,
  ILocation,
  IFieldValues,
} from 'types';

// Styles
import './UcReviewSubmissions.scss';
import FieldValidationHeader from 'components/FieldValidationHeader';
import { UCQFORMS } from 'utils';

export interface IUcReviewSubmissionsProps {
  opptyName: string;
  location: ILocation;
  forms: IForm[];
  submissions: ISubmission[];
  distros: IDistro[];
  refForPrint: MutableRefObject<HTMLDivElement | null>;
}

/** Displays submission data on the UcReviewPage */

const UcReviewSubmissions = ({
  opptyName,
  location,
  forms,
  submissions,
  distros,
  refForPrint,
}: IUcReviewSubmissionsProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { locationId } = useParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useScrollToTop({ ref: scrollRef, refNestLevel: 0, locationId });

  const fields = useMemo(
    () =>
      forms.reduce((acc: IField[], form) => {
        acc.push(...form.fields);
        return acc;
      }, []),
    [forms]
  );

  const { currentEstimate } = useQueryData();
  const formRules = useMemo(() => fields.map((f) => f.rules).flat(), [fields]);

  const fieldValues: IFieldValues = useMemo(() => {
    return fields.reduce((acc: IFieldValues, field) => {
      acc[field.id] =
        submissions.find((sub) => sub.fieldId === field.id)?.answer || null;
      return acc;
    }, {});
  }, [fields, submissions]);

  const { data: locationForms } = useGetForms({
    params: {
      location: 1,
      type: UCQFORMS,
    },
  });

  const estimateLocationIds = useMemo(
    () => currentEstimate?.locations.map((l) => l.id) || [],
    [currentEstimate]
  );

  const locationFields = useMemo(
    () =>
      locationForms?.map((locationForm) => locationForm.fields).flat() || [],
    [locationForms]
  );

  const locationFieldValues = useMemo(() => {
    return estimateLocationIds.reduce(
      (
        acc: { [locationId: string]: { [fieldId: string]: string | null } },
        locationId
      ) => {
        const fieldValues = locationFields.reduce(
          (acc: { [fieldId: string]: string | null }, field) => {
            const subMatch =
              submissions.find(
                (sub) =>
                  sub.locationId &&
                  sub.locationId === locationId &&
                  sub.fieldId === field.id
              )?.answer || null;
            acc[field.id] = subMatch;
            return acc;
          },
          {}
        );
        acc[locationId] = fieldValues;

        return acc;
      },
      {}
    );
  }, [estimateLocationIds, locationFields, submissions]);

  const { ruleMap, ruleKeys, locationRuleMap } = useFormRules({
    fieldValues,
    formRules,
    locationFieldValues,
  });

  const { renderForm } = useRenderReviewUcFields({
    location,
    ruleMap,
    ruleKeys,
    locationRuleMap,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const nodeSize = useMemo(() => ({ width: 200, height: 300 }), []);
  const { height: networkDraftHeight } = useFlowNodes({
    distros,
    nodeSize,
  });

  // =======================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const renderDesignFlow = useCallback(
    (name: string, fieldId: string) => {
      return !distros.length ? null : (
        <div
          className="uc-review-submissions__form uc-review-submissions__form--design-flow"
          key={fieldId}
        >
          <FieldValidationHeader label={name} />
          {!!distros.length && (
            <div
              style={{
                height: networkDraftHeight,
              }}
            >
              <UCQDesignFlow
                distros={distros}
                draggable={false}
                isFitView
                actionsAreEnabled={false}
              />
            </div>
          )}
          <DesignFlowNotes isOpen />
        </div>
      );
    },
    [distros, networkDraftHeight]
  );

  const renderAllFormSubmissions = useMemo(() => {
    const formSubmissions = () => {
      let generalDiscFormId = '';
      return forms.map(({ id, name, fields }) => {
        if (name === 'Network Draft') {
          return renderDesignFlow(name, id);
        } else if (name === 'General Discovery') {
          // General Discovery and location specific discovery rendered under Discovery
          generalDiscFormId = id;
          const currentFormSubs = submissions?.filter(
            (sub) =>
              (sub.formId === id && locationId === sub.locationId) ||
              sub.formId === generalDiscFormId
          );
          return renderForm(name, fields, currentFormSubs);
        } else {
          const currentFormSubs = submissions?.filter(
            (sub) => sub.formId === id && locationId === sub.locationId
          );
          return renderForm(name, fields, currentFormSubs);
        }
      });
    };
    return formSubmissions;
  }, [forms, renderDesignFlow, submissions, locationId, renderForm]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div
      className="uc-review-submissions"
      id="uc-review-submissions"
      ref={refForPrint}
    >
      <div className="uc-review-submissions__header-container">
        <UcReviewLocationHeader
          opptyName={opptyName}
          locationName={location.name}
          hasHistory
          hasProgress
          hasBadges
        />
      </div>
      <div className="uc-review-submissions__content" ref={scrollRef}>
        {renderAllFormSubmissions()}
      </div>
    </div>
  );
};

export default UcReviewSubmissions;
