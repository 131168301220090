// Packages
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

// Redux

// Components
import { KiteIcon } from '@kite/react-kite';

// Hooks
import { useQueryData } from 'hooks';

// Utils
import { RcQuestionIconsMap } from 'utils';

// Types
import { ISalesTeamMember, ISubmissionHistory } from 'types';

// Styles
import './RCQHistoryRow.scss';

export interface IRCQHistoryRowProps {
  historyData?: ISubmissionHistory | null;
  teamMember?: ISalesTeamMember;
  onClick?: () => void;
}

/** Row that displays a change in the RCQHistory drawer */

const RCQHistoryRow = ({
  historyData,
  teamMember,
  onClick,
}: IRCQHistoryRowProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const [imgError, setImgError] = useState(false);

  const { currentEstimate } = useQueryData();

  const {
    editedBy: pid,
    formName = '',
    fieldOrder,
    createdAt = '',
    locationId,
  } = historyData || ({} as ISubmissionHistory);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const locationName = useMemo(
    () =>
      currentEstimate?.locations.find((l) => l.id === locationId)?.name ||
      'General',
    [locationId, currentEstimate]
  );

  const { firstName, lastName } = teamMember || {
    firstName: pid,
    lastName: '',
  };

  const displayName = `${firstName} ${
    lastName.length ? lastName.split('')[0] + '.' : ''
  }`;

  const formatCreatedAt = dayjs(createdAt).format('hh:mm A');

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================
  const avatarImage = useMemo(() => {
    if (!teamMember?.imageURI || imgError)
      return (
        <KiteIcon
          name="account"
          className="rcq-history-row__avatar--generic"
          size="30px"
        />
      );
    return (
      <img
        src={teamMember.imageURI}
        alt={`${teamMember.fullName} avatar`}
        className="rcq-history-row__avatar"
        onError={() => setImgError(true)}
      />
    );
  }, [teamMember, imgError]);

  const rowContent = useMemo(
    () =>
      formName === 'Discovery Questions/Notes' || formName === 'General' ? (
        <div className="rcq-history-row__content">
          <div className="rcq-history-row__title">
            {avatarImage} <span>{displayName}</span>{' '}
            <span
              className="rcq-history-row__title--location"
              title={locationName}
            >
              {locationName}
            </span>
          </div>
          <div className="rcq-history-row__sub-title">
            <KiteIcon
              className="rcq-history-row__sub-title__icon"
              name={RcQuestionIconsMap[formName]}
            />
            <span>{formName}</span>
            <span className="rcq-history-row__sub-title--question-change">
              {fieldOrder === 1.1
                ? 'Spectrum Account Number Changed'
                : fieldOrder === 1.2
                ? 'Campaign code for Spectrum Voice porting Changed'
                : fieldOrder === 101
                ? 'Special Voice Solution Needs,Additional Solution Notes Changed'
                : fieldOrder === 100
                ? 'Current Solution Notes Changed'
                : `Question ${fieldOrder} changed`}
            </span>
            <span className="rcq-history-row__sub-title--time">
              {formatCreatedAt}
            </span>
          </div>
        </div>
      ) : null,
    [
      avatarImage,
      locationName,
      displayName,
      fieldOrder,
      formName,
      formatCreatedAt,
    ]
  );

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    rowContent && (
      <div className="rcq-history-row">
        {onClick ? <button onClick={onClick}>{rowContent}</button> : rowContent}
      </div>
    )
  );
};

export default RCQHistoryRow;
